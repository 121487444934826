import { signInWithCustomToken, User } from 'firebase/auth';
import { auth } from './setupFirebase';

export const loginToFirebase = (token: string) => {
  return new Promise<User>((resolve, reject) => {
    signInWithCustomToken(auth, token)
      .then(function (result) {
        // User signed in successfully.
        if (result.user) {
          resolve(result.user);
        } else {
          reject('User Not Found');
        }
      })
      .catch(function () {
        reject('Connecting to PopShop.Live Failed');
      });
  });
};
