import React, { memo, useState } from 'react';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Conatiner from './Conatiner';
import { reload } from '../Dispatcher';
import { shopifyApi } from '../../apis/services';
import { AnalyticEvents } from '../../generated/AnalyticEvents';
import { getUser } from '../../util/setupFirebase';

interface LocationSelectionProps {
  storeId: string;
  locations: {
    id: string;
    name: string;
  }[];
}

export default memo(function LocationSelection({
  storeId,
  locations,
}: LocationSelectionProps) {
  const user = getUser();
  const [value, setValue] = useState(
    locations.find(
      (location) =>
        'popshoplive' === location.name.replace(/\s+/g, '').toLowerCase()
    )?.id ?? locations[0].id
  );
  const [submiting, setSubmiting] = useState(false);

  const syncLocationIdToPopShop = async (id: string) => {
    if (!user) return;

    AnalyticEvents.default.shopifyLinkerInitializeSelectLocation(
      { id, sellerId: user.uid },
      {
        shopifyStoreId: storeId,
        setupLocationIdRequestDto: { locationId: id },
      }
    );

    try {
      await shopifyApi.shopifyApiControllerEditLocationId({
        sellerId: user.uid,
        shopifyStoreId: storeId,
        setupLocationIdRequestDto: { locationId: id },
      });
      reload();
    } catch (error) {
      setSubmiting(false);
    }
  };

  return (
    <Conatiner>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'auto',
        }}
      >
        <Typography variant="h4">Choose a location</Typography>
        <RadioGroup
          aria-label="gender"
          defaultValue="female"
          value={value}
          onChange={(data) => setValue(data.target.value)}
        >
          {locations.map((location) => (
            <FormControlLabel
              key={location.id}
              value={location.id}
              control={<Radio />}
              label={location.name}
            />
          ))}
        </RadioGroup>
        {submiting ? (
          <CircularProgress size={24} color="primary" />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => syncLocationIdToPopShop(value)}
          >
            Start
          </Button>
        )}
      </div>
    </Conatiner>
  );
});
