/* tslint:disable */
/* eslint-disable */
/**
 * PopshopLive API
 * PopshopLive API service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateOrderRequest,
    CustomTokenDto,
    GenerateCustomTokenRequest,
    LocationsDto,
    OneTimeUUIDDto,
    OneTimeUUIDRequestDto,
    PatchShopifyStoreRequest,
    QueryAllProductsResponse,
    QueryProductsResponseDto,
    SetupLocationIdRequestDto,
    SetupProductRequestDto,
    ShoifyStoresDto,
    ShoifyStoresWebhooksConfigStatusResponse,
    StoreListingItem,
    ValidationStoreDto,
} from '../models';

export interface ShopifyApiControllerConfigureFulfillmentsWebhooksRequest {
    sellerId: string;
    shopifyStoreId: string;
}

export interface ShopifyApiControllerConfigureProductsUpdateWebhookRequest {
    sellerId: string;
    shopifyStoreId: string;
}

export interface ShopifyApiControllerConfigureWebhooksRequest {
    sellerId: string;
    shopifyStoreId: string;
}

export interface ShopifyApiControllerEditLocationIdRequest {
    sellerId: string;
    shopifyStoreId: string;
    setupLocationIdRequestDto: SetupLocationIdRequestDto;
}

export interface ShopifyApiControllerEditProductRequest {
    sellerId: string;
    shopifyStoreId: string;
    inventoryLevelId: string;
    setupProductRequestDto: SetupProductRequestDto;
}

export interface ShopifyApiControllerGenerateCustomTokenRequest {
    generateCustomTokenRequest: GenerateCustomTokenRequest;
}

export interface ShopifyApiControllerGenerateOneTimeUUIDRequest {
    sellerId: string;
    oneTimeUUIDRequestDto: OneTimeUUIDRequestDto;
}

export interface ShopifyApiControllerIsWebhooksConfiguredRequest {
    sellerId: string;
    shopifyStoreId: string;
}

export interface ShopifyApiControllerPatchShopifyStoreRequest {
    sellerId: string;
    shopifyStoreId: string;
    patchShopifyStoreRequest: PatchShopifyStoreRequest;
}

export interface ShopifyApiControllerQueryAllProductsRequest {
    sellerId: string;
    shopifyStoreId: string;
    query?: string;
}

export interface ShopifyApiControllerQueryLocationsRequest {
    sellerId: string;
    shopifyStoreId: string;
}

export interface ShopifyApiControllerQueryProductRequest {
    sellerId: string;
    shopifyStoreId: string;
    productId: string;
}

export interface ShopifyApiControllerQueryProductsRequest {
    sellerId: string;
    shopifyStoreId: string;
    first?: number;
    after?: string;
    query?: string;
}

export interface ShopifyApiControllerQueryStoresRequest {
    sellerId: string;
}

export interface ShopifyApiControllerRemoveAccessTokenRequest {
    sellerId: string;
    shopifyStoreId: string;
}

export interface ShopifyApiControllerRemoveFulfillmentsWebhooksRequest {
    sellerId: string;
    shopifyStoreId: string;
}

export interface ShopifyApiControllerRemoveProductsUpdateWebhookRequest {
    sellerId: string;
    shopifyStoreId: string;
}

export interface ShopifyApiControllerRemoveWebhooksConfiguresRequest {
    sellerId: string;
    shopifyStoreId: string;
}

export interface ShopifyApiControllerSyncOrderRequest {
    sellerId: string;
    masterOrderId: string;
}

export interface ShopifyApiControllerSyncOrdersRequest {
    sellerId: string;
    createOrderRequest: CreateOrderRequest;
}

export interface ShopifyApiControllerValidateStoreRequest {
    sellerId: string;
    shopifyStoreId: string;
}

/**
 * 
 */
export class ShopifyApi extends runtime.BaseAPI {

    /**
     */
    async shopifyApiControllerConfigureFulfillmentsWebhooksRaw(requestParameters: ShopifyApiControllerConfigureFulfillmentsWebhooksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling shopifyApiControllerConfigureFulfillmentsWebhooks.');
        }

        if (requestParameters.shopifyStoreId === null || requestParameters.shopifyStoreId === undefined) {
            throw new runtime.RequiredError('shopifyStoreId','Required parameter requestParameters.shopifyStoreId was null or undefined when calling shopifyApiControllerConfigureFulfillmentsWebhooks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/shopify/{shopifyStoreId}/webhooks/fulfillments`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"shopifyStoreId"}}`, encodeURIComponent(String(requestParameters.shopifyStoreId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async shopifyApiControllerConfigureFulfillmentsWebhooks(requestParameters: ShopifyApiControllerConfigureFulfillmentsWebhooksRequest, initOverrides?: RequestInit): Promise<void> {
        await this.shopifyApiControllerConfigureFulfillmentsWebhooksRaw(requestParameters, initOverrides);
    }

    /**
     */
    async shopifyApiControllerConfigureProductsUpdateWebhookRaw(requestParameters: ShopifyApiControllerConfigureProductsUpdateWebhookRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling shopifyApiControllerConfigureProductsUpdateWebhook.');
        }

        if (requestParameters.shopifyStoreId === null || requestParameters.shopifyStoreId === undefined) {
            throw new runtime.RequiredError('shopifyStoreId','Required parameter requestParameters.shopifyStoreId was null or undefined when calling shopifyApiControllerConfigureProductsUpdateWebhook.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/shopify/{shopifyStoreId}/webhooks/products-update`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"shopifyStoreId"}}`, encodeURIComponent(String(requestParameters.shopifyStoreId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async shopifyApiControllerConfigureProductsUpdateWebhook(requestParameters: ShopifyApiControllerConfigureProductsUpdateWebhookRequest, initOverrides?: RequestInit): Promise<void> {
        await this.shopifyApiControllerConfigureProductsUpdateWebhookRaw(requestParameters, initOverrides);
    }

    /**
     */
    async shopifyApiControllerConfigureWebhooksRaw(requestParameters: ShopifyApiControllerConfigureWebhooksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling shopifyApiControllerConfigureWebhooks.');
        }

        if (requestParameters.shopifyStoreId === null || requestParameters.shopifyStoreId === undefined) {
            throw new runtime.RequiredError('shopifyStoreId','Required parameter requestParameters.shopifyStoreId was null or undefined when calling shopifyApiControllerConfigureWebhooks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/shopify/{shopifyStoreId}/webhooks`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"shopifyStoreId"}}`, encodeURIComponent(String(requestParameters.shopifyStoreId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async shopifyApiControllerConfigureWebhooks(requestParameters: ShopifyApiControllerConfigureWebhooksRequest, initOverrides?: RequestInit): Promise<void> {
        await this.shopifyApiControllerConfigureWebhooksRaw(requestParameters, initOverrides);
    }

    /**
     */
    async shopifyApiControllerEditLocationIdRaw(requestParameters: ShopifyApiControllerEditLocationIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling shopifyApiControllerEditLocationId.');
        }

        if (requestParameters.shopifyStoreId === null || requestParameters.shopifyStoreId === undefined) {
            throw new runtime.RequiredError('shopifyStoreId','Required parameter requestParameters.shopifyStoreId was null or undefined when calling shopifyApiControllerEditLocationId.');
        }

        if (requestParameters.setupLocationIdRequestDto === null || requestParameters.setupLocationIdRequestDto === undefined) {
            throw new runtime.RequiredError('setupLocationIdRequestDto','Required parameter requestParameters.setupLocationIdRequestDto was null or undefined when calling shopifyApiControllerEditLocationId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/shopify/{shopifyStoreId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"shopifyStoreId"}}`, encodeURIComponent(String(requestParameters.shopifyStoreId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setupLocationIdRequestDto,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async shopifyApiControllerEditLocationId(requestParameters: ShopifyApiControllerEditLocationIdRequest, initOverrides?: RequestInit): Promise<void> {
        await this.shopifyApiControllerEditLocationIdRaw(requestParameters, initOverrides);
    }

    /**
     */
    async shopifyApiControllerEditProductRaw(requestParameters: ShopifyApiControllerEditProductRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling shopifyApiControllerEditProduct.');
        }

        if (requestParameters.shopifyStoreId === null || requestParameters.shopifyStoreId === undefined) {
            throw new runtime.RequiredError('shopifyStoreId','Required parameter requestParameters.shopifyStoreId was null or undefined when calling shopifyApiControllerEditProduct.');
        }

        if (requestParameters.inventoryLevelId === null || requestParameters.inventoryLevelId === undefined) {
            throw new runtime.RequiredError('inventoryLevelId','Required parameter requestParameters.inventoryLevelId was null or undefined when calling shopifyApiControllerEditProduct.');
        }

        if (requestParameters.setupProductRequestDto === null || requestParameters.setupProductRequestDto === undefined) {
            throw new runtime.RequiredError('setupProductRequestDto','Required parameter requestParameters.setupProductRequestDto was null or undefined when calling shopifyApiControllerEditProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/shopify/{shopifyStoreId}/inventories/{inventoryLevelId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"shopifyStoreId"}}`, encodeURIComponent(String(requestParameters.shopifyStoreId))).replace(`{${"inventoryLevelId"}}`, encodeURIComponent(String(requestParameters.inventoryLevelId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setupProductRequestDto,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async shopifyApiControllerEditProduct(requestParameters: ShopifyApiControllerEditProductRequest, initOverrides?: RequestInit): Promise<void> {
        await this.shopifyApiControllerEditProductRaw(requestParameters, initOverrides);
    }

    /**
     */
    async shopifyApiControllerGenerateCustomTokenRaw(requestParameters: ShopifyApiControllerGenerateCustomTokenRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomTokenDto>> {
        if (requestParameters.generateCustomTokenRequest === null || requestParameters.generateCustomTokenRequest === undefined) {
            throw new runtime.RequiredError('generateCustomTokenRequest','Required parameter requestParameters.generateCustomTokenRequest was null or undefined when calling shopifyApiControllerGenerateCustomToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/shopify:generate-custom-token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.generateCustomTokenRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async shopifyApiControllerGenerateCustomToken(requestParameters: ShopifyApiControllerGenerateCustomTokenRequest, initOverrides?: RequestInit): Promise<CustomTokenDto> {
        const response = await this.shopifyApiControllerGenerateCustomTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async shopifyApiControllerGenerateOneTimeUUIDRaw(requestParameters: ShopifyApiControllerGenerateOneTimeUUIDRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OneTimeUUIDDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling shopifyApiControllerGenerateOneTimeUUID.');
        }

        if (requestParameters.oneTimeUUIDRequestDto === null || requestParameters.oneTimeUUIDRequestDto === undefined) {
            throw new runtime.RequiredError('oneTimeUUIDRequestDto','Required parameter requestParameters.oneTimeUUIDRequestDto was null or undefined when calling shopifyApiControllerGenerateOneTimeUUID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/shopify:generate-uuid`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.oneTimeUUIDRequestDto,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async shopifyApiControllerGenerateOneTimeUUID(requestParameters: ShopifyApiControllerGenerateOneTimeUUIDRequest, initOverrides?: RequestInit): Promise<OneTimeUUIDDto> {
        const response = await this.shopifyApiControllerGenerateOneTimeUUIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async shopifyApiControllerIsWebhooksConfiguredRaw(requestParameters: ShopifyApiControllerIsWebhooksConfiguredRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ShoifyStoresWebhooksConfigStatusResponse>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling shopifyApiControllerIsWebhooksConfigured.');
        }

        if (requestParameters.shopifyStoreId === null || requestParameters.shopifyStoreId === undefined) {
            throw new runtime.RequiredError('shopifyStoreId','Required parameter requestParameters.shopifyStoreId was null or undefined when calling shopifyApiControllerIsWebhooksConfigured.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/shopify/{shopifyStoreId}/webhooks:config-status`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"shopifyStoreId"}}`, encodeURIComponent(String(requestParameters.shopifyStoreId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async shopifyApiControllerIsWebhooksConfigured(requestParameters: ShopifyApiControllerIsWebhooksConfiguredRequest, initOverrides?: RequestInit): Promise<ShoifyStoresWebhooksConfigStatusResponse> {
        const response = await this.shopifyApiControllerIsWebhooksConfiguredRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async shopifyApiControllerPatchShopifyStoreRaw(requestParameters: ShopifyApiControllerPatchShopifyStoreRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling shopifyApiControllerPatchShopifyStore.');
        }

        if (requestParameters.shopifyStoreId === null || requestParameters.shopifyStoreId === undefined) {
            throw new runtime.RequiredError('shopifyStoreId','Required parameter requestParameters.shopifyStoreId was null or undefined when calling shopifyApiControllerPatchShopifyStore.');
        }

        if (requestParameters.patchShopifyStoreRequest === null || requestParameters.patchShopifyStoreRequest === undefined) {
            throw new runtime.RequiredError('patchShopifyStoreRequest','Required parameter requestParameters.patchShopifyStoreRequest was null or undefined when calling shopifyApiControllerPatchShopifyStore.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/shopify/{shopifyStoreId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"shopifyStoreId"}}`, encodeURIComponent(String(requestParameters.shopifyStoreId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.patchShopifyStoreRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async shopifyApiControllerPatchShopifyStore(requestParameters: ShopifyApiControllerPatchShopifyStoreRequest, initOverrides?: RequestInit): Promise<void> {
        await this.shopifyApiControllerPatchShopifyStoreRaw(requestParameters, initOverrides);
    }

    /**
     */
    async shopifyApiControllerQueryAllProductsRaw(requestParameters: ShopifyApiControllerQueryAllProductsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<QueryAllProductsResponse>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling shopifyApiControllerQueryAllProducts.');
        }

        if (requestParameters.shopifyStoreId === null || requestParameters.shopifyStoreId === undefined) {
            throw new runtime.RequiredError('shopifyStoreId','Required parameter requestParameters.shopifyStoreId was null or undefined when calling shopifyApiControllerQueryAllProducts.');
        }

        const queryParameters: any = {};

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/shopify/{shopifyStoreId}/all-products`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"shopifyStoreId"}}`, encodeURIComponent(String(requestParameters.shopifyStoreId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async shopifyApiControllerQueryAllProducts(requestParameters: ShopifyApiControllerQueryAllProductsRequest, initOverrides?: RequestInit): Promise<QueryAllProductsResponse> {
        const response = await this.shopifyApiControllerQueryAllProductsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async shopifyApiControllerQueryLocationsRaw(requestParameters: ShopifyApiControllerQueryLocationsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LocationsDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling shopifyApiControllerQueryLocations.');
        }

        if (requestParameters.shopifyStoreId === null || requestParameters.shopifyStoreId === undefined) {
            throw new runtime.RequiredError('shopifyStoreId','Required parameter requestParameters.shopifyStoreId was null or undefined when calling shopifyApiControllerQueryLocations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/shopify/{shopifyStoreId}/locations`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"shopifyStoreId"}}`, encodeURIComponent(String(requestParameters.shopifyStoreId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async shopifyApiControllerQueryLocations(requestParameters: ShopifyApiControllerQueryLocationsRequest, initOverrides?: RequestInit): Promise<LocationsDto> {
        const response = await this.shopifyApiControllerQueryLocationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async shopifyApiControllerQueryProductRaw(requestParameters: ShopifyApiControllerQueryProductRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StoreListingItem>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling shopifyApiControllerQueryProduct.');
        }

        if (requestParameters.shopifyStoreId === null || requestParameters.shopifyStoreId === undefined) {
            throw new runtime.RequiredError('shopifyStoreId','Required parameter requestParameters.shopifyStoreId was null or undefined when calling shopifyApiControllerQueryProduct.');
        }

        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling shopifyApiControllerQueryProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/shopify/{shopifyStoreId}/products/{productId}`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"shopifyStoreId"}}`, encodeURIComponent(String(requestParameters.shopifyStoreId))).replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters.productId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async shopifyApiControllerQueryProduct(requestParameters: ShopifyApiControllerQueryProductRequest, initOverrides?: RequestInit): Promise<StoreListingItem> {
        const response = await this.shopifyApiControllerQueryProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async shopifyApiControllerQueryProductsRaw(requestParameters: ShopifyApiControllerQueryProductsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<QueryProductsResponseDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling shopifyApiControllerQueryProducts.');
        }

        if (requestParameters.shopifyStoreId === null || requestParameters.shopifyStoreId === undefined) {
            throw new runtime.RequiredError('shopifyStoreId','Required parameter requestParameters.shopifyStoreId was null or undefined when calling shopifyApiControllerQueryProducts.');
        }

        const queryParameters: any = {};

        if (requestParameters.first !== undefined) {
            queryParameters['first'] = requestParameters.first;
        }

        if (requestParameters.after !== undefined) {
            queryParameters['after'] = requestParameters.after;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/shopify/{shopifyStoreId}/products`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"shopifyStoreId"}}`, encodeURIComponent(String(requestParameters.shopifyStoreId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async shopifyApiControllerQueryProducts(requestParameters: ShopifyApiControllerQueryProductsRequest, initOverrides?: RequestInit): Promise<QueryProductsResponseDto> {
        const response = await this.shopifyApiControllerQueryProductsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async shopifyApiControllerQueryStoresRaw(requestParameters: ShopifyApiControllerQueryStoresRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ShoifyStoresDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling shopifyApiControllerQueryStores.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/shopify/stores`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async shopifyApiControllerQueryStores(requestParameters: ShopifyApiControllerQueryStoresRequest, initOverrides?: RequestInit): Promise<ShoifyStoresDto> {
        const response = await this.shopifyApiControllerQueryStoresRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async shopifyApiControllerRemoveAccessTokenRaw(requestParameters: ShopifyApiControllerRemoveAccessTokenRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling shopifyApiControllerRemoveAccessToken.');
        }

        if (requestParameters.shopifyStoreId === null || requestParameters.shopifyStoreId === undefined) {
            throw new runtime.RequiredError('shopifyStoreId','Required parameter requestParameters.shopifyStoreId was null or undefined when calling shopifyApiControllerRemoveAccessToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/shopify/{shopifyStoreId}:remove-access-token`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"shopifyStoreId"}}`, encodeURIComponent(String(requestParameters.shopifyStoreId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async shopifyApiControllerRemoveAccessToken(requestParameters: ShopifyApiControllerRemoveAccessTokenRequest, initOverrides?: RequestInit): Promise<void> {
        await this.shopifyApiControllerRemoveAccessTokenRaw(requestParameters, initOverrides);
    }

    /**
     */
    async shopifyApiControllerRemoveFulfillmentsWebhooksRaw(requestParameters: ShopifyApiControllerRemoveFulfillmentsWebhooksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling shopifyApiControllerRemoveFulfillmentsWebhooks.');
        }

        if (requestParameters.shopifyStoreId === null || requestParameters.shopifyStoreId === undefined) {
            throw new runtime.RequiredError('shopifyStoreId','Required parameter requestParameters.shopifyStoreId was null or undefined when calling shopifyApiControllerRemoveFulfillmentsWebhooks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/shopify/{shopifyStoreId}/webhooks/fulfillments`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"shopifyStoreId"}}`, encodeURIComponent(String(requestParameters.shopifyStoreId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async shopifyApiControllerRemoveFulfillmentsWebhooks(requestParameters: ShopifyApiControllerRemoveFulfillmentsWebhooksRequest, initOverrides?: RequestInit): Promise<void> {
        await this.shopifyApiControllerRemoveFulfillmentsWebhooksRaw(requestParameters, initOverrides);
    }

    /**
     */
    async shopifyApiControllerRemoveProductsUpdateWebhookRaw(requestParameters: ShopifyApiControllerRemoveProductsUpdateWebhookRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling shopifyApiControllerRemoveProductsUpdateWebhook.');
        }

        if (requestParameters.shopifyStoreId === null || requestParameters.shopifyStoreId === undefined) {
            throw new runtime.RequiredError('shopifyStoreId','Required parameter requestParameters.shopifyStoreId was null or undefined when calling shopifyApiControllerRemoveProductsUpdateWebhook.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/shopify/{shopifyStoreId}/webhooks/products-update`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"shopifyStoreId"}}`, encodeURIComponent(String(requestParameters.shopifyStoreId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async shopifyApiControllerRemoveProductsUpdateWebhook(requestParameters: ShopifyApiControllerRemoveProductsUpdateWebhookRequest, initOverrides?: RequestInit): Promise<void> {
        await this.shopifyApiControllerRemoveProductsUpdateWebhookRaw(requestParameters, initOverrides);
    }

    /**
     */
    async shopifyApiControllerRemoveWebhooksConfiguresRaw(requestParameters: ShopifyApiControllerRemoveWebhooksConfiguresRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling shopifyApiControllerRemoveWebhooksConfigures.');
        }

        if (requestParameters.shopifyStoreId === null || requestParameters.shopifyStoreId === undefined) {
            throw new runtime.RequiredError('shopifyStoreId','Required parameter requestParameters.shopifyStoreId was null or undefined when calling shopifyApiControllerRemoveWebhooksConfigures.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/shopify/{shopifyStoreId}/webhooks`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"shopifyStoreId"}}`, encodeURIComponent(String(requestParameters.shopifyStoreId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async shopifyApiControllerRemoveWebhooksConfigures(requestParameters: ShopifyApiControllerRemoveWebhooksConfiguresRequest, initOverrides?: RequestInit): Promise<void> {
        await this.shopifyApiControllerRemoveWebhooksConfiguresRaw(requestParameters, initOverrides);
    }

    /**
     */
    async shopifyApiControllerSetupAccessTokenRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/shopify:setup-access-token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async shopifyApiControllerSetupAccessToken(initOverrides?: RequestInit): Promise<void> {
        await this.shopifyApiControllerSetupAccessTokenRaw(initOverrides);
    }

    /**
     */
    async shopifyApiControllerSyncOrderRaw(requestParameters: ShopifyApiControllerSyncOrderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling shopifyApiControllerSyncOrder.');
        }

        if (requestParameters.masterOrderId === null || requestParameters.masterOrderId === undefined) {
            throw new runtime.RequiredError('masterOrderId','Required parameter requestParameters.masterOrderId was null or undefined when calling shopifyApiControllerSyncOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/orders/{masterOrderId}:sync-to-shopify`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"masterOrderId"}}`, encodeURIComponent(String(requestParameters.masterOrderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async shopifyApiControllerSyncOrder(requestParameters: ShopifyApiControllerSyncOrderRequest, initOverrides?: RequestInit): Promise<void> {
        await this.shopifyApiControllerSyncOrderRaw(requestParameters, initOverrides);
    }

    /**
     */
    async shopifyApiControllerSyncOrdersRaw(requestParameters: ShopifyApiControllerSyncOrdersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling shopifyApiControllerSyncOrders.');
        }

        if (requestParameters.createOrderRequest === null || requestParameters.createOrderRequest === undefined) {
            throw new runtime.RequiredError('createOrderRequest','Required parameter requestParameters.createOrderRequest was null or undefined when calling shopifyApiControllerSyncOrders.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/orders:sync-to-shopify`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createOrderRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async shopifyApiControllerSyncOrders(requestParameters: ShopifyApiControllerSyncOrdersRequest, initOverrides?: RequestInit): Promise<void> {
        await this.shopifyApiControllerSyncOrdersRaw(requestParameters, initOverrides);
    }

    /**
     */
    async shopifyApiControllerValidateStoreRaw(requestParameters: ShopifyApiControllerValidateStoreRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ValidationStoreDto>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling shopifyApiControllerValidateStore.');
        }

        if (requestParameters.shopifyStoreId === null || requestParameters.shopifyStoreId === undefined) {
            throw new runtime.RequiredError('shopifyStoreId','Required parameter requestParameters.shopifyStoreId was null or undefined when calling shopifyApiControllerValidateStore.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/shopify/{shopifyStoreId}:validate`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))).replace(`{${"shopifyStoreId"}}`, encodeURIComponent(String(requestParameters.shopifyStoreId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async shopifyApiControllerValidateStore(requestParameters: ShopifyApiControllerValidateStoreRequest, initOverrides?: RequestInit): Promise<ValidationStoreDto> {
        const response = await this.shopifyApiControllerValidateStoreRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
