import apiConfig from '../../config/apiConfig.json';
import { Configuration } from '../../generated/api';
import BearerAuthMiddleware from './middlewares/BearerAuthMiddleware';
import ErrorMiddleware from './middlewares/ErrorMiddleware';

export default new Configuration({
  // basePath: 'http://localhost:8080',
  basePath: apiConfig.apiEndpoint,
  middleware: [new BearerAuthMiddleware(), new ErrorMiddleware()],
});
