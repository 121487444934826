import { collection, query, onSnapshot, where } from 'firebase/firestore';
import React, { useState } from 'react';
import { PopProduct } from '../types/products';
import { db, getUser } from './setupFirebase';

export const readStoreListing = async () => {
  return new Promise<PopProduct[]>((resovle, reject) => {
    const user = getUser();

    if (user) {
      const q = query(collection(db, `/stores/${user.uid}/listings`));
      onSnapshot(q, (snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data() as PopProduct);
        resovle(data);
      });
    } else {
      reject('fail to read store listing');
    }
  });
};

interface Result {
  data: PopProduct[];
  loading?: boolean;
  error?: string;
}

export const useReadStoreListing = (storeId: string) => {
  const user = getUser();
  const [result, setResult] = useState<Result>({
    data: [] as PopProduct[],
    loading: true,
  });

  React.useEffect(() => {
    if (!user) {
      setResult({ data: [], error: 'fail to read store listing' });
      return;
    }

    const q = query(
      collection(db, `/stores/${user.uid}/listings`),
      where('originalInfo.type', '==', 'shopify')
    );
    const cancel = onSnapshot(
      q,
      (snapshot) => {
        const data = snapshot.docs.map(
          (doc) => ({ id: doc.id, ...doc.data() } as PopProduct)
        );
        setResult({ data });
      },
      (error) => {
        setResult({ data: [], error: error.message });
      }
    );

    return () => {
      cancel && cancel();
    };
  }, [user, storeId]);

  return result;
};
