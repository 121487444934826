/* tslint:disable */
/* eslint-disable */
/**
 * 
 * @export
 * @interface ActiveFeaturedShowsRequest
 */
export interface ActiveFeaturedShowsRequest {
    /**
     * 
     * @type {string}
     * @memberof ActiveFeaturedShowsRequest
     */
    eventId?: string;
}
/**
 * 
 * @export
 * @interface AddListingsToLiveStreamInBatchDto
 */
export interface AddListingsToLiveStreamInBatchDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddListingsToLiveStreamInBatchDto
     */
    listingIds: Array<string>;
}
/**
 * 
 * @export
 * @interface AddListingsToLiveStreamInBatchResponse
 */
export interface AddListingsToLiveStreamInBatchResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddListingsToLiveStreamInBatchResponse
     */
    successfulListingIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddListingsToLiveStreamInBatchResponse
     */
    nonexistentListingIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddListingsToLiveStreamInBatchResponse
     */
    autoRemovedListingIdsOnSoldOut: Array<string>;
}
/**
 * 
 * @export
 * @interface AddLiveStreamListingRequest
 */
export interface AddLiveStreamListingRequest {
    /**
     * 
     * @type {string}
     * @memberof AddLiveStreamListingRequest
     */
    listingId: string;
}
/**
 * 
 * @export
 * @interface AddPaymentMethodRequest
 */
export interface AddPaymentMethodRequest {
    /**
     * 
     * @type {string}
     * @memberof AddPaymentMethodRequest
     */
    nonce: string;
    /**
     * 
     * @type {string}
     * @memberof AddPaymentMethodRequest
     */
    name: string;
}
/**
 * 
 * @export
 * @interface AddPaymentMethodRequestV2
 */
export interface AddPaymentMethodRequestV2 {
    /**
     * 
     * @type {string}
     * @memberof AddPaymentMethodRequestV2
     */
    nonce: string;
    /**
     * 
     * @type {string}
     * @memberof AddPaymentMethodRequestV2
     */
    name: string;
    /**
     * 
     * @type {BillingAddressDto}
     * @memberof AddPaymentMethodRequestV2
     */
    billingAddress: BillingAddressDto;
}
/**
 * 
 * @export
 * @interface AddPayoutRequest
 */
export interface AddPayoutRequest {
    /**
     * 
     * @type {string}
     * @memberof AddPayoutRequest
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof AddPayoutRequest
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof AddPayoutRequest
     */
    status: AddPayoutRequestStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum AddPayoutRequestStatusEnum {
    Pending = 'Pending',
    Processed = 'Processed',
    Completed = 'Completed',
    Failed = 'Failed'
}
/**
 * 
 * @export
 * @interface AddUserToGroupRequest
 */
export interface AddUserToGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof AddUserToGroupRequest
     */
    userId: string;
}
/**
 * 
 * @export
 * @interface AddUserToLiveStreamCommentDenylist
 */
export interface AddUserToLiveStreamCommentDenylist {
    /**
     * 
     * @type {string}
     * @memberof AddUserToLiveStreamCommentDenylist
     */
    reason: AddUserToLiveStreamCommentDenylistReasonEnum;
}

/**
* @export
* @enum {string}
*/
export enum AddUserToLiveStreamCommentDenylistReasonEnum {
    Spam = 'spam',
    Inappropriate = 'inappropriate',
    Aggressive = 'aggressive'
}
/**
 * 
 * @export
 * @interface AddUserToOrderDenylistRequest
 */
export interface AddUserToOrderDenylistRequest {
    /**
     * 
     * @type {string}
     * @memberof AddUserToOrderDenylistRequest
     */
    userId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddUserToOrderDenylistRequest
     */
    sellerIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AddUserToOrderDenylistRequest
     */
    reason: AddUserToOrderDenylistRequestReasonEnum;
}

/**
* @export
* @enum {string}
*/
export enum AddUserToOrderDenylistRequestReasonEnum {
    Fraud = 'fraud',
    Chargebacks = 'chargebacks',
    MultipleAccounts = 'multipleAccounts',
    InappropriateBehavior = 'inappropriateBehavior'
}
/**
 * 
 * @export
 * @interface AddUserToShowEventCommentDenylist
 */
export interface AddUserToShowEventCommentDenylist {
    /**
     * 
     * @type {string}
     * @memberof AddUserToShowEventCommentDenylist
     */
    reason: AddUserToShowEventCommentDenylistReasonEnum;
}

/**
* @export
* @enum {string}
*/
export enum AddUserToShowEventCommentDenylistReasonEnum {
    Spam = 'spam',
    Inappropriate = 'inappropriate',
    Aggressive = 'aggressive'
}
/**
 * 
 * @export
 * @interface AddUserToUpcomingShowCommentDenylist
 */
export interface AddUserToUpcomingShowCommentDenylist {
    /**
     * 
     * @type {string}
     * @memberof AddUserToUpcomingShowCommentDenylist
     */
    reason: AddUserToUpcomingShowCommentDenylistReasonEnum;
}

/**
* @export
* @enum {string}
*/
export enum AddUserToUpcomingShowCommentDenylistReasonEnum {
    Spam = 'spam',
    Inappropriate = 'inappropriate',
    Aggressive = 'aggressive'
}
/**
 * 
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    primaryPhone: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    line1: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    compareHash?: string;
}
/**
 * 
 * @export
 * @interface AddressItem
 */
export interface AddressItem {
    /**
     * 
     * @type {string}
     * @memberof AddressItem
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AddressItem
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AddressItem
     */
    primaryPhone: string;
    /**
     * 
     * @type {string}
     * @memberof AddressItem
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AddressItem
     */
    line1: string;
    /**
     * 
     * @type {string}
     * @memberof AddressItem
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressItem
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof AddressItem
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof AddressItem
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressItem
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof AddressItem
     */
    compareHash?: string;
}
/**
 * 
 * @export
 * @interface AddressRequest
 */
export interface AddressRequest {
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    primaryPhone: string;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    line1: string;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressRequest
     */
    postalCode: string;
}
/**
 * 
 * @export
 * @interface AdminPromoteRequest
 */
export interface AdminPromoteRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminPromoteRequest
     */
    password: string;
}
/**
 * 
 * @export
 * @interface Analytics
 */
export interface Analytics {
    /**
     * 
     * @type {GooglePlayAnalytics}
     * @memberof Analytics
     */
    googlePlay?: GooglePlayAnalytics;
    /**
     * 
     * @type {ItunesConnectAnalytics}
     * @memberof Analytics
     */
    itunesConnect?: ItunesConnectAnalytics;
}
/**
 * 
 * @export
 * @interface AndroidConfig
 */
export interface AndroidConfig {
    /**
     * 
     * @type {string}
     * @memberof AndroidConfig
     */
    afl?: string;
    /**
     * 
     * @type {string}
     * @memberof AndroidConfig
     */
    amv?: string;
}
/**
 * 
 * @export
 * @interface AppSellerApplicationItem
 */
export interface AppSellerApplicationItem {
    /**
     * 
     * @type {string}
     * @memberof AppSellerApplicationItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AppSellerApplicationItem
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSellerApplicationItem
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSellerApplicationItem
     */
    whereOnline?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSellerApplicationItem
     */
    whatSell?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSellerApplicationItem
     */
    whatExperienceProducts?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSellerApplicationItem
     */
    whatLiveStream?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSellerApplicationItem
     */
    howHearUs?: string;
    /**
     * 
     * @type {AddressItem}
     * @memberof AppSellerApplicationItem
     */
    sellerAddress?: AddressItem;
    /**
     * 
     * @type {string}
     * @memberof AppSellerApplicationItem
     */
    storeName?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSellerApplicationItem
     */
    storeDesc?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSellerApplicationItem
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSellerApplicationItem
     */
    social?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSellerApplicationItem
     */
    sellerDesc?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSellerApplicationItem
     */
    sellerHasPhysicalStore?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSellerApplicationItem
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof AppSellerApplicationItem
     */
    status: AppSellerApplicationItemStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AppSellerApplicationItem
     */
    userId: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof AppSellerApplicationItem
     */
    profile: ProfileItem;
    /**
     * 
     * @type {string}
     * @memberof AppSellerApplicationItem
     */
    createdAt: string;
}

/**
* @export
* @enum {string}
*/
export enum AppSellerApplicationItemStatusEnum {
    Submitted = 'submitted',
    Approved = 'approved',
    Rejected = 'rejected'
}
/**
 * 
 * @export
 * @interface AppSellerApplicationPageRows
 */
export interface AppSellerApplicationPageRows {
    /**
     * 
     * @type {Array<AppSellerApplicationItem>}
     * @memberof AppSellerApplicationPageRows
     */
    rows: Array<AppSellerApplicationItem>;
}
/**
 * 
 * @export
 * @interface AppSellerApplicationsSearchResponse
 */
export interface AppSellerApplicationsSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof AppSellerApplicationsSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof AppSellerApplicationsSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {AppSellerApplicationPageRows}
     * @memberof AppSellerApplicationsSearchResponse
     */
    data: AppSellerApplicationPageRows;
}
/**
 * 
 * @export
 * @interface ApplyChangePayoutMethodDto
 */
export interface ApplyChangePayoutMethodDto {
    /**
     * 
     * @type {PayoutBankDto}
     * @memberof ApplyChangePayoutMethodDto
     */
    payoutMethodBank: PayoutBankDto;
}
/**
 * 
 * @export
 * @interface ApproveWithdrawTransactionDto
 */
export interface ApproveWithdrawTransactionDto {
    /**
     * 
     * @type {string}
     * @memberof ApproveWithdrawTransactionDto
     */
    note?: string;
}
/**
 * 
 * @export
 * @interface AssignCouponsToUserRequest
 */
export interface AssignCouponsToUserRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignCouponsToUserRequest
     */
    userId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignCouponsToUserRequest
     */
    couponIds: Array<string>;
}
/**
 * 
 * @export
 * @interface AuctionTimer
 */
export interface AuctionTimer {
    /**
     * 
     * @type {number}
     * @memberof AuctionTimer
     */
    durationSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof AuctionTimer
     */
    countdownSeconds: number;
}
/**
 * 
 * @export
 * @interface AuditChangePayoutMethodDto
 */
export interface AuditChangePayoutMethodDto {
    /**
     * 
     * @type {string}
     * @memberof AuditChangePayoutMethodDto
     */
    payoutHistoryId: string;
}
/**
 * 
 * @export
 * @interface AvailableAssignToUserCouponsItem
 */
export interface AvailableAssignToUserCouponsItem {
    /**
     * 
     * @type {string}
     * @memberof AvailableAssignToUserCouponsItem
     */
    couponId: string;
    /**
     * 
     * @type {CouponItem}
     * @memberof AvailableAssignToUserCouponsItem
     */
    coupon: CouponItem;
}
/**
 * 
 * @export
 * @interface AvailableAssignToUserCouponsResponse
 */
export interface AvailableAssignToUserCouponsResponse {
    /**
     * 
     * @type {Array<AvailableAssignToUserCouponsItem>}
     * @memberof AvailableAssignToUserCouponsResponse
     */
    coupons: Array<AvailableAssignToUserCouponsItem>;
}
/**
 * 
 * @export
 * @interface AvailableCouponDto
 */
export interface AvailableCouponDto {
    /**
     * 
     * @type {string}
     * @memberof AvailableCouponDto
     */
    couponId: string;
    /**
     * not null if coupon is redeemed and not used
     * @type {string}
     * @memberof AvailableCouponDto
     */
    redeemedCouponId?: string;
    /**
     * 
     * @type {CouponItem}
     * @memberof AvailableCouponDto
     */
    coupon: CouponItem;
    /**
     * 
     * @type {string}
     * @memberof AvailableCouponDto
     */
    redeemStatus: AvailableCouponDtoRedeemStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AvailableCouponDto
     */
    reason?: string;
}

/**
* @export
* @enum {string}
*/
export enum AvailableCouponDtoRedeemStatusEnum {
    Redeemed = 'redeemed',
    Redeemable = 'redeemable',
    Nonredeemable = 'nonredeemable'
}
/**
 * 
 * @export
 * @interface AvailableCouponsResponseDto
 */
export interface AvailableCouponsResponseDto {
    /**
     * 
     * @type {number}
     * @memberof AvailableCouponsResponseDto
     */
    total: number;
    /**
     * 
     * @type {boolean}
     * @memberof AvailableCouponsResponseDto
     */
    isFirstPurchaseUser: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AvailableCouponsResponseDto
     */
    isNewUser: boolean;
    /**
     * 
     * @type {Array<AvailableCouponDto>}
     * @memberof AvailableCouponsResponseDto
     */
    coupons: Array<AvailableCouponDto>;
}
/**
 * 
 * @export
 * @interface BannerLiveStreamRow
 */
export interface BannerLiveStreamRow {
    /**
     * 
     * @type {string}
     * @memberof BannerLiveStreamRow
     */
    listingId: string;
    /**
     * 
     * @type {ProductsListing}
     * @memberof BannerLiveStreamRow
     */
    listing: ProductsListing;
    /**
     * 
     * @type {string}
     * @memberof BannerLiveStreamRow
     */
    type: BannerLiveStreamRowTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BannerLiveStreamRow
     */
    liveStreamId: string;
}

/**
* @export
* @enum {string}
*/
export enum BannerLiveStreamRowTypeEnum {
    LiveStream = 'liveStream'
}
/**
 * 
 * @export
 * @interface BannerProductsResponse
 */
export interface BannerProductsResponse {
    /**
     * 
     * @type {Array<BannerLiveStreamRow | BannerUpcomingShowRow>}
     * @memberof BannerProductsResponse
     */
    data: Array<BannerLiveStreamRow | BannerUpcomingShowRow>;
}
/**
 * 
 * @export
 * @interface BannerUpcomingShowRow
 */
export interface BannerUpcomingShowRow {
    /**
     * 
     * @type {string}
     * @memberof BannerUpcomingShowRow
     */
    listingId: string;
    /**
     * 
     * @type {ProductsListing}
     * @memberof BannerUpcomingShowRow
     */
    listing: ProductsListing;
    /**
     * 
     * @type {string}
     * @memberof BannerUpcomingShowRow
     */
    type: BannerUpcomingShowRowTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BannerUpcomingShowRow
     */
    upcomingShowId: string;
}

/**
* @export
* @enum {string}
*/
export enum BannerUpcomingShowRowTypeEnum {
    UpcomingShow = 'upcomingShow'
}
/**
 * 
 * @export
 * @interface BaseProfileItem
 */
export interface BaseProfileItem {
    /**
     * 
     * @type {string}
     * @memberof BaseProfileItem
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseProfileItem
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof BaseProfileItem
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof BaseProfileItem
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof BaseProfileItem
     */
    storeFrontName?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseProfileItem
     */
    createdAt?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof BaseProfileItem
     */
    hashtags?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof BaseProfileItem
     */
    userType?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseProfileItem
     */
    urlCode?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseProfileItem
     */
    username?: string;
    /**
     * 
     * @type {ProfileBadges}
     * @memberof BaseProfileItem
     */
    badges?: ProfileBadges;
}
/**
 * 
 * @export
 * @interface BasePromotionPageItem
 */
export interface BasePromotionPageItem {
    /**
     * 
     * @type {string}
     * @memberof BasePromotionPageItem
     */
    avatar: string;
    /**
     * 
     * @type {string}
     * @memberof BasePromotionPageItem
     */
    coverImage: string;
    /**
     * 
     * @type {boolean}
     * @memberof BasePromotionPageItem
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof BasePromotionPageItem
     */
    thumbnailNickName: string;
    /**
     * 
     * @type {string}
     * @memberof BasePromotionPageItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof BasePromotionPageItem
     */
    video: string;
}
/**
 * 
 * @export
 * @interface BaseShowEventWithMetadataItem
 */
export interface BaseShowEventWithMetadataItem {
    /**
     * 
     * @type {string}
     * @memberof BaseShowEventWithMetadataItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BaseShowEventWithMetadataItem
     */
    urlCode: string;
    /**
     * 
     * @type {string}
     * @memberof BaseShowEventWithMetadataItem
     */
    previewVideoURL?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseShowEventWithMetadataItem
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseShowEventWithMetadataItem
     */
    backgroundURL?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseShowEventWithMetadataItem
     */
    active: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseShowEventWithMetadataItem
     */
    sellerUserGroupIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseShowEventWithMetadataItem
     */
    userGroupIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BaseShowEventWithMetadataItem
     */
    createdAt: string;
    /**
     * 
     * @type {number}
     * @memberof BaseShowEventWithMetadataItem
     */
    salesTaxRate?: number;
    /**
     * 
     * @type {number}
     * @memberof BaseShowEventWithMetadataItem
     */
    maximumPurchaseQuantity?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseShowEventWithMetadataItem
     */
    startedAfter?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseShowEventWithMetadataItem
     */
    endedBefore?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseShowEventWithMetadataItem
     */
    shippingFee?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BaseShowEventWithMetadataItem
     */
    isClosed?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseShowEventWithMetadataItem
     */
    visibilityGroupIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BaseShowEventWithMetadataItem
     */
    sortedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof BaseShowEventWithMetadataItem
     */
    commissionFeeRate?: number;
    /**
     * 
     * @type {string}
     * @memberof BaseShowEventWithMetadataItem
     */
    featuredStoreStackName?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseShowEventWithMetadataItem
     */
    sellerApplicationDisclosure?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseShowEventWithMetadataItem
     */
    sellerApplicationDisclosureUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseShowEventWithMetadataItem
     */
    unBundleOnDiscovery?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BaseShowEventWithMetadataItem
     */
    visibilityOnDiscoveryBanner?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BaseShowEventWithMetadataItem
     */
    visibleOnCategoryBanner?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseShowEventWithMetadataItem
     */
    id: string;
    /**
     * 
     * @type {Metadata}
     * @memberof BaseShowEventWithMetadataItem
     */
    metadata: Metadata;
}
/**
 * 
 * @export
 * @interface BaseUpcomingShowItem
 */
export interface BaseUpcomingShowItem {
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseUpcomingShowItem
     */
    badges: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BaseUpcomingShowItem
     */
    title: string;
    /**
     * 
     * @type {BaseProfileItem}
     * @memberof BaseUpcomingShowItem
     */
    user: BaseProfileItem;
    /**
     * 
     * @type {string}
     * @memberof BaseUpcomingShowItem
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof BaseUpcomingShowItem
     */
    coverImage?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseUpcomingShowItem
     */
    originalCoverImage?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseUpcomingShowItem
     */
    previewVideoURL: string;
    /**
     * 
     * @type {string}
     * @memberof BaseUpcomingShowItem
     */
    shareVideo?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseUpcomingShowItem
     */
    startsAt: string;
    /**
     * 
     * @type {string}
     * @memberof BaseUpcomingShowItem
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseUpcomingShowItem
     */
    createdAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseUpcomingShowItem
     */
    available: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseUpcomingShowItem
     */
    visibility?: string;
    /**
     * 
     * @type {string}
     * @memberof BaseUpcomingShowItem
     */
    permission?: BaseUpcomingShowItemPermissionEnum;
    /**
     * 
     * @type {string}
     * @memberof BaseUpcomingShowItem
     */
    eventId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseUpcomingShowItem
     */
    userGroupIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BaseUpcomingShowItem
     */
    eventTemplateId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BaseUpcomingShowItem
     */
    disableComments?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BaseUpcomingShowItem
     */
    notificationSent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BaseUpcomingShowItem
     */
    liveStreamId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BaseUpcomingShowItem
     */
    historyLiveStreamIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BaseUpcomingShowItem
     */
    transcodeStatus?: BaseUpcomingShowItemTranscodeStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum BaseUpcomingShowItemPermissionEnum {
    All = 'all',
    Follower = 'follower',
    NoOne = 'no-one'
}/**
* @export
* @enum {string}
*/
export enum BaseUpcomingShowItemTranscodeStatusEnum {
    Initial = 'initial',
    Processing = 'processing',
    TranscodeOriginal = 'transcode-original',
    TranscodeOriginalShare = 'transcode-original-share',
    RequestRetranscode = 'request-retranscode'
}
/**
 * 
 * @export
 * @interface BatchAddStorePublicListingRequest
 */
export interface BatchAddStorePublicListingRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BatchAddStorePublicListingRequest
     */
    listingIds: Array<string>;
}
/**
 * 
 * @export
 * @interface BatchApproveWithdrawTransactionDto
 */
export interface BatchApproveWithdrawTransactionDto {
    /**
     * 
     * @type {string}
     * @memberof BatchApproveWithdrawTransactionDto
     */
    withdrawTransactionId: string;
    /**
     * 
     * @type {string}
     * @memberof BatchApproveWithdrawTransactionDto
     */
    note?: string;
}
/**
 * 
 * @export
 * @interface BatchApproveWithdrawTransactionErrorDto
 */
export interface BatchApproveWithdrawTransactionErrorDto {
    /**
     * 
     * @type {string}
     * @memberof BatchApproveWithdrawTransactionErrorDto
     */
    withdrawTransactionId: string;
    /**
     * 
     * @type {string}
     * @memberof BatchApproveWithdrawTransactionErrorDto
     */
    message: string;
}
/**
 * 
 * @export
 * @interface BatchApproveWithdrawTransactionResponse
 */
export interface BatchApproveWithdrawTransactionResponse {
    /**
     * 
     * @type {number}
     * @memberof BatchApproveWithdrawTransactionResponse
     */
    succeeded: number;
    /**
     * 
     * @type {number}
     * @memberof BatchApproveWithdrawTransactionResponse
     */
    failed: number;
    /**
     * 
     * @type {Array<BatchApproveWithdrawTransactionErrorDto>}
     * @memberof BatchApproveWithdrawTransactionResponse
     */
    errors: Array<BatchApproveWithdrawTransactionErrorDto>;
}
/**
 * 
 * @export
 * @interface BatchApproveWithdrawTransactionsDto
 */
export interface BatchApproveWithdrawTransactionsDto {
    /**
     * 
     * @type {Array<BatchApproveWithdrawTransactionDto>}
     * @memberof BatchApproveWithdrawTransactionsDto
     */
    withdrawTransactions: Array<BatchApproveWithdrawTransactionDto>;
}
/**
 * 
 * @export
 * @interface BatchDeleteStorePublicListingRequest
 */
export interface BatchDeleteStorePublicListingRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BatchDeleteStorePublicListingRequest
     */
    listingIds: Array<string>;
}
/**
 * 
 * @export
 * @interface BatchFeaturedStoreRequest
 */
export interface BatchFeaturedStoreRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BatchFeaturedStoreRequest
     */
    originalFeaturedStoreIds: Array<string>;
    /**
     * 
     * @type {Array<FeaturedStoreRequestDto>}
     * @memberof BatchFeaturedStoreRequest
     */
    latestFeaturedStores: Array<FeaturedStoreRequestDto>;
}
/**
 * 
 * @export
 * @interface BatchImportResponse
 */
export interface BatchImportResponse {
    /**
     * 
     * @type {number}
     * @memberof BatchImportResponse
     */
    success: number;
    /**
     * 
     * @type {number}
     * @memberof BatchImportResponse
     */
    failed?: number;
    /**
     * 
     * @type {Array<ImportError>}
     * @memberof BatchImportResponse
     */
    errors?: Array<ImportError>;
}
/**
 * 
 * @export
 * @interface BatchImportStoreListingsRequest
 */
export interface BatchImportStoreListingsRequest {
    /**
     * 
     * @type {Array<ImportStoreListing>}
     * @memberof BatchImportStoreListingsRequest
     */
    storeListings: Array<ImportStoreListing>;
}
/**
 * 
 * @export
 * @interface BatchSaveFeaturedStoreResponse
 */
export interface BatchSaveFeaturedStoreResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof BatchSaveFeaturedStoreResponse
     */
    notExistsStoreIds: Array<string>;
}
/**
 * 
 * @export
 * @interface BatchSaveShowEventFeaturedStoreResponse
 */
export interface BatchSaveShowEventFeaturedStoreResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof BatchSaveShowEventFeaturedStoreResponse
     */
    notExistsStoreIds: Array<string>;
}
/**
 * 
 * @export
 * @interface BatchShowEventFeaturedStoreRequest
 */
export interface BatchShowEventFeaturedStoreRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BatchShowEventFeaturedStoreRequest
     */
    originalFeaturedStoreIds: Array<string>;
    /**
     * 
     * @type {Array<FeaturedStoreRequestDto>}
     * @memberof BatchShowEventFeaturedStoreRequest
     */
    latestFeaturedStores: Array<FeaturedStoreRequestDto>;
}
/**
 * 
 * @export
 * @interface BatchSortFeaturedShows
 */
export interface BatchSortFeaturedShows {
    /**
     * 
     * @type {Array<SortFeaturedShow>}
     * @memberof BatchSortFeaturedShows
     */
    featuredShows: Array<SortFeaturedShow>;
}
/**
 * 
 * @export
 * @interface BatchSortShowEventFeaturedShows
 */
export interface BatchSortShowEventFeaturedShows {
    /**
     * 
     * @type {Array<SortShowEventFeaturedShow>}
     * @memberof BatchSortShowEventFeaturedShows
     */
    featuredShows: Array<SortShowEventFeaturedShow>;
}
/**
 * 
 * @export
 * @interface BatchSortShowEventsRequest
 */
export interface BatchSortShowEventsRequest {
    /**
     * 
     * @type {Array<SortShowEventItem>}
     * @memberof BatchSortShowEventsRequest
     */
    showEvents: Array<SortShowEventItem>;
}
/**
 * 
 * @export
 * @interface BatchSortShowTicketsRequest
 */
export interface BatchSortShowTicketsRequest {
    /**
     * 
     * @type {Array<SortShowTickets>}
     * @memberof BatchSortShowTicketsRequest
     */
    showTickets: Array<SortShowTickets>;
}
/**
 * 
 * @export
 * @interface BatchSortStorePublicListingRequest
 */
export interface BatchSortStorePublicListingRequest {
    /**
     * 
     * @type {Array<SortedPublicListingsDto>}
     * @memberof BatchSortStorePublicListingRequest
     */
    sortedListings: Array<SortedPublicListingsDto>;
}
/**
 * 
 * @export
 * @interface BatchUpdateListingDiscountPriceRequest
 */
export interface BatchUpdateListingDiscountPriceRequest {
    /**
     * 
     * @type {number}
     * @memberof BatchUpdateListingDiscountPriceRequest
     */
    discountPercent: number;
}
/**
 * 
 * @export
 * @interface BatchUpdateLiveStreamListingDiscountPriceRequest
 */
export interface BatchUpdateLiveStreamListingDiscountPriceRequest {
    /**
     * 
     * @type {number}
     * @memberof BatchUpdateLiveStreamListingDiscountPriceRequest
     */
    discountPercent: number;
}
/**
 * 
 * @export
 * @interface BidRequest
 */
export interface BidRequest {
    /**
     * 
     * @type {number}
     * @memberof BidRequest
     */
    bidPrice: number;
}
/**
 * 
 * @export
 * @interface BillingAddressDto
 */
export interface BillingAddressDto {
    /**
     * 
     * @type {string}
     * @memberof BillingAddressDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingAddressDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof BillingAddressDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof BillingAddressDto
     */
    line1: string;
    /**
     * 
     * @type {string}
     * @memberof BillingAddressDto
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingAddressDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof BillingAddressDto
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof BillingAddressDto
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingAddressDto
     */
    postalCode: string;
}
/**
 * 
 * @export
 * @interface BillingAddressItem
 */
export interface BillingAddressItem {
    /**
     * For shipping addresses, id will be undefined while for billing addresses, id will be defined
     * @type {string}
     * @memberof BillingAddressItem
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingAddressItem
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof BillingAddressItem
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof BillingAddressItem
     */
    line1: string;
    /**
     * 
     * @type {string}
     * @memberof BillingAddressItem
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingAddressItem
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof BillingAddressItem
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof BillingAddressItem
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingAddressItem
     */
    postalCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof BillingAddressItem
     */
    isDefault: boolean;
}
/**
 * 
 * @export
 * @interface BlockUserCommentRequest
 */
export interface BlockUserCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof BlockUserCommentRequest
     */
    reason: BlockUserCommentRequestReasonEnum;
}

/**
* @export
* @enum {string}
*/
export enum BlockUserCommentRequestReasonEnum {
    Spam = 'spam',
    Inappropriate = 'inappropriate',
    Aggressive = 'aggressive'
}
/**
 * 
 * @export
 * @interface BlockUserRequest
 */
export interface BlockUserRequest {
    /**
     * 
     * @type {string}
     * @memberof BlockUserRequest
     */
    userId: string;
}
/**
 * 
 * @export
 * @interface BraintreeDisbursementDetailsItem
 */
export interface BraintreeDisbursementDetailsItem {
    /**
     * 
     * @type {string}
     * @memberof BraintreeDisbursementDetailsItem
     */
    disbursementDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof BraintreeDisbursementDetailsItem
     */
    fundsHeld: boolean;
    /**
     * 
     * @type {string}
     * @memberof BraintreeDisbursementDetailsItem
     */
    settlementAmount: string;
    /**
     * 
     * @type {string}
     * @memberof BraintreeDisbursementDetailsItem
     */
    settlementCurrencyExchangeRate: string;
    /**
     * 
     * @type {string}
     * @memberof BraintreeDisbursementDetailsItem
     */
    settlementCurrencyIsoCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof BraintreeDisbursementDetailsItem
     */
    success: boolean;
}
/**
 * 
 * @export
 * @interface BraintreeDisputeItem
 */
export interface BraintreeDisputeItem {
    /**
     * 
     * @type {string}
     * @memberof BraintreeDisputeItem
     */
    amountDisputed: string;
    /**
     * 
     * @type {string}
     * @memberof BraintreeDisputeItem
     */
    amountWon: string;
    /**
     * 
     * @type {string}
     * @memberof BraintreeDisputeItem
     */
    caseNumber: string;
    /**
     * 
     * @type {string}
     * @memberof BraintreeDisputeItem
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof BraintreeDisputeItem
     */
    currencyIsoCode: string;
    /**
     * 
     * @type {EvidenceItem}
     * @memberof BraintreeDisputeItem
     */
    evidence: EvidenceItem;
    /**
     * 
     * @type {string}
     * @memberof BraintreeDisputeItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BraintreeDisputeItem
     */
    kind: string;
    /**
     * 
     * @type {string}
     * @memberof BraintreeDisputeItem
     */
    merchantAccountId: string;
    /**
     * 
     * @type {string}
     * @memberof BraintreeDisputeItem
     */
    originalDisputeId: string;
    /**
     * 
     * @type {string}
     * @memberof BraintreeDisputeItem
     */
    processorComments: string;
    /**
     * 
     * @type {string}
     * @memberof BraintreeDisputeItem
     */
    reason: string;
    /**
     * 
     * @type {string}
     * @memberof BraintreeDisputeItem
     */
    reasonCode: string;
    /**
     * 
     * @type {string}
     * @memberof BraintreeDisputeItem
     */
    reasonDescription: string;
    /**
     * 
     * @type {string}
     * @memberof BraintreeDisputeItem
     */
    receivedDate: string;
    /**
     * 
     * @type {string}
     * @memberof BraintreeDisputeItem
     */
    referenceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof BraintreeDisputeItem
     */
    replyByDate: string;
    /**
     * 
     * @type {string}
     * @memberof BraintreeDisputeItem
     */
    status: BraintreeDisputeItemStatusEnum;
    /**
     * 
     * @type {Array<DisputeStatusHistoryItem>}
     * @memberof BraintreeDisputeItem
     */
    statusHistory: Array<DisputeStatusHistoryItem>;
    /**
     * 
     * @type {string}
     * @memberof BraintreeDisputeItem
     */
    updatedAt: string;
}

/**
* @export
* @enum {string}
*/
export enum BraintreeDisputeItemStatusEnum {
    Accepted = 'Accepted',
    Disputed = 'Disputed',
    Expired = 'Expired',
    Lost = 'Lost',
    Won = 'Won',
    Open = 'Open'
}
/**
 * 
 * @export
 * @interface BraintreeTokenResponse
 */
export interface BraintreeTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof BraintreeTokenResponse
     */
    clientToken: string;
}
/**
 * 
 * @export
 * @interface BraintreeTransactionItem
 */
export interface BraintreeTransactionItem {
    /**
     * 
     * @type {string}
     * @memberof BraintreeTransactionItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BraintreeTransactionItem
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof BraintreeTransactionItem
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof BraintreeTransactionItem
     */
    amount: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BraintreeTransactionItem
     */
    refundIds: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof BraintreeTransactionItem
     */
    refundedTransactionId?: object;
    /**
     * 
     * @type {string}
     * @memberof BraintreeTransactionItem
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof BraintreeTransactionItem
     */
    updatedAt: string;
    /**
     * 
     * @type {Array<BraintreeDisputeItem>}
     * @memberof BraintreeTransactionItem
     */
    disputes?: Array<BraintreeDisputeItem>;
    /**
     * 
     * @type {BraintreeDisbursementDetailsItem}
     * @memberof BraintreeTransactionItem
     */
    disbursementDetails?: BraintreeDisbursementDetailsItem;
}
/**
 * 
 * @export
 * @interface CampaignActionsItem
 */
export interface CampaignActionsItem {
    /**
     * 
     * @type {Array<string>}
     * @memberof CampaignActionsItem
     */
    usersToFollow?: Array<string>;
}
/**
 * 
 * @export
 * @interface CampaignItem
 */
export interface CampaignItem {
    /**
     * 
     * @type {string}
     * @memberof CampaignItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignItem
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignItem
     */
    isWebOnly?: boolean;
    /**
     * 
     * @type {CampaignActionsItem}
     * @memberof CampaignItem
     */
    actions?: CampaignActionsItem;
    /**
     * 
     * @type {string}
     * @memberof CampaignItem
     */
    showEventId?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignItem
     */
    profileId?: string;
    /**
     * 
     * @type {ContentFDLSocialItem}
     * @memberof CampaignItem
     */
    social?: ContentFDLSocialItem;
    /**
     * 
     * @type {string}
     * @memberof CampaignItem
     */
    shortLinkFDL: string;
    /**
     * 
     * @type {object}
     * @memberof CampaignItem
     */
    contentFDL: object;
    /**
     * 
     * @type {object}
     * @memberof CampaignItem
     */
    queryParams?: object;
    /**
     * 
     * @type {string}
     * @memberof CampaignItem
     */
    targetUserId?: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof CampaignItem
     */
    targetUserProfileSnapshot?: ProfileItem;
    /**
     * 
     * @type {string}
     * @memberof CampaignItem
     */
    targetEventId?: string;
    /**
     * 
     * @type {ShowEventItem}
     * @memberof CampaignItem
     */
    targetEventSnapshot?: ShowEventItem;
    /**
     * 
     * @type {string}
     * @memberof CampaignItem
     */
    createdAt: string;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignItem
     */
    isActive: boolean;
    /**
     * 
     * @type {CreateShareTargetRequest}
     * @memberof CampaignItem
     */
    shareTarget: CreateShareTargetRequest;
    /**
     * 
     * @type {object}
     * @memberof CampaignItem
     */
    updatedAt: object;
}
/**
 * 
 * @export
 * @interface CampaignPageRows
 */
export interface CampaignPageRows {
    /**
     * 
     * @type {Array<CampaignItem>}
     * @memberof CampaignPageRows
     */
    rows: Array<CampaignItem>;
}
/**
 * 
 * @export
 * @interface CampaignsSearchResponse
 */
export interface CampaignsSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof CampaignsSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof CampaignsSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {CampaignPageRows}
     * @memberof CampaignsSearchResponse
     */
    data: CampaignPageRows;
}
/**
 * 
 * @export
 * @interface CategoriesSearchResponse
 */
export interface CategoriesSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof CategoriesSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof CategoriesSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {CategoryPageRows}
     * @memberof CategoriesSearchResponse
     */
    data: CategoryPageRows;
}
/**
 * 
 * @export
 * @interface CategorizedResourceItem
 */
export interface CategorizedResourceItem {
    /**
     * 
     * @type {string}
     * @memberof CategorizedResourceItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CategorizedResourceItem
     */
    categoryId: string;
    /**
     * 
     * @type {CategoryItem}
     * @memberof CategorizedResourceItem
     */
    category: CategoryItem;
    /**
     * 
     * @type {number}
     * @memberof CategorizedResourceItem
     */
    sort: number;
    /**
     * 
     * @type {string}
     * @memberof CategorizedResourceItem
     */
    resourceType: CategorizedResourceItemResourceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CategorizedResourceItem
     */
    storeId?: string;
    /**
     * 
     * @type {StoreFrontItem}
     * @memberof CategorizedResourceItem
     */
    store?: StoreFrontItem;
    /**
     * 
     * @type {string}
     * @memberof CategorizedResourceItem
     */
    upcomingShowId?: string;
    /**
     * 
     * @type {UpcomingShowItem}
     * @memberof CategorizedResourceItem
     */
    upcomingShow?: UpcomingShowItem;
    /**
     * 
     * @type {string}
     * @memberof CategorizedResourceItem
     */
    liveStreamId?: string;
    /**
     * 
     * @type {LiveStreamItem}
     * @memberof CategorizedResourceItem
     */
    liveStream?: LiveStreamItem;
    /**
     * 
     * @type {string}
     * @memberof CategorizedResourceItem
     */
    userId?: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof CategorizedResourceItem
     */
    user?: ProfileItem;
    /**
     * 
     * @type {string}
     * @memberof CategorizedResourceItem
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof CategorizedResourceItem
     */
    updatedAt: string;
}

/**
* @export
* @enum {string}
*/
export enum CategorizedResourceItemResourceTypeEnum {
    Store = 'STORE',
    UpcomingShow = 'UPCOMING_SHOW',
    LiveStream = 'LIVE_STREAM',
    User = 'USER'
}
/**
 * 
 * @export
 * @interface CategorizedResourcePageRows
 */
export interface CategorizedResourcePageRows {
    /**
     * 
     * @type {Array<CategorizedResourceItem>}
     * @memberof CategorizedResourcePageRows
     */
    rows: Array<CategorizedResourceItem>;
}
/**
 * 
 * @export
 * @interface CategorizedResourcesSearchResponse
 */
export interface CategorizedResourcesSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof CategorizedResourcesSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof CategorizedResourcesSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {CategorizedResourcePageRows}
     * @memberof CategorizedResourcesSearchResponse
     */
    data: CategorizedResourcePageRows;
}
/**
 * 
 * @export
 * @interface CategoryItem
 */
export interface CategoryItem {
    /**
     * 
     * @type {string}
     * @memberof CategoryItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryItem
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryItem
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryItem
     */
    parentName?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryItem
     */
    rootCategory: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryItem
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryItem
     */
    imageUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryItem
     */
    sort: number;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryItem
     */
    enabled: boolean;
    /**
     * 
     * @type {Metadata}
     * @memberof CategoryItem
     */
    metadata: Metadata;
    /**
     * 
     * @type {string}
     * @memberof CategoryItem
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryItem
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface CategoryPageRows
 */
export interface CategoryPageRows {
    /**
     * 
     * @type {Array<CategoryItem>}
     * @memberof CategoryPageRows
     */
    rows: Array<CategoryItem>;
}
/**
 * 
 * @export
 * @interface ChatMentionDto
 */
export interface ChatMentionDto {
    /**
     * 
     * @type {number}
     * @memberof ChatMentionDto
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof ChatMentionDto
     */
    length: number;
    /**
     * 
     * @type {string}
     * @memberof ChatMentionDto
     */
    userId: string;
}
/**
 * 
 * @export
 * @interface ChatMentionProfileItem
 */
export interface ChatMentionProfileItem {
    /**
     * 
     * @type {string}
     * @memberof ChatMentionProfileItem
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMentionProfileItem
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMentionProfileItem
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMentionProfileItem
     */
    lastNameInitial: string;
}
/**
 * 
 * @export
 * @interface CheckPayoutMethodUpdateStatusResponse
 */
export interface CheckPayoutMethodUpdateStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckPayoutMethodUpdateStatusResponse
     */
    canWithdraw: boolean;
    /**
     * it means status is approved or reject.
     * @type {boolean}
     * @memberof CheckPayoutMethodUpdateStatusResponse
     */
    hasCompleteApply: boolean;
    /**
     * it means status is pending. can not withdraw.
     * @type {boolean}
     * @memberof CheckPayoutMethodUpdateStatusResponse
     */
    hasPendingApply: boolean;
    /**
     * it means whether the last request is within 24 hours. if hasCompleteApply && isInLimitHours && !firstCreate, can not withdraw 
     * @type {boolean}
     * @memberof CheckPayoutMethodUpdateStatusResponse
     */
    isInLimitHours?: boolean;
    /**
     * no status means seller never submits update request
     * @type {string}
     * @memberof CheckPayoutMethodUpdateStatusResponse
     */
    status?: CheckPayoutMethodUpdateStatusResponseStatusEnum;
    /**
     * first create payout method can be withdraw
     * @type {boolean}
     * @memberof CheckPayoutMethodUpdateStatusResponse
     */
    firstCreate?: boolean;
    /**
     * The last four digits of latest update bank account number when status is pending
     * @type {string}
     * @memberof CheckPayoutMethodUpdateStatusResponse
     */
    latestAccountNumber?: string;
}

/**
* @export
* @enum {string}
*/
export enum CheckPayoutMethodUpdateStatusResponseStatusEnum {
    Approved = 'approved',
    Pending = 'pending',
    Rejected = 'rejected',
    Canceled = 'canceled'
}
/**
 * 
 * @export
 * @interface CheckReviewMessageUpvoteStatusResponse
 */
export interface CheckReviewMessageUpvoteStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckReviewMessageUpvoteStatusResponse
     */
    isUpvote: boolean;
}
/**
 * 
 * @export
 * @interface CloseSendItemRequest
 */
export interface CloseSendItemRequest {
    /**
     * 
     * @type {boolean}
     * @memberof CloseSendItemRequest
     */
    noNeedNotification?: boolean;
}
/**
 * 
 * @export
 * @interface CommentUpvoteItem
 */
export interface CommentUpvoteItem {
    /**
     * 
     * @type {string}
     * @memberof CommentUpvoteItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CommentUpvoteItem
     */
    commentId: string;
    /**
     * 
     * @type {number}
     * @memberof CommentUpvoteItem
     */
    totalUpvotes: number;
    /**
     * 
     * @type {Array<ChatMentionProfileItem>}
     * @memberof CommentUpvoteItem
     */
    latestUpvoters: Array<ChatMentionProfileItem>;
    /**
     * 
     * @type {string}
     * @memberof CommentUpvoteItem
     */
    userId: string;
    /**
     * 
     * @type {ChatMentionProfileItem}
     * @memberof CommentUpvoteItem
     */
    profile: ChatMentionProfileItem;
    /**
     * 
     * @type {string}
     * @memberof CommentUpvoteItem
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @interface CommentUpvoterItem
 */
export interface CommentUpvoterItem {
    /**
     * 
     * @type {string}
     * @memberof CommentUpvoterItem
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentUpvoterItem
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentUpvoterItem
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CommentUpvoterItem
     */
    lastNameInitial: string;
    /**
     * 
     * @type {string}
     * @memberof CommentUpvoterItem
     */
    userId: string;
}
/**
 * 
 * @export
 * @interface CommentUpvotersResponse
 */
export interface CommentUpvotersResponse {
    /**
     * 
     * @type {string}
     * @memberof CommentUpvotersResponse
     */
    commentId: string;
    /**
     * 
     * @type {number}
     * @memberof CommentUpvotersResponse
     */
    total: number;
    /**
     * 
     * @type {Array<CommentUpvoterItem>}
     * @memberof CommentUpvotersResponse
     */
    upvoters: Array<CommentUpvoterItem>;
}
/**
 * 
 * @export
 * @interface ConfirmTicketRequest
 */
export interface ConfirmTicketRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfirmTicketRequest
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmTicketRequest
     */
    uid: string;
    /**
     * 
     * @type {number}
     * @memberof ConfirmTicketRequest
     */
    purchaseAt: number;
    /**
     * 
     * @type {string}
     * @memberof ConfirmTicketRequest
     */
    fromType: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmTicketRequest
     */
    ticketType: string;
    /**
     * 
     * @type {object}
     * @memberof ConfirmTicketRequest
     */
    extra?: object;
}
/**
 * 
 * @export
 * @interface ConfirmTicketResponse
 */
export interface ConfirmTicketResponse {
    /**
     * 
     * @type {string}
     * @memberof ConfirmTicketResponse
     */
    response: string;
}
/**
 * 
 * @export
 * @interface ConnectTwoWayStreamRequest
 */
export interface ConnectTwoWayStreamRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectTwoWayStreamRequest
     */
    device: string;
}
/**
 * 
 * @export
 * @interface ContentFDLSocial
 */
export interface ContentFDLSocial {
    /**
     * 
     * @type {string}
     * @memberof ContentFDLSocial
     */
    st: string;
    /**
     * 
     * @type {string}
     * @memberof ContentFDLSocial
     */
    sd: string;
    /**
     * 
     * @type {string}
     * @memberof ContentFDLSocial
     */
    si: string;
}
/**
 * 
 * @export
 * @interface ContentFDLSocialItem
 */
export interface ContentFDLSocialItem {
    /**
     * 
     * @type {string}
     * @memberof ContentFDLSocialItem
     */
    st: string;
    /**
     * 
     * @type {string}
     * @memberof ContentFDLSocialItem
     */
    sd: string;
    /**
     * 
     * @type {string}
     * @memberof ContentFDLSocialItem
     */
    si: string;
}
/**
 * 
 * @export
 * @interface CouponCreatedByItem
 */
export interface CouponCreatedByItem {
    /**
     * 
     * @type {string}
     * @memberof CouponCreatedByItem
     */
    profileId: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof CouponCreatedByItem
     */
    profile?: ProfileItem;
}
/**
 * 
 * @export
 * @interface CouponDetailDto
 */
export interface CouponDetailDto {
    /**
     * 
     * @type {string}
     * @memberof CouponDetailDto
     */
    couponId: string;
    /**
     * not null if coupon is redeemed and not used
     * @type {string}
     * @memberof CouponDetailDto
     */
    redeemedCouponId?: string;
    /**
     * 
     * @type {CouponItem}
     * @memberof CouponDetailDto
     */
    coupon: CouponItem;
    /**
     * 
     * @type {string}
     * @memberof CouponDetailDto
     */
    redeemStatus: CouponDetailDtoRedeemStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CouponDetailDto
     */
    reason?: string;
    /**
     * 
     * @type {RedeemedCouponDetailItem}
     * @memberof CouponDetailDto
     */
    redeemedCoupon?: RedeemedCouponDetailItem;
}

/**
* @export
* @enum {string}
*/
export enum CouponDetailDtoRedeemStatusEnum {
    Redeemed = 'redeemed',
    Redeemable = 'redeemable',
    Nonredeemable = 'nonredeemable'
}
/**
 * 
 * @export
 * @interface CouponDetailResponse
 */
export interface CouponDetailResponse {
    /**
     * 
     * @type {CouponDetailDto}
     * @memberof CouponDetailResponse
     */
    data?: CouponDetailDto | null;
}
/**
 * 
 * @export
 * @interface CouponItem
 */
export interface CouponItem {
    /**
     * 
     * @type {string}
     * @memberof CouponItem
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof CouponItem
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponItem
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponItem
     */
    pagUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponItem
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItem
     */
    enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItem
     */
    visible: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItem
     */
    exclusive: boolean;
    /**
     * 
     * @type {string}
     * @memberof CouponItem
     */
    createdAt: string;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItem
     */
    quantityLimit: boolean;
    /**
     * 
     * @type {number}
     * @memberof CouponItem
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof CouponItem
     */
    redeemedQuantity: number;
    /**
     * 
     * @type {string}
     * @memberof CouponItem
     */
    startedAt: string;
    /**
     * 
     * @type {string}
     * @memberof CouponItem
     */
    expiredAt: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CouponItem
     */
    storeIds?: Array<string>;
    /**
     * 
     * @type {StoreItem}
     * @memberof CouponItem
     */
    store?: StoreItem;
    /**
     * 
     * @type {string}
     * @memberof CouponItem
     */
    couponCreateMethod?: CouponItemCouponCreateMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof CouponItem
     */
    type: CouponItemTypeEnum;
    /**
     * belong to type discountPercent and non-nullable.
     * @type {number}
     * @memberof CouponItem
     */
    discountPercent?: number;
    /**
     * belong to type discountPercent and nullable.
     * @type {number}
     * @memberof CouponItem
     */
    maximumDiscount?: number;
    /**
     * belong to type discountAmount and non-nullable.
     * @type {number}
     * @memberof CouponItem
     */
    discountAmount?: number;
    /**
     * belong to type discountAmount and nullable.
     * @type {number}
     * @memberof CouponItem
     */
    minimalPurchase?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItem
     */
    onlyPurchasedUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItem
     */
    onlyNewUserPerStore?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItem
     */
    onlyReactivatedUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItem
     */
    onlyNewUser?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CouponItem
     */
    userGroupIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItem
     */
    oncePerStore?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CouponItem
     */
    sellerGroupIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItem
     */
    followSelectedSellers?: boolean;
    /**
     * 
     * @type {CouponCreatedByItem}
     * @memberof CouponItem
     */
    createdBy?: CouponCreatedByItem;
    /**
     * 
     * @type {CouponCreatedByItem}
     * @memberof CouponItem
     */
    lastUpdatedBy?: CouponCreatedByItem;
    /**
     * 
     * @type {Array<string>}
     * @memberof CouponItem
     */
    liveStreamIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItem
     */
    onlyFollower?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItem
     */
    onlyPopshopShipping?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum CouponItemCouponCreateMethodEnum {
    SellerProvide = 'sellerProvide',
    PopshopProvide = 'popshopProvide'
}/**
* @export
* @enum {string}
*/
export enum CouponItemTypeEnum {
    DiscountPercent = 'discountPercent',
    DiscountAmount = 'discountAmount',
    FreeShipping = 'freeShipping'
}
/**
 * 
 * @export
 * @interface CouponItemWithId
 */
export interface CouponItemWithId {
    /**
     * 
     * @type {string}
     * @memberof CouponItemWithId
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof CouponItemWithId
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponItemWithId
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponItemWithId
     */
    pagUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponItemWithId
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItemWithId
     */
    enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItemWithId
     */
    visible: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItemWithId
     */
    exclusive: boolean;
    /**
     * 
     * @type {string}
     * @memberof CouponItemWithId
     */
    createdAt: string;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItemWithId
     */
    quantityLimit: boolean;
    /**
     * 
     * @type {number}
     * @memberof CouponItemWithId
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof CouponItemWithId
     */
    redeemedQuantity: number;
    /**
     * 
     * @type {string}
     * @memberof CouponItemWithId
     */
    startedAt: string;
    /**
     * 
     * @type {string}
     * @memberof CouponItemWithId
     */
    expiredAt: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CouponItemWithId
     */
    storeIds?: Array<string>;
    /**
     * 
     * @type {StoreItem}
     * @memberof CouponItemWithId
     */
    store?: StoreItem;
    /**
     * 
     * @type {string}
     * @memberof CouponItemWithId
     */
    couponCreateMethod?: CouponItemWithIdCouponCreateMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof CouponItemWithId
     */
    type: CouponItemWithIdTypeEnum;
    /**
     * belong to type discountPercent and non-nullable.
     * @type {number}
     * @memberof CouponItemWithId
     */
    discountPercent?: number;
    /**
     * belong to type discountPercent and nullable.
     * @type {number}
     * @memberof CouponItemWithId
     */
    maximumDiscount?: number;
    /**
     * belong to type discountAmount and non-nullable.
     * @type {number}
     * @memberof CouponItemWithId
     */
    discountAmount?: number;
    /**
     * belong to type discountAmount and nullable.
     * @type {number}
     * @memberof CouponItemWithId
     */
    minimalPurchase?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItemWithId
     */
    onlyPurchasedUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItemWithId
     */
    onlyNewUserPerStore?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItemWithId
     */
    onlyReactivatedUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItemWithId
     */
    onlyNewUser?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CouponItemWithId
     */
    userGroupIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItemWithId
     */
    oncePerStore?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CouponItemWithId
     */
    sellerGroupIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItemWithId
     */
    followSelectedSellers?: boolean;
    /**
     * 
     * @type {CouponCreatedByItem}
     * @memberof CouponItemWithId
     */
    createdBy?: CouponCreatedByItem;
    /**
     * 
     * @type {CouponCreatedByItem}
     * @memberof CouponItemWithId
     */
    lastUpdatedBy?: CouponCreatedByItem;
    /**
     * 
     * @type {Array<string>}
     * @memberof CouponItemWithId
     */
    liveStreamIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItemWithId
     */
    onlyFollower?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CouponItemWithId
     */
    onlyPopshopShipping?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CouponItemWithId
     */
    id: string;
    /**
     * 
     * @type {Metadata}
     * @memberof CouponItemWithId
     */
    metadata: Metadata;
}

/**
* @export
* @enum {string}
*/
export enum CouponItemWithIdCouponCreateMethodEnum {
    SellerProvide = 'sellerProvide',
    PopshopProvide = 'popshopProvide'
}/**
* @export
* @enum {string}
*/
export enum CouponItemWithIdTypeEnum {
    DiscountPercent = 'discountPercent',
    DiscountAmount = 'discountAmount',
    FreeShipping = 'freeShipping'
}
/**
 * 
 * @export
 * @interface CouponPageRows
 */
export interface CouponPageRows {
    /**
     * 
     * @type {Array<CouponItemWithId>}
     * @memberof CouponPageRows
     */
    rows: Array<CouponItemWithId>;
}
/**
 * 
 * @export
 * @interface CouponsSearchResponse
 */
export interface CouponsSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof CouponsSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof CouponsSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {CouponPageRows}
     * @memberof CouponsSearchResponse
     */
    data: CouponPageRows;
}
/**
 * 
 * @export
 * @interface CreateAppSellerApplicationRequest
 */
export interface CreateAppSellerApplicationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateAppSellerApplicationRequest
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppSellerApplicationRequest
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppSellerApplicationRequest
     */
    whereOnline?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppSellerApplicationRequest
     */
    whatSell?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppSellerApplicationRequest
     */
    whatExperienceProducts?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppSellerApplicationRequest
     */
    whatLiveStream?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppSellerApplicationRequest
     */
    howHearUs?: string;
    /**
     * 
     * @type {SellerAddress}
     * @memberof CreateAppSellerApplicationRequest
     */
    sellerAddress?: SellerAddress;
    /**
     * 
     * @type {string}
     * @memberof CreateAppSellerApplicationRequest
     */
    storeName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppSellerApplicationRequest
     */
    storeDesc?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppSellerApplicationRequest
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppSellerApplicationRequest
     */
    social?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppSellerApplicationRequest
     */
    sellerDesc?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAppSellerApplicationRequest
     */
    sellerHasPhysicalStore?: string;
}
/**
 * 
 * @export
 * @interface CreateCategorizedResourceRequest
 */
export interface CreateCategorizedResourceRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCategorizedResourceRequest
     */
    resourceId: string;
}
/**
 * 
 * @export
 * @interface CreateCategoryRequest
 */
export interface CreateCategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryRequest
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryRequest
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryRequest
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryRequest
     */
    rootCategory: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCategoryRequest
     */
    enabled: boolean;
}
/**
 * 
 * @export
 * @interface CreateCategoryResponse
 */
export interface CreateCategoryResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateCategoryResponse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CreateCouponByAdminDto
 */
export interface CreateCouponByAdminDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponByAdminDto
     */
    enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponByAdminDto
     */
    visible?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponByAdminDto
     */
    exclusive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponByAdminDto
     */
    quantityLimit: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateCouponByAdminDto
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponByAdminDto
     */
    startedAt: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponByAdminDto
     */
    expiredAt: string;
    /**
     * 
     * @type {number}
     * @memberof CreateCouponByAdminDto
     */
    discountAmount: number;
    /**
     * 
     * @type {number}
     * @memberof CreateCouponByAdminDto
     */
    minimalPurchase?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponByAdminDto
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponByAdminDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponByAdminDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponByAdminDto
     */
    pagUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponByAdminDto
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponByAdminDto
     */
    isTest?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCouponByAdminDto
     */
    userGroupIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponByAdminDto
     */
    onlyNewUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponByAdminDto
     */
    onlyFollower?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponByAdminDto
     */
    onlyPurchasedUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponByAdminDto
     */
    onlyNewUserPerStore?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponByAdminDto
     */
    onlyReactivatedUser?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCouponByAdminDto
     */
    liveStreamIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponByAdminDto
     */
    type: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCouponByAdminDto
     */
    storeIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponByAdminDto
     */
    followSelectedSellers?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCouponByAdminDto
     */
    sellerGroupIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponByAdminDto
     */
    oncePerStore?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponByAdminDto
     */
    onlyPopshopShipping?: boolean;
}
/**
 * 
 * @export
 * @interface CreateCouponBySellerDto
 */
export interface CreateCouponBySellerDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponBySellerDto
     */
    enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponBySellerDto
     */
    visible?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponBySellerDto
     */
    exclusive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponBySellerDto
     */
    quantityLimit: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateCouponBySellerDto
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponBySellerDto
     */
    startedAt: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponBySellerDto
     */
    expiredAt: string;
    /**
     * 
     * @type {number}
     * @memberof CreateCouponBySellerDto
     */
    discountAmount: number;
    /**
     * 
     * @type {number}
     * @memberof CreateCouponBySellerDto
     */
    minimalPurchase?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponBySellerDto
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponBySellerDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponBySellerDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponBySellerDto
     */
    pagUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponBySellerDto
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponBySellerDto
     */
    isTest?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCouponBySellerDto
     */
    userGroupIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponBySellerDto
     */
    onlyNewUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponBySellerDto
     */
    onlyFollower?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponBySellerDto
     */
    onlyPurchasedUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponBySellerDto
     */
    onlyNewUserPerStore?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponBySellerDto
     */
    onlyReactivatedUser?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCouponBySellerDto
     */
    liveStreamIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateCouponBySellerDto
     */
    type: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCouponBySellerDto
     */
    onlyAssignToUser?: boolean;
}
/**
 * 
 * @export
 * @interface CreateCouponResponse
 */
export interface CreateCouponResponse {
    /**
     * Excel file download url. Only available when creating in batch and non-test mode 
     * @type {string}
     * @memberof CreateCouponResponse
     */
    excelFile?: string;
}
/**
 * 
 * @export
 * @interface CreateCustomNotificationDTO
 */
export interface CreateCustomNotificationDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCustomNotificationDTO
     */
    sourceIds: Array<string>;
    /**
     * 
     * @type {CreateFirebaseConsoleCustomPayload}
     * @memberof CreateCustomNotificationDTO
     */
    payload: CreateFirebaseConsoleCustomPayload | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomNotificationDTO
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomNotificationDTO
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomNotificationDTO
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomNotificationDTO
     */
    type: CreateCustomNotificationDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomNotificationDTO
     */
    scheduledDate?: string;
}

/**
* @export
* @enum {string}
*/
export enum CreateCustomNotificationDTOTypeEnum {
    UserGroupUsers = 'UserGroupUsers',
    ProfileFollowers = 'ProfileFollowers',
    ProfileFollowing = 'ProfileFollowing',
    Profile = 'Profile'
}
/**
 * 
 * @export
 * @interface CreateCustomNotificationResponse
 */
export interface CreateCustomNotificationResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomNotificationResponse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CreateEventSettingTemplateRequest
 */
export interface CreateEventSettingTemplateRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateEventSettingTemplateRequest
     */
    commissionFeeRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateEventSettingTemplateRequest
     */
    salesTaxRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateEventSettingTemplateRequest
     */
    shippingFee?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateEventSettingTemplateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEventSettingTemplateRequest
     */
    description?: string | null;
    /**
     * 
     * @type {object}
     * @memberof CreateEventSettingTemplateRequest
     */
    parcel: object;
    /**
     * 
     * @type {string}
     * @memberof CreateEventSettingTemplateRequest
     */
    shipmentProvider?: CreateEventSettingTemplateRequestShipmentProviderEnum;
}

/**
* @export
* @enum {string}
*/
export enum CreateEventSettingTemplateRequestShipmentProviderEnum {
    Ups = 'ups',
    Usps = 'usps',
    UspsFirst = 'usps_first'
}
/**
 * 
 * @export
 * @interface CreateFirebaseConsoleCustomPayload
 */
export interface CreateFirebaseConsoleCustomPayload {
    /**
     * 
     * @type {string}
     * @memberof CreateFirebaseConsoleCustomPayload
     */
    type: CreateFirebaseConsoleCustomPayloadTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateFirebaseConsoleCustomPayload
     */
    liveStreamId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFirebaseConsoleCustomPayload
     */
    showId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFirebaseConsoleCustomPayload
     */
    showEventId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFirebaseConsoleCustomPayload
     */
    userId?: string;
}

/**
* @export
* @enum {string}
*/
export enum CreateFirebaseConsoleCustomPayloadTypeEnum {
    Custom = 'custom'
}
/**
 * 
 * @export
 * @interface CreateFollowerDto
 */
export interface CreateFollowerDto {
    /**
     * 
     * @type {CreateFollowerSource}
     * @memberof CreateFollowerDto
     */
    source?: CreateFollowerSource;
}
/**
 * 
 * @export
 * @interface CreateFollowerSource
 */
export interface CreateFollowerSource {
    /**
     * 
     * @type {string}
     * @memberof CreateFollowerSource
     */
    type: CreateFollowerSourceTypeEnum;
    /**
     * belong type upcoming show
     * @type {string}
     * @memberof CreateFollowerSource
     */
    upcomingShowId?: string;
    /**
     * belong type live stream
     * @type {string}
     * @memberof CreateFollowerSource
     */
    liveStreamId?: string;
}

/**
* @export
* @enum {string}
*/
export enum CreateFollowerSourceTypeEnum {
    LiveStream = 'LIVE_STREAM',
    UpcomingShow = 'UPCOMING_SHOW'
}
/**
 * 
 * @export
 * @interface CreateGlobalMessageDto
 */
export interface CreateGlobalMessageDto {
    /**
     * 
     * @type {string}
     * @memberof CreateGlobalMessageDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateGlobalMessageDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof CreateGlobalMessageDto
     */
    cta?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateGlobalMessageDto
     */
    thumbnail?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateGlobalMessageDto
     */
    enabled: boolean;
}
/**
 * 
 * @export
 * @interface CreateHighlightVideoRequest
 */
export interface CreateHighlightVideoRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateHighlightVideoRequest
     */
    url: string;
}
/**
 * 
 * @export
 * @interface CreateInvitationRequest
 */
export interface CreateInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateInvitationRequest
     */
    code: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateInvitationRequest
     */
    sellerIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateInvitationRequest
     */
    couponCodes: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvitationRequest
     */
    enabled: boolean;
}
/**
 * 
 * @export
 * @interface CreateInvitationResponse
 */
export interface CreateInvitationResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateInvitationResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInvitationResponse
     */
    code: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateInvitationResponse
     */
    sellerIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateInvitationResponse
     */
    couponCodes: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInvitationResponse
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateInvitationResponse
     */
    createdAt?: string;
}
/**
 * 
 * @export
 * @interface CreateInviteRequest
 */
export interface CreateInviteRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateInviteRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInviteRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInviteRequest
     */
    storeFrontName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInviteRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInviteRequest
     */
    phone?: string;
}
/**
 * 
 * @export
 * @interface CreateLinkRequest
 */
export interface CreateLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateLinkRequest
     */
    referrerId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLinkRequest
     */
    type: CreateLinkRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateLinkRequest
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLinkRequest
     */
    isWebOnly?: boolean;
    /**
     * 
     * @type {ContentFDLSocial}
     * @memberof CreateLinkRequest
     */
    social?: ContentFDLSocial;
    /**
     * 
     * @type {Analytics}
     * @memberof CreateLinkRequest
     */
    analytics?: Analytics;
    /**
     * 
     * @type {IOSConfig}
     * @memberof CreateLinkRequest
     */
    ios?: IOSConfig;
    /**
     * 
     * @type {AndroidConfig}
     * @memberof CreateLinkRequest
     */
    android?: AndroidConfig;
    /**
     * 
     * @type {CreateShareTargetItem}
     * @memberof CreateLinkRequest
     */
    shareTarget: CreateShareTargetItem;
    /**
     * 
     * @type {string}
     * @memberof CreateLinkRequest
     */
    method?: CreateLinkRequestMethodEnum;
    /**
     * 
     * @type {LinkActions}
     * @memberof CreateLinkRequest
     */
    actions: LinkActions;
    /**
     * 
     * @type {object}
     * @memberof CreateLinkRequest
     */
    linkParams?: object;
}

/**
* @export
* @enum {string}
*/
export enum CreateLinkRequestTypeEnum {
    Share = 'SHARE',
    Campaign = 'CAMPAIGN'
}/**
* @export
* @enum {string}
*/
export enum CreateLinkRequestMethodEnum {
    Sms = 'SMS',
    Email = 'EMAIL',
    Facebook = 'FACEBOOK',
    Instagram = 'INSTAGRAM',
    Twitter = 'TWITTER',
    Embed = 'EMBED',
    Generic = 'GENERIC'
}
/**
 * 
 * @export
 * @interface CreateLinkResponse
 */
export interface CreateLinkResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateLinkResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLinkResponse
     */
    shortLink: string;
}
/**
 * 
 * @export
 * @interface CreateLiveStreamListingRequest
 */
export interface CreateLiveStreamListingRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateLiveStreamListingRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CreateLiveStreamListingRequest
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLiveStreamListingRequest
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof CreateLiveStreamListingRequest
     */
    defaultImage: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLiveStreamListingRequest
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateLiveStreamListingRequest
     */
    discountPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateLiveStreamListingRequest
     */
    sku?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateLiveStreamListingRequest
     */
    extraImages?: Array<string>;
    /**
     * 
     * @type {Array<LiveStreamListingVariant>}
     * @memberof CreateLiveStreamListingRequest
     */
    variants?: Array<LiveStreamListingVariant>;
    /**
     * 
     * @type {number}
     * @memberof CreateLiveStreamListingRequest
     */
    maximumPurchaseQuantity?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLiveStreamListingRequest
     */
    maximumPurchaseQuantityPerLiveStream?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLiveStreamListingRequest
     */
    shippingFee?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateLiveStreamListingRequest
     */
    tags?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateLiveStreamRequest
 */
export interface CreateLiveStreamRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateLiveStreamRequest
     */
    device: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLiveStreamRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLiveStreamRequest
     */
    upcomingShowId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLiveStreamRequest
     */
    versionTag?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLiveStreamRequest
     */
    showEventId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLiveStreamRequest
     */
    showEventTemplateId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLiveStreamRequest
     */
    permission: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateLiveStreamRequest
     */
    shippingMode?: Array<CreateLiveStreamRequestShippingModeEnum>;
    /**
     * 
     * @type {string}
     * @memberof CreateLiveStreamRequest
     */
    addressId?: string;
    /**
     * prerequisite: nftGatedShows feature enabled for this seller
     * @type {boolean}
     * @memberof CreateLiveStreamRequest
     */
    isNftGated?: boolean;
    /**
     * prerequisite: patreonGatedShows feature enabled for this seller
     * @type {boolean}
     * @memberof CreateLiveStreamRequest
     */
    isPatreonGated?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum CreateLiveStreamRequestShippingModeEnum {
    Shipping = 'shipping',
    Pickup = 'pickup'
}
/**
 * 
 * @export
 * @interface CreateLiveStreamResponse
 */
export interface CreateLiveStreamResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateLiveStreamResponse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CreateMessageLinkRequest
 */
export interface CreateMessageLinkRequest {
    /**
     * 
     * @type {object}
     * @memberof CreateMessageLinkRequest
     */
    linkParams?: object;
}
/**
 * 
 * @export
 * @interface CreateMessageLinkResponse
 */
export interface CreateMessageLinkResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateMessageLinkResponse
     */
    shortLink: string;
}
/**
 * 
 * @export
 * @interface CreateMessageReportRequest
 */
export interface CreateMessageReportRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateMessageReportRequest
     */
    reason: CreateMessageReportRequestReasonEnum;
}

/**
* @export
* @enum {string}
*/
export enum CreateMessageReportRequestReasonEnum {
    Spam = 'spam',
    Inappropriate = 'inappropriate',
    Aggressive = 'aggressive'
}
/**
 * 
 * @export
 * @interface CreateNewsRequest
 */
export interface CreateNewsRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateNewsRequest
     */
    brief: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateNewsRequest
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateNewsRequest
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewsRequest
     */
    startedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewsRequest
     */
    expiredAt?: string;
}
/**
 * 
 * @export
 * @interface CreateNftFakerRequest
 */
export interface CreateNftFakerRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateNftFakerRequest
     */
    walletAddress: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNftFakerRequest
     */
    asAddress: string;
}
/**
 * 
 * @export
 * @interface CreateOrderDto
 */
export interface CreateOrderDto {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    listingId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    sellerId: string;
    /**
     * The recipient of this gift order
     * @type {string}
     * @memberof CreateOrderDto
     */
    recipientId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    sendItemId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    liveStreamId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    variantId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    shippingMethod?: CreateOrderDtoShippingMethodEnum;
    /**
     * Optional for gifting orders
     * @type {AddressDto}
     * @memberof CreateOrderDto
     */
    shippingAddress?: AddressDto | null;
    /**
     * 
     * @type {PickupAddressDto}
     * @memberof CreateOrderDto
     */
    pickupAddress?: PickupAddressDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOrderDto
     */
    redeemedCouponIds?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderDto
     */
    quantity: number;
    /**
     * 
     * @type {LiveStreamSource | TwoWayStreamSource | UpcomingShowSource | StoreSource}
     * @memberof CreateOrderDto
     */
    source?: LiveStreamSource | TwoWayStreamSource | UpcomingShowSource | StoreSource | null;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    nonce?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    creditCardId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    paymentType: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    buyerName: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderDto
     */
    useBonus?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    requestTrackingID: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderDto
     */
    userAgent?: string;
    /**
     * 
     * @type {GiftPackageDto}
     * @memberof CreateOrderDto
     */
    giftPackage?: GiftPackageDto;
}

/**
* @export
* @enum {string}
*/
export enum CreateOrderDtoShippingMethodEnum {
    SellerProvide = 'sellerProvide',
    PopshopProvide = 'popshopProvide',
    Pickup = 'pickup'
}
/**
 * 
 * @export
 * @interface CreateOrderRequest
 */
export interface CreateOrderRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOrderRequest
     */
    masterOrderIds: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateOrderResponse
 */
export interface CreateOrderResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    eventId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    orderNumber: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderResponse
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    buyerName: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderResponse
     */
    buyerPaidAmount: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderResponse
     */
    shippingFee: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderResponse
     */
    shippingFeeAdjusted: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderResponse
     */
    braintreeShippingFeeRefunded?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderResponse
     */
    salesTaxRate?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderResponse
     */
    salesTaxAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderResponse
     */
    total: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    buyerId: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof CreateOrderResponse
     */
    buyer: ProfileItem;
    /**
     * 
     * @type {OrderGiftingItem}
     * @memberof CreateOrderResponse
     */
    gifting?: OrderGiftingItem;
    /**
     * 
     * @type {OrderGiftPackageItem}
     * @memberof CreateOrderResponse
     */
    giftPackage?: OrderGiftPackageItem;
    /**
     * 
     * @type {ShipmentItem}
     * @memberof CreateOrderResponse
     */
    shipment?: ShipmentItem;
    /**
     * 
     * @type {PackingSlipItem}
     * @memberof CreateOrderResponse
     */
    packingSlip?: PackingSlipItem;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderResponse
     */
    refund?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    userAgent?: string;
    /**
     * 
     * @type {OrderPaymentItem}
     * @memberof CreateOrderResponse
     */
    orderPayment?: OrderPaymentItem;
    /**
     * 
     * @type {Array<RedeemedCouponItem>}
     * @memberof CreateOrderResponse
     */
    coupons?: Array<RedeemedCouponItem>;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderResponse
     */
    couponDiscountProductAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderResponse
     */
    couponDiscountShippingFeeAmount?: number;
    /**
     * 
     * @type {SellerTrackingInfoItem}
     * @memberof CreateOrderResponse
     */
    sellerTrackingInfo?: SellerTrackingInfoItem;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderResponse
     */
    sellerGotAmount: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderResponse
     */
    bonus?: number;
    /**
     * 
     * @type {AddressItem}
     * @memberof CreateOrderResponse
     */
    shippingAddress: AddressItem;
    /**
     * 
     * @type {PickupAddressItem}
     * @memberof CreateOrderResponse
     */
    pickupAddress?: PickupAddressItem;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    listingId: string;
    /**
     * 
     * @type {StoreListingItem}
     * @memberof CreateOrderResponse
     */
    listingSnapshot: StoreListingItem;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    sendItemId?: string;
    /**
     * 
     * @type {SendItemItem}
     * @memberof CreateOrderResponse
     */
    sendItem?: SendItemItem;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    sellerId: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof CreateOrderResponse
     */
    seller: ProfileItem;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    status: CreateOrderResponseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    pickupStatus?: CreateOrderResponsePickupStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    cancelStatus?: CreateOrderResponseCancelStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    shippingMethod: CreateOrderResponseShippingMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    shippoOrderId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    liveStreamId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    variantId?: string;
    /**
     * 
     * @type {StoreFrontItem}
     * @memberof CreateOrderResponse
     */
    store: StoreFrontItem;
    /**
     * 
     * @type {OrderShipmentGroupStatus}
     * @memberof CreateOrderResponse
     */
    orderShipmentGroupStatus: OrderShipmentGroupStatus;
    /**
     * 
     * @type {ShippingLabelStatus}
     * @memberof CreateOrderResponse
     */
    shippingLabelStatus: ShippingLabelStatus;
    /**
     * 
     * @type {ShippingLabelStatus}
     * @memberof CreateOrderResponse
     */
    packingSlipStatus: ShippingLabelStatus;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    masterOrderId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    planTimeOfShippingLabelGeneration?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderResponse
     */
    totalQuantitiesIncludeSlaveOrders: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderResponse
     */
    totalPriceIncludeSlaveOrders: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderResponse
     */
    totalPriceIncludeSlaveOrdersWithProductCoupon: number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrderResponse
     */
    totalRefundIncludeSlaveOrders?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderResponse
     */
    newCustomer?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    firstPurchaseAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    updatedAt?: string;
    /**
     * 
     * @type {OrderCompositionCancellationStatus}
     * @memberof CreateOrderResponse
     */
    orderCompositionCancellationStatus?: OrderCompositionCancellationStatus;
    /**
     * 
     * @type {OrderChargeDistributionItem}
     * @memberof CreateOrderResponse
     */
    orderChargeDistribution?: OrderChargeDistributionItem;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderResponse
     */
    packed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    pickupNotifiedAt?: string;
    /**
     * 
     * @type {OrderSource}
     * @memberof CreateOrderResponse
     */
    source?: OrderSource;
    /**
     * 
     * @type {OrderShipmentParcel}
     * @memberof CreateOrderResponse
     */
    shipmentParcel?: OrderShipmentParcel;
    /**
     * 
     * @type {OrderShipmentParcel}
     * @memberof CreateOrderResponse
     */
    shipmentOriginalParcel?: OrderShipmentParcel;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    shipmentOriginalParcelSource?: CreateOrderResponseShipmentOriginalParcelSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateOrderResponse
     */
    orderId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOrderResponse
     */
    isFirstPurchaseFromSeller: boolean;
}

/**
* @export
* @enum {string}
*/
export enum CreateOrderResponseStatusEnum {
    Unshipped = 'unshipped',
    Shipped = 'shipped',
    Completed = 'completed',
    Canceled = 'canceled'
}/**
* @export
* @enum {string}
*/
export enum CreateOrderResponsePickupStatusEnum {
    Open = 'open',
    New = 'new',
    Processing = 'processing',
    Pending = 'pending',
    Packed = 'packed',
    Completed = 'completed',
    Canceled = 'canceled'
}/**
* @export
* @enum {string}
*/
export enum CreateOrderResponseCancelStatusEnum {
    SellerApplied = 'seller_applied',
    AdminApproved = 'admin_approved'
}/**
* @export
* @enum {string}
*/
export enum CreateOrderResponseShippingMethodEnum {
    SellerProvide = 'sellerProvide',
    PopshopProvide = 'popshopProvide',
    Pickup = 'pickup'
}/**
* @export
* @enum {string}
*/
export enum CreateOrderResponseShipmentOriginalParcelSourceEnum {
    Default = 'DEFAULT',
    ShowEvent = 'SHOW_EVENT'
}
/**
 * 
 * @export
 * @interface CreatePayoutMethodDto
 */
export interface CreatePayoutMethodDto {
    /**
     * 
     * @type {PayoutBankDto}
     * @memberof CreatePayoutMethodDto
     */
    payoutMethodBank: PayoutBankDto;
}
/**
 * 
 * @export
 * @interface CreatePayoutMethodResponse
 */
export interface CreatePayoutMethodResponse {
    /**
     * 
     * @type {string}
     * @memberof CreatePayoutMethodResponse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CreatePickupAddressRequest
 */
export interface CreatePickupAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePickupAddressRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePickupAddressRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePickupAddressRequest
     */
    primaryPhone: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePickupAddressRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePickupAddressRequest
     */
    line1: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePickupAddressRequest
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePickupAddressRequest
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePickupAddressRequest
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePickupAddressRequest
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePickupAddressRequest
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePickupAddressRequest
     */
    pickupNote?: string;
}
/**
 * 
 * @export
 * @interface CreateSellerApplicationTemplateRequest
 */
export interface CreateSellerApplicationTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateSellerApplicationTemplateRequest
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSellerApplicationTemplateRequest
     */
    sellerUserGroupIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerApplicationTemplateRequest
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSellerApplicationTemplateRequest
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerApplicationTemplateRequest
     */
    image?: string;
}
/**
 * 
 * @export
 * @interface CreateSellerApplicationsRequest
 */
export interface CreateSellerApplicationsRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateSellerApplicationsRequest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSellerApplicationsRequest
     */
    sellerId?: string;
}
/**
 * 
 * @export
 * @interface CreateSendItemRequest
 */
export interface CreateSendItemRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateSendItemRequest
     */
    listingId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSendItemRequest
     */
    variantId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSendItemRequest
     */
    to: string;
    /**
     * 
     * @type {number}
     * @memberof CreateSendItemRequest
     */
    quantity?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSendItemRequest
     */
    needShippingFee: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateSendItemRequest
     */
    price?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSendItemRequest
     */
    noNeedNotification?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateSendItemRequest
     */
    auctionId?: string;
}
/**
 * 
 * @export
 * @interface CreateSendItemResponse
 */
export interface CreateSendItemResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateSendItemResponse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CreateShareTargetItem
 */
export interface CreateShareTargetItem {
    /**
     * 
     * @type {string}
     * @memberof CreateShareTargetItem
     */
    type: CreateShareTargetItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateShareTargetItem
     */
    profileId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShareTargetItem
     */
    storeId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShareTargetItem
     */
    liveStreamId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShareTargetItem
     */
    listingId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShareTargetItem
     */
    showEventId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShareTargetItem
     */
    ticketId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShareTargetItem
     */
    upcomingShowId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShareTargetItem
     */
    promotionId?: string;
}

/**
* @export
* @enum {string}
*/
export enum CreateShareTargetItemTypeEnum {
    Profile = 'PROFILE',
    LiveStream = 'LIVE_STREAM',
    UpcomingShow = 'UPCOMING_SHOW',
    ListingLiveStream = 'LISTING_LIVE_STREAM',
    ListingEvergreen = 'LISTING_EVERGREEN',
    ListingUpcomingShow = 'LISTING_UPCOMING_SHOW',
    ListingPromotion = 'LISTING_PROMOTION',
    Event = 'EVENT',
    EventDiscovery = 'EVENT_DISCOVERY',
    EventTicket = 'EVENT_TICKET',
    HomePage = 'HOME_PAGE',
    Promotion = 'PROMOTION'
}
/**
 * 
 * @export
 * @interface CreateShareTargetRequest
 */
export interface CreateShareTargetRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateShareTargetRequest
     */
    type: CreateShareTargetRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateShareTargetRequest
     */
    profileId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShareTargetRequest
     */
    storeId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShareTargetRequest
     */
    liveStreamId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShareTargetRequest
     */
    listingId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShareTargetRequest
     */
    showEventId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShareTargetRequest
     */
    upcomingShowId?: string;
}

/**
* @export
* @enum {string}
*/
export enum CreateShareTargetRequestTypeEnum {
    Profile = 'PROFILE',
    LiveStream = 'LIVE_STREAM',
    UpcomingShow = 'UPCOMING_SHOW',
    ListingLiveStream = 'LISTING_LIVE_STREAM',
    ListingEvergreen = 'LISTING_EVERGREEN',
    ListingUpcomingShow = 'LISTING_UPCOMING_SHOW',
    ListingPromotion = 'LISTING_PROMOTION',
    Event = 'EVENT',
    EventDiscovery = 'EVENT_DISCOVERY',
    EventTicket = 'EVENT_TICKET',
    HomePage = 'HOME_PAGE',
    Promotion = 'PROMOTION'
}
/**
 * 
 * @export
 * @interface CreateShipmentRequest
 */
export interface CreateShipmentRequest {
    /**
     * 
     * @type {CreateShipmentRequestParcel}
     * @memberof CreateShipmentRequest
     */
    parcel: CreateShipmentRequestParcel;
}
/**
 * 
 * @export
 * @interface CreateShipmentRequestParcel
 */
export interface CreateShipmentRequestParcel {
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentRequestParcel
     */
    distanceUnit: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentRequestParcel
     */
    height: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentRequestParcel
     */
    length: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentRequestParcel
     */
    massUnit: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentRequestParcel
     */
    weight: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentRequestParcel
     */
    width: string;
}
/**
 * 
 * @export
 * @interface CreateShowEventRequest
 */
export interface CreateShowEventRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateShowEventRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShowEventRequest
     */
    urlCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateShowEventRequest
     */
    sellerUserGroupIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateShowEventRequest
     */
    userGroupIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateShowEventRequest
     */
    previewVideoURL?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShowEventRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShowEventRequest
     */
    backgroundURL?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShowEventRequest
     */
    verticalBackgroundURL?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateShowEventRequest
     */
    salesTaxRate?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateShowEventRequest
     */
    maximumPurchaseQuantity?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateShowEventRequest
     */
    startedAfter?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShowEventRequest
     */
    endedBefore?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateShowEventRequest
     */
    shippingFee?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateShowEventRequest
     */
    isClosed?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateShowEventRequest
     */
    visibilityGroupIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateShowEventRequest
     */
    sortedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateShowEventRequest
     */
    extraCommissionFeeRate?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateShowEventRequest
     */
    featuredStoreStackName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShowEventRequest
     */
    sellerApplicationDisclosure?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShowEventRequest
     */
    sellerApplicationDisclosureUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateShowEventRequest
     */
    unBundleOnDiscovery?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateShowEventRequest
     */
    visibilityOnDiscoveryBanner?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateShowEventRequest
     */
    customLightingBoltImages?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof CreateShowEventRequest
     */
    parcel: object;
    /**
     * 
     * @type {string}
     * @memberof CreateShowEventRequest
     */
    shipmentProvider?: CreateShowEventRequestShipmentProviderEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CreateShowEventRequest
     */
    visibleOnCategoryBanner?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateShowEventRequest
     */
    categoryCoverUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateShowEventRequest
     */
    active: boolean;
}

/**
* @export
* @enum {string}
*/
export enum CreateShowEventRequestShipmentProviderEnum {
    Ups = 'ups',
    Usps = 'usps',
    UspsFirst = 'usps_first'
}
/**
 * 
 * @export
 * @interface CreateShowEventResponse
 */
export interface CreateShowEventResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateShowEventResponse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CreateShowEventTemplateDto
 */
export interface CreateShowEventTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof CreateShowEventTemplateDto
     */
    type: CreateShowEventTemplateDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateShowEventTemplateDto
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateShowEventTemplateDto
     */
    userGroupIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateShowEventTemplateDto
     */
    startedAfter: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShowEventTemplateDto
     */
    endedBefore: string;
}

/**
* @export
* @enum {string}
*/
export enum CreateShowEventTemplateDtoTypeEnum {
    TimeRange = 'timeRange'
}
/**
 * 
 * @export
 * @interface CreateShowTicketRequest
 */
export interface CreateShowTicketRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateShowTicketRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShowTicketRequest
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateShowTicketRequest
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof CreateShowTicketRequest
     */
    discountPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateShowTicketRequest
     */
    sku?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateShowTicketRequest
     */
    userGroupIds: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CreateShowTicketRequest
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateShowTicketRequest
     */
    maximumPurchaseQuantity?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateShowTicketRequest
     */
    upgradeTicket?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof CreateShowTicketRequest
     */
    fromTicketId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateShowTicketRequest
     */
    toTicketId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateShowTicketRequest
     */
    emailConfirmationDisclosure?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShowTicketRequest
     */
    emailConfirmationDisclosureUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateShowTicketRequest
     */
    emailConfirmationHeaderBackgroundUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateShowTicketRequest
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateShowTicketRequest
     */
    requireShipping: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateShowTicketRequest
     */
    shippingFee?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateShowTicketRequest
     */
    defaultImage: string;
}
/**
 * 
 * @export
 * @interface CreateStoreListingRequest
 */
export interface CreateStoreListingRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateStoreListingRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CreateStoreListingRequest
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof CreateStoreListingRequest
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof CreateStoreListingRequest
     */
    defaultImage: string;
    /**
     * 
     * @type {string}
     * @memberof CreateStoreListingRequest
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateStoreListingRequest
     */
    discountPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateStoreListingRequest
     */
    sku?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateStoreListingRequest
     */
    extraImages?: Array<string>;
    /**
     * 
     * @type {Array<StoreListingVariant>}
     * @memberof CreateStoreListingRequest
     */
    variants?: Array<StoreListingVariant>;
    /**
     * 
     * @type {number}
     * @memberof CreateStoreListingRequest
     */
    maximumPurchaseQuantity?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateStoreListingRequest
     */
    maximumPurchaseQuantityPerLiveStream?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateStoreListingRequest
     */
    shippingFee?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateStoreListingRequest
     */
    tags?: Array<string>;
    /**
     * 
     * @type {StoreListingOriginalInfo}
     * @memberof CreateStoreListingRequest
     */
    originalInfo?: StoreListingOriginalInfo;
}
/**
 * 
 * @export
 * @interface CreateStoreListingResponse
 */
export interface CreateStoreListingResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateStoreListingResponse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CreateStoreRequest
 */
export interface CreateStoreRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateStoreRequest
     */
    passcode?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateStoreRequest
     */
    storeName?: string;
    /**
     * 
     * @type {SellerAddressRequest}
     * @memberof CreateStoreRequest
     */
    sellerAddress?: SellerAddressRequest;
    /**
     * 
     * @type {string}
     * @memberof CreateStoreRequest
     */
    userId: string;
}
/**
 * 
 * @export
 * @interface CreateTicketOrderRequest
 */
export interface CreateTicketOrderRequest {
    /**
     * 
     * @type {TicketOrderAddressRequest}
     * @memberof CreateTicketOrderRequest
     */
    shippingAddress?: TicketOrderAddressRequest;
    /**
     * 
     * @type {string}
     * @memberof CreateTicketOrderRequest
     */
    creditCardId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTicketOrderRequest
     */
    nonce?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTicketOrderRequest
     */
    paymentType: CreateTicketOrderRequestPaymentTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum CreateTicketOrderRequestPaymentTypeEnum {
    Credit = 'credit',
    Applepay = 'applepay',
    Empty = 'empty'
}
/**
 * 
 * @export
 * @interface CreateTicketOrderResponse
 */
export interface CreateTicketOrderResponse {
    /**
     * 
     * @type {number}
     * @memberof CreateTicketOrderResponse
     */
    id: number;
}
/**
 * 
 * @export
 * @interface CreateTwoWayStreamRequest
 */
export interface CreateTwoWayStreamRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTwoWayStreamRequest
     */
    secondaryUserId: string;
}
/**
 * 
 * @export
 * @interface CreateUpcomingShowBySellerDto
 */
export interface CreateUpcomingShowBySellerDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpcomingShowBySellerDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpcomingShowBySellerDto
     */
    startsAt: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpcomingShowBySellerDto
     */
    permission?: CreateUpcomingShowBySellerDtoPermissionEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpcomingShowBySellerDto
     */
    eventId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpcomingShowBySellerDto
     */
    eventTemplateId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpcomingShowBySellerDto
     */
    userGroupIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateUpcomingShowBySellerDto
     */
    previewVideoURL: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpcomingShowBySellerDto
     */
    coverImage?: string;
}

/**
* @export
* @enum {string}
*/
export enum CreateUpcomingShowBySellerDtoPermissionEnum {
    All = 'all',
    Follower = 'follower',
    NoOne = 'no-one'
}
/**
 * 
 * @export
 * @interface CreateUpcomingShowDto
 */
export interface CreateUpcomingShowDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpcomingShowDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpcomingShowDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpcomingShowDto
     */
    startsAt: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpcomingShowDto
     */
    permission?: CreateUpcomingShowDtoPermissionEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUpcomingShowDto
     */
    eventId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpcomingShowDto
     */
    userGroupIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpcomingShowDto
     */
    available: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpcomingShowDto
     */
    shareVideo?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpcomingShowDto
     */
    previewVideoURL: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpcomingShowDto
     */
    coverImage?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpcomingShowDto
     */
    originalCoverImage?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpcomingShowDto
     */
    transcodeStatus?: CreateUpcomingShowDtoTranscodeStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum CreateUpcomingShowDtoPermissionEnum {
    All = 'all',
    Follower = 'follower',
    NoOne = 'no-one'
}/**
* @export
* @enum {string}
*/
export enum CreateUpcomingShowDtoTranscodeStatusEnum {
    Initial = 'initial',
    Processing = 'processing',
    TranscodeOriginal = 'transcode-original',
    TranscodeOriginalShare = 'transcode-original-share',
    RequestRetranscode = 'request-retranscode'
}
/**
 * 
 * @export
 * @interface CreateUserGroupDto
 */
export interface CreateUserGroupDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserGroupDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserGroupDto
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserGroupDto
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUserGroupDto
     */
    userType?: CreateUserGroupDtoUserTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum CreateUserGroupDtoUserTypeEnum {
    Normal = 'normal',
    Seller = 'seller',
    VerifiedSeller = 'verifiedSeller'
}
/**
 * 
 * @export
 * @interface CreateUserParamsV2
 */
export interface CreateUserParamsV2 {
    /**
     * 
     * @type {string}
     * @memberof CreateUserParamsV2
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserParamsV2
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserParamsV2
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserParamsV2
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserParamsV2
     */
    bio?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserParamsV2
     */
    avatar?: string | null;
}
/**
 * 
 * @export
 * @interface CreateVideoMessageRequest
 */
export interface CreateVideoMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateVideoMessageRequest
     */
    orderId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVideoMessageRequest
     */
    userIdTo: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVideoMessageRequest
     */
    videoURL: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVideoMessageRequest
     */
    emoji?: CreateVideoMessageRequestEmojiEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateVideoMessageRequest
     */
    purpose?: CreateVideoMessageRequestPurposeEnum;
}

/**
* @export
* @enum {string}
*/
export enum CreateVideoMessageRequestEmojiEnum {
    RollingEyesFace = 'rolling_eyes_face',
    Sweat = 'sweat',
    Blush = 'blush',
    Smile = 'smile',
    HeartEyes = 'heart_eyes'
}/**
* @export
* @enum {string}
*/
export enum CreateVideoMessageRequestPurposeEnum {
    Review = 'review',
    ThankYou = 'thankYou'
}
/**
 * 
 * @export
 * @interface CreateVideoMessageResponse
 */
export interface CreateVideoMessageResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateVideoMessageResponse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface CreateWithdrawByAdminDto
 */
export interface CreateWithdrawByAdminDto {
    /**
     * 
     * @type {number}
     * @memberof CreateWithdrawByAdminDto
     */
    withdrawableAmount: number;
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawByAdminDto
     */
    userAgent?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawByAdminDto
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawByAdminDto
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawByAdminDto
     */
    userId: string;
}
/**
 * 
 * @export
 * @interface CreateWithdrawDto
 */
export interface CreateWithdrawDto {
    /**
     * 
     * @type {number}
     * @memberof CreateWithdrawDto
     */
    withdrawableAmount: number;
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawDto
     */
    userAgent?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawDto
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateWithdrawDto
     */
    note?: string;
}
/**
 * 
 * @export
 * @interface CustomTokenDto
 */
export interface CustomTokenDto {
    /**
     * 
     * @type {string}
     * @memberof CustomTokenDto
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof CustomTokenDto
     */
    storeId: string;
}
/**
 * 
 * @export
 * @interface CustomUserClaim
 */
export interface CustomUserClaim {
    /**
     * 
     * @type {boolean}
     * @memberof CustomUserClaim
     */
    isAdmin?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomUserClaim
     */
    roles?: Array<CustomUserClaimRolesEnum>;
}

/**
* @export
* @enum {string}
*/
export enum CustomUserClaimRolesEnum {
    Moderator = 'moderator',
    Seller = 'seller',
    SystemAdmin = 'system-admin',
    User = 'user',
    Finance = 'finance'
}
/**
 * 
 * @export
 * @interface DiscoveryCategoryEventsResponse
 */
export interface DiscoveryCategoryEventsResponse {
    /**
     * 
     * @type {Array<ShowEventWithoutMetaDataItem>}
     * @memberof DiscoveryCategoryEventsResponse
     */
    data: Array<ShowEventWithoutMetaDataItem>;
}
/**
 * 
 * @export
 * @interface DiscoveryFeaturedEventsResponse
 */
export interface DiscoveryFeaturedEventsResponse {
    /**
     * 
     * @type {Array<EventFeaturedShowItem>}
     * @memberof DiscoveryFeaturedEventsResponse
     */
    data: Array<EventFeaturedShowItem>;
}
/**
 * 
 * @export
 * @interface DiscoveryRowShowsResponse
 */
export interface DiscoveryRowShowsResponse {
    /**
     * 
     * @type {Array<DiscoveryShowLiveStreamItem | DiscoveryShowUpcomingShowItem>}
     * @memberof DiscoveryRowShowsResponse
     */
    data: Array<DiscoveryShowLiveStreamItem | DiscoveryShowUpcomingShowItem>;
}
/**
 * 
 * @export
 * @interface DiscoveryShowLiveStreamItem
 */
export interface DiscoveryShowLiveStreamItem {
    /**
     * 
     * @type {string}
     * @memberof DiscoveryShowLiveStreamItem
     */
    type: DiscoveryShowLiveStreamItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DiscoveryShowLiveStreamItem
     */
    liveStreamId: string;
    /**
     * 
     * @type {LiveStreamItem}
     * @memberof DiscoveryShowLiveStreamItem
     */
    liveStream: LiveStreamItem;
}

/**
* @export
* @enum {string}
*/
export enum DiscoveryShowLiveStreamItemTypeEnum {
    LiveStream = 'liveStream'
}
/**
 * 
 * @export
 * @interface DiscoveryShowPromotionPageItem
 */
export interface DiscoveryShowPromotionPageItem {
    /**
     * 
     * @type {string}
     * @memberof DiscoveryShowPromotionPageItem
     */
    type: DiscoveryShowPromotionPageItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DiscoveryShowPromotionPageItem
     */
    promotionPageId: string;
    /**
     * 
     * @type {BasePromotionPageItem}
     * @memberof DiscoveryShowPromotionPageItem
     */
    promotionPage: BasePromotionPageItem;
}

/**
* @export
* @enum {string}
*/
export enum DiscoveryShowPromotionPageItemTypeEnum {
    PromotionPage = 'promotionPage'
}
/**
 * 
 * @export
 * @interface DiscoveryShowResponse
 */
export interface DiscoveryShowResponse {
    /**
     * 
     * @type {Array<DiscoveryShowLiveStreamItem | DiscoveryShowPromotionPageItem | DiscoveryShowUpcomingShowItem>}
     * @memberof DiscoveryShowResponse
     */
    data: Array<DiscoveryShowLiveStreamItem | DiscoveryShowPromotionPageItem | DiscoveryShowUpcomingShowItem>;
}
/**
 * 
 * @export
 * @interface DiscoveryShowUpcomingShowItem
 */
export interface DiscoveryShowUpcomingShowItem {
    /**
     * 
     * @type {string}
     * @memberof DiscoveryShowUpcomingShowItem
     */
    type: DiscoveryShowUpcomingShowItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DiscoveryShowUpcomingShowItem
     */
    upcomingShowId: string;
    /**
     * 
     * @type {BaseUpcomingShowItem}
     * @memberof DiscoveryShowUpcomingShowItem
     */
    upcomingShow: BaseUpcomingShowItem;
}

/**
* @export
* @enum {string}
*/
export enum DiscoveryShowUpcomingShowItemTypeEnum {
    UpcomingShow = 'upcomingShow'
}
/**
 * 
 * @export
 * @interface DiscoveryShowsByEventResponse
 */
export interface DiscoveryShowsByEventResponse {
    /**
     * 
     * @type {Array<DiscoveryShowLiveStreamItem | DiscoveryShowUpcomingShowItem>}
     * @memberof DiscoveryShowsByEventResponse
     */
    data: Array<DiscoveryShowLiveStreamItem | DiscoveryShowUpcomingShowItem>;
}
/**
 * 
 * @export
 * @interface DiscoveryUnBundleEventRow
 */
export interface DiscoveryUnBundleEventRow {
    /**
     * 
     * @type {string}
     * @memberof DiscoveryUnBundleEventRow
     */
    eventId: string;
    /**
     * 
     * @type {string}
     * @memberof DiscoveryUnBundleEventRow
     */
    eventName: string;
}
/**
 * 
 * @export
 * @interface DiscoveryUnBundleEventsResponse
 */
export interface DiscoveryUnBundleEventsResponse {
    /**
     * 
     * @type {Array<DiscoveryUnBundleEventRow>}
     * @memberof DiscoveryUnBundleEventsResponse
     */
    data: Array<DiscoveryUnBundleEventRow>;
}
/**
 * 
 * @export
 * @interface DisputeStatusHistoryItem
 */
export interface DisputeStatusHistoryItem {
    /**
     * 
     * @type {string}
     * @memberof DisputeStatusHistoryItem
     */
    disbursementDate: string;
    /**
     * 
     * @type {string}
     * @memberof DisputeStatusHistoryItem
     */
    effectiveDate: string;
    /**
     * 
     * @type {string}
     * @memberof DisputeStatusHistoryItem
     */
    status: DisputeStatusHistoryItemStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DisputeStatusHistoryItem
     */
    timestamp: string;
}

/**
* @export
* @enum {string}
*/
export enum DisputeStatusHistoryItemStatusEnum {
    Accepted = 'Accepted',
    Disputed = 'Disputed',
    Expired = 'Expired',
    Lost = 'Lost',
    Won = 'Won',
    Open = 'Open'
}
/**
 * 
 * @export
 * @interface Edge
 */
export interface Edge {
    /**
     * 
     * @type {string}
     * @memberof Edge
     */
    cursor: string;
    /**
     * 
     * @type {StoreListingItem}
     * @memberof Edge
     */
    node: StoreListingItem;
}
/**
 * 
 * @export
 * @interface EditCategoryRequest
 */
export interface EditCategoryRequest {
    /**
     * 
     * @type {string}
     * @memberof EditCategoryRequest
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof EditCategoryRequest
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof EditCategoryRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EditCategoryRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof EditCategoryRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EditCategoryRequest
     */
    rootCategory?: string;
    /**
     * 
     * @type {number}
     * @memberof EditCategoryRequest
     */
    sort?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EditCategoryRequest
     */
    enabled?: boolean;
}
/**
 * 
 * @export
 * @interface EditCouponByAdminDto
 */
export interface EditCouponByAdminDto {
    /**
     * 
     * @type {string}
     * @memberof EditCouponByAdminDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof EditCouponByAdminDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EditCouponByAdminDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof EditCouponByAdminDto
     */
    pagUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof EditCouponByAdminDto
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditCouponByAdminDto
     */
    storeIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EditCouponByAdminDto
     */
    startedAt: string;
    /**
     * 
     * @type {string}
     * @memberof EditCouponByAdminDto
     */
    expiredAt: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditCouponByAdminDto
     */
    userGroupIds?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof EditCouponByAdminDto
     */
    minimalPurchase?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EditCouponByAdminDto
     */
    exclusive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditCouponByAdminDto
     */
    oncePerStore?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditCouponByAdminDto
     */
    onlyPurchasedUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditCouponByAdminDto
     */
    onlyNewUserPerStore?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditCouponByAdminDto
     */
    onlyReactivatedUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditCouponByAdminDto
     */
    onlyNewUser?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditCouponByAdminDto
     */
    onlyFollower?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditCouponByAdminDto
     */
    onlyPopshopShipping?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditCouponByAdminDto
     */
    followSelectedSellers?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditCouponByAdminDto
     */
    sellerGroupIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface EditCouponBySellerDto
 */
export interface EditCouponBySellerDto {
    /**
     * 
     * @type {string}
     * @memberof EditCouponBySellerDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof EditCouponBySellerDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EditCouponBySellerDto
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof EditCouponBySellerDto
     */
    pagUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof EditCouponBySellerDto
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditCouponBySellerDto
     */
    storeIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EditCouponBySellerDto
     */
    startedAt: string;
    /**
     * 
     * @type {string}
     * @memberof EditCouponBySellerDto
     */
    expiredAt: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditCouponBySellerDto
     */
    userGroupIds?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof EditCouponBySellerDto
     */
    minimalPurchase?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EditCouponBySellerDto
     */
    exclusive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditCouponBySellerDto
     */
    onlyAssignToUser?: boolean;
}
/**
 * 
 * @export
 * @interface EditEventSettingTemplateRequest
 */
export interface EditEventSettingTemplateRequest {
    /**
     * 
     * @type {number}
     * @memberof EditEventSettingTemplateRequest
     */
    commissionFeeRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EditEventSettingTemplateRequest
     */
    salesTaxRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EditEventSettingTemplateRequest
     */
    shippingFee?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EditEventSettingTemplateRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EditEventSettingTemplateRequest
     */
    description?: string | null;
    /**
     * 
     * @type {object}
     * @memberof EditEventSettingTemplateRequest
     */
    parcel: object;
    /**
     * 
     * @type {string}
     * @memberof EditEventSettingTemplateRequest
     */
    shipmentProvider?: EditEventSettingTemplateRequestShipmentProviderEnum;
}

/**
* @export
* @enum {string}
*/
export enum EditEventSettingTemplateRequestShipmentProviderEnum {
    Ups = 'ups',
    Usps = 'usps',
    UspsFirst = 'usps_first'
}
/**
 * 
 * @export
 * @interface EditInvitationRequest
 */
export interface EditInvitationRequest {
    /**
     * 
     * @type {string}
     * @memberof EditInvitationRequest
     */
    code?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditInvitationRequest
     */
    sellerIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditInvitationRequest
     */
    couponCodes?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof EditInvitationRequest
     */
    enabled?: boolean;
}
/**
 * 
 * @export
 * @interface EditLinkRequest
 */
export interface EditLinkRequest {
    /**
     * 
     * @type {boolean}
     * @memberof EditLinkRequest
     */
    isActive: boolean;
}
/**
 * 
 * @export
 * @interface EditLiveStreamListingRequest
 */
export interface EditLiveStreamListingRequest {
    /**
     * 
     * @type {string}
     * @memberof EditLiveStreamListingRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof EditLiveStreamListingRequest
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof EditLiveStreamListingRequest
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof EditLiveStreamListingRequest
     */
    defaultImage: string;
    /**
     * 
     * @type {string}
     * @memberof EditLiveStreamListingRequest
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof EditLiveStreamListingRequest
     */
    discountPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof EditLiveStreamListingRequest
     */
    sku?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditLiveStreamListingRequest
     */
    extraImages?: Array<string>;
    /**
     * 
     * @type {Array<LiveStreamListingVariant>}
     * @memberof EditLiveStreamListingRequest
     */
    variants?: Array<LiveStreamListingVariant>;
    /**
     * 
     * @type {number}
     * @memberof EditLiveStreamListingRequest
     */
    maximumPurchaseQuantity?: number;
    /**
     * 
     * @type {number}
     * @memberof EditLiveStreamListingRequest
     */
    maximumPurchaseQuantityPerLiveStream?: number;
    /**
     * 
     * @type {number}
     * @memberof EditLiveStreamListingRequest
     */
    shippingFee?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditLiveStreamListingRequest
     */
    tags?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof EditLiveStreamListingRequest
     */
    currentQuantity: number;
    /**
     * 
     * @type {Array<LiveStreamListingVariant>}
     * @memberof EditLiveStreamListingRequest
     */
    currentVariants?: Array<LiveStreamListingVariant>;
}
/**
 * 
 * @export
 * @interface EditLiveStreamRequest
 */
export interface EditLiveStreamRequest {
    /**
     * 
     * @type {string}
     * @memberof EditLiveStreamRequest
     */
    mode: string;
    /**
     * 
     * @type {string}
     * @memberof EditLiveStreamRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof EditLiveStreamRequest
     */
    background: string;
    /**
     * 
     * @type {string}
     * @memberof EditLiveStreamRequest
     */
    preview: string;
    /**
     * 
     * @type {string}
     * @memberof EditLiveStreamRequest
     */
    showEventId: string;
    /**
     * 
     * @type {string}
     * @memberof EditLiveStreamRequest
     */
    permission: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditLiveStreamRequest
     */
    shippingMode?: Array<EditLiveStreamRequestShippingModeEnum>;
    /**
     * 
     * @type {string}
     * @memberof EditLiveStreamRequest
     */
    addressId?: string;
}

/**
* @export
* @enum {string}
*/
export enum EditLiveStreamRequestShippingModeEnum {
    Shipping = 'shipping',
    Pickup = 'pickup'
}
/**
 * 
 * @export
 * @interface EditNewsRequest
 */
export interface EditNewsRequest {
    /**
     * 
     * @type {string}
     * @memberof EditNewsRequest
     */
    brief: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditNewsRequest
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditNewsRequest
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof EditNewsRequest
     */
    startedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof EditNewsRequest
     */
    expiredAt?: string;
}
/**
 * 
 * @export
 * @interface EditPayoutRequest
 */
export interface EditPayoutRequest {
    /**
     * 
     * @type {string}
     * @memberof EditPayoutRequest
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof EditPayoutRequest
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof EditPayoutRequest
     */
    status: EditPayoutRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof EditPayoutRequest
     */
    payoutDate?: string;
}

/**
* @export
* @enum {string}
*/
export enum EditPayoutRequestStatusEnum {
    Pending = 'Pending',
    Processed = 'Processed',
    Completed = 'Completed',
    Failed = 'Failed'
}
/**
 * 
 * @export
 * @interface EditSellerApplicationTemplateRequest
 */
export interface EditSellerApplicationTemplateRequest {
    /**
     * 
     * @type {string}
     * @memberof EditSellerApplicationTemplateRequest
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditSellerApplicationTemplateRequest
     */
    sellerUserGroupIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EditSellerApplicationTemplateRequest
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditSellerApplicationTemplateRequest
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditSellerApplicationTemplateRequest
     */
    image?: string;
}
/**
 * 
 * @export
 * @interface EditShowEventRequest
 */
export interface EditShowEventRequest {
    /**
     * 
     * @type {string}
     * @memberof EditShowEventRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EditShowEventRequest
     */
    urlCode: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditShowEventRequest
     */
    sellerUserGroupIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditShowEventRequest
     */
    userGroupIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EditShowEventRequest
     */
    previewVideoURL?: string;
    /**
     * 
     * @type {string}
     * @memberof EditShowEventRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof EditShowEventRequest
     */
    backgroundURL?: string;
    /**
     * 
     * @type {string}
     * @memberof EditShowEventRequest
     */
    verticalBackgroundURL?: string;
    /**
     * 
     * @type {number}
     * @memberof EditShowEventRequest
     */
    salesTaxRate?: number;
    /**
     * 
     * @type {number}
     * @memberof EditShowEventRequest
     */
    maximumPurchaseQuantity?: number;
    /**
     * 
     * @type {string}
     * @memberof EditShowEventRequest
     */
    startedAfter?: string;
    /**
     * 
     * @type {string}
     * @memberof EditShowEventRequest
     */
    endedBefore?: string;
    /**
     * 
     * @type {number}
     * @memberof EditShowEventRequest
     */
    shippingFee?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EditShowEventRequest
     */
    isClosed?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditShowEventRequest
     */
    visibilityGroupIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EditShowEventRequest
     */
    sortedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof EditShowEventRequest
     */
    extraCommissionFeeRate?: number;
    /**
     * 
     * @type {string}
     * @memberof EditShowEventRequest
     */
    featuredStoreStackName?: string;
    /**
     * 
     * @type {string}
     * @memberof EditShowEventRequest
     */
    sellerApplicationDisclosure?: string;
    /**
     * 
     * @type {string}
     * @memberof EditShowEventRequest
     */
    sellerApplicationDisclosureUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditShowEventRequest
     */
    unBundleOnDiscovery?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditShowEventRequest
     */
    visibilityOnDiscoveryBanner?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditShowEventRequest
     */
    customLightingBoltImages?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof EditShowEventRequest
     */
    parcel: object;
    /**
     * 
     * @type {string}
     * @memberof EditShowEventRequest
     */
    shipmentProvider?: EditShowEventRequestShipmentProviderEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EditShowEventRequest
     */
    visibleOnCategoryBanner?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EditShowEventRequest
     */
    categoryCoverUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditShowEventRequest
     */
    active?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum EditShowEventRequestShipmentProviderEnum {
    Ups = 'ups',
    Usps = 'usps',
    UspsFirst = 'usps_first'
}
/**
 * 
 * @export
 * @interface EditShowEventResponse
 */
export interface EditShowEventResponse {
    /**
     * 
     * @type {string}
     * @memberof EditShowEventResponse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface EditShowTicketRequest
 */
export interface EditShowTicketRequest {
    /**
     * 
     * @type {string}
     * @memberof EditShowTicketRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EditShowTicketRequest
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof EditShowTicketRequest
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof EditShowTicketRequest
     */
    discountPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof EditShowTicketRequest
     */
    sku?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditShowTicketRequest
     */
    userGroupIds: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof EditShowTicketRequest
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof EditShowTicketRequest
     */
    maximumPurchaseQuantity?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EditShowTicketRequest
     */
    upgradeTicket?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof EditShowTicketRequest
     */
    fromTicketId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditShowTicketRequest
     */
    toTicketId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EditShowTicketRequest
     */
    emailConfirmationDisclosure?: string;
    /**
     * 
     * @type {string}
     * @memberof EditShowTicketRequest
     */
    emailConfirmationDisclosureUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof EditShowTicketRequest
     */
    emailConfirmationHeaderBackgroundUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EditShowTicketRequest
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EditShowTicketRequest
     */
    requireShipping?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EditShowTicketRequest
     */
    shippingFee?: number;
    /**
     * 
     * @type {string}
     * @memberof EditShowTicketRequest
     */
    defaultImage?: string;
    /**
     * 
     * @type {string}
     * @memberof EditShowTicketRequest
     */
    sortedAt?: string;
}
/**
 * 
 * @export
 * @interface EditStoreListingRequest
 */
export interface EditStoreListingRequest {
    /**
     * 
     * @type {string}
     * @memberof EditStoreListingRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof EditStoreListingRequest
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof EditStoreListingRequest
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof EditStoreListingRequest
     */
    defaultImage: string;
    /**
     * 
     * @type {string}
     * @memberof EditStoreListingRequest
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof EditStoreListingRequest
     */
    discountPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof EditStoreListingRequest
     */
    sku?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditStoreListingRequest
     */
    extraImages?: Array<string>;
    /**
     * 
     * @type {Array<StoreListingVariant>}
     * @memberof EditStoreListingRequest
     */
    variants?: Array<StoreListingVariant>;
    /**
     * 
     * @type {number}
     * @memberof EditStoreListingRequest
     */
    maximumPurchaseQuantity?: number;
    /**
     * 
     * @type {number}
     * @memberof EditStoreListingRequest
     */
    maximumPurchaseQuantityPerLiveStream?: number;
    /**
     * 
     * @type {number}
     * @memberof EditStoreListingRequest
     */
    shippingFee?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditStoreListingRequest
     */
    tags?: Array<string>;
    /**
     * 
     * @type {StoreListingOriginalInfo}
     * @memberof EditStoreListingRequest
     */
    originalInfo?: StoreListingOriginalInfo;
    /**
     * 
     * @type {number}
     * @memberof EditStoreListingRequest
     */
    currentQuantity: number;
    /**
     * 
     * @type {Array<StoreListingVariant>}
     * @memberof EditStoreListingRequest
     */
    currentVariants?: Array<StoreListingVariant>;
}
/**
 * 
 * @export
 * @interface EnsureAddressResponse
 */
export interface EnsureAddressResponse {
    /**
     * 
     * @type {boolean}
     * @memberof EnsureAddressResponse
     */
    haveAddress: boolean;
}
/**
 * 
 * @export
 * @interface EventFeaturedShowItem
 */
export interface EventFeaturedShowItem {
    /**
     * 
     * @type {string}
     * @memberof EventFeaturedShowItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EventFeaturedShowItem
     */
    sortedAt: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventFeaturedShowItem
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventFeaturedShowItem
     */
    isLive?: boolean;
    /**
     * 
     * @type {BaseShowEventWithMetadataItem}
     * @memberof EventFeaturedShowItem
     */
    event: BaseShowEventWithMetadataItem;
}
/**
 * 
 * @export
 * @interface EvergreenVideoItem
 */
export interface EvergreenVideoItem {
    /**
     * 
     * @type {string}
     * @memberof EvergreenVideoItem
     */
    videoURL: string;
    /**
     * 
     * @type {string}
     * @memberof EvergreenVideoItem
     */
    previewImageURL: string;
    /**
     * 
     * @type {VideoResolution}
     * @memberof EvergreenVideoItem
     */
    size: VideoResolution;
}
/**
 * 
 * @export
 * @interface EvidenceItem
 */
export interface EvidenceItem {
    /**
     * 
     * @type {string}
     * @memberof EvidenceItem
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof EvidenceItem
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof EvidenceItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EvidenceItem
     */
    sendToProcessorAt: string;
    /**
     * 
     * @type {string}
     * @memberof EvidenceItem
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface ExecuteActionRequest
 */
export interface ExecuteActionRequest {
    /**
     * 
     * @type {string}
     * @memberof ExecuteActionRequest
     */
    userAgent?: string;
}
/**
 * 
 * @export
 * @interface ExecuteClaimActionRequest
 */
export interface ExecuteClaimActionRequest {
    /**
     * 
     * @type {string}
     * @memberof ExecuteClaimActionRequest
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof ExecuteClaimActionRequest
     */
    userAgent?: string;
}
/**
 * 
 * @export
 * @interface ExposureSellerStatsItem
 */
export interface ExposureSellerStatsItem {
    /**
     * 
     * @type {number}
     * @memberof ExposureSellerStatsItem
     */
    showCount: number;
    /**
     * 
     * @type {number}
     * @memberof ExposureSellerStatsItem
     */
    gt750sShowCount: number;
    /**
     * 
     * @type {number}
     * @memberof ExposureSellerStatsItem
     */
    durationSecond: number;
    /**
     * 
     * @type {number}
     * @memberof ExposureSellerStatsItem
     */
    commentCount: number;
    /**
     * 
     * @type {number}
     * @memberof ExposureSellerStatsItem
     */
    shareCount: number;
    /**
     * 
     * @type {number}
     * @memberof ExposureSellerStatsItem
     */
    likeCount: number;
    /**
     * 
     * @type {number}
     * @memberof ExposureSellerStatsItem
     */
    viewerCount: number;
    /**
     * 
     * @type {number}
     * @memberof ExposureSellerStatsItem
     */
    purchaseCount: number;
    /**
     * 
     * @type {number}
     * @memberof ExposureSellerStatsItem
     */
    mainOrderCount: number;
    /**
     * 
     * @type {number}
     * @memberof ExposureSellerStatsItem
     */
    customerOrderCount: number;
    /**
     * 
     * @type {number}
     * @memberof ExposureSellerStatsItem
     */
    masterOrderCount: number;
    /**
     * 
     * @type {number}
     * @memberof ExposureSellerStatsItem
     */
    revenue: number;
    /**
     * 
     * @type {number}
     * @memberof ExposureSellerStatsItem
     */
    netRevenue: number;
}
/**
 * 
 * @export
 * @interface FeaturedShowItem
 */
export interface FeaturedShowItem {
    /**
     * 
     * @type {string}
     * @memberof FeaturedShowItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FeaturedShowItem
     */
    sortedAt: string;
    /**
     * 
     * @type {string}
     * @memberof FeaturedShowItem
     */
    type: string;
    /**
     * 
     * @type {boolean}
     * @memberof FeaturedShowItem
     */
    active: boolean;
    /**
     * 
     * @type {BaseUpcomingShowItem}
     * @memberof FeaturedShowItem
     */
    upcomingShow?: BaseUpcomingShowItem;
    /**
     * 
     * @type {LiveStreamItem}
     * @memberof FeaturedShowItem
     */
    liveStream?: LiveStreamItem;
    /**
     * 
     * @type {string}
     * @memberof FeaturedShowItem
     */
    featuredEvent?: FeaturedShowItemFeaturedEventEnum;
}

/**
* @export
* @enum {string}
*/
export enum FeaturedShowItemFeaturedEventEnum {
    AutoFeaturedStoreShow = 'autoFeaturedStoreShow'
}
/**
 * 
 * @export
 * @interface FeaturedShowPageRows
 */
export interface FeaturedShowPageRows {
    /**
     * 
     * @type {Array<FeaturedShowItem>}
     * @memberof FeaturedShowPageRows
     */
    rows: Array<FeaturedShowItem>;
}
/**
 * 
 * @export
 * @interface FeaturedShowSearchResponse
 */
export interface FeaturedShowSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof FeaturedShowSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof FeaturedShowSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {FeaturedShowPageRows}
     * @memberof FeaturedShowSearchResponse
     */
    data: FeaturedShowPageRows;
}
/**
 * 
 * @export
 * @interface FeaturedStoreItemWithId
 */
export interface FeaturedStoreItemWithId {
    /**
     * 
     * @type {string}
     * @memberof FeaturedStoreItemWithId
     */
    userId: string;
    /**
     * 
     * @type {number}
     * @memberof FeaturedStoreItemWithId
     */
    position: number;
    /**
     * 
     * @type {string}
     * @memberof FeaturedStoreItemWithId
     */
    sortedAt?: string;
    /**
     * 
     * @type {StoreFrontItem}
     * @memberof FeaturedStoreItemWithId
     */
    store: StoreFrontItem;
    /**
     * 
     * @type {string}
     * @memberof FeaturedStoreItemWithId
     */
    id: string;
}
/**
 * 
 * @export
 * @interface FeaturedStoreRequestDto
 */
export interface FeaturedStoreRequestDto {
    /**
     * 
     * @type {string}
     * @memberof FeaturedStoreRequestDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FeaturedStoreRequestDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof FeaturedStoreRequestDto
     */
    sortedAt: string;
}
/**
 * 
 * @export
 * @interface FeaturedStoresPageRows
 */
export interface FeaturedStoresPageRows {
    /**
     * 
     * @type {Array<FeaturedStoreItemWithId>}
     * @memberof FeaturedStoresPageRows
     */
    rows: Array<FeaturedStoreItemWithId>;
}
/**
 * 
 * @export
 * @interface FeaturedStoresSearchResponse
 */
export interface FeaturedStoresSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof FeaturedStoresSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof FeaturedStoresSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {FeaturedStoresPageRows}
     * @memberof FeaturedStoresSearchResponse
     */
    data: FeaturedStoresPageRows;
}
/**
 * 
 * @export
 * @interface GenerateCoverImageRequest
 */
export interface GenerateCoverImageRequest {
    /**
     * 
     * @type {string}
     * @memberof GenerateCoverImageRequest
     */
    coverImage: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateCoverImageRequest
     */
    colorImage: string;
}
/**
 * 
 * @export
 * @interface GenerateCustomTokenRequest
 */
export interface GenerateCustomTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof GenerateCustomTokenRequest
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface GenerateTokenRequest
 */
export interface GenerateTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof GenerateTokenRequest
     */
    deviceId: string;
    /**
     * used by host only
     * @type {boolean}
     * @memberof GenerateTokenRequest
     */
    publish?: boolean;
}
/**
 * 
 * @export
 * @interface GetAccessResponse
 */
export interface GetAccessResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetAccessResponse
     */
    access: boolean;
}
/**
 * 
 * @export
 * @interface GetFireStoreTimestamp
 */
export interface GetFireStoreTimestamp {
    /**
     * 
     * @type {string}
     * @memberof GetFireStoreTimestamp
     */
    timestamp: string;
}
/**
 * 
 * @export
 * @interface GetLiveStreamNftsResponse
 */
export interface GetLiveStreamNftsResponse {
    /**
     * 
     * @type {Array<NftItem>}
     * @memberof GetLiveStreamNftsResponse
     */
    nfts: Array<NftItem>;
}
/**
 * 
 * @export
 * @interface GetNftAvatarResponse
 */
export interface GetNftAvatarResponse {
    /**
     * 
     * @type {string}
     * @memberof GetNftAvatarResponse
     */
    nftImageUri: string;
}
/**
 * 
 * @export
 * @interface GetRecommendStoresResponse
 */
export interface GetRecommendStoresResponse {
    /**
     * 
     * @type {Array<Store>}
     * @memberof GetRecommendStoresResponse
     */
    stores: Array<Store>;
}
/**
 * 
 * @export
 * @interface GetRecordingURLsResponse
 */
export interface GetRecordingURLsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetRecordingURLsResponse
     */
    urls: Array<string>;
}
/**
 * 
 * @export
 * @interface GetRedirectionUrlResponse
 */
export interface GetRedirectionUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof GetRedirectionUrlResponse
     */
    url: string;
}
/**
 * 
 * @export
 * @interface GetUserTokenRequest
 */
export interface GetUserTokenRequest {
    /**
     * 
     * @type {object}
     * @memberof GetUserTokenRequest
     */
    developerClaims?: object;
}
/**
 * 
 * @export
 * @interface GiftPackageDto
 */
export interface GiftPackageDto {
    /**
     * 
     * @type {boolean}
     * @memberof GiftPackageDto
     */
    isGiftPackage: boolean;
    /**
     * 
     * @type {string}
     * @memberof GiftPackageDto
     */
    giftMessage?: string;
}
/**
 * 
 * @export
 * @interface GooglePlayAnalytics
 */
export interface GooglePlayAnalytics {
    /**
     * 
     * @type {string}
     * @memberof GooglePlayAnalytics
     */
    utmSource?: string;
    /**
     * 
     * @type {string}
     * @memberof GooglePlayAnalytics
     */
    utmMedium?: string;
    /**
     * 
     * @type {string}
     * @memberof GooglePlayAnalytics
     */
    utmCampaign?: string;
    /**
     * 
     * @type {string}
     * @memberof GooglePlayAnalytics
     */
    utmTerm?: string;
    /**
     * 
     * @type {string}
     * @memberof GooglePlayAnalytics
     */
    utmContent?: string;
    /**
     * 
     * @type {string}
     * @memberof GooglePlayAnalytics
     */
    gclid?: string;
}
/**
 * 
 * @export
 * @interface IOSConfig
 */
export interface IOSConfig {
    /**
     * 
     * @type {string}
     * @memberof IOSConfig
     */
    ifl?: string;
    /**
     * 
     * @type {string}
     * @memberof IOSConfig
     */
    ius?: string;
    /**
     * 
     * @type {string}
     * @memberof IOSConfig
     */
    ipfl?: string;
    /**
     * 
     * @type {string}
     * @memberof IOSConfig
     */
    ipbi?: string;
    /**
     * 
     * @type {string}
     * @memberof IOSConfig
     */
    imv?: string;
    /**
     * 
     * @type {string}
     * @memberof IOSConfig
     */
    efr?: string;
}
/**
 * 
 * @export
 * @interface IdResponse
 */
export interface IdResponse {
    /**
     * 
     * @type {string}
     * @memberof IdResponse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface ImportError
 */
export interface ImportError {
    /**
     * 
     * @type {string}
     * @memberof ImportError
     */
    storeId: string;
    /**
     * 
     * @type {string}
     * @memberof ImportError
     */
    message: string;
}
/**
 * 
 * @export
 * @interface ImportStoreListing
 */
export interface ImportStoreListing {
    /**
     * 
     * @type {string}
     * @memberof ImportStoreListing
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ImportStoreListing
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof ImportStoreListing
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof ImportStoreListing
     */
    defaultImage: string;
    /**
     * 
     * @type {string}
     * @memberof ImportStoreListing
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof ImportStoreListing
     */
    discountPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ImportStoreListing
     */
    sku?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportStoreListing
     */
    extraImages?: Array<string>;
    /**
     * 
     * @type {Array<StoreListingVariant>}
     * @memberof ImportStoreListing
     */
    variants?: Array<StoreListingVariant>;
    /**
     * 
     * @type {number}
     * @memberof ImportStoreListing
     */
    maximumPurchaseQuantity?: number;
    /**
     * 
     * @type {number}
     * @memberof ImportStoreListing
     */
    maximumPurchaseQuantityPerLiveStream?: number;
    /**
     * 
     * @type {number}
     * @memberof ImportStoreListing
     */
    shippingFee?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportStoreListing
     */
    tags?: Array<string>;
    /**
     * 
     * @type {StoreListingOriginalInfo}
     * @memberof ImportStoreListing
     */
    originalInfo?: StoreListingOriginalInfo;
    /**
     * 
     * @type {string}
     * @memberof ImportStoreListing
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ImportStoreListing
     */
    status?: ImportStoreListingStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum ImportStoreListingStatusEnum {
    Active = 'active',
    Archived = 'archived'
}
/**
 * 
 * @export
 * @interface Invite
 */
export interface Invite {
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    referrerUserId: string;
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    referrerUserStoreName: string;
    /**
     * 
     * @type {InviteRequest}
     * @memberof Invite
     */
    presenteeUserProfile?: InviteRequest;
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface InviteRequest
 */
export interface InviteRequest {
    /**
     * 
     * @type {string}
     * @memberof InviteRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof InviteRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof InviteRequest
     */
    storeFrontName: string;
    /**
     * 
     * @type {string}
     * @memberof InviteRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof InviteRequest
     */
    phone?: string;
}
/**
 * 
 * @export
 * @interface ItunesConnectAnalytics
 */
export interface ItunesConnectAnalytics {
    /**
     * 
     * @type {string}
     * @memberof ItunesConnectAnalytics
     */
    at?: string;
    /**
     * 
     * @type {string}
     * @memberof ItunesConnectAnalytics
     */
    ct?: string;
    /**
     * 
     * @type {string}
     * @memberof ItunesConnectAnalytics
     */
    mt?: string;
    /**
     * 
     * @type {string}
     * @memberof ItunesConnectAnalytics
     */
    pt?: string;
}
/**
 * 
 * @export
 * @interface JoinNftRequest
 */
export interface JoinNftRequest {
    /**
     * 
     * @type {number}
     * @memberof JoinNftRequest
     */
    tokenId: number;
    /**
     * 
     * @type {string}
     * @memberof JoinNftRequest
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof JoinNftRequest
     */
    signature: string;
    /**
     * 
     * @type {string}
     * @memberof JoinNftRequest
     */
    expiredAt: string;
}
/**
 * 
 * @export
 * @interface JoinPatreonRequest
 */
export interface JoinPatreonRequest {
    /**
     * 
     * @type {string}
     * @memberof JoinPatreonRequest
     */
    oauthCode: string;
    /**
     * 
     * @type {string}
     * @memberof JoinPatreonRequest
     */
    redirectUri: string;
}
/**
 * 
 * @export
 * @interface JoinRequest
 */
export interface JoinRequest {
    /**
     * 
     * @type {JoinNftRequest}
     * @memberof JoinRequest
     */
    nft?: JoinNftRequest;
    /**
     * 
     * @type {JoinPatreonRequest}
     * @memberof JoinRequest
     */
    patreon?: JoinPatreonRequest;
}
/**
 * 
 * @export
 * @interface LabelRegenerationJobStatus
 */
export interface LabelRegenerationJobStatus {
    /**
     * 
     * @type {boolean}
     * @memberof LabelRegenerationJobStatus
     */
    canRegenerate: boolean;
}
/**
 * 
 * @export
 * @interface LandingUserRequest
 */
export interface LandingUserRequest {
    /**
     * 
     * @type {string}
     * @memberof LandingUserRequest
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingUserRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof LandingUserRequest
     */
    url?: string;
}
/**
 * 
 * @export
 * @interface LatestUnreviewedOrdersResponse
 */
export interface LatestUnreviewedOrdersResponse {
    /**
     * 
     * @type {Array<OrderItem>}
     * @memberof LatestUnreviewedOrdersResponse
     */
    orders: Array<OrderItem>;
}
/**
 * 
 * @export
 * @interface LinkAccountResponse
 */
export interface LinkAccountResponse {
    /**
     * 
     * @type {string}
     * @memberof LinkAccountResponse
     */
    customToken: string;
}
/**
 * 
 * @export
 * @interface LinkActions
 */
export interface LinkActions {
    /**
     * 
     * @type {Array<string>}
     * @memberof LinkActions
     */
    usersToFollow?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LinkActions
     */
    joinToUserGroups?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LinkActions
     */
    addToWatchLists?: Array<string>;
}
/**
 * 
 * @export
 * @interface LinkHistoryItem
 */
export interface LinkHistoryItem {
    /**
     * 
     * @type {LinkItem}
     * @memberof LinkHistoryItem
     */
    link: LinkItem;
}
/**
 * 
 * @export
 * @interface LinkItem
 */
export interface LinkItem {
    /**
     * 
     * @type {string}
     * @memberof LinkItem
     */
    type: LinkItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LinkItem
     */
    userId: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof LinkItem
     */
    profile: ProfileItem;
    /**
     * 
     * @type {string}
     * @memberof LinkItem
     */
    method: LinkItemMethodEnum;
    /**
     * 
     * @type {object}
     * @memberof LinkItem
     */
    queryParams?: object;
    /**
     * 
     * @type {LinkUserToFollow}
     * @memberof LinkItem
     */
    actions: LinkUserToFollow;
    /**
     * 
     * @type {ShareTargetItem}
     * @memberof LinkItem
     */
    shareTarget: ShareTargetItem;
    /**
     * 
     * @type {string}
     * @memberof LinkItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LinkItem
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LinkItem
     */
    isWebOnly?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LinkItem
     */
    showEventId?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkItem
     */
    profileId?: string;
    /**
     * 
     * @type {ContentFDLSocialItem}
     * @memberof LinkItem
     */
    social?: ContentFDLSocialItem;
    /**
     * 
     * @type {string}
     * @memberof LinkItem
     */
    shortLinkFDL: string;
    /**
     * 
     * @type {object}
     * @memberof LinkItem
     */
    contentFDL: object;
    /**
     * 
     * @type {string}
     * @memberof LinkItem
     */
    createdAt: string;
    /**
     * 
     * @type {boolean}
     * @memberof LinkItem
     */
    isActive: boolean;
    /**
     * 
     * @type {string}
     * @memberof LinkItem
     */
    updatedAt: string;
}

/**
* @export
* @enum {string}
*/
export enum LinkItemTypeEnum {
    Share = 'SHARE',
    Campaign = 'CAMPAIGN'
}/**
* @export
* @enum {string}
*/
export enum LinkItemMethodEnum {
    Sms = 'SMS',
    Email = 'EMAIL',
    Facebook = 'FACEBOOK',
    Instagram = 'INSTAGRAM',
    Twitter = 'TWITTER',
    Embed = 'EMBED',
    Generic = 'GENERIC'
}
/**
 * 
 * @export
 * @interface LinkPageRows
 */
export interface LinkPageRows {
    /**
     * 
     * @type {Array<LinkItem>}
     * @memberof LinkPageRows
     */
    rows: Array<LinkItem>;
}
/**
 * 
 * @export
 * @interface LinkUserDto
 */
export interface LinkUserDto {
    /**
     * 
     * @type {string}
     * @memberof LinkUserDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof LinkUserDto
     */
    verificationId: string;
}
/**
 * 
 * @export
 * @interface LinkUserToFollow
 */
export interface LinkUserToFollow {
    /**
     * 
     * @type {Array<string>}
     * @memberof LinkUserToFollow
     */
    usersToFollow?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LinkUserToFollow
     */
    joinToUserGroups?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LinkUserToFollow
     */
    addToWatchLists?: Array<string>;
}
/**
 * 
 * @export
 * @interface LinksSearchResponse
 */
export interface LinksSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof LinksSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof LinksSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {LinkPageRows}
     * @memberof LinksSearchResponse
     */
    data: LinkPageRows;
}
/**
 * 
 * @export
 * @interface ListingVariantItem
 */
export interface ListingVariantItem {
    /**
     * 
     * @type {string}
     * @memberof ListingVariantItem
     */
    variantId: string;
    /**
     * 
     * @type {number}
     * @memberof ListingVariantItem
     */
    quantity: number;
    /**
     * 
     * @type {StoreListingVariant}
     * @memberof ListingVariantItem
     */
    variant?: StoreListingVariant;
}
/**
 * 
 * @export
 * @interface ListingsAggregatePerShowItem
 */
export interface ListingsAggregatePerShowItem {
    /**
     * 
     * @type {string}
     * @memberof ListingsAggregatePerShowItem
     */
    listingId: string;
    /**
     * 
     * @type {number}
     * @memberof ListingsAggregatePerShowItem
     */
    quantity: number;
    /**
     * 
     * @type {StoreListingItem}
     * @memberof ListingsAggregatePerShowItem
     */
    listing?: StoreListingItem;
    /**
     * 
     * @type {Array<ListingVariantItem>}
     * @memberof ListingsAggregatePerShowItem
     */
    variants?: Array<ListingVariantItem>;
}
/**
 * 
 * @export
 * @interface LiveShowsByEventIdResponse
 */
export interface LiveShowsByEventIdResponse {
    /**
     * 
     * @type {Array<DiscoveryShowLiveStreamItem>}
     * @memberof LiveShowsByEventIdResponse
     */
    data: Array<DiscoveryShowLiveStreamItem>;
}
/**
 * 
 * @export
 * @interface LiveStreamAuctionItem
 */
export interface LiveStreamAuctionItem {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamAuctionItem
     */
    liveStreamId: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamAuctionItem
     */
    auctionId: string;
}
/**
 * 
 * @export
 * @interface LiveStreamAudiencesItem
 */
export interface LiveStreamAudiencesItem {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamAudiencesItem
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamAudiencesItem
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamAudiencesItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamAudiencesItem
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamAudiencesItem
     */
    avatar?: string;
}
/**
 * 
 * @export
 * @interface LiveStreamAudiencesPageRows
 */
export interface LiveStreamAudiencesPageRows {
    /**
     * 
     * @type {Array<LiveStreamAudiencesItem>}
     * @memberof LiveStreamAudiencesPageRows
     */
    rows: Array<LiveStreamAudiencesItem>;
}
/**
 * 
 * @export
 * @interface LiveStreamAudiencesSearchResponse
 */
export interface LiveStreamAudiencesSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof LiveStreamAudiencesSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof LiveStreamAudiencesSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {LiveStreamAudiencesPageRows}
     * @memberof LiveStreamAudiencesSearchResponse
     */
    data: LiveStreamAudiencesPageRows;
}
/**
 * 
 * @export
 * @interface LiveStreamBasicOrder
 */
export interface LiveStreamBasicOrder {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamBasicOrder
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamBasicOrder
     */
    orderNumber: string;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamBasicOrder
     */
    canceled: boolean;
}
/**
 * 
 * @export
 * @interface LiveStreamBuyerAddressItemWithId
 */
export interface LiveStreamBuyerAddressItemWithId {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamBuyerAddressItemWithId
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamBuyerAddressItemWithId
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamBuyerAddressItemWithId
     */
    latestOrderCreatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamBuyerAddressItemWithId
     */
    buyerId: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof LiveStreamBuyerAddressItemWithId
     */
    buyer: ProfileItem;
    /**
     * 
     * @type {AddressItem}
     * @memberof LiveStreamBuyerAddressItemWithId
     */
    shippingAddress: AddressItem;
    /**
     * 
     * @type {Array<LiveStreamBasicOrder>}
     * @memberof LiveStreamBuyerAddressItemWithId
     */
    masterOrders: Array<LiveStreamBasicOrder>;
    /**
     * 
     * @type {Array<LiveStreamBasicOrder>}
     * @memberof LiveStreamBuyerAddressItemWithId
     */
    slaveOrders: Array<LiveStreamBasicOrder>;
    /**
     * 
     * @type {Array<LiveStreamBasicOrder>}
     * @memberof LiveStreamBuyerAddressItemWithId
     */
    customerOrders: Array<LiveStreamBasicOrder>;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamBuyerAddressItemWithId
     */
    id: string;
}
/**
 * 
 * @export
 * @interface LiveStreamBuyerAddressSearchResponse
 */
export interface LiveStreamBuyerAddressSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof LiveStreamBuyerAddressSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof LiveStreamBuyerAddressSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {LiveStreamBuyerAddressesPageRows}
     * @memberof LiveStreamBuyerAddressSearchResponse
     */
    data: LiveStreamBuyerAddressesPageRows;
}
/**
 * 
 * @export
 * @interface LiveStreamBuyerAddressesPageRows
 */
export interface LiveStreamBuyerAddressesPageRows {
    /**
     * 
     * @type {Array<LiveStreamBuyerAddressItemWithId>}
     * @memberof LiveStreamBuyerAddressesPageRows
     */
    rows: Array<LiveStreamBuyerAddressItemWithId>;
}
/**
 * 
 * @export
 * @interface LiveStreamItem
 */
export interface LiveStreamItem {
    /**
     * 
     * @type {Array<string>}
     * @memberof LiveStreamItem
     */
    badges?: Array<string>;
    /**
     * 
     * @type {LiveStreamUserItem}
     * @memberof LiveStreamItem
     */
    primary: LiveStreamUserItem;
    /**
     * 
     * @type {LiveStreamUserItem}
     * @memberof LiveStreamItem
     */
    secondary?: LiveStreamUserItem;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItem
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItem
     */
    disconnectedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItem
     */
    mode: LiveStreamItemModeEnum;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItem
     */
    background?: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItem
     */
    preview?: string;
    /**
     * 
     * @type {BaseUpcomingShowItem}
     * @memberof LiveStreamItem
     */
    upcomingShow?: BaseUpcomingShowItem;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItem
     */
    upcomingShowId?: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItem
     */
    permission?: LiveStreamItemPermissionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamItem
     */
    hasListing?: boolean;
    /**
     * 
     * @type {LiveStreamRecordingItem}
     * @memberof LiveStreamItem
     */
    recording?: LiveStreamRecordingItem;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItem
     */
    eventId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LiveStreamItem
     */
    userGroupIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItem
     */
    eventTemplateId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamItem
     */
    visibilityOfEventShowOnDiscovery?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItem
     */
    wrapUpEmailSentAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamItem
     */
    isDebutShow?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof LiveStreamItem
     */
    shippingMode?: Array<LiveStreamItemShippingModeEnum>;
}

/**
* @export
* @enum {string}
*/
export enum LiveStreamItemModeEnum {
    Normal = 'normal',
    Cap = 'cap'
}/**
* @export
* @enum {string}
*/
export enum LiveStreamItemPermissionEnum {
    All = 'all',
    Follower = 'follower',
    NoOne = 'no-one'
}/**
* @export
* @enum {string}
*/
export enum LiveStreamItemShippingModeEnum {
    Shipping = 'shipping',
    Pickup = 'pickup'
}
/**
 * 
 * @export
 * @interface LiveStreamItemWithId
 */
export interface LiveStreamItemWithId {
    /**
     * 
     * @type {Array<string>}
     * @memberof LiveStreamItemWithId
     */
    badges?: Array<string>;
    /**
     * 
     * @type {LiveStreamUserItem}
     * @memberof LiveStreamItemWithId
     */
    primary: LiveStreamUserItem;
    /**
     * 
     * @type {LiveStreamUserItem}
     * @memberof LiveStreamItemWithId
     */
    secondary?: LiveStreamUserItem;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItemWithId
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItemWithId
     */
    disconnectedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItemWithId
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItemWithId
     */
    mode: LiveStreamItemWithIdModeEnum;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItemWithId
     */
    background?: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItemWithId
     */
    preview?: string;
    /**
     * 
     * @type {BaseUpcomingShowItem}
     * @memberof LiveStreamItemWithId
     */
    upcomingShow?: BaseUpcomingShowItem;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItemWithId
     */
    upcomingShowId?: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItemWithId
     */
    permission?: LiveStreamItemWithIdPermissionEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamItemWithId
     */
    hasListing?: boolean;
    /**
     * 
     * @type {LiveStreamRecordingItem}
     * @memberof LiveStreamItemWithId
     */
    recording?: LiveStreamRecordingItem;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItemWithId
     */
    eventId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LiveStreamItemWithId
     */
    userGroupIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItemWithId
     */
    eventTemplateId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamItemWithId
     */
    visibilityOfEventShowOnDiscovery?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItemWithId
     */
    wrapUpEmailSentAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamItemWithId
     */
    isDebutShow?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof LiveStreamItemWithId
     */
    shippingMode?: Array<LiveStreamItemWithIdShippingModeEnum>;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamItemWithId
     */
    id: string;
}

/**
* @export
* @enum {string}
*/
export enum LiveStreamItemWithIdModeEnum {
    Normal = 'normal',
    Cap = 'cap'
}/**
* @export
* @enum {string}
*/
export enum LiveStreamItemWithIdPermissionEnum {
    All = 'all',
    Follower = 'follower',
    NoOne = 'no-one'
}/**
* @export
* @enum {string}
*/
export enum LiveStreamItemWithIdShippingModeEnum {
    Shipping = 'shipping',
    Pickup = 'pickup'
}
/**
 * 
 * @export
 * @interface LiveStreamListingVariant
 */
export interface LiveStreamListingVariant {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamListingVariant
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamListingVariant
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof LiveStreamListingVariant
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamListingVariant
     */
    sku?: string;
    /**
     * 
     * @type {number}
     * @memberof LiveStreamListingVariant
     */
    price?: number;
}
/**
 * 
 * @export
 * @interface LiveStreamPageRows
 */
export interface LiveStreamPageRows {
    /**
     * 
     * @type {Array<LiveStreamItemWithId>}
     * @memberof LiveStreamPageRows
     */
    rows: Array<LiveStreamItemWithId>;
}
/**
 * 
 * @export
 * @interface LiveStreamRecordingItem
 */
export interface LiveStreamRecordingItem {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamRecordingItem
     */
    status: LiveStreamRecordingItemStatusEnum;
    /**
     * 
     * @type {Array<LiveStreamVideo>}
     * @memberof LiveStreamRecordingItem
     */
    videos?: Array<LiveStreamVideo>;
}

/**
* @export
* @enum {string}
*/
export enum LiveStreamRecordingItemStatusEnum {
    Unknown = 'unknown',
    Recording = 'recording',
    Recorded = 'recorded',
    Error = 'error'
}
/**
 * 
 * @export
 * @interface LiveStreamSource
 */
export interface LiveStreamSource {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamSource
     */
    type: LiveStreamSourceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamSource
     */
    liveStreamId: string;
}

/**
* @export
* @enum {string}
*/
export enum LiveStreamSourceTypeEnum {
    LiveStream = 'LIVE_STREAM'
}
/**
 * 
 * @export
 * @interface LiveStreamStatusItem
 */
export interface LiveStreamStatusItem {
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamStatusItem
     */
    initialized: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamStatusItem
     */
    available: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamStatusItem
     */
    finished: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamStatusItem
     */
    aborted?: boolean;
}
/**
 * 
 * @export
 * @interface LiveStreamUserItem
 */
export interface LiveStreamUserItem {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamUserItem
     */
    userId: string;
    /**
     * 
     * @type {BaseProfileItem}
     * @memberof LiveStreamUserItem
     */
    profile: BaseProfileItem;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamUserItem
     */
    device?: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamUserItem
     */
    lastConnect?: string;
    /**
     * 
     * @type {LiveStreamStatusItem}
     * @memberof LiveStreamUserItem
     */
    status: LiveStreamStatusItem;
}
/**
 * 
 * @export
 * @interface LiveStreamUserStatSegment
 */
export interface LiveStreamUserStatSegment {
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamUserStatSegment
     */
    isNewViewer: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamUserStatSegment
     */
    isNewFollower: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamUserStatSegment
     */
    isNewPurchaser: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamUserStatSegment
     */
    isRepeatPurchaser: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamUserStatSegment
     */
    isRepeatViewer: boolean;
}
/**
 * 
 * @export
 * @interface LiveStreamUserStatsItem
 */
export interface LiveStreamUserStatsItem {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamUserStatsItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamUserStatsItem
     */
    liveStreamId: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamUserStatsItem
     */
    sellerId: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamUserStatsItem
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamUserStatsItem
     */
    userAvatar: string;
    /**
     * duration hours
     * @type {number}
     * @memberof LiveStreamUserStatsItem
     */
    watchedSeconds: number;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamUserStatsItem
     */
    watchedFormatted: string;
    /**
     * likes
     * @type {number}
     * @memberof LiveStreamUserStatsItem
     */
    likeCount: number;
    /**
     * total comments number customer typed in the chat
     * @type {number}
     * @memberof LiveStreamUserStatsItem
     */
    commentCount: number;
    /**
     * 
     * @type {number}
     * @memberof LiveStreamUserStatsItem
     */
    shareCount: number;
    /**
     * the number of items that user purchased
     * @type {number}
     * @memberof LiveStreamUserStatsItem
     */
    unitsSold: number;
    /**
     * gmv
     * @type {number}
     * @memberof LiveStreamUserStatsItem
     */
    grossSales: number;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamUserStatsItem
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamUserStatsItem
     */
    isFollower: boolean;
    /**
     * 
     * @type {LiveStreamUserStatSegment}
     * @memberof LiveStreamUserStatsItem
     */
    segment: LiveStreamUserStatSegment;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamUserStatsItem
     */
    sellerFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamUserStatsItem
     */
    sellerLastName: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamUserStatsItem
     */
    sellerUserName: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamUserStatsItem
     */
    userFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamUserStatsItem
     */
    userLastName: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamUserStatsItem
     */
    customerUserName: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamUserStatsItem
     */
    lastMainOrderId: string;
}
/**
 * 
 * @export
 * @interface LiveStreamUserStatsPageRows
 */
export interface LiveStreamUserStatsPageRows {
    /**
     * 
     * @type {Array<LiveStreamUserStatsItem>}
     * @memberof LiveStreamUserStatsPageRows
     */
    rows: Array<LiveStreamUserStatsItem>;
}
/**
 * 
 * @export
 * @interface LiveStreamVideo
 */
export interface LiveStreamVideo {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamVideo
     */
    url: string;
    /**
     * 
     * @type {number}
     * @memberof LiveStreamVideo
     */
    size: number;
}
/**
 * 
 * @export
 * @interface LiveStreamsSearchResponse
 */
export interface LiveStreamsSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof LiveStreamsSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof LiveStreamsSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {LiveStreamPageRows}
     * @memberof LiveStreamsSearchResponse
     */
    data: LiveStreamPageRows;
}
/**
 * 
 * @export
 * @interface LiveStreamsViewerItem
 */
export interface LiveStreamsViewerItem {
    /**
     * 
     * @type {string}
     * @memberof LiveStreamsViewerItem
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamsViewerItem
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamsViewerItem
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamsViewerItem
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamsViewerItem
     */
    storeFrontName?: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamsViewerItem
     */
    createdAt?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LiveStreamsViewerItem
     */
    hashtags?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof LiveStreamsViewerItem
     */
    userType?: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamsViewerItem
     */
    urlCode?: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamsViewerItem
     */
    username?: string;
    /**
     * 
     * @type {ProfileBadges}
     * @memberof LiveStreamsViewerItem
     */
    badges?: ProfileBadges;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamsViewerItem
     */
    id: string;
    /**
     * 
     * @type {Metadata}
     * @memberof LiveStreamsViewerItem
     */
    metadata: Metadata;
    /**
     * 
     * @type {boolean}
     * @memberof LiveStreamsViewerItem
     */
    isWatching?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamsViewerItem
     */
    startedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamsViewerItem
     */
    stoppedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamsViewerItem
     */
    deviceType?: string;
    /**
     * 
     * @type {string}
     * @memberof LiveStreamsViewerItem
     */
    deviceId?: string;
}
/**
 * 
 * @export
 * @interface LiveStreamsViewerPageRows
 */
export interface LiveStreamsViewerPageRows {
    /**
     * 
     * @type {Array<LiveStreamsViewerItem>}
     * @memberof LiveStreamsViewerPageRows
     */
    rows: Array<LiveStreamsViewerItem>;
}
/**
 * 
 * @export
 * @interface LiveStreamsViewersSearchResponse
 */
export interface LiveStreamsViewersSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof LiveStreamsViewersSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof LiveStreamsViewersSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {LiveStreamsViewerPageRows}
     * @memberof LiveStreamsViewersSearchResponse
     */
    data: LiveStreamsViewerPageRows;
}
/**
 * 
 * @export
 * @interface LocationDto
 */
export interface LocationDto {
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    name: string;
}
/**
 * 
 * @export
 * @interface LocationsDto
 */
export interface LocationsDto {
    /**
     * 
     * @type {Array<LocationDto>}
     * @memberof LocationsDto
     */
    locations: Array<LocationDto>;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    sessionId: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    deviceModel: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    lastUsedAppVersion: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    lastUsedAppId: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoginRequest
     */
    hasPushToken: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    userAgent?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    platform?: LoginRequestPlatformEnum;
}

/**
* @export
* @enum {string}
*/
export enum LoginRequestPlatformEnum {
    Web = 'web'
}
/**
 * 
 * @export
 * @interface LogoutRequest
 */
export interface LogoutRequest {
    /**
     * 
     * @type {string}
     * @memberof LogoutRequest
     */
    sessionId: string;
}
/**
 * 
 * @export
 * @interface MailDeliveryInfoItem
 */
export interface MailDeliveryInfoItem {
    /**
     * 
     * @type {string}
     * @memberof MailDeliveryInfoItem
     */
    messageId: string;
    /**
     * 
     * @type {string}
     * @memberof MailDeliveryInfoItem
     */
    response: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailDeliveryInfoItem
     */
    accepted: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailDeliveryInfoItem
     */
    rejected: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailDeliveryInfoItem
     */
    pending: Array<string>;
}
/**
 * 
 * @export
 * @interface MailDeliveryItem
 */
export interface MailDeliveryItem {
    /**
     * 
     * @type {string}
     * @memberof MailDeliveryItem
     */
    state: MailDeliveryItemStateEnum;
    /**
     * 
     * @type {string}
     * @memberof MailDeliveryItem
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof MailDeliveryItem
     */
    endTime: string;
    /**
     * 
     * @type {string}
     * @memberof MailDeliveryItem
     */
    leaseExpireTime: string;
    /**
     * 
     * @type {string}
     * @memberof MailDeliveryItem
     */
    error?: string;
    /**
     * 
     * @type {number}
     * @memberof MailDeliveryItem
     */
    attempts: number;
    /**
     * 
     * @type {MailDeliveryInfoItem}
     * @memberof MailDeliveryItem
     */
    info: MailDeliveryInfoItem;
}

/**
* @export
* @enum {string}
*/
export enum MailDeliveryItemStateEnum {
    Pending = 'PENDING',
    Processing = 'PROCESSING',
    Success = 'SUCCESS',
    Error = 'ERROR'
}
/**
 * 
 * @export
 * @interface MailItem
 */
export interface MailItem {
    /**
     * 
     * @type {string}
     * @memberof MailItem
     */
    id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailItem
     */
    to?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailItem
     */
    toUids?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailItem
     */
    cc?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailItem
     */
    ccUids?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailItem
     */
    bcc?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MailItem
     */
    bccUids?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof MailItem
     */
    headers?: object;
    /**
     * 
     * @type {MailMessageItem}
     * @memberof MailItem
     */
    message?: MailMessageItem;
    /**
     * 
     * @type {MailTemplateItem}
     * @memberof MailItem
     */
    template?: MailTemplateItem;
    /**
     * 
     * @type {string}
     * @memberof MailItem
     */
    createdAt?: string;
    /**
     * 
     * @type {MailDeliveryItem}
     * @memberof MailItem
     */
    delivery?: MailDeliveryItem;
    /**
     * 
     * @type {string}
     * @memberof MailItem
     */
    externalId?: string;
}
/**
 * 
 * @export
 * @interface MailMessageItem
 */
export interface MailMessageItem {
    /**
     * 
     * @type {string}
     * @memberof MailMessageItem
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof MailMessageItem
     */
    html: string;
    /**
     * 
     * @type {string}
     * @memberof MailMessageItem
     */
    text: string;
}
/**
 * 
 * @export
 * @interface MailPageRows
 */
export interface MailPageRows {
    /**
     * 
     * @type {Array<MailItem>}
     * @memberof MailPageRows
     */
    rows: Array<MailItem>;
}
/**
 * 
 * @export
 * @interface MailTemplateItem
 */
export interface MailTemplateItem {
    /**
     * 
     * @type {string}
     * @memberof MailTemplateItem
     */
    name: string;
    /**
     * 
     * @type {object}
     * @memberof MailTemplateItem
     */
    data?: object;
}
/**
 * 
 * @export
 * @interface MailsSearchResponse
 */
export interface MailsSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof MailsSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof MailsSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {MailPageRows}
     * @memberof MailsSearchResponse
     */
    data: MailPageRows;
}
/**
 * 
 * @export
 * @interface Masked
 */
export interface Masked {
    /**
     * 
     * @type {Array<string>}
     * @memberof Masked
     */
    updateMask: Array<MaskedUpdateMaskEnum>;
}

/**
* @export
* @enum {string}
*/
export enum MaskedUpdateMaskEnum {
    Name = 'name',
    Age = 'age'
}
/**
 * 
 * @export
 * @interface MessageItemShow
 */
export interface MessageItemShow {
    /**
     * 
     * @type {string}
     * @memberof MessageItemShow
     */
    showName: string;
    /**
     * 
     * @type {string}
     * @memberof MessageItemShow
     */
    showImage?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageItemShow
     */
    showId: string;
    /**
     * 
     * @type {string}
     * @memberof MessageItemShow
     */
    showType: MessageItemShowShowTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum MessageItemShowShowTypeEnum {
    LiveStream = 'liveStream',
    UpcomingShow = 'upcomingShow'
}
/**
 * 
 * @export
 * @interface MessageItemWithId
 */
export interface MessageItemWithId {
    /**
     * 
     * @type {string}
     * @memberof MessageItemWithId
     */
    userIdFrom: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof MessageItemWithId
     */
    userProfileFrom: ProfileItem;
    /**
     * 
     * @type {string}
     * @memberof MessageItemWithId
     */
    userIdTo: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof MessageItemWithId
     */
    userProfileTo: ProfileItem;
    /**
     * 
     * @type {string}
     * @memberof MessageItemWithId
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof MessageItemWithId
     */
    orderId: string;
    /**
     * 
     * @type {string}
     * @memberof MessageItemWithId
     */
    buyerId: string;
    /**
     * 
     * @type {string}
     * @memberof MessageItemWithId
     */
    sellerId: string;
    /**
     * 
     * @type {string}
     * @memberof MessageItemWithId
     */
    videoURL: string;
    /**
     * 
     * @type {string}
     * @memberof MessageItemWithId
     */
    originalVideoURL?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageItemWithId
     */
    transcodeStatus: MessageItemWithIdTranscodeStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MessageItemWithId
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof MessageItemWithId
     */
    gifURL?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageItemWithId
     */
    thumbnailURL?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageItemWithId
     */
    emoji?: MessageItemWithIdEmojiEnum;
    /**
     * 
     * @type {string}
     * @memberof MessageItemWithId
     */
    visibility: MessageItemWithIdVisibilityEnum;
    /**
     * 
     * @type {boolean}
     * @memberof MessageItemWithId
     */
    available: boolean;
    /**
     * 
     * @type {OrderItem}
     * @memberof MessageItemWithId
     */
    order?: OrderItem;
    /**
     * 
     * @type {string}
     * @memberof MessageItemWithId
     */
    purpose?: MessageItemWithIdPurposeEnum;
    /**
     * 
     * @type {MessageItemShow}
     * @memberof MessageItemWithId
     */
    show?: MessageItemShow;
    /**
     * 
     * @type {number}
     * @memberof MessageItemWithId
     */
    upvoteCount?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageItemWithId
     */
    totalReportCount?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageItemWithId
     */
    newReportCount?: number;
    /**
     * 
     * @type {string}
     * @memberof MessageItemWithId
     */
    reportResolveStatus?: MessageItemWithIdReportResolveStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MessageItemWithId
     */
    thankYouType?: MessageItemWithIdThankYouTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MessageItemWithId
     */
    id: string;
}

/**
* @export
* @enum {string}
*/
export enum MessageItemWithIdTranscodeStatusEnum {
    None = 'none',
    Processing = 'processing',
    Transcode = 'transcode',
    Error = 'error'
}/**
* @export
* @enum {string}
*/
export enum MessageItemWithIdEmojiEnum {
    RollingEyesFace = 'rolling_eyes_face',
    Sweat = 'sweat',
    Blush = 'blush',
    Smile = 'smile',
    HeartEyes = 'heart_eyes'
}/**
* @export
* @enum {string}
*/
export enum MessageItemWithIdVisibilityEnum {
    Public = 'public',
    Private = 'private'
}/**
* @export
* @enum {string}
*/
export enum MessageItemWithIdPurposeEnum {
    Review = 'review',
    ThankYou = 'thankYou'
}/**
* @export
* @enum {string}
*/
export enum MessageItemWithIdReportResolveStatusEnum {
    Unresolved = 'Unresolved',
    Resolved = 'Resolved',
    Dismissed = 'Dismissed'
}/**
* @export
* @enum {string}
*/
export enum MessageItemWithIdThankYouTypeEnum {
    RecipientToBuyer = 'RecipientToBuyer',
    SellerToBuyer = 'SellerToBuyer'
}
/**
 * 
 * @export
 * @interface MessageReportItemWithId
 */
export interface MessageReportItemWithId {
    /**
     * 
     * @type {string}
     * @memberof MessageReportItemWithId
     */
    reporterId: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof MessageReportItemWithId
     */
    reporterProfile: ProfileItem;
    /**
     * 
     * @type {string}
     * @memberof MessageReportItemWithId
     */
    reason: MessageReportItemWithIdReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof MessageReportItemWithId
     */
    resolveStatus: MessageReportItemWithIdResolveStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MessageReportItemWithId
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof MessageReportItemWithId
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof MessageReportItemWithId
     */
    id: string;
}

/**
* @export
* @enum {string}
*/
export enum MessageReportItemWithIdReasonEnum {
    Spam = 'spam',
    Inappropriate = 'inappropriate',
    Aggressive = 'aggressive'
}/**
* @export
* @enum {string}
*/
export enum MessageReportItemWithIdResolveStatusEnum {
    Unresolved = 'Unresolved',
    Resolved = 'Resolved',
    Dismissed = 'Dismissed'
}
/**
 * 
 * @export
 * @interface MessageReportPageRows
 */
export interface MessageReportPageRows {
    /**
     * 
     * @type {Array<MessageReportItemWithId>}
     * @memberof MessageReportPageRows
     */
    rows: Array<MessageReportItemWithId>;
}
/**
 * 
 * @export
 * @interface MessageReportSearchResponse
 */
export interface MessageReportSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof MessageReportSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof MessageReportSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {MessageReportPageRows}
     * @memberof MessageReportSearchResponse
     */
    data: MessageReportPageRows;
}
/**
 * 
 * @export
 * @interface MessagesPageRows
 */
export interface MessagesPageRows {
    /**
     * 
     * @type {Array<MessageItemWithId>}
     * @memberof MessagesPageRows
     */
    rows: Array<MessageItemWithId>;
}
/**
 * 
 * @export
 * @interface MessagesSearchResponse
 */
export interface MessagesSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof MessagesSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof MessagesSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {MessagesPageRows}
     * @memberof MessagesSearchResponse
     */
    data: MessagesPageRows;
}
/**
 * 
 * @export
 * @interface Metadata
 */
export interface Metadata {
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof Metadata
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface MyCouponDto
 */
export interface MyCouponDto {
    /**
     * 
     * @type {string}
     * @memberof MyCouponDto
     */
    couponId: string;
    /**
     * redeemedCoupons/id
     * @type {string}
     * @memberof MyCouponDto
     */
    redeemedCouponId: string;
    /**
     * 
     * @type {CouponItem}
     * @memberof MyCouponDto
     */
    coupon: CouponItem;
    /**
     * 
     * @type {string}
     * @memberof MyCouponDto
     */
    usedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof MyCouponDto
     */
    redeemedQuantity: number;
}
/**
 * 
 * @export
 * @interface MyCouponsResponseDto
 */
export interface MyCouponsResponseDto {
    /**
     * 
     * @type {number}
     * @memberof MyCouponsResponseDto
     */
    total: number;
    /**
     * 
     * @type {Array<MyCouponDto>}
     * @memberof MyCouponsResponseDto
     */
    coupons: Array<MyCouponDto>;
}
/**
 * 
 * @export
 * @interface MyStoreListingItem
 */
export interface MyStoreListingItem {
    /**
     * 
     * @type {string}
     * @memberof MyStoreListingItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MyStoreListingItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MyStoreListingItem
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof MyStoreListingItem
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof MyStoreListingItem
     */
    discountPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof MyStoreListingItem
     */
    sku?: string;
    /**
     * 
     * @type {string}
     * @memberof MyStoreListingItem
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MyStoreListingItem
     */
    defaultImage: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MyStoreListingItem
     */
    extraImages?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof MyStoreListingItem
     */
    quantity: number;
    /**
     * 
     * @type {Array<StoreListingVariant>}
     * @memberof MyStoreListingItem
     */
    variants?: Array<StoreListingVariant>;
    /**
     * 
     * @type {number}
     * @memberof MyStoreListingItem
     */
    maximumPurchaseQuantity?: number;
    /**
     * 
     * @type {number}
     * @memberof MyStoreListingItem
     */
    shippingFee?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof MyStoreListingItem
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MyStoreListingItem
     */
    status?: MyStoreListingItemStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof MyStoreListingItem
     */
    maximumPurchaseQuantityPerLiveStream?: number;
    /**
     * 
     * @type {StoreListingOriginalInfo}
     * @memberof MyStoreListingItem
     */
    originalInfo?: StoreListingOriginalInfo;
    /**
     * 
     * @type {Metadata}
     * @memberof MyStoreListingItem
     */
    metadata: Metadata;
    /**
     * 
     * @type {boolean}
     * @memberof MyStoreListingItem
     */
    isLive?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum MyStoreListingItemStatusEnum {
    Active = 'active',
    Archived = 'archived'
}
/**
 * 
 * @export
 * @interface MyStoreListingPageRows
 */
export interface MyStoreListingPageRows {
    /**
     * 
     * @type {Array<MyStoreListingItem>}
     * @memberof MyStoreListingPageRows
     */
    rows: Array<MyStoreListingItem>;
}
/**
 * 
 * @export
 * @interface MyStoreListingSearchResponse
 */
export interface MyStoreListingSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof MyStoreListingSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof MyStoreListingSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {MyStoreListingPageRows}
     * @memberof MyStoreListingSearchResponse
     */
    data: MyStoreListingPageRows;
}
/**
 * 
 * @export
 * @interface Nested
 */
export interface Nested {
    /**
     * 
     * @type {string}
     * @memberof Nested
     */
    required: string;
    /**
     * 
     * @type {string}
     * @memberof Nested
     */
    optional?: string;
}
/**
 * 
 * @export
 * @interface News
 */
export interface News {
    /**
     * 
     * @type {string}
     * @memberof News
     */
    brief: string;
    /**
     * 
     * @type {boolean}
     * @memberof News
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof News
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof News
     */
    startedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof News
     */
    expiredAt?: string;
    /**
     * 
     * @type {string}
     * @memberof News
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof News
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface NftItem
 */
export interface NftItem {
    /**
     * 
     * @type {number}
     * @memberof NftItem
     */
    blockNumber: number;
    /**
     * 
     * @type {string}
     * @memberof NftItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NftItem
     */
    ownerAddress: string;
    /**
     * 
     * @type {NftRemoteInfo}
     * @memberof NftItem
     */
    detail: NftRemoteInfo;
    /**
     * 
     * @type {string}
     * @memberof NftItem
     */
    symbol: string;
    /**
     * 
     * @type {number}
     * @memberof NftItem
     */
    tokenId: number;
    /**
     * 
     * @type {string}
     * @memberof NftItem
     */
    tokenUri: string;
}
/**
 * 
 * @export
 * @interface NftRemoteInfo
 */
export interface NftRemoteInfo {
    /**
     * 
     * @type {string}
     * @memberof NftRemoteInfo
     */
    imageUri?: string;
}
/**
 * 
 * @export
 * @interface NotificationSettingsAggregateResponse
 */
export interface NotificationSettingsAggregateResponse {
    /**
     * 
     * @type {number}
     * @memberof NotificationSettingsAggregateResponse
     */
    authorized?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationSettingsAggregateResponse
     */
    denied?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationSettingsAggregateResponse
     */
    notDetermined?: number;
}
/**
 * 
 * @export
 * @interface NumberRange
 */
export interface NumberRange {
    /**
     * 
     * @type {number}
     * @memberof NumberRange
     */
    gt?: number;
    /**
     * 
     * @type {number}
     * @memberof NumberRange
     */
    gte?: number;
    /**
     * 
     * @type {number}
     * @memberof NumberRange
     */
    lt?: number;
    /**
     * 
     * @type {number}
     * @memberof NumberRange
     */
    lte?: number;
    /**
     * 
     * @type {number}
     * @memberof NumberRange
     */
    equal?: number;
}
/**
 * 
 * @export
 * @interface OneTimeUUIDDto
 */
export interface OneTimeUUIDDto {
    /**
     * 
     * @type {string}
     * @memberof OneTimeUUIDDto
     */
    uuid: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimeUUIDDto
     */
    authUrl: string;
}
/**
 * 
 * @export
 * @interface OneTimeUUIDRequestDto
 */
export interface OneTimeUUIDRequestDto {
    /**
     * 
     * @type {string}
     * @memberof OneTimeUUIDRequestDto
     */
    shopName?: string;
}
/**
 * 
 * @export
 * @interface OperatorItem
 */
export interface OperatorItem {
    /**
     * 
     * @type {string}
     * @memberof OperatorItem
     */
    profileId: string;
    /**
     * 
     * @type {SimpleProfileItem}
     * @memberof OperatorItem
     */
    profile?: SimpleProfileItem;
}
/**
 * 
 * @export
 * @interface OrderChargeDistributionItem
 */
export interface OrderChargeDistributionItem {
    /**
     * 
     * @type {number}
     * @memberof OrderChargeDistributionItem
     */
    commissionFeeRate: number;
    /**
     * 
     * @type {number}
     * @memberof OrderChargeDistributionItem
     */
    commissionFee: number;
    /**
     * 
     * @type {number}
     * @memberof OrderChargeDistributionItem
     */
    gatewayProcessingFeeRate: number;
    /**
     * 
     * @type {number}
     * @memberof OrderChargeDistributionItem
     */
    gatewayProcessingFee: number;
    /**
     * 
     * @type {number}
     * @memberof OrderChargeDistributionItem
     */
    sellerWithdrawableAmount: number;
    /**
     * 
     * @type {number}
     * @memberof OrderChargeDistributionItem
     */
    buyerPaidAmount: number;
    /**
     * 
     * @type {number}
     * @memberof OrderChargeDistributionItem
     */
    salesTaxAmount: number;
    /**
     * 
     * @type {number}
     * @memberof OrderChargeDistributionItem
     */
    salesTaxRate: number;
    /**
     * 
     * @type {number}
     * @memberof OrderChargeDistributionItem
     */
    couponSellerGotAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderChargeDistributionItem
     */
    bonus: number;
    /**
     * 
     * @type {number}
     * @memberof OrderChargeDistributionItem
     */
    couponDiscountProductAmount: number;
    /**
     * 
     * @type {string}
     * @memberof OrderChargeDistributionItem
     */
    shippingFeePayer?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderChargeDistributionItem
     */
    popshopShippingFee?: number;
}
/**
 * 
 * @export
 * @interface OrderCompositionCancellationStatus
 */
export interface OrderCompositionCancellationStatus {
    /**
     * 
     * @type {boolean}
     * @memberof OrderCompositionCancellationStatus
     */
    allCancelled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCompositionCancellationStatus
     */
    noneCancelled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderCompositionCancellationStatus
     */
    partiallyCancelled: boolean;
}
/**
 * 
 * @export
 * @interface OrderDetailItem
 */
export interface OrderDetailItem {
    /**
     * 
     * @type {OrderItemForDetail}
     * @memberof OrderDetailItem
     */
    orderItem: OrderItemForDetail;
    /**
     * 
     * @type {OrderItem}
     * @memberof OrderDetailItem
     */
    mainOrderItem?: OrderItem;
}
/**
 * 
 * @export
 * @interface OrderDetailPageRows
 */
export interface OrderDetailPageRows {
    /**
     * 
     * @type {Array<OrderDetailItem>}
     * @memberof OrderDetailPageRows
     */
    rows: Array<OrderDetailItem>;
}
/**
 * 
 * @export
 * @interface OrderDetailsSearchResponse
 */
export interface OrderDetailsSearchResponse {
    /**
     * 
     * @type {number}
     * @memberof OrderDetailsSearchResponse
     */
    total: number;
    /**
     * 
     * @type {OrderDetailPageRows}
     * @memberof OrderDetailsSearchResponse
     */
    data: OrderDetailPageRows;
}
/**
 * 
 * @export
 * @interface OrderGiftPackageItem
 */
export interface OrderGiftPackageItem {
    /**
     * 
     * @type {boolean}
     * @memberof OrderGiftPackageItem
     */
    isGiftPackage: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderGiftPackageItem
     */
    giftMessage?: string;
}
/**
 * 
 * @export
 * @interface OrderGiftingItem
 */
export interface OrderGiftingItem {
    /**
     * 
     * @type {string}
     * @memberof OrderGiftingItem
     */
    recipientId: string;
    /**
     * 
     * @type {RecipientItem}
     * @memberof OrderGiftingItem
     */
    recipient: RecipientItem;
    /**
     * 
     * @type {string}
     * @memberof OrderGiftingItem
     */
    status: OrderGiftingItemStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum OrderGiftingItemStatusEnum {
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Rejected = 'REJECTED'
}
/**
 * 
 * @export
 * @interface OrderItem
 */
export interface OrderItem {
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    eventId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    orderNumber: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    buyerName: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    buyerPaidAmount: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    shippingFee: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    shippingFeeAdjusted: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItem
     */
    braintreeShippingFeeRefunded?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    salesTaxRate?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    salesTaxAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    total: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    buyerId: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof OrderItem
     */
    buyer: ProfileItem;
    /**
     * 
     * @type {OrderGiftingItem}
     * @memberof OrderItem
     */
    gifting?: OrderGiftingItem;
    /**
     * 
     * @type {OrderGiftPackageItem}
     * @memberof OrderItem
     */
    giftPackage?: OrderGiftPackageItem;
    /**
     * 
     * @type {ShipmentItem}
     * @memberof OrderItem
     */
    shipment?: ShipmentItem;
    /**
     * 
     * @type {PackingSlipItem}
     * @memberof OrderItem
     */
    packingSlip?: PackingSlipItem;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    refund?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    userAgent?: string;
    /**
     * 
     * @type {OrderPaymentItem}
     * @memberof OrderItem
     */
    orderPayment?: OrderPaymentItem;
    /**
     * 
     * @type {Array<RedeemedCouponItem>}
     * @memberof OrderItem
     */
    coupons?: Array<RedeemedCouponItem>;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    couponDiscountProductAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    couponDiscountShippingFeeAmount?: number;
    /**
     * 
     * @type {SellerTrackingInfoItem}
     * @memberof OrderItem
     */
    sellerTrackingInfo?: SellerTrackingInfoItem;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    sellerGotAmount: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    bonus?: number;
    /**
     * 
     * @type {AddressItem}
     * @memberof OrderItem
     */
    shippingAddress: AddressItem;
    /**
     * 
     * @type {PickupAddressItem}
     * @memberof OrderItem
     */
    pickupAddress?: PickupAddressItem;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    listingId: string;
    /**
     * 
     * @type {StoreListingItem}
     * @memberof OrderItem
     */
    listingSnapshot: StoreListingItem;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    sendItemId?: string;
    /**
     * 
     * @type {SendItemItem}
     * @memberof OrderItem
     */
    sendItem?: SendItemItem;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    sellerId: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof OrderItem
     */
    seller: ProfileItem;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    status: OrderItemStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    pickupStatus?: OrderItemPickupStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    cancelStatus?: OrderItemCancelStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    shippingMethod: OrderItemShippingMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    shippoOrderId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    liveStreamId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    variantId?: string;
    /**
     * 
     * @type {StoreFrontItem}
     * @memberof OrderItem
     */
    store: StoreFrontItem;
    /**
     * 
     * @type {OrderShipmentGroupStatus}
     * @memberof OrderItem
     */
    orderShipmentGroupStatus: OrderShipmentGroupStatus;
    /**
     * 
     * @type {ShippingLabelStatus}
     * @memberof OrderItem
     */
    shippingLabelStatus: ShippingLabelStatus;
    /**
     * 
     * @type {ShippingLabelStatus}
     * @memberof OrderItem
     */
    packingSlipStatus: ShippingLabelStatus;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    masterOrderId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    planTimeOfShippingLabelGeneration?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    totalQuantitiesIncludeSlaveOrders: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    totalPriceIncludeSlaveOrders: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    totalPriceIncludeSlaveOrdersWithProductCoupon: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    totalRefundIncludeSlaveOrders?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItem
     */
    newCustomer?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    firstPurchaseAt?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    updatedAt?: string;
    /**
     * 
     * @type {OrderCompositionCancellationStatus}
     * @memberof OrderItem
     */
    orderCompositionCancellationStatus?: OrderCompositionCancellationStatus;
    /**
     * 
     * @type {OrderChargeDistributionItem}
     * @memberof OrderItem
     */
    orderChargeDistribution?: OrderChargeDistributionItem;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItem
     */
    packed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    pickupNotifiedAt?: string;
    /**
     * 
     * @type {OrderSource}
     * @memberof OrderItem
     */
    source?: OrderSource;
    /**
     * 
     * @type {OrderShipmentParcel}
     * @memberof OrderItem
     */
    shipmentParcel?: OrderShipmentParcel;
    /**
     * 
     * @type {OrderShipmentParcel}
     * @memberof OrderItem
     */
    shipmentOriginalParcel?: OrderShipmentParcel;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    shipmentOriginalParcelSource?: OrderItemShipmentOriginalParcelSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    totalSalesTaxAmountIncludeSlaveOrders?: number;
}

/**
* @export
* @enum {string}
*/
export enum OrderItemStatusEnum {
    Unshipped = 'unshipped',
    Shipped = 'shipped',
    Completed = 'completed',
    Canceled = 'canceled'
}/**
* @export
* @enum {string}
*/
export enum OrderItemPickupStatusEnum {
    Open = 'open',
    New = 'new',
    Processing = 'processing',
    Pending = 'pending',
    Packed = 'packed',
    Completed = 'completed',
    Canceled = 'canceled'
}/**
* @export
* @enum {string}
*/
export enum OrderItemCancelStatusEnum {
    SellerApplied = 'seller_applied',
    AdminApproved = 'admin_approved'
}/**
* @export
* @enum {string}
*/
export enum OrderItemShippingMethodEnum {
    SellerProvide = 'sellerProvide',
    PopshopProvide = 'popshopProvide',
    Pickup = 'pickup'
}/**
* @export
* @enum {string}
*/
export enum OrderItemShipmentOriginalParcelSourceEnum {
    Default = 'DEFAULT',
    ShowEvent = 'SHOW_EVENT'
}
/**
 * 
 * @export
 * @interface OrderItemForDetail
 */
export interface OrderItemForDetail {
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    eventId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    orderNumber: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemForDetail
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    buyerName: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemForDetail
     */
    buyerPaidAmount: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemForDetail
     */
    shippingFee: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemForDetail
     */
    shippingFeeAdjusted: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemForDetail
     */
    braintreeShippingFeeRefunded?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderItemForDetail
     */
    salesTaxRate?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemForDetail
     */
    salesTaxAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemForDetail
     */
    total: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    buyerId: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof OrderItemForDetail
     */
    buyer: ProfileItem;
    /**
     * 
     * @type {OrderGiftingItem}
     * @memberof OrderItemForDetail
     */
    gifting?: OrderGiftingItem;
    /**
     * 
     * @type {OrderGiftPackageItem}
     * @memberof OrderItemForDetail
     */
    giftPackage?: OrderGiftPackageItem;
    /**
     * 
     * @type {ShipmentItem}
     * @memberof OrderItemForDetail
     */
    shipment?: ShipmentItem;
    /**
     * 
     * @type {PackingSlipItem}
     * @memberof OrderItemForDetail
     */
    packingSlip?: PackingSlipItem;
    /**
     * 
     * @type {number}
     * @memberof OrderItemForDetail
     */
    refund?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    userAgent?: string;
    /**
     * 
     * @type {OrderPaymentItem}
     * @memberof OrderItemForDetail
     */
    orderPayment?: OrderPaymentItem;
    /**
     * 
     * @type {Array<RedeemedCouponItem>}
     * @memberof OrderItemForDetail
     */
    coupons?: Array<RedeemedCouponItem>;
    /**
     * 
     * @type {number}
     * @memberof OrderItemForDetail
     */
    couponDiscountProductAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemForDetail
     */
    couponDiscountShippingFeeAmount?: number;
    /**
     * 
     * @type {SellerTrackingInfoItem}
     * @memberof OrderItemForDetail
     */
    sellerTrackingInfo?: SellerTrackingInfoItem;
    /**
     * 
     * @type {number}
     * @memberof OrderItemForDetail
     */
    sellerGotAmount: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemForDetail
     */
    bonus?: number;
    /**
     * 
     * @type {AddressItem}
     * @memberof OrderItemForDetail
     */
    shippingAddress: AddressItem;
    /**
     * 
     * @type {PickupAddressItem}
     * @memberof OrderItemForDetail
     */
    pickupAddress?: PickupAddressItem;
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    listingId: string;
    /**
     * 
     * @type {StoreListingItem}
     * @memberof OrderItemForDetail
     */
    listingSnapshot: StoreListingItem;
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    sendItemId?: string;
    /**
     * 
     * @type {SendItemItem}
     * @memberof OrderItemForDetail
     */
    sendItem?: SendItemItem;
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    sellerId: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof OrderItemForDetail
     */
    seller: ProfileItem;
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    status: OrderItemForDetailStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    pickupStatus?: OrderItemForDetailPickupStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    cancelStatus?: OrderItemForDetailCancelStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    shippingMethod: OrderItemForDetailShippingMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    shippoOrderId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    liveStreamId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    variantId?: string;
    /**
     * 
     * @type {StoreFrontItem}
     * @memberof OrderItemForDetail
     */
    store: StoreFrontItem;
    /**
     * 
     * @type {OrderShipmentGroupStatus}
     * @memberof OrderItemForDetail
     */
    orderShipmentGroupStatus: OrderShipmentGroupStatus;
    /**
     * 
     * @type {ShippingLabelStatus}
     * @memberof OrderItemForDetail
     */
    shippingLabelStatus: ShippingLabelStatus;
    /**
     * 
     * @type {ShippingLabelStatus}
     * @memberof OrderItemForDetail
     */
    packingSlipStatus: ShippingLabelStatus;
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    masterOrderId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    planTimeOfShippingLabelGeneration?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemForDetail
     */
    totalQuantitiesIncludeSlaveOrders: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemForDetail
     */
    totalPriceIncludeSlaveOrders: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemForDetail
     */
    totalPriceIncludeSlaveOrdersWithProductCoupon: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemForDetail
     */
    totalRefundIncludeSlaveOrders?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemForDetail
     */
    newCustomer?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    firstPurchaseAt?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    updatedAt?: string;
    /**
     * 
     * @type {OrderCompositionCancellationStatus}
     * @memberof OrderItemForDetail
     */
    orderCompositionCancellationStatus?: OrderCompositionCancellationStatus;
    /**
     * 
     * @type {OrderChargeDistributionItem}
     * @memberof OrderItemForDetail
     */
    orderChargeDistribution?: OrderChargeDistributionItem;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItemForDetail
     */
    packed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    pickupNotifiedAt?: string;
    /**
     * 
     * @type {OrderSource}
     * @memberof OrderItemForDetail
     */
    source?: OrderSource;
    /**
     * 
     * @type {OrderShipmentParcel}
     * @memberof OrderItemForDetail
     */
    shipmentParcel?: OrderShipmentParcel;
    /**
     * 
     * @type {OrderShipmentParcel}
     * @memberof OrderItemForDetail
     */
    shipmentOriginalParcel?: OrderShipmentParcel;
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    shipmentOriginalParcelSource?: OrderItemForDetailShipmentOriginalParcelSourceEnum;
    /**
     * 
     * @type {LiveStreamItem}
     * @memberof OrderItemForDetail
     */
    liveStream?: LiveStreamItem;
    /**
     * 
     * @type {string}
     * @memberof OrderItemForDetail
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItemForDetail
     */
    totalSalesTaxAmountIncludeSlaveOrders?: number;
}

/**
* @export
* @enum {string}
*/
export enum OrderItemForDetailStatusEnum {
    Unshipped = 'unshipped',
    Shipped = 'shipped',
    Completed = 'completed',
    Canceled = 'canceled'
}/**
* @export
* @enum {string}
*/
export enum OrderItemForDetailPickupStatusEnum {
    Open = 'open',
    New = 'new',
    Processing = 'processing',
    Pending = 'pending',
    Packed = 'packed',
    Completed = 'completed',
    Canceled = 'canceled'
}/**
* @export
* @enum {string}
*/
export enum OrderItemForDetailCancelStatusEnum {
    SellerApplied = 'seller_applied',
    AdminApproved = 'admin_approved'
}/**
* @export
* @enum {string}
*/
export enum OrderItemForDetailShippingMethodEnum {
    SellerProvide = 'sellerProvide',
    PopshopProvide = 'popshopProvide',
    Pickup = 'pickup'
}/**
* @export
* @enum {string}
*/
export enum OrderItemForDetailShipmentOriginalParcelSourceEnum {
    Default = 'DEFAULT',
    ShowEvent = 'SHOW_EVENT'
}
/**
 * 
 * @export
 * @interface OrderPageRows
 */
export interface OrderPageRows {
    /**
     * 
     * @type {Array<OrderItem>}
     * @memberof OrderPageRows
     */
    rows: Array<OrderItem>;
}
/**
 * 
 * @export
 * @interface OrderPaymentItem
 */
export interface OrderPaymentItem {
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentItem
     */
    orderId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentItem
     */
    braintreeId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentItem
     */
    nonce?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentItem
     */
    creditCardId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentItem
     */
    paymentType: OrderPaymentItemPaymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentItem
     */
    status: OrderPaymentItemStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentItem
     */
    braintreeStatus?: string;
    /**
     * 
     * @type {BraintreeTransactionItem}
     * @memberof OrderPaymentItem
     */
    transaction?: BraintreeTransactionItem;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderPaymentItem
     */
    refundTransactions?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrderPaymentItem
     */
    createdAt: string;
}

/**
* @export
* @enum {string}
*/
export enum OrderPaymentItemPaymentTypeEnum {
    Credit = 'credit',
    Applepay = 'applepay',
    Empty = 'empty'
}/**
* @export
* @enum {string}
*/
export enum OrderPaymentItemStatusEnum {
    Approved = 'approved',
    Rejected = 'rejected'
}
/**
 * 
 * @export
 * @interface OrderShipmentGroupStatus
 */
export interface OrderShipmentGroupStatus {
    /**
     * 
     * @type {boolean}
     * @memberof OrderShipmentGroupStatus
     */
    master: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderShipmentGroupStatus
     */
    slave: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderShipmentGroupStatus
     */
    customer: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderShipmentGroupStatus
     */
    legacy: boolean;
}
/**
 * 
 * @export
 * @interface OrderShipmentParcel
 */
export interface OrderShipmentParcel {
    /**
     * 
     * @type {string}
     * @memberof OrderShipmentParcel
     */
    distanceUnit: string;
    /**
     * 
     * @type {string}
     * @memberof OrderShipmentParcel
     */
    height: string;
    /**
     * 
     * @type {string}
     * @memberof OrderShipmentParcel
     */
    length: string;
    /**
     * 
     * @type {string}
     * @memberof OrderShipmentParcel
     */
    massUnit: string;
    /**
     * 
     * @type {string}
     * @memberof OrderShipmentParcel
     */
    weight: string;
    /**
     * 
     * @type {string}
     * @memberof OrderShipmentParcel
     */
    width: string;
}
/**
 * 
 * @export
 * @interface OrderSource
 */
export interface OrderSource {
    /**
     * 
     * @type {string}
     * @memberof OrderSource
     */
    type?: OrderSourceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderSource
     */
    liveStreamId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderSource
     */
    upcomingShowId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderSource
     */
    storeId?: string;
}

/**
* @export
* @enum {string}
*/
export enum OrderSourceTypeEnum {
    UpcomingShow = 'UPCOMING_SHOW',
    LiveStream = 'LIVE_STREAM',
    Store = 'STORE',
    TwoWayStream = 'TWO_WAY_STREAM'
}
/**
 * 
 * @export
 * @interface OrderTransactionItem
 */
export interface OrderTransactionItem {
    /**
     * 
     * @type {string}
     * @memberof OrderTransactionItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTransactionItem
     */
    orderId: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTransactionItem
     */
    sellerId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTransactionItem
     */
    buyerId: string;
    /**
     * 
     * @type {number}
     * @memberof OrderTransactionItem
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof OrderTransactionItem
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTransactionItem
     */
    updatedAt: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderTransactionItem
     */
    fromBraintree: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderTransactionItem
     */
    type: OrderTransactionItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderTransactionItem
     */
    status: OrderTransactionItemStatusEnum;
    /**
     * 
     * @type {BraintreeTransactionItem}
     * @memberof OrderTransactionItem
     */
    braintreeTransaction: BraintreeTransactionItem;
    /**
     * 
     * @type {string}
     * @memberof OrderTransactionItem
     */
    orderNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderTransactionItem
     */
    listingName?: string;
}

/**
* @export
* @enum {string}
*/
export enum OrderTransactionItemTypeEnum {
    Sale = 'sale',
    RefundBraintree = 'refundBraintree',
    ShippingFee = 'shippingFee',
    RefundShippingFee = 'refundShippingFee',
    Bonus = 'bonus',
    RefundBonus = 'refundBonus',
    ProductCoupon = 'productCoupon',
    RefundProductCoupon = 'refundProductCoupon'
}/**
* @export
* @enum {string}
*/
export enum OrderTransactionItemStatusEnum {
    Pending = 'pending',
    Completed = 'completed',
    Canceled = 'canceled'
}
/**
 * 
 * @export
 * @interface OrderTransactionPageRows
 */
export interface OrderTransactionPageRows {
    /**
     * 
     * @type {Array<OrderTransactionItem>}
     * @memberof OrderTransactionPageRows
     */
    rows: Array<OrderTransactionItem>;
}
/**
 * 
 * @export
 * @interface OrderTransactionSearchResponse
 */
export interface OrderTransactionSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof OrderTransactionSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof OrderTransactionSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {OrderTransactionPageRows}
     * @memberof OrderTransactionSearchResponse
     */
    data: OrderTransactionPageRows;
}
/**
 * 
 * @export
 * @interface OrdersAggregateByPickupStatusResponse
 */
export interface OrdersAggregateByPickupStatusResponse {
    /**
     * 
     * @type {number}
     * @memberof OrdersAggregateByPickupStatusResponse
     */
    open?: number;
    /**
     * 
     * @type {number}
     * @memberof OrdersAggregateByPickupStatusResponse
     */
    pending?: number;
    /**
     * 
     * @type {number}
     * @memberof OrdersAggregateByPickupStatusResponse
     */
    _new?: number;
    /**
     * 
     * @type {number}
     * @memberof OrdersAggregateByPickupStatusResponse
     */
    processing?: number;
    /**
     * 
     * @type {number}
     * @memberof OrdersAggregateByPickupStatusResponse
     */
    packed?: number;
    /**
     * 
     * @type {number}
     * @memberof OrdersAggregateByPickupStatusResponse
     */
    completed?: number;
    /**
     * 
     * @type {number}
     * @memberof OrdersAggregateByPickupStatusResponse
     */
    canceled?: number;
}
/**
 * 
 * @export
 * @interface OrdersSearchResponse
 */
export interface OrdersSearchResponse {
    /**
     * 
     * @type {number}
     * @memberof OrdersSearchResponse
     */
    total: number;
    /**
     * 
     * @type {OrderPageRows}
     * @memberof OrdersSearchResponse
     */
    data: OrderPageRows;
}
/**
 * 
 * @export
 * @interface PackingSlipItem
 */
export interface PackingSlipItem {
    /**
     * 
     * @type {string}
     * @memberof PackingSlipItem
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof PackingSlipItem
     */
    expires: string;
    /**
     * 
     * @type {string}
     * @memberof PackingSlipItem
     */
    slipUrl: string;
}
/**
 * 
 * @export
 * @interface PageInfo
 */
export interface PageInfo {
    /**
     * 
     * @type {boolean}
     * @memberof PageInfo
     */
    hasNextPage: boolean;
    /**
     * 
     * @type {string}
     * @memberof PageInfo
     */
    endCursor: string;
}
/**
 * 
 * @export
 * @interface PageLiveStreamRow
 */
export interface PageLiveStreamRow {
    /**
     * 
     * @type {string}
     * @memberof PageLiveStreamRow
     */
    sellerId: string;
    /**
     * 
     * @type {string}
     * @memberof PageLiveStreamRow
     */
    type: PageLiveStreamRowTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PageLiveStreamRow
     */
    liveStreamId: string;
}

/**
* @export
* @enum {string}
*/
export enum PageLiveStreamRowTypeEnum {
    LiveStream = 'liveStream'
}
/**
 * 
 * @export
 * @interface PageProductsResponse
 */
export interface PageProductsResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof PageProductsResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof PageProductsResponse
     */
    exact: boolean;
    /**
     * 
     * @type {PageProductsRows}
     * @memberof PageProductsResponse
     */
    data: PageProductsRows;
}
/**
 * 
 * @export
 * @interface PageProductsRows
 */
export interface PageProductsRows {
    /**
     * 
     * @type {Array<PageLiveStreamRow | PageUpcomingShowRow>}
     * @memberof PageProductsRows
     */
    rows: Array<PageLiveStreamRow | PageUpcomingShowRow>;
}
/**
 * 
 * @export
 * @interface PageUpcomingShowRow
 */
export interface PageUpcomingShowRow {
    /**
     * 
     * @type {string}
     * @memberof PageUpcomingShowRow
     */
    sellerId: string;
    /**
     * 
     * @type {string}
     * @memberof PageUpcomingShowRow
     */
    type: PageUpcomingShowRowTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PageUpcomingShowRow
     */
    upcomingShowId: string;
}

/**
* @export
* @enum {string}
*/
export enum PageUpcomingShowRowTypeEnum {
    UpcomingShow = 'upcomingShow'
}
/**
 * 
 * @export
 * @interface PatchFeaturedShowRequest
 */
export interface PatchFeaturedShowRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PatchFeaturedShowRequest
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchFeaturedShowRequest
     */
    sortedAt?: string;
}
/**
 * 
 * @export
 * @interface PatchLiveStreamRequest
 */
export interface PatchLiveStreamRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchLiveStreamRequest
     */
    soldOutAction?: PatchLiveStreamRequestSoldOutActionEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchLiveStreamRequest
     */
    mode?: PatchLiveStreamRequestModeEnum;
}

/**
* @export
* @enum {string}
*/
export enum PatchLiveStreamRequestSoldOutActionEnum {
    MoveToBack = 'MoveToBack',
    RemoveFromShow = 'RemoveFromShow'
}/**
* @export
* @enum {string}
*/
export enum PatchLiveStreamRequestModeEnum {
    Normal = 'normal',
    Cap = 'cap'
}
/**
 * 
 * @export
 * @interface PatchPayoutRequest
 */
export interface PatchPayoutRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchPayoutRequest
     */
    status?: PatchPayoutRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchPayoutRequest
     */
    payoutDate?: string;
}

/**
* @export
* @enum {string}
*/
export enum PatchPayoutRequestStatusEnum {
    Pending = 'Pending',
    Processed = 'Processed',
    Completed = 'Completed',
    Failed = 'Failed'
}
/**
 * 
 * @export
 * @interface PatchPrivateProfileDto
 */
export interface PatchPrivateProfileDto {
    /**
     * 
     * @type {string}
     * @memberof PatchPrivateProfileDto
     */
    defaultVisibility?: PatchPrivateProfileDtoDefaultVisibilityEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PatchPrivateProfileDto
     */
    shouldStartShowByWeb?: boolean;
    /**
     * 
     * @type {PrintFormat}
     * @memberof PatchPrivateProfileDto
     */
    printFormat?: PrintFormat;
    /**
     * 
     * @type {string}
     * @memberof PatchPrivateProfileDto
     */
    mccCode?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchPrivateProfileDto
     */
    updateMask: Array<PatchPrivateProfileDtoUpdateMaskEnum>;
}

/**
* @export
* @enum {string}
*/
export enum PatchPrivateProfileDtoDefaultVisibilityEnum {
    All = 'all',
    AllSeller = 'allSeller',
    AllBuyer = 'allBuyer',
    Deferred = 'deferred'
}/**
* @export
* @enum {string}
*/
export enum PatchPrivateProfileDtoUpdateMaskEnum {
    DefaultVisibility = 'defaultVisibility',
    ShouldStartShowByWeb = 'shouldStartShowByWeb',
    PrintFormat = 'printFormat',
    MccCode = 'mccCode'
}
/**
 * 
 * @export
 * @interface PatchReadonlyProfileRequest
 */
export interface PatchReadonlyProfileRequest {
    /**
     * 
     * @type {number}
     * @memberof PatchReadonlyProfileRequest
     */
    commissionRate?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchReadonlyProfileRequest
     */
    taxIDConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchReadonlyProfileRequest
     */
    notes?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchReadonlyProfileRequest
     */
    updateMask: Array<PatchReadonlyProfileRequestUpdateMaskEnum>;
}

/**
* @export
* @enum {string}
*/
export enum PatchReadonlyProfileRequestUpdateMaskEnum {
    CommissionRate = 'commissionRate',
    TaxIdConfirmed = 'taxIDConfirmed',
    Notes = 'notes'
}
/**
 * 
 * @export
 * @interface PatchShopifyStoreRequest
 */
export interface PatchShopifyStoreRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchShopifyStoreRequest
     */
    shopName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchShopifyStoreRequest
     */
    autoSync?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PatchShopifyStoreRequest
     */
    accessToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchShopifyStoreRequest
     */
    uuid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchShopifyStoreRequest
     */
    locationId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchShopifyStoreRequest
     */
    needRefreshToken?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchShopifyStoreRequest
     */
    updateMask: Array<PatchShopifyStoreRequestUpdateMaskEnum>;
}

/**
* @export
* @enum {string}
*/
export enum PatchShopifyStoreRequestUpdateMaskEnum {
    ShopName = 'shopName',
    AutoSync = 'autoSync',
    AccessToken = 'accessToken',
    Uuid = 'uuid',
    LocationId = 'locationId',
    NeedRefreshToken = 'needRefreshToken'
}
/**
 * 
 * @export
 * @interface PatchShowEventFeaturedShowRequest
 */
export interface PatchShowEventFeaturedShowRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PatchShowEventFeaturedShowRequest
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchShowEventFeaturedShowRequest
     */
    sortedAt?: string;
}
/**
 * 
 * @export
 * @interface PatchShowEventRequest
 */
export interface PatchShowEventRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchShowEventRequest
     */
    featuredStoreStackName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchShowEventRequest
     */
    backgroundURL?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchShowEventRequest
     */
    verticalBackgroundURL?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchShowEventRequest
     */
    previewVideoURL?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchShowEventRequest
     */
    visibleOnCategoryBanner?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PatchShowEventRequest
     */
    categoryCoverUrl?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchShowEventRequest
     */
    showCover?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchShowEventRequest
     */
    updateMask: Array<PatchShowEventRequestUpdateMaskEnum>;
}

/**
* @export
* @enum {string}
*/
export enum PatchShowEventRequestUpdateMaskEnum {
    FeaturedStoreStackName = 'featuredStoreStackName',
    BackgroundUrl = 'backgroundURL',
    VerticalBackgroundUrl = 'verticalBackgroundURL',
    PreviewVideoUrl = 'previewVideoURL',
    VisibleOnCategoryBanner = 'visibleOnCategoryBanner',
    CategoryCoverUrl = 'categoryCoverUrl',
    ShowCover = 'showCover'
}
/**
 * 
 * @export
 * @interface PatchStoreListingRequest
 */
export interface PatchStoreListingRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchStoreListingRequest
     */
    status?: PatchStoreListingRequestStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchStoreListingRequest
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchStoreListingRequest
     */
    updateMask: Array<PatchStoreListingRequestUpdateMaskEnum>;
}

/**
* @export
* @enum {string}
*/
export enum PatchStoreListingRequestStatusEnum {
    Active = 'active',
    Archived = 'archived'
}/**
* @export
* @enum {string}
*/
export enum PatchStoreListingRequestUpdateMaskEnum {
    Status = 'status',
    Tags = 'tags'
}
/**
 * 
 * @export
 * @interface PatchStoreRequest
 */
export interface PatchStoreRequest {
    /**
     * 
     * @type {number}
     * @memberof PatchStoreRequest
     */
    shippingFee?: number | null;
}
/**
 * 
 * @export
 * @interface PatchStoreV2Request
 */
export interface PatchStoreV2Request {
    /**
     * 
     * @type {string}
     * @memberof PatchStoreV2Request
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchStoreV2Request
     */
    bio?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchStoreV2Request
     */
    announcement?: string;
    /**
     * 
     * @type {EvergreenVideoItem}
     * @memberof PatchStoreV2Request
     */
    evergreenVideo?: EvergreenVideoItem;
    /**
     * 
     * @type {string}
     * @memberof PatchStoreV2Request
     */
    shortDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchStoreV2Request
     */
    sellerPasscode?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchStoreV2Request
     */
    secondOfShippingLabelGeneration?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchStoreV2Request
     */
    shippingFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchStoreV2Request
     */
    adjustedPopshopShippingFee?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchStoreV2Request
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchStoreV2Request
     */
    badges?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PatchStoreV2Request
     */
    salesTaxRate?: { [key: string]: number; };
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchStoreV2Request
     */
    updateMask: Array<PatchStoreV2RequestUpdateMaskEnum>;
}

/**
* @export
* @enum {string}
*/
export enum PatchStoreV2RequestUpdateMaskEnum {
    Name = 'name',
    Bio = 'bio',
    Announcement = 'announcement',
    EvergreenVideo = 'evergreenVideo',
    ShortDescription = 'shortDescription',
    SellerPasscode = 'sellerPasscode',
    SecondOfShippingLabelGeneration = 'secondOfShippingLabelGeneration',
    ShippingFee = 'shippingFee',
    AdjustedPopshopShippingFee = 'adjustedPopshopShippingFee',
    Tags = 'tags',
    Badges = 'badges',
    SalesTaxRate = 'salesTaxRate'
}
/**
 * 
 * @export
 * @interface PatchVideoMessageRequest
 */
export interface PatchVideoMessageRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchVideoMessageRequest
     */
    videoURL?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchVideoMessageRequest
     */
    emoji?: PatchVideoMessageRequestEmojiEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchVideoMessageRequest
     */
    updateMask: Array<PatchVideoMessageRequestUpdateMaskEnum>;
}

/**
* @export
* @enum {string}
*/
export enum PatchVideoMessageRequestEmojiEnum {
    RollingEyesFace = 'rolling_eyes_face',
    Sweat = 'sweat',
    Blush = 'blush',
    Smile = 'smile',
    HeartEyes = 'heart_eyes'
}/**
* @export
* @enum {string}
*/
export enum PatchVideoMessageRequestUpdateMaskEnum {
    VideoUrl = 'videoURL',
    Emoji = 'emoji'
}
/**
 * 
 * @export
 * @interface PauseRequest
 */
export interface PauseRequest {
    /**
     * 
     * @type {string}
     * @memberof PauseRequest
     */
    streamPausedText?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PauseRequest
     */
    manually?: boolean;
}
/**
 * 
 * @export
 * @interface PayoutBankDto
 */
export interface PayoutBankDto {
    /**
     * 
     * @type {string}
     * @memberof PayoutBankDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBankDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBankDto
     */
    accountNumber: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBankDto
     */
    routingNumber: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBankDto
     */
    accountType?: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBankDto
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBankDto
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBankDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBankDto
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBankDto
     */
    zipcode?: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBankDto
     */
    country?: string;
}
/**
 * 
 * @export
 * @interface PayoutBankItem
 */
export interface PayoutBankItem {
    /**
     * 
     * @type {string}
     * @memberof PayoutBankItem
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBankItem
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBankItem
     */
    accountNumber: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBankItem
     */
    routingNumber: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBankItem
     */
    accountType?: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBankItem
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBankItem
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBankItem
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBankItem
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBankItem
     */
    zipcode?: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutBankItem
     */
    country?: string;
}
/**
 * 
 * @export
 * @interface PayoutPaypalDto
 */
export interface PayoutPaypalDto {
    /**
     * 
     * @type {string}
     * @memberof PayoutPaypalDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutPaypalDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutPaypalDto
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutPaypalDto
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface PayoutPaypalItem
 */
export interface PayoutPaypalItem {
    /**
     * 
     * @type {string}
     * @memberof PayoutPaypalItem
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutPaypalItem
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutPaypalItem
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutPaypalItem
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface PayoutVenmoDto
 */
export interface PayoutVenmoDto {
    /**
     * 
     * @type {string}
     * @memberof PayoutVenmoDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutVenmoDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutVenmoDto
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutVenmoDto
     */
    email: string;
}
/**
 * 
 * @export
 * @interface PayoutVenmoItem
 */
export interface PayoutVenmoItem {
    /**
     * 
     * @type {string}
     * @memberof PayoutVenmoItem
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutVenmoItem
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutVenmoItem
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutVenmoItem
     */
    email: string;
}
/**
 * 
 * @export
 * @interface PickWinnerRequest
 */
export interface PickWinnerRequest {
    /**
     * 
     * @type {boolean}
     * @memberof PickWinnerRequest
     */
    needDeduplicate: boolean;
    /**
     * 
     * @type {string}
     * @memberof PickWinnerRequest
     */
    type: PickWinnerRequestTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum PickWinnerRequestTypeEnum {
    UserEnter = 'userEnter',
    UserFollow = 'userFollow',
    Purchase = 'purchase',
    Share = 'share'
}
/**
 * 
 * @export
 * @interface PickWinnerResponse
 */
export interface PickWinnerResponse {
    /**
     * 
     * @type {string}
     * @memberof PickWinnerResponse
     */
    winnerId: string;
}
/**
 * 
 * @export
 * @interface PickupAddressDto
 */
export interface PickupAddressDto {
    /**
     * 
     * @type {string}
     * @memberof PickupAddressDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressDto
     */
    primaryPhone: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressDto
     */
    line1: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressDto
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressDto
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressDto
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressDto
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressDto
     */
    compareHash?: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressDto
     */
    addressId: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressDto
     */
    pickupNote?: string;
}
/**
 * 
 * @export
 * @interface PickupAddressItem
 */
export interface PickupAddressItem {
    /**
     * 
     * @type {string}
     * @memberof PickupAddressItem
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressItem
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressItem
     */
    primaryPhone: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressItem
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressItem
     */
    line1: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressItem
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressItem
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressItem
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressItem
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressItem
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressItem
     */
    compareHash?: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressItem
     */
    addressId: string;
}
/**
 * 
 * @export
 * @interface PickupAddressResponse
 */
export interface PickupAddressResponse {
    /**
     * 
     * @type {string}
     * @memberof PickupAddressResponse
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressResponse
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressResponse
     */
    primaryPhone: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressResponse
     */
    line1: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressResponse
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressResponse
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressResponse
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressResponse
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressResponse
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressResponse
     */
    pickupNote?: string;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressResponse
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof PickupAddressResponse
     */
    isDefault: boolean;
    /**
     * 
     * @type {string}
     * @memberof PickupAddressResponse
     */
    compareHash: string;
    /**
     * 
     * @type {object}
     * @memberof PickupAddressResponse
     */
    createdAt: object;
    /**
     * 
     * @type {object}
     * @memberof PickupAddressResponse
     */
    updatedAt: object;
}
/**
 * 
 * @export
 * @interface PickupNotifyDto
 */
export interface PickupNotifyDto {
    /**
     * 
     * @type {string}
     * @memberof PickupNotifyDto
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface PickupTimeWindowResponse
 */
export interface PickupTimeWindowResponse {
    /**
     * 
     * @type {number}
     * @memberof PickupTimeWindowResponse
     */
    window: number;
}
/**
 * 
 * @export
 * @interface PreviewLiveStreamWrapEmailRequest
 */
export interface PreviewLiveStreamWrapEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof PreviewLiveStreamWrapEmailRequest
     */
    note?: string;
}
/**
 * 
 * @export
 * @interface PreviewLiveStreamWrapEmailResponse
 */
export interface PreviewLiveStreamWrapEmailResponse {
    /**
     * 
     * @type {string}
     * @memberof PreviewLiveStreamWrapEmailResponse
     */
    html: string;
}
/**
 * 
 * @export
 * @interface PreviewOrderAvailableCouponsDto
 */
export interface PreviewOrderAvailableCouponsDto {
    /**
     * 
     * @type {string}
     * @memberof PreviewOrderAvailableCouponsDto
     */
    redeemedCouponId: string;
    /**
     * 
     * @type {string}
     * @memberof PreviewOrderAvailableCouponsDto
     */
    selectStatus: PreviewOrderAvailableCouponsDtoSelectStatusEnum;
    /**
     * 
     * @type {CouponItem}
     * @memberof PreviewOrderAvailableCouponsDto
     */
    coupon: CouponItem;
    /**
     * 
     * @type {string}
     * @memberof PreviewOrderAvailableCouponsDto
     */
    couponId: string;
    /**
     * 
     * @type {string}
     * @memberof PreviewOrderAvailableCouponsDto
     */
    reason?: string;
}

/**
* @export
* @enum {string}
*/
export enum PreviewOrderAvailableCouponsDtoSelectStatusEnum {
    Selected = 'selected',
    Selectable = 'selectable',
    Unselectable = 'unselectable'
}
/**
 * 
 * @export
 * @interface PreviewOrderDto
 */
export interface PreviewOrderDto {
    /**
     * 
     * @type {string}
     * @memberof PreviewOrderDto
     */
    listingId: string;
    /**
     * 
     * @type {string}
     * @memberof PreviewOrderDto
     */
    sellerId: string;
    /**
     * The recipient of this gift order
     * @type {string}
     * @memberof PreviewOrderDto
     */
    recipientId?: string;
    /**
     * 
     * @type {string}
     * @memberof PreviewOrderDto
     */
    sendItemId?: string;
    /**
     * 
     * @type {string}
     * @memberof PreviewOrderDto
     */
    liveStreamId?: string;
    /**
     * 
     * @type {string}
     * @memberof PreviewOrderDto
     */
    variantId?: string;
    /**
     * 
     * @type {string}
     * @memberof PreviewOrderDto
     */
    shippingMethod?: PreviewOrderDtoShippingMethodEnum;
    /**
     * Optional for gifting orders
     * @type {AddressDto}
     * @memberof PreviewOrderDto
     */
    shippingAddress?: AddressDto | null;
    /**
     * 
     * @type {PickupAddressDto}
     * @memberof PreviewOrderDto
     */
    pickupAddress?: PickupAddressDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof PreviewOrderDto
     */
    redeemedCouponIds?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PreviewOrderDto
     */
    quantity: number;
    /**
     * 
     * @type {LiveStreamSource | TwoWayStreamSource | UpcomingShowSource | StoreSource}
     * @memberof PreviewOrderDto
     */
    source?: LiveStreamSource | TwoWayStreamSource | UpcomingShowSource | StoreSource | null;
}

/**
* @export
* @enum {string}
*/
export enum PreviewOrderDtoShippingMethodEnum {
    SellerProvide = 'sellerProvide',
    PopshopProvide = 'popshopProvide',
    Pickup = 'pickup'
}
/**
 * 
 * @export
 * @interface PreviewOrderResponse
 */
export interface PreviewOrderResponse {
    /**
     * 
     * @type {number}
     * @memberof PreviewOrderResponse
     */
    buyerPaidAmount: number;
    /**
     * 
     * @type {number}
     * @memberof PreviewOrderResponse
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof PreviewOrderResponse
     */
    salesTaxAmount: number;
    /**
     * 
     * @type {number}
     * @memberof PreviewOrderResponse
     */
    salesTaxRate: number;
    /**
     * 
     * @type {number}
     * @memberof PreviewOrderResponse
     */
    shippingFee: number;
    /**
     * 
     * @type {number}
     * @memberof PreviewOrderResponse
     */
    couponDiscountShippingFeeAmount: number;
    /**
     * 
     * @type {number}
     * @memberof PreviewOrderResponse
     */
    couponDiscountProductAmount: number;
    /**
     * 
     * @type {Array<PreviewOrderAvailableCouponsDto>}
     * @memberof PreviewOrderResponse
     */
    availableCoupons?: Array<PreviewOrderAvailableCouponsDto>;
    /**
     * 
     * @type {string}
     * @memberof PreviewOrderResponse
     */
    popshopShippingFeeSource?: PreviewOrderResponsePopshopShippingFeeSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof PreviewOrderResponse
     */
    shipmentGroupStatus?: PreviewOrderResponseShipmentGroupStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PreviewOrderResponse
     */
    shipmentShippingMethod: PreviewOrderResponseShipmentShippingMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof PreviewOrderResponse
     */
    masterOrderId?: string;
}

/**
* @export
* @enum {string}
*/
export enum PreviewOrderResponsePopshopShippingFeeSourceEnum {
    ReadonlyProfile = 'READONLY_PROFILE',
    Default = 'DEFAULT',
    ShowEvent = 'SHOW_EVENT'
}/**
* @export
* @enum {string}
*/
export enum PreviewOrderResponseShipmentGroupStatusEnum {
    Customer = 'customer',
    Slave = 'slave',
    Master = 'master'
}/**
* @export
* @enum {string}
*/
export enum PreviewOrderResponseShipmentShippingMethodEnum {
    SellerProvide = 'sellerProvide',
    PopshopProvide = 'popshopProvide',
    Pickup = 'pickup'
}
/**
 * 
 * @export
 * @interface PreviewTicketOrderRequest
 */
export interface PreviewTicketOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof PreviewTicketOrderRequest
     */
    userAgent?: string;
    /**
     * 
     * @type {TicketOrderAddressRequest}
     * @memberof PreviewTicketOrderRequest
     */
    shippingAddress?: TicketOrderAddressRequest;
}
/**
 * 
 * @export
 * @interface PreviewTicketOrderResponse
 */
export interface PreviewTicketOrderResponse {
    /**
     * 
     * @type {number}
     * @memberof PreviewTicketOrderResponse
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof PreviewTicketOrderResponse
     */
    salesTaxRate: number;
    /**
     * 
     * @type {number}
     * @memberof PreviewTicketOrderResponse
     */
    salesTaxAmount: number;
    /**
     * 
     * @type {number}
     * @memberof PreviewTicketOrderResponse
     */
    shippingFee: number;
}
/**
 * 
 * @export
 * @interface PrintFormat
 */
export interface PrintFormat {
    /**
     * 
     * @type {string}
     * @memberof PrintFormat
     */
    packingSlip?: string;
    /**
     * 
     * @type {string}
     * @memberof PrintFormat
     */
    shippingLabel?: string;
}
/**
 * 
 * @export
 * @interface PrivateProfileItem
 */
export interface PrivateProfileItem {
    /**
     * 
     * @type {string}
     * @memberof PrivateProfileItem
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PrivateProfileItem
     */
    email?: string;
    /**
     * 
     * @type {AddressItem}
     * @memberof PrivateProfileItem
     */
    defaultAddress?: AddressItem;
    /**
     * 
     * @type {AddressItem}
     * @memberof PrivateProfileItem
     */
    sellerShippingAddress?: AddressItem;
    /**
     * 
     * @type {string}
     * @memberof PrivateProfileItem
     */
    defaultVisibility?: PrivateProfileItemDefaultVisibilityEnum;
    /**
     * 
     * @type {PayoutBankItem}
     * @memberof PrivateProfileItem
     */
    payoutMethodBank?: PayoutBankItem;
    /**
     * 
     * @type {PayoutVenmoItem}
     * @memberof PrivateProfileItem
     */
    payoutMethodVenmo?: PayoutVenmoItem;
    /**
     * 
     * @type {PayoutPaypalItem}
     * @memberof PrivateProfileItem
     */
    payoutMethodPaypal?: PayoutPaypalItem;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrivateProfileItem
     */
    watchPasscodes?: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum PrivateProfileItemDefaultVisibilityEnum {
    All = 'all',
    AllSeller = 'allSeller',
    AllBuyer = 'allBuyer',
    Deferred = 'deferred'
}
/**
 * 
 * @export
 * @interface PrivateProfilesPayoutHistoryItemWithId
 */
export interface PrivateProfilesPayoutHistoryItemWithId {
    /**
     * 
     * @type {string}
     * @memberof PrivateProfilesPayoutHistoryItemWithId
     */
    userId?: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof PrivateProfilesPayoutHistoryItemWithId
     */
    profile: ProfileItem;
    /**
     * 
     * @type {string}
     * @memberof PrivateProfilesPayoutHistoryItemWithId
     */
    operatorId?: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof PrivateProfilesPayoutHistoryItemWithId
     */
    operatorProfile?: ProfileItem;
    /**
     * 
     * @type {string}
     * @memberof PrivateProfilesPayoutHistoryItemWithId
     */
    status?: PrivateProfilesPayoutHistoryItemWithIdStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateProfilesPayoutHistoryItemWithId
     */
    sourceType?: PrivateProfilesPayoutHistoryItemWithIdSourceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateProfilesPayoutHistoryItemWithId
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof PrivateProfilesPayoutHistoryItemWithId
     */
    updatedAt: string;
    /**
     * 
     * @type {PayoutBankItem | PayoutVenmoItem | PayoutPaypalItem}
     * @memberof PrivateProfilesPayoutHistoryItemWithId
     */
    before?: PayoutBankItem | PayoutVenmoItem | PayoutPaypalItem | null;
    /**
     * 
     * @type {PayoutBankItem | PayoutVenmoItem | PayoutPaypalItem}
     * @memberof PrivateProfilesPayoutHistoryItemWithId
     */
    after?: PayoutBankItem | PayoutVenmoItem | PayoutPaypalItem | null;
    /**
     * 
     * @type {string}
     * @memberof PrivateProfilesPayoutHistoryItemWithId
     */
    id: string;
}

/**
* @export
* @enum {string}
*/
export enum PrivateProfilesPayoutHistoryItemWithIdStatusEnum {
    Approved = 'approved',
    Pending = 'pending',
    Rejected = 'rejected',
    Canceled = 'canceled'
}/**
* @export
* @enum {string}
*/
export enum PrivateProfilesPayoutHistoryItemWithIdSourceTypeEnum {
    Admin = 'admin',
    Seller = 'seller'
}
/**
 * 
 * @export
 * @interface PrivateProfilesPayoutHistoryPageRows
 */
export interface PrivateProfilesPayoutHistoryPageRows {
    /**
     * 
     * @type {Array<PrivateProfilesPayoutHistoryItemWithId>}
     * @memberof PrivateProfilesPayoutHistoryPageRows
     */
    rows: Array<PrivateProfilesPayoutHistoryItemWithId>;
}
/**
 * 
 * @export
 * @interface PrivateProfilesPayoutHistorySearchResponse
 */
export interface PrivateProfilesPayoutHistorySearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof PrivateProfilesPayoutHistorySearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof PrivateProfilesPayoutHistorySearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {PrivateProfilesPayoutHistoryPageRows}
     * @memberof PrivateProfilesPayoutHistorySearchResponse
     */
    data: PrivateProfilesPayoutHistoryPageRows;
}
/**
 * 
 * @export
 * @interface ProductsListing
 */
export interface ProductsListing {
    /**
     * 
     * @type {string}
     * @memberof ProductsListing
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsListing
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsListing
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductsListing
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsListing
     */
    discountPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductsListing
     */
    sku?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsListing
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductsListing
     */
    defaultImage: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductsListing
     */
    extraImages?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProductsListing
     */
    quantity: number;
    /**
     * 
     * @type {Array<StoreListingVariant>}
     * @memberof ProductsListing
     */
    variants?: Array<StoreListingVariant>;
    /**
     * 
     * @type {number}
     * @memberof ProductsListing
     */
    maximumPurchaseQuantity?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductsListing
     */
    shippingFee?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductsListing
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProductsListing
     */
    status?: ProductsListingStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductsListing
     */
    maximumPurchaseQuantityPerLiveStream?: number;
    /**
     * 
     * @type {StoreListingOriginalInfo}
     * @memberof ProductsListing
     */
    originalInfo?: StoreListingOriginalInfo;
}

/**
* @export
* @enum {string}
*/
export enum ProductsListingStatusEnum {
    Active = 'active',
    Archived = 'archived'
}
/**
 * 
 * @export
 * @interface ProductsQueryInfo
 */
export interface ProductsQueryInfo {
    /**
     * 
     * @type {Array<Edge>}
     * @memberof ProductsQueryInfo
     */
    edges: Array<Edge>;
    /**
     * 
     * @type {PageInfo}
     * @memberof ProductsQueryInfo
     */
    pageInfo: PageInfo;
}
/**
 * 
 * @export
 * @interface ProfileBadges
 */
export interface ProfileBadges {
    /**
     * 
     * @type {boolean}
     * @memberof ProfileBadges
     */
    activeSeller?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileBadges
     */
    staff?: boolean;
}
/**
 * 
 * @export
 * @interface ProfileItem
 */
export interface ProfileItem {
    /**
     * 
     * @type {string}
     * @memberof ProfileItem
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileItem
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileItem
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileItem
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileItem
     */
    storeFrontName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileItem
     */
    createdAt?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ProfileItem
     */
    hashtags?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ProfileItem
     */
    userType?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileItem
     */
    urlCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileItem
     */
    username?: string;
    /**
     * 
     * @type {ProfileBadges}
     * @memberof ProfileItem
     */
    badges?: ProfileBadges;
    /**
     * 
     * @type {string}
     * @memberof ProfileItem
     */
    id: string;
}
/**
 * 
 * @export
 * @interface ProfilePageRows
 */
export interface ProfilePageRows {
    /**
     * 
     * @type {Array<ProfileItem>}
     * @memberof ProfilePageRows
     */
    rows: Array<ProfileItem>;
}
/**
 * 
 * @export
 * @interface ProfileResponse
 */
export interface ProfileResponse {
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    avatar?: string;
    /**
     * 
     * @type {object}
     * @memberof ProfileResponse
     */
    createdAt?: object;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    firstName: string;
    /**
     * 
     * @type {object}
     * @memberof ProfileResponse
     */
    hashtags?: object;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    storeFrontName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    urlCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileResponse
     */
    userType?: string;
}
/**
 * 
 * @export
 * @interface ProfileSearchResponse
 */
export interface ProfileSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof ProfileSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof ProfileSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {ProfilePageRows}
     * @memberof ProfileSearchResponse
     */
    data: ProfilePageRows;
}
/**
 * 
 * @export
 * @interface ProfilesFollowerItem
 */
export interface ProfilesFollowerItem {
    /**
     * 
     * @type {string}
     * @memberof ProfilesFollowerItem
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfilesFollowerItem
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ProfilesFollowerItem
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ProfilesFollowerItem
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ProfilesFollowerItem
     */
    storeFrontName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfilesFollowerItem
     */
    createdAt?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ProfilesFollowerItem
     */
    hashtags?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ProfilesFollowerItem
     */
    userType?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfilesFollowerItem
     */
    urlCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfilesFollowerItem
     */
    username?: string;
    /**
     * 
     * @type {ProfileBadges}
     * @memberof ProfilesFollowerItem
     */
    badges?: ProfileBadges;
    /**
     * 
     * @type {string}
     * @memberof ProfilesFollowerItem
     */
    id: string;
    /**
     * 
     * @type {Metadata}
     * @memberof ProfilesFollowerItem
     */
    metadata: Metadata;
    /**
     * 
     * @type {string}
     * @memberof ProfilesFollowerItem
     */
    timestamp: string;
}
/**
 * 
 * @export
 * @interface ProfilesFollowerPageRows
 */
export interface ProfilesFollowerPageRows {
    /**
     * 
     * @type {Array<ProfilesFollowerItem>}
     * @memberof ProfilesFollowerPageRows
     */
    rows: Array<ProfilesFollowerItem>;
}
/**
 * 
 * @export
 * @interface ProfilesFollowersSearchResponse
 */
export interface ProfilesFollowersSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof ProfilesFollowersSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof ProfilesFollowersSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {ProfilesFollowerPageRows}
     * @memberof ProfilesFollowersSearchResponse
     */
    data: ProfilesFollowerPageRows;
}
/**
 * 
 * @export
 * @interface ProfilesFollowingsSearchResponse
 */
export interface ProfilesFollowingsSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof ProfilesFollowingsSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof ProfilesFollowingsSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {ProfilesFollowerPageRows}
     * @memberof ProfilesFollowingsSearchResponse
     */
    data: ProfilesFollowerPageRows;
}
/**
 * 
 * @export
 * @interface PromotionPageItem
 */
export interface PromotionPageItem {
    /**
     * 
     * @type {string}
     * @memberof PromotionPageItem
     */
    video: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionPageItem
     */
    avatar: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionPageItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PromotionPageItem
     */
    coverImage: string;
    /**
     * 
     * @type {boolean}
     * @memberof PromotionPageItem
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof PromotionPageItem
     */
    thumbnailNickName: string;
}
/**
 * 
 * @export
 * @interface ProviderUser
 */
export interface ProviderUser {
    /**
     * 
     * @type {string}
     * @memberof ProviderUser
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderUser
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderUser
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderUser
     */
    photoURL: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderUser
     */
    providerId: string;
}
/**
 * 
 * @export
 * @interface PurchasedStoresItem
 */
export interface PurchasedStoresItem {
    /**
     * 
     * @type {string}
     * @memberof PurchasedStoresItem
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasedStoresItem
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasedStoresItem
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasedStoresItem
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasedStoresItem
     */
    storeFrontName?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasedStoresItem
     */
    createdAt?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PurchasedStoresItem
     */
    hashtags?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof PurchasedStoresItem
     */
    userType?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasedStoresItem
     */
    urlCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasedStoresItem
     */
    username?: string;
    /**
     * 
     * @type {ProfileBadges}
     * @memberof PurchasedStoresItem
     */
    badges?: ProfileBadges;
    /**
     * 
     * @type {string}
     * @memberof PurchasedStoresItem
     */
    id: string;
    /**
     * 
     * @type {Metadata}
     * @memberof PurchasedStoresItem
     */
    metadata: Metadata;
    /**
     * 
     * @type {string}
     * @memberof PurchasedStoresItem
     */
    sellerId: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasedStoresItem
     */
    latestPurchasedAt: string;
}
/**
 * 
 * @export
 * @interface PurchasedStoresResponse
 */
export interface PurchasedStoresResponse {
    /**
     * 
     * @type {Array<PurchasedStoresItem>}
     * @memberof PurchasedStoresResponse
     */
    rows: Array<PurchasedStoresItem>;
}
/**
 * 
 * @export
 * @interface QueryAllProductsResponse
 */
export interface QueryAllProductsResponse {
    /**
     * 
     * @type {Array<StoreListingItem>}
     * @memberof QueryAllProductsResponse
     */
    products: Array<StoreListingItem>;
}
/**
 * 
 * @export
 * @interface QueryProductsResponseDto
 */
export interface QueryProductsResponseDto {
    /**
     * 
     * @type {ProductsQueryInfo}
     * @memberof QueryProductsResponseDto
     */
    products: ProductsQueryInfo;
}
/**
 * 
 * @export
 * @interface RecentlyAddedShowsResponse
 */
export interface RecentlyAddedShowsResponse {
    /**
     * 
     * @type {Array<DiscoveryShowUpcomingShowItem>}
     * @memberof RecentlyAddedShowsResponse
     */
    data: Array<DiscoveryShowUpcomingShowItem>;
}
/**
 * 
 * @export
 * @interface RecipientItem
 */
export interface RecipientItem {
    /**
     * 
     * @type {string}
     * @memberof RecipientItem
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientItem
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientItem
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientItem
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientItem
     */
    storeFrontName?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientItem
     */
    createdAt?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RecipientItem
     */
    hashtags?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof RecipientItem
     */
    userType?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientItem
     */
    urlCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientItem
     */
    username?: string;
    /**
     * 
     * @type {ProfileBadges}
     * @memberof RecipientItem
     */
    badges?: ProfileBadges;
    /**
     * 
     * @type {string}
     * @memberof RecipientItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RecipientItem
     */
    nftImageUrl?: string;
}
/**
 * 
 * @export
 * @interface RedeemCouponRequest
 */
export interface RedeemCouponRequest {
    /**
     * 
     * @type {string}
     * @memberof RedeemCouponRequest
     */
    sellerId?: string;
}
/**
 * 
 * @export
 * @interface RedeemedCouponDetailItem
 */
export interface RedeemedCouponDetailItem {
    /**
     * 
     * @type {string}
     * @memberof RedeemedCouponDetailItem
     */
    couponId: string;
    /**
     * 
     * @type {CouponItem}
     * @memberof RedeemedCouponDetailItem
     */
    coupon: CouponItem;
    /**
     * 
     * @type {string}
     * @memberof RedeemedCouponDetailItem
     */
    usedAt: string;
    /**
     * 
     * @type {boolean}
     * @memberof RedeemedCouponDetailItem
     */
    used: boolean;
}
/**
 * 
 * @export
 * @interface RedeemedCouponItem
 */
export interface RedeemedCouponItem {
    /**
     * 
     * @type {string}
     * @memberof RedeemedCouponItem
     */
    redeemedCouponId: string;
    /**
     * 
     * @type {string}
     * @memberof RedeemedCouponItem
     */
    couponId: string;
    /**
     * 
     * @type {CouponItem}
     * @memberof RedeemedCouponItem
     */
    coupon: CouponItem;
}
/**
 * 
 * @export
 * @interface RefundOrderRequest
 */
export interface RefundOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof RefundOrderRequest
     */
    refundType: string;
    /**
     * 
     * @type {number}
     * @memberof RefundOrderRequest
     */
    amount?: number;
}
/**
 * 
 * @export
 * @interface RejectWithdrawTransactionDto
 */
export interface RejectWithdrawTransactionDto {
    /**
     * 
     * @type {string}
     * @memberof RejectWithdrawTransactionDto
     */
    note?: string;
}
/**
 * 
 * @export
 * @interface Relations
 */
export interface Relations {
    /**
     * 
     * @type {string}
     * @memberof Relations
     */
    parentId: string;
}
/**
 * 
 * @export
 * @interface ResumeLiveStreamRequest
 */
export interface ResumeLiveStreamRequest {
    /**
     * 
     * @type {string}
     * @memberof ResumeLiveStreamRequest
     */
    device?: string;
}
/**
 * 
 * @export
 * @interface RolesSearchResponse
 */
export interface RolesSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof RolesSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof RolesSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {UserPageRows}
     * @memberof RolesSearchResponse
     */
    data: UserPageRows;
}
/**
 * 
 * @export
 * @interface SaveFromRemote
 */
export interface SaveFromRemote {
    /**
     * 
     * @type {string}
     * @memberof SaveFromRemote
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof SaveFromRemote
     */
    cloudPath: string;
}
/**
 * 
 * @export
 * @interface SaveFromRemoteResponse
 */
export interface SaveFromRemoteResponse {
    /**
     * 
     * @type {string}
     * @memberof SaveFromRemoteResponse
     */
    url: string;
}
/**
 * 
 * @export
 * @interface SearchAggregateLiveStreamUserStatsResponse
 */
export interface SearchAggregateLiveStreamUserStatsResponse {
    /**
     * 
     * @type {number}
     * @memberof SearchAggregateLiveStreamUserStatsResponse
     */
    repeatPurchasers?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchAggregateLiveStreamUserStatsResponse
     */
    newViewers?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchAggregateLiveStreamUserStatsResponse
     */
    newFollowers?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchAggregateLiveStreamUserStatsResponse
     */
    newPurchasers?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchAggregateLiveStreamUserStatsResponse
     */
    repeatViewers?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchAggregateLiveStreamUserStatsResponse
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface SearchLiveStreamUserStatsParams
 */
export interface SearchLiveStreamUserStatsParams {
    /**
     * Must be greater than 0.
     * @type {number}
     * @memberof SearchLiveStreamUserStatsParams
     */
    pageNo: number;
    /**
     * Must be between 1 and 200.
     * @type {number}
     * @memberof SearchLiveStreamUserStatsParams
     */
    pageSize: number;
    /**
     * 
     * @type {string}
     * @memberof SearchLiveStreamUserStatsParams
     */
    order?: SearchLiveStreamUserStatsParamsOrderEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SearchLiveStreamUserStatsParams
     */
    isFollower?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchLiveStreamUserStatsParams
     */
    isNewFollower?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchLiveStreamUserStatsParams
     */
    isNewSellerCustomer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchLiveStreamUserStatsParams
     */
    isRepeatSellerCustomer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchLiveStreamUserStatsParams
     */
    isNewSellerViewer?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchLiveStreamUserStatsParams
     */
    isRepeatSellerViewer?: boolean;
    /**
     * 
     * @type {SearchLiveStreamUserStatsRangeFilter}
     * @memberof SearchLiveStreamUserStatsParams
     */
    range?: SearchLiveStreamUserStatsRangeFilter;
    /**
     * 
     * @type {string}
     * @memberof SearchLiveStreamUserStatsParams
     */
    query?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchLiveStreamUserStatsParams
     */
    sortBy?: SearchLiveStreamUserStatsParamsSortByEnum;
}

/**
* @export
* @enum {string}
*/
export enum SearchLiveStreamUserStatsParamsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}/**
* @export
* @enum {string}
*/
export enum SearchLiveStreamUserStatsParamsSortByEnum {
    Gmv = 'gmv',
    WatchedSec = 'watched_sec',
    LikeCnt = 'like_cnt',
    CommentCnt = 'comment_cnt',
    ItemCnt = 'item_cnt',
    ShareCnt = 'share_cnt',
    Id = 'id'
}
/**
 * 
 * @export
 * @interface SearchLiveStreamUserStatsRangeFilter
 */
export interface SearchLiveStreamUserStatsRangeFilter {
    /**
     * 
     * @type {NumberRange}
     * @memberof SearchLiveStreamUserStatsRangeFilter
     */
    watchedSec?: NumberRange;
    /**
     * 
     * @type {NumberRange}
     * @memberof SearchLiveStreamUserStatsRangeFilter
     */
    likeCnt?: NumberRange;
    /**
     * 
     * @type {NumberRange}
     * @memberof SearchLiveStreamUserStatsRangeFilter
     */
    commentCnt?: NumberRange;
    /**
     * 
     * @type {NumberRange}
     * @memberof SearchLiveStreamUserStatsRangeFilter
     */
    itemCnt?: NumberRange;
    /**
     * 
     * @type {NumberRange}
     * @memberof SearchLiveStreamUserStatsRangeFilter
     */
    gmv?: NumberRange;
    /**
     * 
     * @type {NumberRange}
     * @memberof SearchLiveStreamUserStatsRangeFilter
     */
    shareCnt?: NumberRange;
}
/**
 * 
 * @export
 * @interface SearchLiveStreamUserStatsResponse
 */
export interface SearchLiveStreamUserStatsResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof SearchLiveStreamUserStatsResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof SearchLiveStreamUserStatsResponse
     */
    exact: boolean;
    /**
     * 
     * @type {LiveStreamUserStatsPageRows}
     * @memberof SearchLiveStreamUserStatsResponse
     */
    data: LiveStreamUserStatsPageRows;
}
/**
 * 
 * @export
 * @interface SearchResponse
 */
export interface SearchResponse {
    /**
     * 
     * @type {Array<StoreDTO>}
     * @memberof SearchResponse
     */
    popshopStores: Array<StoreDTO>;
}
/**
 * 
 * @export
 * @interface SearchSellerLiveStreamsStatsResponse
 */
export interface SearchSellerLiveStreamsStatsResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof SearchSellerLiveStreamsStatsResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof SearchSellerLiveStreamsStatsResponse
     */
    exact: boolean;
    /**
     * 
     * @type {SellerLiveStreamsStatsPageRows}
     * @memberof SearchSellerLiveStreamsStatsResponse
     */
    data: SellerLiveStreamsStatsPageRows;
}
/**
 * 
 * @export
 * @interface SearchSellerViewersParams
 */
export interface SearchSellerViewersParams {
    /**
     * Must be greater than 0.
     * @type {number}
     * @memberof SearchSellerViewersParams
     */
    pageNo: number;
    /**
     * Must be between 1 and 200.
     * @type {number}
     * @memberof SearchSellerViewersParams
     */
    pageSize: number;
    /**
     * 
     * @type {string}
     * @memberof SearchSellerViewersParams
     */
    order?: SearchSellerViewersParamsOrderEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchSellerViewersParams
     */
    period?: SearchSellerViewersParamsPeriodEnum;
    /**
     * search by username
     * @type {string}
     * @memberof SearchSellerViewersParams
     */
    query?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SearchSellerViewersParams
     */
    isFollower?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchSellerViewersParams
     */
    isReferral?: boolean;
    /**
     * 
     * @type {ProfileBadges}
     * @memberof SearchSellerViewersParams
     */
    badges?: ProfileBadges;
    /**
     * 
     * @type {SearchSellerViewersRaneFilter}
     * @memberof SearchSellerViewersParams
     */
    range?: SearchSellerViewersRaneFilter;
    /**
     * 
     * @type {string}
     * @memberof SearchSellerViewersParams
     */
    sortBy?: SearchSellerViewersParamsSortByEnum;
}

/**
* @export
* @enum {string}
*/
export enum SearchSellerViewersParamsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}/**
* @export
* @enum {string}
*/
export enum SearchSellerViewersParamsPeriodEnum {
    All = 'all',
    Recent1Y = 'recent1Y',
    Recent30D = 'recent30D'
}/**
* @export
* @enum {string}
*/
export enum SearchSellerViewersParamsSortByEnum {
    Gmv = 'gmv',
    FirstPurchaseAt = 'first_purchase_at',
    LastPurchaseAt = 'last_purchase_at',
    FirstWatchedAt = 'first_watched_at',
    LastWatchedAt = 'last_watched_at',
    FollowedAt = 'followed_at',
    ReferralAt = 'referral_at',
    PurchaseCnt = 'purchase_cnt',
    WatchedSec = 'watched_sec',
    ReferralCnt = 'referral_cnt',
    WatchedShowCnt = 'watched_show_cnt',
    LikeCnt = 'like_cnt',
    ShareCnt = 'share_cnt',
    CommentCnt = 'comment_cnt',
    Id = 'id'
}
/**
 * 
 * @export
 * @interface SearchSellerViewersRaneFilter
 */
export interface SearchSellerViewersRaneFilter {
    /**
     * 
     * @type {StringRange}
     * @memberof SearchSellerViewersRaneFilter
     */
    firstPurchaseAt?: StringRange;
    /**
     * 
     * @type {StringRange}
     * @memberof SearchSellerViewersRaneFilter
     */
    lastPurchaseAt?: StringRange;
    /**
     * 
     * @type {StringRange}
     * @memberof SearchSellerViewersRaneFilter
     */
    firstWatchedAt?: StringRange;
    /**
     * 
     * @type {StringRange}
     * @memberof SearchSellerViewersRaneFilter
     */
    lastWatchedAt?: StringRange;
    /**
     * 
     * @type {StringRange}
     * @memberof SearchSellerViewersRaneFilter
     */
    followedAt?: StringRange;
    /**
     * 
     * @type {StringRange}
     * @memberof SearchSellerViewersRaneFilter
     */
    referralAt?: StringRange;
    /**
     * 
     * @type {NumberRange}
     * @memberof SearchSellerViewersRaneFilter
     */
    watchedSec?: NumberRange;
    /**
     * 
     * @type {NumberRange}
     * @memberof SearchSellerViewersRaneFilter
     */
    likeCnt?: NumberRange;
    /**
     * 
     * @type {NumberRange}
     * @memberof SearchSellerViewersRaneFilter
     */
    commentCnt?: NumberRange;
    /**
     * 
     * @type {NumberRange}
     * @memberof SearchSellerViewersRaneFilter
     */
    itemCnt?: NumberRange;
    /**
     * 
     * @type {NumberRange}
     * @memberof SearchSellerViewersRaneFilter
     */
    gmv?: NumberRange;
    /**
     * 
     * @type {NumberRange}
     * @memberof SearchSellerViewersRaneFilter
     */
    shareCnt?: NumberRange;
    /**
     * 
     * @type {NumberRange}
     * @memberof SearchSellerViewersRaneFilter
     */
    watchedShowCnt?: NumberRange;
    /**
     * 
     * @type {NumberRange}
     * @memberof SearchSellerViewersRaneFilter
     */
    referralCnt?: NumberRange;
}
/**
 * 
 * @export
 * @interface SearchSellerViewersResponse
 */
export interface SearchSellerViewersResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof SearchSellerViewersResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof SearchSellerViewersResponse
     */
    exact: boolean;
    /**
     * 
     * @type {SellerViewersPageRows}
     * @memberof SearchSellerViewersResponse
     */
    data: SellerViewersPageRows;
}
/**
 * 
 * @export
 * @interface SellerAddress
 */
export interface SellerAddress {
    /**
     * 
     * @type {string}
     * @memberof SellerAddress
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof SellerAddress
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof SellerAddress
     */
    primaryPhone: string;
    /**
     * 
     * @type {string}
     * @memberof SellerAddress
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SellerAddress
     */
    line1: string;
    /**
     * 
     * @type {string}
     * @memberof SellerAddress
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerAddress
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof SellerAddress
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof SellerAddress
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof SellerAddress
     */
    postalCode: string;
}
/**
 * 
 * @export
 * @interface SellerAddressRequest
 */
export interface SellerAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof SellerAddressRequest
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof SellerAddressRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SellerAddressRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof SellerAddressRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof SellerAddressRequest
     */
    line1: string;
    /**
     * 
     * @type {string}
     * @memberof SellerAddressRequest
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerAddressRequest
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof SellerAddressRequest
     */
    primaryPhone: string;
    /**
     * 
     * @type {string}
     * @memberof SellerAddressRequest
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof SellerAddressRequest
     */
    country: string;
}
/**
 * 
 * @export
 * @interface SellerLiveStreamsStatsItem
 */
export interface SellerLiveStreamsStatsItem {
    /**
     * 
     * @type {string}
     * @memberof SellerLiveStreamsStatsItem
     */
    sellerId: string;
    /**
     * 
     * @type {string}
     * @memberof SellerLiveStreamsStatsItem
     */
    liveStreamId: string;
    /**
     * 
     * @type {LiveStreamItem}
     * @memberof SellerLiveStreamsStatsItem
     */
    liveStream: LiveStreamItem;
    /**
     * 
     * @type {number}
     * @memberof SellerLiveStreamsStatsItem
     */
    orders: number;
    /**
     * 
     * @type {number}
     * @memberof SellerLiveStreamsStatsItem
     */
    viewers: number;
    /**
     * 
     * @type {number}
     * @memberof SellerLiveStreamsStatsItem
     */
    newViewers: number;
    /**
     * 
     * @type {number}
     * @memberof SellerLiveStreamsStatsItem
     */
    customers: number;
    /**
     * 
     * @type {number}
     * @memberof SellerLiveStreamsStatsItem
     */
    newCustomers: number;
    /**
     * 
     * @type {number}
     * @memberof SellerLiveStreamsStatsItem
     */
    newFollowers: number;
    /**
     * 
     * @type {number}
     * @memberof SellerLiveStreamsStatsItem
     */
    gmv: number;
    /**
     * 
     * @type {string}
     * @memberof SellerLiveStreamsStatsItem
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof SellerLiveStreamsStatsItem
     */
    disconnectedAt: string;
}
/**
 * 
 * @export
 * @interface SellerLiveStreamsStatsPageRows
 */
export interface SellerLiveStreamsStatsPageRows {
    /**
     * 
     * @type {Array<SellerLiveStreamsStatsItem>}
     * @memberof SellerLiveStreamsStatsPageRows
     */
    rows: Array<SellerLiveStreamsStatsItem>;
}
/**
 * 
 * @export
 * @interface SellerTrackingInfoItem
 */
export interface SellerTrackingInfoItem {
    /**
     * 
     * @type {string}
     * @memberof SellerTrackingInfoItem
     */
    trackingInfo: string;
    /**
     * 
     * @type {string}
     * @memberof SellerTrackingInfoItem
     */
    trackingURL?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerTrackingInfoItem
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @interface SellerUpdateAddressDto
 */
export interface SellerUpdateAddressDto {
    /**
     * 
     * @type {string}
     * @memberof SellerUpdateAddressDto
     */
    line1: string;
    /**
     * 
     * @type {string}
     * @memberof SellerUpdateAddressDto
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerUpdateAddressDto
     */
    city: string;
}
/**
 * 
 * @export
 * @interface SellerViewersItem
 */
export interface SellerViewersItem {
    /**
     * 
     * @type {string}
     * @memberof SellerViewersItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SellerViewersItem
     */
    sellerId: string;
    /**
     * 
     * @type {string}
     * @memberof SellerViewersItem
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof SellerViewersItem
     */
    userAvatar: string;
    /**
     * duration hours
     * @type {number}
     * @memberof SellerViewersItem
     */
    watchedSeconds: number;
    /**
     * 
     * @type {string}
     * @memberof SellerViewersItem
     */
    watchedFormatted: string;
    /**
     * likes
     * @type {number}
     * @memberof SellerViewersItem
     */
    likeCount: number;
    /**
     * total comments number customer typed in the chat
     * @type {number}
     * @memberof SellerViewersItem
     */
    commentCount: number;
    /**
     * 
     * @type {number}
     * @memberof SellerViewersItem
     */
    shareCount: number;
    /**
     * the number of items that user purchased
     * @type {number}
     * @memberof SellerViewersItem
     */
    unitsSold: number;
    /**
     * gmv
     * @type {number}
     * @memberof SellerViewersItem
     */
    grossSales: number;
    /**
     * 
     * @type {number}
     * @memberof SellerViewersItem
     */
    referralCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof SellerViewersItem
     */
    isFollower: boolean;
    /**
     * 
     * @type {string}
     * @memberof SellerViewersItem
     */
    firstPurchaseAt: string;
    /**
     * 
     * @type {string}
     * @memberof SellerViewersItem
     */
    lastPurchaseAt: string;
    /**
     * 
     * @type {string}
     * @memberof SellerViewersItem
     */
    firstWatchedAt: string;
    /**
     * 
     * @type {string}
     * @memberof SellerViewersItem
     */
    lastWatchedAt: string;
    /**
     * 
     * @type {number}
     * @memberof SellerViewersItem
     */
    showsViewed: number;
    /**
     * 
     * @type {string}
     * @memberof SellerViewersItem
     */
    lastMainOrderId: string;
    /**
     * 
     * @type {string}
     * @memberof SellerViewersItem
     */
    referralPasscode?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerViewersItem
     */
    referralAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SellerViewersItem
     */
    followedAt: string;
    /**
     * 
     * @type {string}
     * @memberof SellerViewersItem
     */
    sellerFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof SellerViewersItem
     */
    sellerLastName: string;
    /**
     * 
     * @type {string}
     * @memberof SellerViewersItem
     */
    sellerUserName: string;
    /**
     * 
     * @type {string}
     * @memberof SellerViewersItem
     */
    userFirstName: string;
    /**
     * 
     * @type {string}
     * @memberof SellerViewersItem
     */
    userLastName: string;
    /**
     * 
     * @type {string}
     * @memberof SellerViewersItem
     */
    customerUserName: string;
    /**
     * 
     * @type {ProfileBadges}
     * @memberof SellerViewersItem
     */
    userBadges?: ProfileBadges;
    /**
     * 
     * @type {boolean}
     * @memberof SellerViewersItem
     */
    isReferral: boolean;
}
/**
 * 
 * @export
 * @interface SellerViewersPageRows
 */
export interface SellerViewersPageRows {
    /**
     * 
     * @type {Array<SellerViewersItem>}
     * @memberof SellerViewersPageRows
     */
    rows: Array<SellerViewersItem>;
}
/**
 * 
 * @export
 * @interface SendAdminFeedbackRequest
 */
export interface SendAdminFeedbackRequest {
    /**
     * 
     * @type {string}
     * @memberof SendAdminFeedbackRequest
     */
    orderId?: string;
    /**
     * showId is from seller dashboard
     * @type {string}
     * @memberof SendAdminFeedbackRequest
     */
    showId?: string;
    /**
     * 
     * @type {string}
     * @memberof SendAdminFeedbackRequest
     */
    message: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendAdminFeedbackRequest
     */
    imageUrls?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendAdminFeedbackRequest
     */
    videoUrls?: Array<string>;
    /**
     * liveStreamId is from live show
     * @type {string}
     * @memberof SendAdminFeedbackRequest
     */
    liveStreamId?: string;
}
/**
 * 
 * @export
 * @interface SendCommentRequest
 */
export interface SendCommentRequest {
    /**
     * 
     * @type {SendPlainCommentRequest | SendShareCommentRequest}
     * @memberof SendCommentRequest
     */
    comment: SendPlainCommentRequest | SendShareCommentRequest | null;
}
/**
 * 
 * @export
 * @interface SendItemItem
 */
export interface SendItemItem {
    /**
     * 
     * @type {ProfileItem}
     * @memberof SendItemItem
     */
    fromUser: ProfileItem;
    /**
     * 
     * @type {string}
     * @memberof SendItemItem
     */
    fromUserId: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof SendItemItem
     */
    toUser: ProfileItem;
    /**
     * 
     * @type {string}
     * @memberof SendItemItem
     */
    toUserId: string;
    /**
     * 
     * @type {string}
     * @memberof SendItemItem
     */
    listingId: string;
    /**
     * 
     * @type {StoreListingItem}
     * @memberof SendItemItem
     */
    listing: StoreListingItem;
    /**
     * 
     * @type {string}
     * @memberof SendItemItem
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof SendItemItem
     */
    variantId?: string;
    /**
     * 
     * @type {number}
     * @memberof SendItemItem
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof SendItemItem
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SendItemItem
     */
    status: SendItemItemStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SendItemItem
     */
    needShippingFee: boolean;
    /**
     * 
     * @type {number}
     * @memberof SendItemItem
     */
    quantity: number;
    /**
     * 
     * @type {LiveStreamAuctionItem}
     * @memberof SendItemItem
     */
    liveStreamAuction: LiveStreamAuctionItem;
}

/**
* @export
* @enum {string}
*/
export enum SendItemItemStatusEnum {
    Processing = 'PROCESSING',
    Canceled = 'CANCELED',
    Confirmed = 'CONFIRMED',
    Rejected = ' REJECTED'
}
/**
 * 
 * @export
 * @interface SendPlainCommentRequest
 */
export interface SendPlainCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof SendPlainCommentRequest
     */
    type: SendPlainCommentRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SendPlainCommentRequest
     */
    content: string;
    /**
     * 
     * @type {Array<ChatMentionDto>}
     * @memberof SendPlainCommentRequest
     */
    mentions?: Array<ChatMentionDto>;
}

/**
* @export
* @enum {string}
*/
export enum SendPlainCommentRequestTypeEnum {
    Comment = 'comment'
}
/**
 * 
 * @export
 * @interface SendShareCommentRequest
 */
export interface SendShareCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof SendShareCommentRequest
     */
    type: SendShareCommentRequestTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum SendShareCommentRequestTypeEnum {
    Share = 'share'
}
/**
 * 
 * @export
 * @interface SendWrapUpEmailRequest
 */
export interface SendWrapUpEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof SendWrapUpEmailRequest
     */
    note?: string;
}
/**
 * 
 * @export
 * @interface SetPaymentMethodBillingAddressRequest
 */
export interface SetPaymentMethodBillingAddressRequest {
    /**
     * 
     * @type {BillingAddressDto}
     * @memberof SetPaymentMethodBillingAddressRequest
     */
    billingAddress: BillingAddressDto;
}
/**
 * 
 * @export
 * @interface SetPayoutMethodDto
 */
export interface SetPayoutMethodDto {
    /**
     * 
     * @type {PayoutBankDto}
     * @memberof SetPayoutMethodDto
     */
    payoutMethodBank?: PayoutBankDto;
    /**
     * 
     * @type {PayoutVenmoDto}
     * @memberof SetPayoutMethodDto
     */
    payoutMethodVenmo?: PayoutVenmoDto;
    /**
     * 
     * @type {PayoutPaypalDto}
     * @memberof SetPayoutMethodDto
     */
    payoutMethodPaypal?: PayoutPaypalDto;
}
/**
 * 
 * @export
 * @interface SetPickupTimeWindowRequest
 */
export interface SetPickupTimeWindowRequest {
    /**
     * 
     * @type {number}
     * @memberof SetPickupTimeWindowRequest
     */
    minutes: number;
}
/**
 * 
 * @export
 * @interface SetShowCustomizationRequest
 */
export interface SetShowCustomizationRequest {
    /**
     * 
     * @type {string}
     * @memberof SetShowCustomizationRequest
     */
    primaryLightColor?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SetShowCustomizationRequest
     */
    primaryDarkColor?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SetShowCustomizationRequest
     */
    lightingBoltImages?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof SetShowCustomizationRequest
     */
    lightingBoltButtonImage?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SetShowCustomizationRequest
     */
    disableLightingBoltForAll?: boolean;
}
/**
 * 
 * @export
 * @interface SetUrlCodeDto
 */
export interface SetUrlCodeDto {
    /**
     * 
     * @type {string}
     * @memberof SetUrlCodeDto
     */
    urlCode: string;
}
/**
 * 
 * @export
 * @interface SetupLocationIdRequestDto
 */
export interface SetupLocationIdRequestDto {
    /**
     * 
     * @type {string}
     * @memberof SetupLocationIdRequestDto
     */
    locationId: string;
}
/**
 * 
 * @export
 * @interface SetupProductRequestDto
 */
export interface SetupProductRequestDto {
    /**
     * 
     * @type {number}
     * @memberof SetupProductRequestDto
     */
    availableDelta: number;
}
/**
 * 
 * @export
 * @interface ShareTargetItem
 */
export interface ShareTargetItem {
    /**
     * 
     * @type {string}
     * @memberof ShareTargetItem
     */
    type: ShareTargetItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ShareTargetItem
     */
    profileId?: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof ShareTargetItem
     */
    profile?: ProfileItem;
    /**
     * 
     * @type {string}
     * @memberof ShareTargetItem
     */
    liveStreamId?: string;
    /**
     * 
     * @type {LiveStreamItem}
     * @memberof ShareTargetItem
     */
    liveStream?: LiveStreamItem;
    /**
     * 
     * @type {string}
     * @memberof ShareTargetItem
     */
    upcomingShowId?: string;
    /**
     * 
     * @type {UpcomingShowItem}
     * @memberof ShareTargetItem
     */
    upcomingShow?: UpcomingShowItem;
    /**
     * 
     * @type {string}
     * @memberof ShareTargetItem
     */
    listingId?: string;
    /**
     * 
     * @type {StoreListingItem}
     * @memberof ShareTargetItem
     */
    listing?: StoreListingItem;
    /**
     * 
     * @type {string}
     * @memberof ShareTargetItem
     */
    storeId?: string;
    /**
     * 
     * @type {StoreFrontItem}
     * @memberof ShareTargetItem
     */
    store?: StoreFrontItem;
    /**
     * 
     * @type {string}
     * @memberof ShareTargetItem
     */
    showEventId?: string;
    /**
     * 
     * @type {ShowEventItem}
     * @memberof ShareTargetItem
     */
    showEvent?: ShowEventItem;
    /**
     * 
     * @type {string}
     * @memberof ShareTargetItem
     */
    ticketId?: string;
    /**
     * 
     * @type {ShowTicketItem}
     * @memberof ShareTargetItem
     */
    ticket?: ShowTicketItem;
    /**
     * 
     * @type {string}
     * @memberof ShareTargetItem
     */
    promotionId?: string;
    /**
     * 
     * @type {PromotionPageItem}
     * @memberof ShareTargetItem
     */
    promotion?: PromotionPageItem;
}

/**
* @export
* @enum {string}
*/
export enum ShareTargetItemTypeEnum {
    Profile = 'PROFILE',
    LiveStream = 'LIVE_STREAM',
    UpcomingShow = 'UPCOMING_SHOW',
    ListingLiveStream = 'LISTING_LIVE_STREAM',
    ListingEvergreen = 'LISTING_EVERGREEN',
    ListingUpcomingShow = 'LISTING_UPCOMING_SHOW',
    ListingPromotion = 'LISTING_PROMOTION',
    Event = 'EVENT',
    EventDiscovery = 'EVENT_DISCOVERY',
    EventTicket = 'EVENT_TICKET',
    HomePage = 'HOME_PAGE',
    Promotion = 'PROMOTION'
}
/**
 * 
 * @export
 * @interface ShipmentItem
 */
export interface ShipmentItem {
    /**
     * 
     * @type {string}
     * @memberof ShipmentItem
     */
    buyerId: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentItem
     */
    sellerId: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentItem
     */
    orderId: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentItem
     */
    shippoTransactionId: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentItem
     */
    labelUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentItem
     */
    trackingNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentItem
     */
    trackingStatus: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentItem
     */
    trackingUrlProvider: string;
    /**
     * 
     * @type {number}
     * @memberof ShipmentItem
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof ShipmentItem
     */
    transaction: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentItem
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentItem
     */
    updateAt: string;
    /**
     * 
     * @type {string}
     * @memberof ShipmentItem
     */
    carrier?: ShipmentItemCarrierEnum;
}

/**
* @export
* @enum {string}
*/
export enum ShipmentItemCarrierEnum {
    Ups = 'UPS',
    Usps = 'USPS'
}
/**
 * 
 * @export
 * @interface ShippingFeeResponse
 */
export interface ShippingFeeResponse {
    /**
     * 
     * @type {number}
     * @memberof ShippingFeeResponse
     */
    shippingFee: number;
}
/**
 * 
 * @export
 * @interface ShippingLabelStatus
 */
export interface ShippingLabelStatus {
    /**
     * 
     * @type {boolean}
     * @memberof ShippingLabelStatus
     */
    disabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShippingLabelStatus
     */
    noLabel: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShippingLabelStatus
     */
    generating: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShippingLabelStatus
     */
    generated: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShippingLabelStatus
     */
    preGenerated: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShippingLabelStatus
     */
    failed: boolean;
}
/**
 * 
 * @export
 * @interface ShoifyStoresDto
 */
export interface ShoifyStoresDto {
    /**
     * 
     * @type {Array<LocationDto>}
     * @memberof ShoifyStoresDto
     */
    stores: Array<LocationDto>;
}
/**
 * 
 * @export
 * @interface ShoifyStoresWebhooksConfigStatusResponse
 */
export interface ShoifyStoresWebhooksConfigStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ShoifyStoresWebhooksConfigStatusResponse
     */
    configured: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShoifyStoresWebhooksConfigStatusResponse
     */
    fulfillments: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShoifyStoresWebhooksConfigStatusResponse
     */
    productsUpdate: boolean;
}
/**
 * 
 * @export
 * @interface ShowEventFeaturedShowItem
 */
export interface ShowEventFeaturedShowItem {
    /**
     * 
     * @type {string}
     * @memberof ShowEventFeaturedShowItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ShowEventFeaturedShowItem
     */
    sortedAt: string;
    /**
     * 
     * @type {string}
     * @memberof ShowEventFeaturedShowItem
     */
    type: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShowEventFeaturedShowItem
     */
    active: boolean;
    /**
     * 
     * @type {BaseUpcomingShowItem}
     * @memberof ShowEventFeaturedShowItem
     */
    upcomingShow?: BaseUpcomingShowItem;
    /**
     * 
     * @type {LiveStreamItem}
     * @memberof ShowEventFeaturedShowItem
     */
    liveStream?: LiveStreamItem;
    /**
     * 
     * @type {string}
     * @memberof ShowEventFeaturedShowItem
     */
    featuredEvent?: ShowEventFeaturedShowItemFeaturedEventEnum;
    /**
     * 
     * @type {Relations}
     * @memberof ShowEventFeaturedShowItem
     */
    relations: Relations;
}

/**
* @export
* @enum {string}
*/
export enum ShowEventFeaturedShowItemFeaturedEventEnum {
    AutoFeaturedStoreShow = 'autoFeaturedStoreShow'
}
/**
 * 
 * @export
 * @interface ShowEventGenerateTokenRequest
 */
export interface ShowEventGenerateTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof ShowEventGenerateTokenRequest
     */
    deviceId: string;
}
/**
 * 
 * @export
 * @interface ShowEventItem
 */
export interface ShowEventItem {
    /**
     * 
     * @type {string}
     * @memberof ShowEventItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ShowEventItem
     */
    urlCode: string;
    /**
     * 
     * @type {string}
     * @memberof ShowEventItem
     */
    previewVideoURL?: string;
    /**
     * 
     * @type {string}
     * @memberof ShowEventItem
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ShowEventItem
     */
    backgroundURL?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShowEventItem
     */
    active: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShowEventItem
     */
    sellerUserGroupIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShowEventItem
     */
    userGroupIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ShowEventItem
     */
    createdAt: string;
    /**
     * 
     * @type {number}
     * @memberof ShowEventItem
     */
    salesTaxRate?: number;
    /**
     * 
     * @type {number}
     * @memberof ShowEventItem
     */
    maximumPurchaseQuantity?: number;
    /**
     * 
     * @type {string}
     * @memberof ShowEventItem
     */
    startedAfter?: string;
    /**
     * 
     * @type {string}
     * @memberof ShowEventItem
     */
    endedBefore?: string;
    /**
     * 
     * @type {number}
     * @memberof ShowEventItem
     */
    shippingFee?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ShowEventItem
     */
    isClosed?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShowEventItem
     */
    visibilityGroupIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ShowEventItem
     */
    sortedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ShowEventItem
     */
    commissionFeeRate?: number;
    /**
     * 
     * @type {string}
     * @memberof ShowEventItem
     */
    featuredStoreStackName?: string;
    /**
     * 
     * @type {string}
     * @memberof ShowEventItem
     */
    sellerApplicationDisclosure?: string;
    /**
     * 
     * @type {string}
     * @memberof ShowEventItem
     */
    sellerApplicationDisclosureUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShowEventItem
     */
    unBundleOnDiscovery?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShowEventItem
     */
    visibilityOnDiscoveryBanner?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShowEventItem
     */
    visibleOnCategoryBanner?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShowEventItem
     */
    id: string;
    /**
     * 
     * @type {Metadata}
     * @memberof ShowEventItem
     */
    metadata: Metadata;
}
/**
 * 
 * @export
 * @interface ShowEventPageRows
 */
export interface ShowEventPageRows {
    /**
     * 
     * @type {Array<ShowEventItem>}
     * @memberof ShowEventPageRows
     */
    rows: Array<ShowEventItem>;
}
/**
 * 
 * @export
 * @interface ShowEventTokenResponse
 */
export interface ShowEventTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof ShowEventTokenResponse
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof ShowEventTokenResponse
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof ShowEventTokenResponse
     */
    channel: string;
}
/**
 * 
 * @export
 * @interface ShowEventTokensResponse
 */
export interface ShowEventTokensResponse {
    /**
     * 
     * @type {ShowEventTokenResponse}
     * @memberof ShowEventTokensResponse
     */
    rtmComments: ShowEventTokenResponse;
    /**
     * 
     * @type {ShowEventTokenResponse}
     * @memberof ShowEventTokensResponse
     */
    rtmCommentUpvotes: ShowEventTokenResponse;
    /**
     * 
     * @type {ShowEventTokenResponse}
     * @memberof ShowEventTokensResponse
     */
    rtmLightningBolts: ShowEventTokenResponse;
}
/**
 * 
 * @export
 * @interface ShowEventWithoutMetaDataItem
 */
export interface ShowEventWithoutMetaDataItem {
    /**
     * 
     * @type {string}
     * @memberof ShowEventWithoutMetaDataItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ShowEventWithoutMetaDataItem
     */
    urlCode: string;
    /**
     * 
     * @type {string}
     * @memberof ShowEventWithoutMetaDataItem
     */
    previewVideoURL?: string;
    /**
     * 
     * @type {string}
     * @memberof ShowEventWithoutMetaDataItem
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ShowEventWithoutMetaDataItem
     */
    backgroundURL?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShowEventWithoutMetaDataItem
     */
    active: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShowEventWithoutMetaDataItem
     */
    sellerUserGroupIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShowEventWithoutMetaDataItem
     */
    userGroupIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ShowEventWithoutMetaDataItem
     */
    createdAt: string;
    /**
     * 
     * @type {number}
     * @memberof ShowEventWithoutMetaDataItem
     */
    salesTaxRate?: number;
    /**
     * 
     * @type {number}
     * @memberof ShowEventWithoutMetaDataItem
     */
    maximumPurchaseQuantity?: number;
    /**
     * 
     * @type {string}
     * @memberof ShowEventWithoutMetaDataItem
     */
    startedAfter?: string;
    /**
     * 
     * @type {string}
     * @memberof ShowEventWithoutMetaDataItem
     */
    endedBefore?: string;
    /**
     * 
     * @type {number}
     * @memberof ShowEventWithoutMetaDataItem
     */
    shippingFee?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ShowEventWithoutMetaDataItem
     */
    isClosed?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShowEventWithoutMetaDataItem
     */
    visibilityGroupIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ShowEventWithoutMetaDataItem
     */
    sortedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ShowEventWithoutMetaDataItem
     */
    commissionFeeRate?: number;
    /**
     * 
     * @type {string}
     * @memberof ShowEventWithoutMetaDataItem
     */
    featuredStoreStackName?: string;
    /**
     * 
     * @type {string}
     * @memberof ShowEventWithoutMetaDataItem
     */
    sellerApplicationDisclosure?: string;
    /**
     * 
     * @type {string}
     * @memberof ShowEventWithoutMetaDataItem
     */
    sellerApplicationDisclosureUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShowEventWithoutMetaDataItem
     */
    unBundleOnDiscovery?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShowEventWithoutMetaDataItem
     */
    visibilityOnDiscoveryBanner?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShowEventWithoutMetaDataItem
     */
    visibleOnCategoryBanner?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShowEventWithoutMetaDataItem
     */
    id: string;
}
/**
 * 
 * @export
 * @interface ShowEventsFeaturedShowPageRows
 */
export interface ShowEventsFeaturedShowPageRows {
    /**
     * 
     * @type {Array<ShowEventFeaturedShowItem>}
     * @memberof ShowEventsFeaturedShowPageRows
     */
    rows: Array<ShowEventFeaturedShowItem>;
}
/**
 * 
 * @export
 * @interface ShowEventsFeaturedShowSearchResponse
 */
export interface ShowEventsFeaturedShowSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof ShowEventsFeaturedShowSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof ShowEventsFeaturedShowSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {ShowEventsFeaturedShowPageRows}
     * @memberof ShowEventsFeaturedShowSearchResponse
     */
    data: ShowEventsFeaturedShowPageRows;
}
/**
 * 
 * @export
 * @interface ShowEventsSearchResponse
 */
export interface ShowEventsSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof ShowEventsSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof ShowEventsSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {ShowEventPageRows}
     * @memberof ShowEventsSearchResponse
     */
    data: ShowEventPageRows;
}
/**
 * 
 * @export
 * @interface ShowTicketItem
 */
export interface ShowTicketItem {
    /**
     * 
     * @type {string}
     * @memberof ShowTicketItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ShowTicketItem
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ShowTicketItem
     */
    defaultImage: string;
    /**
     * 
     * @type {number}
     * @memberof ShowTicketItem
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof ShowTicketItem
     */
    discountPrice?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ShowTicketItem
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShowTicketItem
     */
    requireShipping: boolean;
    /**
     * 
     * @type {number}
     * @memberof ShowTicketItem
     */
    shippingFee?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShowTicketItem
     */
    userGroupIds: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ShowTicketItem
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof ShowTicketItem
     */
    soldQuantity?: number;
    /**
     * 
     * @type {number}
     * @memberof ShowTicketItem
     */
    maximumPurchaseQuantity?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ShowTicketItem
     */
    upgradeTicket?: boolean | null;
    /**
     * 
     * @type {object}
     * @memberof ShowTicketItem
     */
    fromTicketId?: object;
    /**
     * 
     * @type {object}
     * @memberof ShowTicketItem
     */
    toTicketId?: object;
    /**
     * 
     * @type {string}
     * @memberof ShowTicketItem
     */
    emailConfirmationDisclosure?: string;
    /**
     * 
     * @type {string}
     * @memberof ShowTicketItem
     */
    emailConfirmationDisclosureUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ShowTicketItem
     */
    emailConfirmationHeaderBackgroundUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ShowTicketItem
     */
    sortedAt: string;
}
/**
 * 
 * @export
 * @interface ShowTicketItemWithId
 */
export interface ShowTicketItemWithId {
    /**
     * 
     * @type {string}
     * @memberof ShowTicketItemWithId
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ShowTicketItemWithId
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ShowTicketItemWithId
     */
    defaultImage: string;
    /**
     * 
     * @type {number}
     * @memberof ShowTicketItemWithId
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof ShowTicketItemWithId
     */
    discountPrice?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ShowTicketItemWithId
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShowTicketItemWithId
     */
    requireShipping: boolean;
    /**
     * 
     * @type {number}
     * @memberof ShowTicketItemWithId
     */
    shippingFee?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShowTicketItemWithId
     */
    userGroupIds: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ShowTicketItemWithId
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof ShowTicketItemWithId
     */
    soldQuantity?: number;
    /**
     * 
     * @type {number}
     * @memberof ShowTicketItemWithId
     */
    maximumPurchaseQuantity?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ShowTicketItemWithId
     */
    upgradeTicket?: boolean | null;
    /**
     * 
     * @type {object}
     * @memberof ShowTicketItemWithId
     */
    fromTicketId?: object;
    /**
     * 
     * @type {object}
     * @memberof ShowTicketItemWithId
     */
    toTicketId?: object;
    /**
     * 
     * @type {string}
     * @memberof ShowTicketItemWithId
     */
    emailConfirmationDisclosure?: string;
    /**
     * 
     * @type {string}
     * @memberof ShowTicketItemWithId
     */
    emailConfirmationDisclosureUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ShowTicketItemWithId
     */
    emailConfirmationHeaderBackgroundUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ShowTicketItemWithId
     */
    sortedAt: string;
    /**
     * 
     * @type {string}
     * @memberof ShowTicketItemWithId
     */
    id: string;
}
/**
 * 
 * @export
 * @interface ShowTicketPageRows
 */
export interface ShowTicketPageRows {
    /**
     * 
     * @type {Array<ShowTicketItemWithId>}
     * @memberof ShowTicketPageRows
     */
    rows: Array<ShowTicketItemWithId>;
}
/**
 * 
 * @export
 * @interface ShowTicketsSearchResponse
 */
export interface ShowTicketsSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof ShowTicketsSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof ShowTicketsSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {ShowTicketPageRows}
     * @memberof ShowTicketsSearchResponse
     */
    data: ShowTicketPageRows;
}
/**
 * 
 * @export
 * @interface SimpleProfileItem
 */
export interface SimpleProfileItem {
    /**
     * 
     * @type {string}
     * @memberof SimpleProfileItem
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleProfileItem
     */
    lastName: string;
}
/**
 * 
 * @export
 * @interface SortFeaturedShow
 */
export interface SortFeaturedShow {
    /**
     * 
     * @type {string}
     * @memberof SortFeaturedShow
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SortFeaturedShow
     */
    sortedAt: string;
}
/**
 * 
 * @export
 * @interface SortShowEventFeaturedShow
 */
export interface SortShowEventFeaturedShow {
    /**
     * 
     * @type {string}
     * @memberof SortShowEventFeaturedShow
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SortShowEventFeaturedShow
     */
    sortedAt: string;
}
/**
 * 
 * @export
 * @interface SortShowEventItem
 */
export interface SortShowEventItem {
    /**
     * 
     * @type {string}
     * @memberof SortShowEventItem
     */
    showEventId: string;
    /**
     * 
     * @type {string}
     * @memberof SortShowEventItem
     */
    sortedAt: string;
}
/**
 * 
 * @export
 * @interface SortShowTickets
 */
export interface SortShowTickets {
    /**
     * 
     * @type {string}
     * @memberof SortShowTickets
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SortShowTickets
     */
    sortedAt: string;
}
/**
 * 
 * @export
 * @interface SortedPublicListingsDto
 */
export interface SortedPublicListingsDto {
    /**
     * 
     * @type {string}
     * @memberof SortedPublicListingsDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SortedPublicListingsDto
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @interface StartAuctionRequest
 */
export interface StartAuctionRequest {
    /**
     * 
     * @type {string}
     * @memberof StartAuctionRequest
     */
    listingId: string;
    /**
     * 
     * @type {number}
     * @memberof StartAuctionRequest
     */
    startingPrice: number;
    /**
     * 
     * @type {AuctionTimer}
     * @memberof StartAuctionRequest
     */
    timer?: AuctionTimer;
}
/**
 * 
 * @export
 * @interface StartAuctionResponse
 */
export interface StartAuctionResponse {
    /**
     * 
     * @type {string}
     * @memberof StartAuctionResponse
     */
    auctionId: string;
}
/**
 * 
 * @export
 * @interface Store
 */
export interface Store {
    /**
     * 
     * @type {number}
     * @memberof Store
     */
    adjustedPopshopShippingFee?: number;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    announcement?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Store
     */
    badges?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    bio?: string;
    /**
     * 
     * @type {object}
     * @memberof Store
     */
    createdAt: object;
    /**
     * 
     * @type {object}
     * @memberof Store
     */
    evergreenVideo?: object;
    /**
     * 
     * @type {object}
     * @memberof Store
     */
    liveStream?: object;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    liveStreamId?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    passcode: string;
    /**
     * 
     * @type {ProfileResponse}
     * @memberof Store
     */
    profile: ProfileResponse;
    /**
     * 
     * @type {object}
     * @memberof Store
     */
    salesTaxRate?: object;
    /**
     * 
     * @type {number}
     * @memberof Store
     */
    secondOfShippingLabelGeneration: number;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    sellerPasscode?: string;
    /**
     * 
     * @type {number}
     * @memberof Store
     */
    shippingFee?: number;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    shortDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    storeId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Store
     */
    tags?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof Store
     */
    upcomingShow?: object;
    /**
     * 
     * @type {string}
     * @memberof Store
     */
    upcomingShowId?: string;
}
/**
 * 
 * @export
 * @interface StoreDTO
 */
export interface StoreDTO {
    /**
     * 
     * @type {string}
     * @memberof StoreDTO
     */
    userId: string;
}
/**
 * 
 * @export
 * @interface StoreFrontItem
 */
export interface StoreFrontItem {
    /**
     * 
     * @type {string}
     * @memberof StoreFrontItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof StoreFrontItem
     */
    sellerPasscode?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreFrontItem
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof StoreFrontItem
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof StoreFrontItem
     */
    passcode: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof StoreFrontItem
     */
    profile: ProfileItem;
    /**
     * 
     * @type {number}
     * @memberof StoreFrontItem
     */
    shippingFee?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreFrontItem
     */
    adjustedPopshopShippingFee?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreFrontItem
     */
    bio?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreFrontItem
     */
    announcement?: string;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof StoreFrontItem
     */
    salesTaxRate?: { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof StoreFrontItem
     */
    secondOfShippingLabelGeneration: number;
    /**
     * 
     * @type {BaseUpcomingShowItem}
     * @memberof StoreFrontItem
     */
    upcomingShow?: BaseUpcomingShowItem;
    /**
     * 
     * @type {string}
     * @memberof StoreFrontItem
     */
    upcomingShowId?: string;
    /**
     * 
     * @type {object}
     * @memberof StoreFrontItem
     */
    liveStream?: object;
    /**
     * 
     * @type {string}
     * @memberof StoreFrontItem
     */
    liveStreamId?: string;
    /**
     * 
     * @type {EvergreenVideoItem}
     * @memberof StoreFrontItem
     */
    evergreenVideo?: EvergreenVideoItem;
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreFrontItem
     */
    badges?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof StoreFrontItem
     */
    shortDescription?: string;
}
/**
 * 
 * @export
 * @interface StoreFrontItemWithId
 */
export interface StoreFrontItemWithId {
    /**
     * 
     * @type {string}
     * @memberof StoreFrontItemWithId
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof StoreFrontItemWithId
     */
    sellerPasscode?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreFrontItemWithId
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof StoreFrontItemWithId
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof StoreFrontItemWithId
     */
    passcode: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof StoreFrontItemWithId
     */
    profile: ProfileItem;
    /**
     * 
     * @type {number}
     * @memberof StoreFrontItemWithId
     */
    shippingFee?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreFrontItemWithId
     */
    adjustedPopshopShippingFee?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreFrontItemWithId
     */
    bio?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreFrontItemWithId
     */
    announcement?: string;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof StoreFrontItemWithId
     */
    salesTaxRate?: { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof StoreFrontItemWithId
     */
    secondOfShippingLabelGeneration: number;
    /**
     * 
     * @type {BaseUpcomingShowItem}
     * @memberof StoreFrontItemWithId
     */
    upcomingShow?: BaseUpcomingShowItem;
    /**
     * 
     * @type {string}
     * @memberof StoreFrontItemWithId
     */
    upcomingShowId?: string;
    /**
     * 
     * @type {object}
     * @memberof StoreFrontItemWithId
     */
    liveStream?: object;
    /**
     * 
     * @type {string}
     * @memberof StoreFrontItemWithId
     */
    liveStreamId?: string;
    /**
     * 
     * @type {EvergreenVideoItem}
     * @memberof StoreFrontItemWithId
     */
    evergreenVideo?: EvergreenVideoItem;
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreFrontItemWithId
     */
    badges?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof StoreFrontItemWithId
     */
    shortDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreFrontItemWithId
     */
    id: string;
}
/**
 * 
 * @export
 * @interface StoreIncentivePageRows
 */
export interface StoreIncentivePageRows {
    /**
     * 
     * @type {Array<StoreIncentivePayoutItem>}
     * @memberof StoreIncentivePageRows
     */
    rows: Array<StoreIncentivePayoutItem>;
}
/**
 * 
 * @export
 * @interface StoreIncentivePayoutItem
 */
export interface StoreIncentivePayoutItem {
    /**
     * 
     * @type {Relations}
     * @memberof StoreIncentivePayoutItem
     */
    relations: Relations;
    /**
     * 
     * @type {string}
     * @memberof StoreIncentivePayoutItem
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof StoreIncentivePayoutItem
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof StoreIncentivePayoutItem
     */
    status: StoreIncentivePayoutItemStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof StoreIncentivePayoutItem
     */
    payoutDate?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreIncentivePayoutItem
     */
    sellerId: string;
    /**
     * 
     * @type {StoreFrontItem}
     * @memberof StoreIncentivePayoutItem
     */
    store: StoreFrontItem;
    /**
     * 
     * @type {OperatorItem}
     * @memberof StoreIncentivePayoutItem
     */
    createdBy: OperatorItem;
    /**
     * 
     * @type {OperatorItem}
     * @memberof StoreIncentivePayoutItem
     */
    lastUpdatedBy: OperatorItem;
    /**
     * 
     * @type {string}
     * @memberof StoreIncentivePayoutItem
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof StoreIncentivePayoutItem
     */
    updatedAt: string;
}

/**
* @export
* @enum {string}
*/
export enum StoreIncentivePayoutItemStatusEnum {
    Pending = 'Pending',
    Processed = 'Processed',
    Completed = 'Completed',
    Failed = 'Failed'
}
/**
 * 
 * @export
 * @interface StoreIncentivePayoutSearchResponse
 */
export interface StoreIncentivePayoutSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof StoreIncentivePayoutSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof StoreIncentivePayoutSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {StoreIncentivePageRows}
     * @memberof StoreIncentivePayoutSearchResponse
     */
    data: StoreIncentivePageRows;
}
/**
 * 
 * @export
 * @interface StoreItem
 */
export interface StoreItem {
    /**
     * 
     * @type {string}
     * @memberof StoreItem
     */
    name: string;
}
/**
 * 
 * @export
 * @interface StoreListingItem
 */
export interface StoreListingItem {
    /**
     * 
     * @type {string}
     * @memberof StoreListingItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StoreListingItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof StoreListingItem
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof StoreListingItem
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof StoreListingItem
     */
    discountPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof StoreListingItem
     */
    sku?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreListingItem
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreListingItem
     */
    defaultImage: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreListingItem
     */
    extraImages?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof StoreListingItem
     */
    quantity: number;
    /**
     * 
     * @type {Array<StoreListingVariant>}
     * @memberof StoreListingItem
     */
    variants?: Array<StoreListingVariant>;
    /**
     * 
     * @type {number}
     * @memberof StoreListingItem
     */
    maximumPurchaseQuantity?: number;
    /**
     * 
     * @type {number}
     * @memberof StoreListingItem
     */
    shippingFee?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof StoreListingItem
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof StoreListingItem
     */
    status?: StoreListingItemStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof StoreListingItem
     */
    maximumPurchaseQuantityPerLiveStream?: number;
    /**
     * 
     * @type {StoreListingOriginalInfo}
     * @memberof StoreListingItem
     */
    originalInfo?: StoreListingOriginalInfo;
}

/**
* @export
* @enum {string}
*/
export enum StoreListingItemStatusEnum {
    Active = 'active',
    Archived = 'archived'
}
/**
 * 
 * @export
 * @interface StoreListingOriginalInfo
 */
export interface StoreListingOriginalInfo {
    /**
     * 
     * @type {string}
     * @memberof StoreListingOriginalInfo
     */
    type: StoreListingOriginalInfoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof StoreListingOriginalInfo
     */
    shopifyStoreId?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreListingOriginalInfo
     */
    shopifyLocationId?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreListingOriginalInfo
     */
    shopifyProductId?: string;
}

/**
* @export
* @enum {string}
*/
export enum StoreListingOriginalInfoTypeEnum {
    Shopify = 'shopify'
}
/**
 * 
 * @export
 * @interface StoreListingPageRows
 */
export interface StoreListingPageRows {
    /**
     * 
     * @type {Array<StoreListingItem>}
     * @memberof StoreListingPageRows
     */
    rows: Array<StoreListingItem>;
}
/**
 * 
 * @export
 * @interface StoreListingSearchResponse
 */
export interface StoreListingSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof StoreListingSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof StoreListingSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {StoreListingPageRows}
     * @memberof StoreListingSearchResponse
     */
    data: StoreListingPageRows;
}
/**
 * 
 * @export
 * @interface StoreListingVariant
 */
export interface StoreListingVariant {
    /**
     * 
     * @type {string}
     * @memberof StoreListingVariant
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StoreListingVariant
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof StoreListingVariant
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof StoreListingVariant
     */
    sku?: string;
    /**
     * 
     * @type {number}
     * @memberof StoreListingVariant
     */
    price?: number;
    /**
     * 
     * @type {StoreListingVariantOriginalInfo}
     * @memberof StoreListingVariant
     */
    originalInfo?: StoreListingVariantOriginalInfo;
}
/**
 * 
 * @export
 * @interface StoreListingVariantOriginalInfo
 */
export interface StoreListingVariantOriginalInfo {
    /**
     * 
     * @type {string}
     * @memberof StoreListingVariantOriginalInfo
     */
    type: StoreListingVariantOriginalInfoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof StoreListingVariantOriginalInfo
     */
    shopifyVariantId?: string;
    /**
     * 
     * @type {string}
     * @memberof StoreListingVariantOriginalInfo
     */
    shopifyInventoryLevelId?: string;
}

/**
* @export
* @enum {string}
*/
export enum StoreListingVariantOriginalInfoTypeEnum {
    Shopify = 'shopify'
}
/**
 * 
 * @export
 * @interface StorePageRows
 */
export interface StorePageRows {
    /**
     * 
     * @type {Array<StoreFrontItemWithId>}
     * @memberof StorePageRows
     */
    rows: Array<StoreFrontItemWithId>;
}
/**
 * 
 * @export
 * @interface StorePublicListingMoveToTopRequest
 */
export interface StorePublicListingMoveToTopRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof StorePublicListingMoveToTopRequest
     */
    listingIds: Array<string>;
}
/**
 * 
 * @export
 * @interface StoreSource
 */
export interface StoreSource {
    /**
     * 
     * @type {string}
     * @memberof StoreSource
     */
    type: StoreSourceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof StoreSource
     */
    storeId: string;
    /**
     * 
     * @type {string}
     * @memberof StoreSource
     */
    liveStreamId?: string;
}

/**
* @export
* @enum {string}
*/
export enum StoreSourceTypeEnum {
    Store = 'STORE'
}
/**
 * 
 * @export
 * @interface StoresSearchResponse
 */
export interface StoresSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof StoresSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof StoresSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {StorePageRows}
     * @memberof StoresSearchResponse
     */
    data: StorePageRows;
}
/**
 * 
 * @export
 * @interface StringRange
 */
export interface StringRange {
    /**
     * 
     * @type {string}
     * @memberof StringRange
     */
    gt?: string;
    /**
     * 
     * @type {string}
     * @memberof StringRange
     */
    gte?: string;
    /**
     * 
     * @type {string}
     * @memberof StringRange
     */
    lt?: string;
    /**
     * 
     * @type {string}
     * @memberof StringRange
     */
    lte?: string;
    /**
     * 
     * @type {string}
     * @memberof StringRange
     */
    equal?: string;
}
/**
 * 
 * @export
 * @interface SwitchShowEventRequest
 */
export interface SwitchShowEventRequest {
    /**
     * 
     * @type {string}
     * @memberof SwitchShowEventRequest
     */
    showEventId?: string;
}
/**
 * 
 * @export
 * @interface SwitchUpcomingShowRequest
 */
export interface SwitchUpcomingShowRequest {
    /**
     * 
     * @type {string}
     * @memberof SwitchUpcomingShowRequest
     */
    upcomingShowId: string;
}
/**
 * 
 * @export
 * @interface TicketOrderAddressRequest
 */
export interface TicketOrderAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketOrderAddressRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof TicketOrderAddressRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof TicketOrderAddressRequest
     */
    primaryPhone: string;
    /**
     * 
     * @type {string}
     * @memberof TicketOrderAddressRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof TicketOrderAddressRequest
     */
    line1: string;
    /**
     * 
     * @type {string}
     * @memberof TicketOrderAddressRequest
     */
    line2?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketOrderAddressRequest
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof TicketOrderAddressRequest
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof TicketOrderAddressRequest
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof TicketOrderAddressRequest
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof TicketOrderAddressRequest
     */
    compareHash?: string;
}
/**
 * 
 * @export
 * @interface TokenResponse
 */
export interface TokenResponse {
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    channel: string;
}
/**
 * 
 * @export
 * @interface TokensResponse
 */
export interface TokensResponse {
    /**
     * 
     * @type {TokenResponse}
     * @memberof TokensResponse
     */
    rtc: TokenResponse;
    /**
     * 
     * @type {TokenResponse}
     * @memberof TokensResponse
     */
    rtmComments: TokenResponse;
    /**
     * 
     * @type {TokenResponse}
     * @memberof TokensResponse
     */
    rtmCommentUpvotes: TokenResponse;
    /**
     * 
     * @type {TokenResponse}
     * @memberof TokensResponse
     */
    rtmLightningBolts: TokenResponse;
}
/**
 * 
 * @export
 * @interface TopReviewItem
 */
export interface TopReviewItem {
    /**
     * 
     * @type {string}
     * @memberof TopReviewItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TopReviewItem
     */
    userIdFrom: string;
    /**
     * 
     * @type {BaseProfileItem}
     * @memberof TopReviewItem
     */
    userProfileFrom: BaseProfileItem;
    /**
     * 
     * @type {string}
     * @memberof TopReviewItem
     */
    userIdTo: string;
    /**
     * 
     * @type {BaseProfileItem}
     * @memberof TopReviewItem
     */
    userProfileTo: BaseProfileItem;
    /**
     * 
     * @type {string}
     * @memberof TopReviewItem
     */
    videoURL: string;
    /**
     * 
     * @type {string}
     * @memberof TopReviewItem
     */
    originalVideoURL?: string;
    /**
     * 
     * @type {string}
     * @memberof TopReviewItem
     */
    gifURL: string;
    /**
     * 
     * @type {string}
     * @memberof TopReviewItem
     */
    thumbnailURL?: string;
    /**
     * 
     * @type {string}
     * @memberof TopReviewItem
     */
    emoji: TopReviewItemEmojiEnum;
    /**
     * 
     * @type {number}
     * @memberof TopReviewItem
     */
    upvoteCount: number;
}

/**
* @export
* @enum {string}
*/
export enum TopReviewItemEmojiEnum {
    RollingEyesFace = 'rolling_eyes_face',
    Sweat = 'sweat',
    Blush = 'blush',
    Smile = 'smile',
    HeartEyes = 'heart_eyes'
}
/**
 * 
 * @export
 * @interface TopReviewsResponse
 */
export interface TopReviewsResponse {
    /**
     * 
     * @type {Array<TopReviewItem>}
     * @memberof TopReviewsResponse
     */
    data: Array<TopReviewItem>;
}
/**
 * 
 * @export
 * @interface TwoWayStreamSource
 */
export interface TwoWayStreamSource {
    /**
     * 
     * @type {string}
     * @memberof TwoWayStreamSource
     */
    type: TwoWayStreamSourceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TwoWayStreamSource
     */
    liveStreamId: string;
}

/**
* @export
* @enum {string}
*/
export enum TwoWayStreamSourceTypeEnum {
    TwoWayStream = 'TWO_WAY_STREAM'
}
/**
 * 
 * @export
 * @interface TypedJSON
 */
export interface TypedJSON {
    /**
     * 
     * @type {string}
     * @memberof TypedJSON
     */
    required: string;
    /**
     * 
     * @type {string}
     * @memberof TypedJSON
     */
    optional?: string;
    /**
     * 
     * @type {Nested}
     * @memberof TypedJSON
     */
    requiredObject: Nested;
    /**
     * 
     * @type {Nested}
     * @memberof TypedJSON
     */
    optionalObject?: Nested;
    /**
     * 
     * @type {Array<Nested>}
     * @memberof TypedJSON
     */
    requiredArray: Array<Nested>;
    /**
     * 
     * @type {Array<Nested>}
     * @memberof TypedJSON
     */
    optionalArray?: Array<Nested>;
}
/**
 * 
 * @export
 * @interface UnupvoteReviewMessageResponse
 */
export interface UnupvoteReviewMessageResponse {
    /**
     * 
     * @type {number}
     * @memberof UnupvoteReviewMessageResponse
     */
    upvoteCount: number;
}
/**
 * 
 * @export
 * @interface UpcomingShowGenerateTokenRequest
 */
export interface UpcomingShowGenerateTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowGenerateTokenRequest
     */
    deviceId: string;
}
/**
 * 
 * @export
 * @interface UpcomingShowItem
 */
export interface UpcomingShowItem {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpcomingShowItem
     */
    badges: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowItem
     */
    title: string;
    /**
     * 
     * @type {BaseProfileItem}
     * @memberof UpcomingShowItem
     */
    user: BaseProfileItem;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowItem
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowItem
     */
    coverImage?: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowItem
     */
    originalCoverImage?: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowItem
     */
    previewVideoURL: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowItem
     */
    shareVideo?: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowItem
     */
    startsAt: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowItem
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowItem
     */
    createdAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpcomingShowItem
     */
    available: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowItem
     */
    visibility?: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowItem
     */
    permission?: UpcomingShowItemPermissionEnum;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowItem
     */
    eventId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpcomingShowItem
     */
    userGroupIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowItem
     */
    eventTemplateId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpcomingShowItem
     */
    disableComments?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpcomingShowItem
     */
    notificationSent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowItem
     */
    liveStreamId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpcomingShowItem
     */
    historyLiveStreamIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowItem
     */
    transcodeStatus?: UpcomingShowItemTranscodeStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowItem
     */
    id: string;
    /**
     * 
     * @type {Metadata}
     * @memberof UpcomingShowItem
     */
    metadata: Metadata;
}

/**
* @export
* @enum {string}
*/
export enum UpcomingShowItemPermissionEnum {
    All = 'all',
    Follower = 'follower',
    NoOne = 'no-one'
}/**
* @export
* @enum {string}
*/
export enum UpcomingShowItemTranscodeStatusEnum {
    Initial = 'initial',
    Processing = 'processing',
    TranscodeOriginal = 'transcode-original',
    TranscodeOriginalShare = 'transcode-original-share',
    RequestRetranscode = 'request-retranscode'
}
/**
 * 
 * @export
 * @interface UpcomingShowPageRows
 */
export interface UpcomingShowPageRows {
    /**
     * 
     * @type {Array<UpcomingShowItem>}
     * @memberof UpcomingShowPageRows
     */
    rows: Array<UpcomingShowItem>;
}
/**
 * 
 * @export
 * @interface UpcomingShowResponse
 */
export interface UpcomingShowResponse {
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowResponse
     */
    id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpcomingShowResponse
     */
    badges: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowResponse
     */
    title: string;
    /**
     * 
     * @type {object}
     * @memberof UpcomingShowResponse
     */
    user: object;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowResponse
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowResponse
     */
    coverImage?: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowResponse
     */
    originalCoverImage?: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowResponse
     */
    previewVideoURL: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowResponse
     */
    shareVideo?: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowResponse
     */
    startsAt: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowResponse
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowResponse
     */
    createdAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpcomingShowResponse
     */
    available: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowResponse
     */
    visibility?: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowResponse
     */
    permission?: UpcomingShowResponsePermissionEnum;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowResponse
     */
    eventId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpcomingShowResponse
     */
    userGroupIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowResponse
     */
    eventTemplateId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpcomingShowResponse
     */
    disableComments?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpcomingShowResponse
     */
    notificationSent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowResponse
     */
    liveStreamId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpcomingShowResponse
     */
    historyLiveStreamIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowResponse
     */
    transcodeStatus?: UpcomingShowResponseTranscodeStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum UpcomingShowResponsePermissionEnum {
    All = 'all',
    Follower = 'follower',
    NoOne = 'no-one'
}/**
* @export
* @enum {string}
*/
export enum UpcomingShowResponseTranscodeStatusEnum {
    Initial = 'initial',
    Processing = 'processing',
    TranscodeOriginal = 'transcode-original',
    TranscodeOriginalShare = 'transcode-original-share',
    RequestRetranscode = 'request-retranscode'
}
/**
 * 
 * @export
 * @interface UpcomingShowSearchResponse
 */
export interface UpcomingShowSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof UpcomingShowSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof UpcomingShowSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {UpcomingShowPageRows}
     * @memberof UpcomingShowSearchResponse
     */
    data: UpcomingShowPageRows;
}
/**
 * 
 * @export
 * @interface UpcomingShowSource
 */
export interface UpcomingShowSource {
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowSource
     */
    type: UpcomingShowSourceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowSource
     */
    upcomingShowId: string;
}

/**
* @export
* @enum {string}
*/
export enum UpcomingShowSourceTypeEnum {
    UpcomingShow = 'UPCOMING_SHOW'
}
/**
 * 
 * @export
 * @interface UpcomingShowTokenResponse
 */
export interface UpcomingShowTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowTokenResponse
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowTokenResponse
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowTokenResponse
     */
    channel: string;
}
/**
 * 
 * @export
 * @interface UpcomingShowTokensResponse
 */
export interface UpcomingShowTokensResponse {
    /**
     * 
     * @type {UpcomingShowTokenResponse}
     * @memberof UpcomingShowTokensResponse
     */
    rtmComments: UpcomingShowTokenResponse;
    /**
     * 
     * @type {UpcomingShowTokenResponse}
     * @memberof UpcomingShowTokensResponse
     */
    rtmCommentUpvotes: UpcomingShowTokenResponse;
    /**
     * 
     * @type {UpcomingShowTokenResponse}
     * @memberof UpcomingShowTokensResponse
     */
    rtmLightningBolts: UpcomingShowTokenResponse;
}
/**
 * 
 * @export
 * @interface UpcomingShowsWatcherItem
 */
export interface UpcomingShowsWatcherItem {
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowsWatcherItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingShowsWatcherItem
     */
    createdAt?: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof UpcomingShowsWatcherItem
     */
    user: ProfileItem;
    /**
     * 
     * @type {Metadata}
     * @memberof UpcomingShowsWatcherItem
     */
    metadata: Metadata;
}
/**
 * 
 * @export
 * @interface UpcomingShowsWatcherPageRows
 */
export interface UpcomingShowsWatcherPageRows {
    /**
     * 
     * @type {Array<UpcomingShowsWatcherItem>}
     * @memberof UpcomingShowsWatcherPageRows
     */
    rows: Array<UpcomingShowsWatcherItem>;
}
/**
 * 
 * @export
 * @interface UpcomingShowsWatchersSearchResponse
 */
export interface UpcomingShowsWatchersSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof UpcomingShowsWatchersSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof UpcomingShowsWatchersSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {UpcomingShowsWatcherPageRows}
     * @memberof UpcomingShowsWatchersSearchResponse
     */
    data: UpcomingShowsWatcherPageRows;
}
/**
 * 
 * @export
 * @interface UpdateAppSellerApplicationStatusBody
 */
export interface UpdateAppSellerApplicationStatusBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateAppSellerApplicationStatusBody
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface UpdateBlockUserCommentRequest
 */
export interface UpdateBlockUserCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateBlockUserCommentRequest
     */
    reason: UpdateBlockUserCommentRequestReasonEnum;
}

/**
* @export
* @enum {string}
*/
export enum UpdateBlockUserCommentRequestReasonEnum {
    Spam = 'spam',
    Inappropriate = 'inappropriate',
    Aggressive = 'aggressive'
}
/**
 * 
 * @export
 * @interface UpdateBuyerAddressRequest
 */
export interface UpdateBuyerAddressRequest {
    /**
     * 
     * @type {AddressRequest}
     * @memberof UpdateBuyerAddressRequest
     */
    address?: AddressRequest;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBuyerAddressRequest
     */
    deleteThis?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateBuyerAddressRequest
     */
    pos?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBuyerAddressRequest
     */
    asDefault?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateConfigResponse
 */
export interface UpdateConfigResponse {
    /**
     * 
     * @type {object}
     * @memberof UpdateConfigResponse
     */
    config: object;
}
/**
 * 
 * @export
 * @interface UpdateFeaturesRequest
 */
export interface UpdateFeaturesRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFeaturesRequest
     */
    pickup?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFeaturesRequest
     */
    unlimitedStoreFavorites?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFeaturesRequest
     */
    shopifyOrderSync?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFeaturesRequest
     */
    nftGatedShows?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateFeaturesRequest
     */
    nftContracts?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFeaturesRequest
     */
    patreonGatedShows?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeaturesRequest
     */
    patreonIdentifier?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFeaturesRequest
     */
    customersTab?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFeaturesRequest
     */
    shopifyOrderSyncWithPSPrefix?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFeaturesRequest
     */
    shopifyListingSyncWithoutSKU?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFeaturesRequest
     */
    streaming?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFeaturesRequest
     */
    voidLabel?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateNftFakerRequest
 */
export interface UpdateNftFakerRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateNftFakerRequest
     */
    asAddress: string;
}
/**
 * 
 * @export
 * @interface UpdateOrderByCommissionFeeRateRequest
 */
export interface UpdateOrderByCommissionFeeRateRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateOrderByCommissionFeeRateRequest
     */
    commissionFeeRate: number;
}
/**
 * 
 * @export
 * @interface UpdateOrderDenyRequest
 */
export interface UpdateOrderDenyRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateOrderDenyRequest
     */
    sellerIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderDenyRequest
     */
    reason: UpdateOrderDenyRequestReasonEnum;
}

/**
* @export
* @enum {string}
*/
export enum UpdateOrderDenyRequestReasonEnum {
    Fraud = 'fraud',
    Chargebacks = 'chargebacks',
    MultipleAccounts = 'multipleAccounts',
    InappropriateBehavior = 'inappropriateBehavior'
}
/**
 * 
 * @export
 * @interface UpdateOrderPackedStatusDto
 */
export interface UpdateOrderPackedStatusDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateOrderPackedStatusDto
     */
    packed: boolean;
}
/**
 * 
 * @export
 * @interface UpdateOrderPickupStatusDto
 */
export interface UpdateOrderPickupStatusDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderPickupStatusDto
     */
    pickupStatus: UpdateOrderPickupStatusDtoPickupStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateOrderPickupStatusDto
     */
    cancelingOrderIds?: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum UpdateOrderPickupStatusDtoPickupStatusEnum {
    New = 'new',
    Processing = 'processing',
    Packed = 'packed',
    Pending = 'pending',
    Completed = 'completed'
}
/**
 * 
 * @export
 * @interface UpdateOrderTrackingInfoDto
 */
export interface UpdateOrderTrackingInfoDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderTrackingInfoDto
     */
    trackingInfo: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderTrackingInfoDto
     */
    trackingURL?: string;
}
/**
 * 
 * @export
 * @interface UpdateRequest
 */
export interface UpdateRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateRequest
     */
    access: boolean;
}
/**
 * 
 * @export
 * @interface UpdateShippingAddressBySellerRequest
 */
export interface UpdateShippingAddressBySellerRequest {
    /**
     * 
     * @type {SellerUpdateAddressDto}
     * @memberof UpdateShippingAddressBySellerRequest
     */
    shippingAddress: SellerUpdateAddressDto;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateShippingAddressBySellerRequest
     */
    generateShippingLabel?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateShippingAddressRequest
 */
export interface UpdateShippingAddressRequest {
    /**
     * 
     * @type {AddressDto}
     * @memberof UpdateShippingAddressRequest
     */
    shippingAddress: AddressDto;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateShippingAddressRequest
     */
    generateShippingLabel?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateStoreReelRequest
 */
export interface UpdateStoreReelRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateStoreReelRequest
     */
    originalURL: string;
}
/**
 * 
 * @export
 * @interface UpdateUpcomingShowDto
 */
export interface UpdateUpcomingShowDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUpcomingShowDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUpcomingShowDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUpcomingShowDto
     */
    startsAt: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUpcomingShowDto
     */
    permission?: UpdateUpcomingShowDtoPermissionEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUpcomingShowDto
     */
    eventId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUpcomingShowDto
     */
    userGroupIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUpcomingShowDto
     */
    available: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateUpcomingShowDto
     */
    shareVideo?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUpcomingShowDto
     */
    previewVideoURL: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUpcomingShowDto
     */
    coverImage?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUpcomingShowDto
     */
    originalCoverImage?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUpcomingShowDto
     */
    transcodeStatus?: UpdateUpcomingShowDtoTranscodeStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum UpdateUpcomingShowDtoPermissionEnum {
    All = 'all',
    Follower = 'follower',
    NoOne = 'no-one'
}/**
* @export
* @enum {string}
*/
export enum UpdateUpcomingShowDtoTranscodeStatusEnum {
    Initial = 'initial',
    Processing = 'processing',
    TranscodeOriginal = 'transcode-original',
    TranscodeOriginalShare = 'transcode-original-share',
    RequestRetranscode = 'request-retranscode'
}
/**
 * 
 * @export
 * @interface UpdateUserGroupDto
 */
export interface UpdateUserGroupDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserGroupDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserGroupDto
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserGroupDto
     */
    active: boolean;
}
/**
 * 
 * @export
 * @interface UpdateUserParams
 */
export interface UpdateUserParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserParams
     */
    phoneNumber?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserParams
     */
    updateMask: Array<UpdateUserParamsUpdateMaskEnum>;
}

/**
* @export
* @enum {string}
*/
export enum UpdateUserParamsUpdateMaskEnum {
    PhoneNumber = 'phoneNumber'
}
/**
 * 
 * @export
 * @interface UpdateUserParamsV2
 */
export interface UpdateUserParamsV2 {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserParamsV2
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserParamsV2
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserParamsV2
     */
    username?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserParamsV2
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserParamsV2
     */
    bio?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserParamsV2
     */
    avatar?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserParamsV2
     */
    updateMask: Array<UpdateUserParamsV2UpdateMaskEnum>;
}

/**
* @export
* @enum {string}
*/
export enum UpdateUserParamsV2UpdateMaskEnum {
    FirstName = 'firstName',
    LastName = 'lastName',
    Username = 'username',
    Email = 'email',
    Bio = 'bio',
    Avatar = 'avatar'
}
/**
 * 
 * @export
 * @interface UpdateUserPhoneNumber
 */
export interface UpdateUserPhoneNumber {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPhoneNumber
     */
    phoneNumber: string;
}
/**
 * 
 * @export
 * @interface UpdateWithdrawableBalanceByAdminRequest
 */
export interface UpdateWithdrawableBalanceByAdminRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateWithdrawableBalanceByAdminRequest
     */
    note: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateWithdrawableBalanceByAdminRequest
     */
    amount: number;
}
/**
 * 
 * @export
 * @interface UpvoteReviewMessageResponse
 */
export interface UpvoteReviewMessageResponse {
    /**
     * 
     * @type {number}
     * @memberof UpvoteReviewMessageResponse
     */
    upvoteCount: number;
}
/**
 * 
 * @export
 * @interface UserDetailItem
 */
export interface UserDetailItem {
    /**
     * 
     * @type {string}
     * @memberof UserDetailItem
     */
    id: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof UserDetailItem
     */
    profile: ProfileItem;
    /**
     * 
     * @type {PrivateProfileItem}
     * @memberof UserDetailItem
     */
    privateProfile?: PrivateProfileItem;
    /**
     * 
     * @type {StoreFrontItem}
     * @memberof UserDetailItem
     */
    store?: StoreFrontItem;
    /**
     * 
     * @type {ExposureSellerStatsItem}
     * @memberof UserDetailItem
     */
    sellerStats?: ExposureSellerStatsItem;
    /**
     * 
     * @type {ZendeskInfoItem}
     * @memberof UserDetailItem
     */
    zendeskInfo?: ZendeskInfoItem;
    /**
     * 
     * @type {Metadata}
     * @memberof UserDetailItem
     */
    metadata: Metadata;
}
/**
 * 
 * @export
 * @interface UserDetailPageRows
 */
export interface UserDetailPageRows {
    /**
     * 
     * @type {Array<UserDetailItem>}
     * @memberof UserDetailPageRows
     */
    rows: Array<UserDetailItem>;
}
/**
 * 
 * @export
 * @interface UserDetailSearchResponse
 */
export interface UserDetailSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof UserDetailSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof UserDetailSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {UserDetailPageRows}
     * @memberof UserDetailSearchResponse
     */
    data: UserDetailPageRows;
}
/**
 * 
 * @export
 * @interface UserGroupItem
 */
export interface UserGroupItem {
    /**
     * 
     * @type {string}
     * @memberof UserGroupItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupItem
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupItem
     */
    createdAt: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserGroupItem
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserGroupItem
     */
    sendGridListId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserGroupItem
     */
    userType?: string;
    /**
     * 
     * @type {Metadata}
     * @memberof UserGroupItem
     */
    metadata: Metadata;
}
/**
 * 
 * @export
 * @interface UserGroupPageRows
 */
export interface UserGroupPageRows {
    /**
     * 
     * @type {Array<UserGroupItem>}
     * @memberof UserGroupPageRows
     */
    rows: Array<UserGroupItem>;
}
/**
 * 
 * @export
 * @interface UserGroupResponse
 */
export interface UserGroupResponse {
    /**
     * 
     * @type {string}
     * @memberof UserGroupResponse
     */
    id: string;
}
/**
 * 
 * @export
 * @interface UserGroupsSearchResponse
 */
export interface UserGroupsSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof UserGroupsSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof UserGroupsSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {UserGroupPageRows}
     * @memberof UserGroupsSearchResponse
     */
    data: UserGroupPageRows;
}
/**
 * 
 * @export
 * @interface UserItem
 */
export interface UserItem {
    /**
     * 
     * @type {string}
     * @memberof UserItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserItem
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof UserItem
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserItem
     */
    emailVerified: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserItem
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserItem
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UserItem
     */
    photoURL?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserItem
     */
    disabled: boolean;
    /**
     * 
     * @type {UserMetadata}
     * @memberof UserItem
     */
    metadata: UserMetadata;
    /**
     * 
     * @type {Array<ProviderUser>}
     * @memberof UserItem
     */
    providerData?: Array<ProviderUser>;
    /**
     * 
     * @type {CustomUserClaim}
     * @memberof UserItem
     */
    customClaims?: CustomUserClaim;
}
/**
 * 
 * @export
 * @interface UserMetadata
 */
export interface UserMetadata {
    /**
     * 
     * @type {string}
     * @memberof UserMetadata
     */
    lastSignInTime: string;
    /**
     * 
     * @type {string}
     * @memberof UserMetadata
     */
    creationTime: string;
    /**
     * 
     * @type {string}
     * @memberof UserMetadata
     */
    lastRefreshTime?: string;
}
/**
 * 
 * @export
 * @interface UserPageRows
 */
export interface UserPageRows {
    /**
     * 
     * @type {Array<UserItem>}
     * @memberof UserPageRows
     */
    rows: Array<UserItem>;
}
/**
 * 
 * @export
 * @interface UserReferralItem
 */
export interface UserReferralItem {
    /**
     * 
     * @type {string}
     * @memberof UserReferralItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserReferralItem
     */
    type: UserReferralItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserReferralItem
     */
    presenteeUserId: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof UserReferralItem
     */
    presenteeUserProfile: ProfileItem;
    /**
     * 
     * @type {string}
     * @memberof UserReferralItem
     */
    referrerUserId: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof UserReferralItem
     */
    referrerUserProfile: ProfileItem;
    /**
     * 
     * @type {LinkHistoryItem}
     * @memberof UserReferralItem
     */
    share?: LinkHistoryItem;
    /**
     * 
     * @type {string}
     * @memberof UserReferralItem
     */
    createdAt: string;
}

/**
* @export
* @enum {string}
*/
export enum UserReferralItemTypeEnum {
    Share = 'SHARE',
    Passcode = 'PASSCODE'
}
/**
 * 
 * @export
 * @interface UserReferralsRows
 */
export interface UserReferralsRows {
    /**
     * 
     * @type {Array<UserReferralItem>}
     * @memberof UserReferralsRows
     */
    rows: Array<UserReferralItem>;
}
/**
 * 
 * @export
 * @interface UserReferralsSearchResponse
 */
export interface UserReferralsSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof UserReferralsSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof UserReferralsSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {UserReferralsRows}
     * @memberof UserReferralsSearchResponse
     */
    data: UserReferralsRows;
}
/**
 * 
 * @export
 * @interface ValidateFeedbackIDRequest
 */
export interface ValidateFeedbackIDRequest {
    /**
     * 
     * @type {string}
     * @memberof ValidateFeedbackIDRequest
     */
    feedbackId: string;
}
/**
 * 
 * @export
 * @interface ValidateFeedbackIDResponse
 */
export interface ValidateFeedbackIDResponse {
    /**
     * 
     * @type {string}
     * @memberof ValidateFeedbackIDResponse
     */
    customToken: string;
    /**
     * 
     * @type {ValidateFeedbackIDTopic}
     * @memberof ValidateFeedbackIDResponse
     */
    topic?: ValidateFeedbackIDTopic;
}
/**
 * 
 * @export
 * @interface ValidateFeedbackIDTopic
 */
export interface ValidateFeedbackIDTopic {
    /**
     * 
     * @type {string}
     * @memberof ValidateFeedbackIDTopic
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ValidateFeedbackIDTopic
     */
    topic: string;
}
/**
 * 
 * @export
 * @interface ValidateUsername
 */
export interface ValidateUsername {
    /**
     * 
     * @type {string}
     * @memberof ValidateUsername
     */
    username: string;
}
/**
 * 
 * @export
 * @interface ValidateUsernameResponse
 */
export interface ValidateUsernameResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ValidateUsernameResponse
     */
    valid: boolean;
}
/**
 * 
 * @export
 * @interface ValidationStoreDto
 */
export interface ValidationStoreDto {
    /**
     * 
     * @type {string}
     * @memberof ValidationStoreDto
     */
    locationId: string;
}
/**
 * 
 * @export
 * @interface VideoResolution
 */
export interface VideoResolution {
    /**
     * 
     * @type {number}
     * @memberof VideoResolution
     */
    width: number;
    /**
     * 
     * @type {number}
     * @memberof VideoResolution
     */
    height: number;
}
/**
 * 
 * @export
 * @interface WatchSellerRequest
 */
export interface WatchSellerRequest {
    /**
     * 
     * @type {string}
     * @memberof WatchSellerRequest
     */
    sellerId: string;
}
/**
 * 
 * @export
 * @interface WithdrawTransactionExportOptions
 */
export interface WithdrawTransactionExportOptions {
    /**
     * default to pending.
     * @type {Array<string>}
     * @memberof WithdrawTransactionExportOptions
     */
    status?: Array<WithdrawTransactionExportOptionsStatusEnum>;
}

/**
* @export
* @enum {string}
*/
export enum WithdrawTransactionExportOptionsStatusEnum {
    Approved = 'approved',
    Rejected = 'rejected',
    Pending = 'pending'
}
/**
 * 
 * @export
 * @interface WithdrawTransactionItemWithId
 */
export interface WithdrawTransactionItemWithId {
    /**
     * 
     * @type {string}
     * @memberof WithdrawTransactionItemWithId
     */
    userId: string;
    /**
     * 
     * @type {ProfileItem}
     * @memberof WithdrawTransactionItemWithId
     */
    profile: ProfileItem;
    /**
     * 
     * @type {number}
     * @memberof WithdrawTransactionItemWithId
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof WithdrawTransactionItemWithId
     */
    balance: number;
    /**
     * 
     * @type {string}
     * @memberof WithdrawTransactionItemWithId
     */
    status: WithdrawTransactionItemWithIdStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof WithdrawTransactionItemWithId
     */
    note?: string;
    /**
     * 
     * @type {PayoutBankItem}
     * @memberof WithdrawTransactionItemWithId
     */
    payoutMethodBank?: PayoutBankItem;
    /**
     * 
     * @type {PayoutVenmoItem}
     * @memberof WithdrawTransactionItemWithId
     */
    payoutMethodVenmo?: PayoutVenmoItem;
    /**
     * 
     * @type {PayoutPaypalItem}
     * @memberof WithdrawTransactionItemWithId
     */
    payoutMethodPaypal?: PayoutPaypalItem;
    /**
     * 
     * @type {string}
     * @memberof WithdrawTransactionItemWithId
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawTransactionItemWithId
     */
    updatedAt: string;
    /**
     * 
     * @type {number}
     * @memberof WithdrawTransactionItemWithId
     */
    withdrawableAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof WithdrawTransactionItemWithId
     */
    withdrawableBalance?: number;
    /**
     * 
     * @type {string}
     * @memberof WithdrawTransactionItemWithId
     */
    userAgent?: string;
    /**
     * 
     * @type {number}
     * @memberof WithdrawTransactionItemWithId
     */
    idNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof WithdrawTransactionItemWithId
     */
    id: string;
}

/**
* @export
* @enum {string}
*/
export enum WithdrawTransactionItemWithIdStatusEnum {
    Approved = 'approved',
    Rejected = 'rejected',
    Pending = 'pending'
}
/**
 * 
 * @export
 * @interface WithdrawTransactionResponse
 */
export interface WithdrawTransactionResponse {
    /**
     * 
     * @type {string}
     * @memberof WithdrawTransactionResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawTransactionResponse
     */
    userId: string;
    /**
     * 
     * @type {object}
     * @memberof WithdrawTransactionResponse
     */
    profile: object;
    /**
     * 
     * @type {number}
     * @memberof WithdrawTransactionResponse
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof WithdrawTransactionResponse
     */
    balance: number;
    /**
     * 
     * @type {string}
     * @memberof WithdrawTransactionResponse
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawTransactionResponse
     */
    note?: string;
    /**
     * 
     * @type {object}
     * @memberof WithdrawTransactionResponse
     */
    payoutMethodBank?: object;
    /**
     * 
     * @type {object}
     * @memberof WithdrawTransactionResponse
     */
    payoutMethodVenmo?: object;
    /**
     * 
     * @type {object}
     * @memberof WithdrawTransactionResponse
     */
    payoutMethodPaypal?: object;
    /**
     * 
     * @type {object}
     * @memberof WithdrawTransactionResponse
     */
    createdAt: object;
    /**
     * 
     * @type {object}
     * @memberof WithdrawTransactionResponse
     */
    updatedAt: object;
    /**
     * 
     * @type {number}
     * @memberof WithdrawTransactionResponse
     */
    withdrawableAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof WithdrawTransactionResponse
     */
    withdrawableBalance?: number;
    /**
     * 
     * @type {string}
     * @memberof WithdrawTransactionResponse
     */
    userAgent?: string;
}
/**
 * 
 * @export
 * @interface WithdrawTransactionsPageRows
 */
export interface WithdrawTransactionsPageRows {
    /**
     * 
     * @type {Array<WithdrawTransactionItemWithId>}
     * @memberof WithdrawTransactionsPageRows
     */
    rows: Array<WithdrawTransactionItemWithId>;
}
/**
 * 
 * @export
 * @interface WithdrawTransactionsSearchResponse
 */
export interface WithdrawTransactionsSearchResponse {
    /**
     * total number of rows if exact is true.
     * @type {number}
     * @memberof WithdrawTransactionsSearchResponse
     */
    total: number;
    /**
     * if false there are more than total number of rows.
     * @type {boolean}
     * @memberof WithdrawTransactionsSearchResponse
     */
    exact: boolean;
    /**
     * 
     * @type {WithdrawTransactionsPageRows}
     * @memberof WithdrawTransactionsSearchResponse
     */
    data: WithdrawTransactionsPageRows;
}
/**
 * 
 * @export
 * @interface ZendeskContactItem
 */
export interface ZendeskContactItem {
    /**
     * 
     * @type {number}
     * @memberof ZendeskContactItem
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ZendeskContactItem
     */
    ownerId?: number;
    /**
     * 
     * @type {string}
     * @memberof ZendeskContactItem
     */
    ownerName?: string;
    /**
     * 
     * @type {string}
     * @memberof ZendeskContactItem
     */
    leadType?: string;
    /**
     * 
     * @type {string}
     * @memberof ZendeskContactItem
     */
    managedStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof ZendeskContactItem
     */
    community?: string;
    /**
     * 
     * @type {string}
     * @memberof ZendeskContactItem
     */
    primaryProduct?: string;
}
/**
 * 
 * @export
 * @interface ZendeskDealItem
 */
export interface ZendeskDealItem {
    /**
     * 
     * @type {number}
     * @memberof ZendeskDealItem
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ZendeskDealItem
     */
    ownerId?: number;
    /**
     * 
     * @type {string}
     * @memberof ZendeskDealItem
     */
    ownerName?: string;
    /**
     * 
     * @type {string}
     * @memberof ZendeskDealItem
     */
    leadType?: string;
}
/**
 * 
 * @export
 * @interface ZendeskInfoItem
 */
export interface ZendeskInfoItem {
    /**
     * 
     * @type {ZendeskContactItem}
     * @memberof ZendeskInfoItem
     */
    contact?: ZendeskContactItem;
    /**
     * 
     * @type {ZendeskDealItem}
     * @memberof ZendeskInfoItem
     */
    deal?: ZendeskDealItem;
    /**
     * 
     * @type {string}
     * @memberof ZendeskInfoItem
     */
    community?: string;
    /**
     * 
     * @type {string}
     * @memberof ZendeskInfoItem
     */
    primaryProduct?: string;
}
