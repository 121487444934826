import React from 'react';
import { Typography } from '@mui/material';
import Conatiner from './components/Conatiner';
import { ErrorOutline } from '@mui/icons-material';

export const ErrorView = React.memo(
  ({ errorMessage }: { errorMessage: string }) => (
    <Conatiner>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '4px',
        }}
      >
        <ErrorOutline fontSize="large" color="error" />
        <Typography variant="body1">{errorMessage}</Typography>
      </div>
    </Conatiner>
  )
);

export class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean; errorMessage: string }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false, errorMessage: '' };
  }

  static getDerivedStateFromError(error: string) {
    return { hasError: true, errorMessage: error };
  }

  render() {
    const { hasError, errorMessage } = this.state;
    if (hasError) {
      return <ErrorView errorMessage={errorMessage} />;
    }

    return this.props.children;
  }
}
