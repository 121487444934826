import React, { CSSProperties } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MoreHoriz, Close } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Box, Avatar } from '@mui/material';
import useSWR from 'swr';
import { Profile } from '../../types/profile';
import SWRKeys from '../../constant/swr';
import { useStoreInfo } from '../Dispatcher';
import { shopifyApi } from '../../apis/services';
import { AnalyticEvents } from '../../generated/AnalyticEvents';
import {
  db,
  getShopifyStore,
  getUser,
  signOut,
} from '../../util/setupFirebase';
import { doc, getDoc } from 'firebase/firestore';
import { PatchShopifyStoreRequestUpdateMaskEnum } from '../../generated/api';
import { styled } from '@mui/material/styles';

interface Props {
  style?: CSSProperties | undefined;
}

export const StyledMenuItem = styled(MenuItem)(() => ({
  display: 'flex',
  minHeight: 50,
  padding: '16px 20px',
  borderBottomWidth: 2,
  borderColor: 'black',
}));

const fetcher = async (storeId: string) => {
  const user = getUser();
  if (user) {
    const storeData = await getShopifyStore(storeId);

    const profileRef = await getDoc(doc(db, `profiles/${user.uid}`));
    const profileData = profileRef.data() as Profile;

    const shopName = (storeData?.shopName ?? '').split('.')[0];

    AnalyticEvents.default.shopifyLinkerLaunchedHomePage({
      sellerId: user.uid,
      shopName,
    });

    return { shopName, avatar: profileData.avatar };
  }
  throw new Error('fail to read store infos');
};

const ProfileButton = (props: Props) => {
  const { locationId, locations, storeId } = useStoreInfo();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [changingLocation, setChangingLocation] = React.useState(false);
  const userInfos = useSWR(SWRKeys.FetchUserProfile, () => fetcher(storeId), {
    suspense: false,
    revalidateOnFocus: false,
  });

  const { data } = userInfos;

  const open = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onUnlikeClicked = async () => {
    try {
      const user = getUser();
      if (!user) return;

      AnalyticEvents.default.shopifyLinkerUnlink({
        sellerId: user.uid,
        shopifyStoreId: storeId,
      });
      await shopifyApi.shopifyApiControllerRemoveAccessToken({
        sellerId: user.uid,
        shopifyStoreId: storeId,
      });
      signOut();
      closeMenu();
      window.location.reload();
    } catch (error) {}
  };

  const onChooseLocationClicked = async () => {
    setChangingLocation(true);
    try {
      const user = getUser();
      if (user) {
        await shopifyApi.shopifyApiControllerPatchShopifyStore({
          sellerId: user.uid,
          shopifyStoreId: storeId,
          patchShopifyStoreRequest: {
            updateMask: [PatchShopifyStoreRequestUpdateMaskEnum.LocationId],
          },
        });
        closeMenu();
        const fromDS =
          new URLSearchParams(window.location.search).get('fromDS') === 'true';
        let url = `${window.location.pathname}`;
        if (fromDS) {
          url += `&fromDS=true`;
        }
        window.location.replace(url);
      }
    } catch (error) {
      setChangingLocation(false);
    }
  };

  return (
    <div style={props.style}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={openMenu}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={closeMenu}
      >
        <StyledMenuItem onClick={closeMenu}>
          {data?.avatar && <Avatar src={data.avatar} />}
          <Typography
            component="div"
            variant="caption"
            sx={{
              marginLeft: 2,
              marginRight: 4,
            }}
          >
            <Box fontWeight="fontWeightBold">{data?.shopName || ''}</Box>
            <Box fontWeight="fontWeightBold">
              {locations.find(({ id }) => id === locationId)?.name || ''}
            </Box>
          </Typography>
          <ListItemIcon>
            <Close color="secondary" sx={{ color: 'black' }} />
          </ListItemIcon>
        </StyledMenuItem>
        <StyledMenuItem onClick={onChooseLocationClicked}>
          {changingLocation ? (
            <CircularProgress size={24} color="primary" />
          ) : (
            <Typography component="div" variant="caption">
              <Box fontWeight="fontWeightBold">Choose another location</Box>
            </Typography>
          )}
        </StyledMenuItem>
        <StyledMenuItem onClick={onUnlikeClicked}>
          <Typography component="div" variant="caption">
            <Box fontWeight="fontWeightBold">Unlink Accounts</Box>
          </Typography>
        </StyledMenuItem>
      </Menu>
    </div>
  );
};

export default React.memo(ProfileButton);
