import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import Conatiner from './Conatiner';
import { InfoOutlined } from '@mui/icons-material';

type Props = { url: string };

export const ShopifyAuth = React.memo((props: Props) => {
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = props.url;
    }, 3000);
    setTimer(timer);
    return () => {
      timer && clearTimeout(timer);
    };
  }, [props.url]);

  return (
    <Conatiner>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '4px',
        }}
      >
        <InfoOutlined fontSize="large" color="error" />
        <Typography variant="body1">
          Redirect to{' '}
          <a
            target="_self"
            rel="noopener noreferrer"
            href={props.url}
            onClick={() => timer && clearTimeout(timer)}
          >
            Shopify
          </a>
          ...
        </Typography>
      </div>
    </Conatiner>
  );
});
