import React, { memo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import Conatiner from './components/Conatiner';

export default memo(function Loading(props: { tip?: string }) {
  return (
    <Conatiner>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CircularProgress color="primary" />
        <Typography variant="body1">
          {props.tip ?? 'Communicating with Popshop.Live'}
        </Typography>
      </div>
    </Conatiner>
  );
});
