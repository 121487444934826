/* tslint:disable */
/* eslint-disable */
/**
 * PopshopLive API
 * PopshopLive API service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AppSellerApplicationsSearchResponse,
    CampaignsSearchResponse,
    CategoriesSearchResponse,
    CategorizedResourcesSearchResponse,
    CouponsSearchResponse,
    FeaturedShowSearchResponse,
    FeaturedStoresSearchResponse,
    LinksSearchResponse,
    ListingsAggregatePerShowItem,
    LiveStreamAudiencesSearchResponse,
    LiveStreamBuyerAddressSearchResponse,
    LiveStreamsSearchResponse,
    LiveStreamsViewersSearchResponse,
    MailsSearchResponse,
    MessageReportSearchResponse,
    MessagesSearchResponse,
    MyStoreListingSearchResponse,
    NotificationSettingsAggregateResponse,
    OrderDetailsSearchResponse,
    OrderTransactionSearchResponse,
    OrdersAggregateByPickupStatusResponse,
    OrdersSearchResponse,
    PrivateProfilesPayoutHistorySearchResponse,
    ProfileSearchResponse,
    ProfilesFollowersSearchResponse,
    ProfilesFollowingsSearchResponse,
    PurchasedStoresResponse,
    RolesSearchResponse,
    SearchAggregateLiveStreamUserStatsResponse,
    SearchLiveStreamUserStatsParams,
    SearchLiveStreamUserStatsResponse,
    SearchSellerLiveStreamsStatsResponse,
    SearchSellerViewersParams,
    SearchSellerViewersResponse,
    ShowEventsFeaturedShowSearchResponse,
    ShowEventsSearchResponse,
    ShowTicketsSearchResponse,
    StoreIncentivePayoutSearchResponse,
    StoreListingSearchResponse,
    StoresSearchResponse,
    UpcomingShowSearchResponse,
    UpcomingShowsWatchersSearchResponse,
    UserDetailSearchResponse,
    UserGroupsSearchResponse,
    UserReferralsSearchResponse,
    WithdrawTransactionsSearchResponse,
} from '../models';

export interface SearchApiControllerAggregateOrdersByPickupStatusRequest {
    sellerId: string;
    liveStreamId?: string;
    query?: string;
}

export interface SearchApiControllerAggregateShowListingRequest {
    liveStreamId: string;
}

export interface SearchApiControllerExportMyStoreListingsRequest {
    sellerId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerExportMyStoreListingsOrderEnum;
    id?: Array<string>;
    query?: string;
    filterOutLiveListings?: boolean;
    loadLiveStatus?: boolean;
    tags?: Array<string>;
    status?: SearchApiControllerExportMyStoreListingsStatusEnum;
    soldOut?: boolean;
    minPrice?: number;
    maxPrice?: number;
    sortBy?: SearchApiControllerExportMyStoreListingsSortByEnum;
}

export interface SearchApiControllerExportSellerOrderDetailsRequest {
    sellerId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerExportSellerOrderDetailsOrderEnum;
    ids?: Array<string>;
    query?: string;
    masterOrderId?: Array<string>;
    orderNumber?: string;
    liveStreamId?: string;
    status?: Array<SearchApiControllerExportSellerOrderDetailsStatusEnum>;
    pickupStatus?: Array<SearchApiControllerExportSellerOrderDetailsPickupStatusEnum>;
    groupStatusMaster?: boolean;
    groupStatus?: Array<SearchApiControllerExportSellerOrderDetailsGroupStatusEnum>;
    packed?: boolean;
    allOrders?: boolean;
    shippingMethod?: Array<SearchApiControllerExportSellerOrderDetailsShippingMethodEnum>;
    createdBefore?: string;
    createdAfter?: string;
    couponCode?: string;
    aggregateTax?: boolean;
    showTitle?: string;
    noLiveStreamId?: boolean;
    mainOrderNumber?: string;
    mainStatus?: Array<SearchApiControllerExportSellerOrderDetailsMainStatusEnum>;
    mainPacked?: boolean;
    showCreatedBefore?: string;
    showCreatedAfter?: string;
    shippingLabelStatus?: Array<SearchApiControllerExportSellerOrderDetailsShippingLabelStatusEnum>;
    mainShippingLabelStatus?: Array<SearchApiControllerExportSellerOrderDetailsMainShippingLabelStatusEnum>;
    shippingStatus?: SearchApiControllerExportSellerOrderDetailsShippingStatusEnum;
    mainShippingStatus?: SearchApiControllerExportSellerOrderDetailsMainShippingStatusEnum;
    filterLabelStatus?: SearchApiControllerExportSellerOrderDetailsFilterLabelStatusEnum;
    sortBy?: SearchApiControllerExportSellerOrderDetailsSortByEnum;
    timezone?: string;
}

export interface SearchApiControllerExportSellerOrderDetailsAsExcelRequest {
    sellerId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerExportSellerOrderDetailsAsExcelOrderEnum;
    ids?: Array<string>;
    query?: string;
    masterOrderId?: Array<string>;
    orderNumber?: string;
    liveStreamId?: string;
    status?: Array<SearchApiControllerExportSellerOrderDetailsAsExcelStatusEnum>;
    pickupStatus?: Array<SearchApiControllerExportSellerOrderDetailsAsExcelPickupStatusEnum>;
    groupStatusMaster?: boolean;
    groupStatus?: Array<SearchApiControllerExportSellerOrderDetailsAsExcelGroupStatusEnum>;
    packed?: boolean;
    allOrders?: boolean;
    shippingMethod?: Array<SearchApiControllerExportSellerOrderDetailsAsExcelShippingMethodEnum>;
    createdBefore?: string;
    createdAfter?: string;
    couponCode?: string;
    aggregateTax?: boolean;
    showTitle?: string;
    noLiveStreamId?: boolean;
    mainOrderNumber?: string;
    mainStatus?: Array<SearchApiControllerExportSellerOrderDetailsAsExcelMainStatusEnum>;
    mainPacked?: boolean;
    showCreatedBefore?: string;
    showCreatedAfter?: string;
    shippingLabelStatus?: Array<SearchApiControllerExportSellerOrderDetailsAsExcelShippingLabelStatusEnum>;
    mainShippingLabelStatus?: Array<SearchApiControllerExportSellerOrderDetailsAsExcelMainShippingLabelStatusEnum>;
    shippingStatus?: SearchApiControllerExportSellerOrderDetailsAsExcelShippingStatusEnum;
    mainShippingStatus?: SearchApiControllerExportSellerOrderDetailsAsExcelMainShippingStatusEnum;
    filterLabelStatus?: SearchApiControllerExportSellerOrderDetailsAsExcelFilterLabelStatusEnum;
    sortBy?: SearchApiControllerExportSellerOrderDetailsAsExcelSortByEnum;
}

export interface SearchApiControllerExportWithdrawTransactionsBySellerRequest {
    sellerId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerExportWithdrawTransactionsBySellerOrderEnum;
    id?: Array<string>;
    query?: string;
    status?: Array<SearchApiControllerExportWithdrawTransactionsBySellerStatusEnum>;
    userId?: string;
    minCreatedAt?: string;
    maxCreatedAt?: string;
    sortBy?: SearchApiControllerExportWithdrawTransactionsBySellerSortByEnum;
    timezone?: string;
}

export interface SearchApiControllerGetMyStoreListingTagsRequest {
    sellerId: string;
}

export interface SearchApiControllerSearchAggregateLiveStreamUserStatsRequest {
    liveStreamId: string;
}

export interface SearchApiControllerSearchAppSellerApplicationsRequest {
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchAppSellerApplicationsOrderEnum;
    sortBy?: SearchApiControllerSearchAppSellerApplicationsSortByEnum;
    status?: SearchApiControllerSearchAppSellerApplicationsStatusEnum;
    phone?: any;
    query?: any;
}

export interface SearchApiControllerSearchCampaignsRequest {
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchCampaignsOrderEnum;
    isActive?: boolean;
    sortBy?: SearchApiControllerSearchCampaignsSortByEnum;
}

export interface SearchApiControllerSearchCategoriesRequest {
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchCategoriesOrderEnum;
    sortBy?: SearchApiControllerSearchCategoriesSortByEnum;
    query?: any;
}

export interface SearchApiControllerSearchCategorizedResourcesRequest {
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchCategorizedResourcesOrderEnum;
    query?: string;
    categoryId?: string;
    resourceType?: SearchApiControllerSearchCategorizedResourcesResourceTypeEnum;
    sortBy?: SearchApiControllerSearchCategorizedResourcesSortByEnum;
}

export interface SearchApiControllerSearchCouponsRequest {
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchCouponsOrderEnum;
    query?: string;
    storeName?: string;
    couponCreateMethod?: SearchApiControllerSearchCouponsCouponCreateMethodEnum;
    sortBy?: SearchApiControllerSearchCouponsSortByEnum;
}

export interface SearchApiControllerSearchFeaturedShowsRequest {
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchFeaturedShowsOrderEnum;
    id?: Array<string>;
    query?: string;
    active?: boolean;
    filterOutExpiredUpcomingShows?: boolean;
    filterOutLiveLiveStreams?: boolean;
    minSortedAt?: string;
    type?: Array<SearchApiControllerSearchFeaturedShowsTypeEnum>;
    sortBy?: SearchApiControllerSearchFeaturedShowsSortByEnum;
}

export interface SearchApiControllerSearchFeaturedStoresRequest {
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchFeaturedStoresOrderEnum;
    sortBy?: SearchApiControllerSearchFeaturedStoresSortByEnum;
}

export interface SearchApiControllerSearchIncentivePayoutsRequest {
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchIncentivePayoutsOrderEnum;
    id?: Array<string>;
    query?: string;
    status?: SearchApiControllerSearchIncentivePayoutsStatusEnum;
    minCreatedAt?: string;
    maxCreatedAt?: string;
    sortBy?: SearchApiControllerSearchIncentivePayoutsSortByEnum;
    sellerId?: string;
}

export interface SearchApiControllerSearchLinksRequest {
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchLinksOrderEnum;
    isActive?: boolean;
    type?: SearchApiControllerSearchLinksTypeEnum;
    sortBy?: SearchApiControllerSearchLinksSortByEnum;
}

export interface SearchApiControllerSearchLiveStreamAudiencesRequest {
    liveStreamId: string;
    pageNo: number;
    pageSize: number;
    fetchDefault?: boolean;
    query?: string;
}

export interface SearchApiControllerSearchLiveStreamBuyerAddressesRequest {
    liveStreamId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchLiveStreamBuyerAddressesOrderEnum;
    id?: Array<string>;
    sortBy?: SearchApiControllerSearchLiveStreamBuyerAddressesSortByEnum;
}

export interface SearchApiControllerSearchLiveStreamUserStatsRequest {
    liveStreamId: string;
    searchLiveStreamUserStatsParams: SearchLiveStreamUserStatsParams;
}

export interface SearchApiControllerSearchLiveStreamsRequest {
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchLiveStreamsOrderEnum;
    id?: Array<string>;
    eventId?: string;
    title?: string;
    query?: string;
    storeName?: string;
    status?: SearchApiControllerSearchLiveStreamsStatusEnum;
    permission?: Array<SearchApiControllerSearchLiveStreamsPermissionEnum>;
    sortBy?: SearchApiControllerSearchLiveStreamsSortByEnum;
    userId?: string;
}

export interface SearchApiControllerSearchLiveStreamsActiveViewersRequest {
    liveStreamId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchLiveStreamsActiveViewersOrderEnum;
    query?: string;
    sortBy?: SearchApiControllerSearchLiveStreamsActiveViewersSortByEnum;
}

export interface SearchApiControllerSearchLiveStreamsListingsRequest {
    liveStreamId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchLiveStreamsListingsOrderEnum;
    id?: Array<string>;
    query?: string;
    sortBy?: SearchApiControllerSearchLiveStreamsListingsSortByEnum;
}

export interface SearchApiControllerSearchLiveStreamsViewersRequest {
    liveStreamId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchLiveStreamsViewersOrderEnum;
    id?: Array<string>;
    query?: string;
    isWatching?: boolean;
    sortBy?: SearchApiControllerSearchLiveStreamsViewersSortByEnum;
}

export interface SearchApiControllerSearchMailsRequest {
    pageNo: number;
    pageSize: number;
    query?: string;
    filterBy?: SearchApiControllerSearchMailsFilterByEnum;
    order?: SearchApiControllerSearchMailsOrderEnum;
    sortBy?: SearchApiControllerSearchMailsSortByEnum;
    state?: SearchApiControllerSearchMailsStateEnum;
}

export interface SearchApiControllerSearchMessagesRequest {
    pageNo: number;
    pageSize: number;
    filterBy: SearchApiControllerSearchMessagesFilterByEnum;
    order?: SearchApiControllerSearchMessagesOrderEnum;
    id?: Array<string>;
    available?: boolean;
    userIdTo?: string;
    userIdFrom?: string;
    sellerId?: string;
    buyerId?: string;
    listingId?: string;
    orderId?: string;
    query?: string;
    reportFilterBy?: SearchApiControllerSearchMessagesReportFilterByEnum;
    purpose?: SearchApiControllerSearchMessagesPurposeEnum;
    thankYouType?: Array<SearchApiControllerSearchMessagesThankYouTypeEnum>;
    sortBy?: SearchApiControllerSearchMessagesSortByEnum;
}

export interface SearchApiControllerSearchMessagesReportsRequest {
    messageId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchMessagesReportsOrderEnum;
    id?: Array<string>;
    reason?: Array<SearchApiControllerSearchMessagesReportsReasonEnum>;
    resolveStatus?: Array<SearchApiControllerSearchMessagesReportsResolveStatusEnum>;
    reporterId?: string;
    sortBy?: SearchApiControllerSearchMessagesReportsSortByEnum;
}

export interface SearchApiControllerSearchMyOrdersRequest {
    sellerId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchMyOrdersOrderEnum;
    ids?: Array<string>;
    query?: string;
    masterOrderId?: Array<string>;
    orderNumber?: string;
    liveStreamId?: string;
    status?: Array<SearchApiControllerSearchMyOrdersStatusEnum>;
    pickupStatus?: Array<SearchApiControllerSearchMyOrdersPickupStatusEnum>;
    groupStatusMaster?: boolean;
    groupStatus?: Array<SearchApiControllerSearchMyOrdersGroupStatusEnum>;
    packed?: boolean;
    allOrders?: boolean;
    shippingMethod?: Array<SearchApiControllerSearchMyOrdersShippingMethodEnum>;
    createdBefore?: string;
    createdAfter?: string;
    couponCode?: string;
    aggregateTax?: boolean;
    noLiveStreamId?: boolean;
    sortBy?: SearchApiControllerSearchMyOrdersSortByEnum;
}

export interface SearchApiControllerSearchMyStoreListingsRequest {
    sellerId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchMyStoreListingsOrderEnum;
    id?: Array<string>;
    query?: string;
    filterOutLiveListings?: boolean;
    loadLiveStatus?: boolean;
    tags?: Array<string>;
    status?: SearchApiControllerSearchMyStoreListingsStatusEnum;
    soldOut?: boolean;
    minPrice?: number;
    maxPrice?: number;
    sortBy?: SearchApiControllerSearchMyStoreListingsSortByEnum;
}

export interface SearchApiControllerSearchMyStorePublicListingsRequest {
    sellerId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchMyStorePublicListingsOrderEnum;
    id?: Array<string>;
    query?: string;
    tags?: Array<string>;
    status?: SearchApiControllerSearchMyStorePublicListingsStatusEnum;
    sortBy?: SearchApiControllerSearchMyStorePublicListingsSortByEnum;
}

export interface SearchApiControllerSearchOrderTransactionsRequest {
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchOrderTransactionsOrderEnum;
    orderId?: string;
    orderNumber?: string;
    sellerId?: string;
    buyerId?: string;
    type?: SearchApiControllerSearchOrderTransactionsTypeEnum;
    status?: SearchApiControllerSearchOrderTransactionsStatusEnum;
    sortBy?: SearchApiControllerSearchOrderTransactionsSortByEnum;
}

export interface SearchApiControllerSearchOrdersRequest {
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchOrdersOrderEnum;
    ids?: Array<string>;
    query?: string;
    masterOrderId?: Array<string>;
    orderNumber?: string;
    liveStreamId?: string;
    status?: Array<SearchApiControllerSearchOrdersStatusEnum>;
    pickupStatus?: Array<SearchApiControllerSearchOrdersPickupStatusEnum>;
    groupStatusMaster?: boolean;
    groupStatus?: Array<SearchApiControllerSearchOrdersGroupStatusEnum>;
    packed?: boolean;
    allOrders?: boolean;
    shippingMethod?: Array<SearchApiControllerSearchOrdersShippingMethodEnum>;
    createdBefore?: string;
    createdAfter?: string;
    couponCode?: string;
    aggregateTax?: boolean;
    eventId?: string;
    listingId?: string;
    sellerId?: string;
    buyerId?: string;
    shippingLabelFailed?: boolean;
    filterBy?: SearchApiControllerSearchOrdersFilterByEnum;
    giftingStatus?: Array<SearchApiControllerSearchOrdersGiftingStatusEnum>;
    isGiftPackage?: boolean;
    sourceType?: SearchApiControllerSearchOrdersSourceTypeEnum;
    hasShipmentParcel?: boolean;
    shipmentOriginalParcelSource?: Array<SearchApiControllerSearchOrdersShipmentOriginalParcelSourceEnum>;
    sourceId?: string;
    sortBy?: SearchApiControllerSearchOrdersSortByEnum;
}

export interface SearchApiControllerSearchPrivateProfilesPayoutHistoryRequest {
    sellerId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchPrivateProfilesPayoutHistoryOrderEnum;
    id?: Array<string>;
    status?: Array<SearchApiControllerSearchPrivateProfilesPayoutHistoryStatusEnum>;
    sourceType?: Array<SearchApiControllerSearchPrivateProfilesPayoutHistorySourceTypeEnum>;
    userId?: string;
    operatorId?: string;
    sortBy?: SearchApiControllerSearchPrivateProfilesPayoutHistorySortByEnum;
}

export interface SearchApiControllerSearchProfilesRequest {
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchProfilesOrderEnum;
    id?: Array<string>;
    filterBy?: SearchApiControllerSearchProfilesFilterByEnum;
    query?: string;
    userType?: Array<SearchApiControllerSearchProfilesUserTypeEnum>;
    sortBy?: SearchApiControllerSearchProfilesSortByEnum;
}

export interface SearchApiControllerSearchProfilesFollowersRequest {
    userId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchProfilesFollowersOrderEnum;
    query?: string;
    sortBy?: SearchApiControllerSearchProfilesFollowersSortByEnum;
}

export interface SearchApiControllerSearchProfilesFollowingsRequest {
    userId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchProfilesFollowingsOrderEnum;
    query?: string;
    sortBy?: SearchApiControllerSearchProfilesFollowingsSortByEnum;
    userType?: Array<SearchApiControllerSearchProfilesFollowingsUserTypeEnum>;
}

export interface SearchApiControllerSearchPurchasedStoresRequest {
    userId: string;
    size?: number;
}

export interface SearchApiControllerSearchReviewsByUserRequest {
    userId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchReviewsByUserOrderEnum;
    sortBy?: SearchApiControllerSearchReviewsByUserSortByEnum;
}

export interface SearchApiControllerSearchReviewsToSellerRequest {
    sellerId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchReviewsToSellerOrderEnum;
    sortBy?: SearchApiControllerSearchReviewsToSellerSortByEnum;
}

export interface SearchApiControllerSearchRolesRequest {
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchRolesOrderEnum;
    query?: string;
    uid?: string;
    isAdmin?: boolean;
    roles?: Array<SearchApiControllerSearchRolesRolesEnum>;
    sortBy?: SearchApiControllerSearchRolesSortByEnum;
}

export interface SearchApiControllerSearchSellerIncentivePayoutsRequest {
    sellerId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchSellerIncentivePayoutsOrderEnum;
    id?: Array<string>;
    query?: string;
    status?: SearchApiControllerSearchSellerIncentivePayoutsStatusEnum;
    minCreatedAt?: string;
    maxCreatedAt?: string;
    sortBy?: SearchApiControllerSearchSellerIncentivePayoutsSortByEnum;
}

export interface SearchApiControllerSearchSellerLiveStreamsStatsRequest {
    sellerId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchSellerLiveStreamsStatsOrderEnum;
    liveStreamId?: string;
    sortBy?: SearchApiControllerSearchSellerLiveStreamsStatsSortByEnum;
}

export interface SearchApiControllerSearchSellerMessagesRequest {
    sellerId: string;
    pageNo: number;
    pageSize: number;
    filterBy: SearchApiControllerSearchSellerMessagesFilterByEnum;
    order?: SearchApiControllerSearchSellerMessagesOrderEnum;
    id?: Array<string>;
    available?: boolean;
    userIdTo?: string;
    userIdFrom?: string;
    sellerId2?: string;
    buyerId?: string;
    listingId?: string;
    orderId?: string;
    query?: string;
    reportFilterBy?: SearchApiControllerSearchSellerMessagesReportFilterByEnum;
    purpose?: SearchApiControllerSearchSellerMessagesPurposeEnum;
    thankYouType?: Array<SearchApiControllerSearchSellerMessagesThankYouTypeEnum>;
    sortBy?: SearchApiControllerSearchSellerMessagesSortByEnum;
}

export interface SearchApiControllerSearchSellerOrderDetailsRequest {
    sellerId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchSellerOrderDetailsOrderEnum;
    ids?: Array<string>;
    query?: string;
    masterOrderId?: Array<string>;
    orderNumber?: string;
    liveStreamId?: string;
    status?: Array<SearchApiControllerSearchSellerOrderDetailsStatusEnum>;
    pickupStatus?: Array<SearchApiControllerSearchSellerOrderDetailsPickupStatusEnum>;
    groupStatusMaster?: boolean;
    groupStatus?: Array<SearchApiControllerSearchSellerOrderDetailsGroupStatusEnum>;
    packed?: boolean;
    allOrders?: boolean;
    shippingMethod?: Array<SearchApiControllerSearchSellerOrderDetailsShippingMethodEnum>;
    createdBefore?: string;
    createdAfter?: string;
    couponCode?: string;
    aggregateTax?: boolean;
    showTitle?: string;
    noLiveStreamId?: boolean;
    mainOrderNumber?: string;
    mainStatus?: Array<SearchApiControllerSearchSellerOrderDetailsMainStatusEnum>;
    mainPacked?: boolean;
    showCreatedBefore?: string;
    showCreatedAfter?: string;
    shippingLabelStatus?: Array<SearchApiControllerSearchSellerOrderDetailsShippingLabelStatusEnum>;
    mainShippingLabelStatus?: Array<SearchApiControllerSearchSellerOrderDetailsMainShippingLabelStatusEnum>;
    shippingStatus?: SearchApiControllerSearchSellerOrderDetailsShippingStatusEnum;
    mainShippingStatus?: SearchApiControllerSearchSellerOrderDetailsMainShippingStatusEnum;
    filterLabelStatus?: SearchApiControllerSearchSellerOrderDetailsFilterLabelStatusEnum;
    sortBy?: SearchApiControllerSearchSellerOrderDetailsSortByEnum;
}

export interface SearchApiControllerSearchSellerOrdersRequest {
    storeId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchSellerOrdersOrderEnum;
    ids?: Array<string>;
    query?: string;
    masterOrderId?: Array<string>;
    orderNumber?: string;
    liveStreamId?: string;
    status?: Array<SearchApiControllerSearchSellerOrdersStatusEnum>;
    pickupStatus?: Array<SearchApiControllerSearchSellerOrdersPickupStatusEnum>;
    groupStatusMaster?: boolean;
    groupStatus?: Array<SearchApiControllerSearchSellerOrdersGroupStatusEnum>;
    packed?: boolean;
    allOrders?: boolean;
    shippingMethod?: Array<SearchApiControllerSearchSellerOrdersShippingMethodEnum>;
    createdBefore?: string;
    createdAfter?: string;
    couponCode?: string;
    aggregateTax?: boolean;
    noLiveStreamId?: boolean;
    sortBy?: SearchApiControllerSearchSellerOrdersSortByEnum;
}

export interface SearchApiControllerSearchSellerStatusRequest {
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchSellerStatusOrderEnum;
    id?: Array<string>;
    query?: string;
    sortBy?: SearchApiControllerSearchSellerStatusSortByEnum;
}

export interface SearchApiControllerSearchSellerViewersRequest {
    sellerId: string;
    searchSellerViewersParams: SearchSellerViewersParams;
}

export interface SearchApiControllerSearchShowEventFeaturedStoresRequest {
    showEventId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchShowEventFeaturedStoresOrderEnum;
    sortBy?: SearchApiControllerSearchShowEventFeaturedStoresSortByEnum;
}

export interface SearchApiControllerSearchShowEventsRequest {
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchShowEventsOrderEnum;
    id?: Array<string>;
    sellerIds?: Array<string>;
    sellerUserGroupIds?: Array<string>;
    query?: string;
    active?: boolean;
    isClosed?: boolean;
    sortBy?: SearchApiControllerSearchShowEventsSortByEnum;
}

export interface SearchApiControllerSearchShowEventsFeaturedShowsRequest {
    showEventId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchShowEventsFeaturedShowsOrderEnum;
    id?: Array<string>;
    query?: string;
    active?: boolean;
    filterOutExpiredUpcomingShows?: boolean;
    filterOutLiveLiveStreams?: boolean;
    minSortedAt?: string;
    type?: Array<SearchApiControllerSearchShowEventsFeaturedShowsTypeEnum>;
    sortBy?: SearchApiControllerSearchShowEventsFeaturedShowsSortByEnum;
}

export interface SearchApiControllerSearchShowEventsTicketsRequest {
    pageNo: number;
    pageSize: number;
    eventId: string;
    order?: SearchApiControllerSearchShowEventsTicketsOrderEnum;
    isActive?: boolean;
    query?: string;
    sortBy?: SearchApiControllerSearchShowEventsTicketsSortByEnum;
}

export interface SearchApiControllerSearchStoreLiveStreamsRequest {
    sellerId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchStoreLiveStreamsOrderEnum;
    id?: Array<string>;
    eventId?: string;
    title?: string;
    query?: string;
    storeName?: string;
    status?: SearchApiControllerSearchStoreLiveStreamsStatusEnum;
    permission?: Array<SearchApiControllerSearchStoreLiveStreamsPermissionEnum>;
    sortBy?: SearchApiControllerSearchStoreLiveStreamsSortByEnum;
    liveOrEnded?: boolean;
    minCreatedAt?: string;
    maxCreatedAt?: string;
    shippingMode?: Array<SearchApiControllerSearchStoreLiveStreamsShippingModeEnum>;
}

export interface SearchApiControllerSearchStoresRequest {
    pageNo: number;
    pageSize: number;
    filterBy: SearchApiControllerSearchStoresFilterByEnum;
    order?: SearchApiControllerSearchStoresOrderEnum;
    id?: Array<string>;
    query?: string;
    sortBy?: SearchApiControllerSearchStoresSortByEnum;
}

export interface SearchApiControllerSearchUpcomingShowsRequest {
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchUpcomingShowsOrderEnum;
    id?: Array<string>;
    query?: string;
    storeName?: string;
    userId?: string;
    available?: boolean;
    noEventId?: boolean;
    sortBy?: SearchApiControllerSearchUpcomingShowsSortByEnum;
}

export interface SearchApiControllerSearchUpcomingShowsWatchersRequest {
    upcomingShowId: string;
    pageNo: number;
    pageSize: number;
    isFollower?: boolean;
}

export interface SearchApiControllerSearchUserDetailsRequest {
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchUserDetailsOrderEnum;
    id?: Array<string>;
    filterBy?: SearchApiControllerSearchUserDetailsFilterByEnum;
    query?: string;
    userType?: Array<SearchApiControllerSearchUserDetailsUserTypeEnum>;
    sortBy?: SearchApiControllerSearchUserDetailsSortByEnum;
}

export interface SearchApiControllerSearchUserGroupsRequest {
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchUserGroupsOrderEnum;
    query?: string;
    id?: Array<string>;
    active?: boolean;
    sortBy?: SearchApiControllerSearchUserGroupsSortByEnum;
}

export interface SearchApiControllerSearchUserGroupsUsersRequest {
    userGroupId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchUserGroupsUsersOrderEnum;
    query?: string;
    userType?: Array<SearchApiControllerSearchUserGroupsUsersUserTypeEnum>;
    sortBy?: SearchApiControllerSearchUserGroupsUsersSortByEnum;
}

export interface SearchApiControllerSearchUserMessagesRequest {
    userId: string;
    pageNo: number;
    pageSize: number;
    type: SearchApiControllerSearchUserMessagesTypeEnum;
    order?: SearchApiControllerSearchUserMessagesOrderEnum;
    available?: boolean;
    sortBy?: SearchApiControllerSearchUserMessagesSortByEnum;
}

export interface SearchApiControllerSearchUserOrdersRequest {
    userId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchUserOrdersOrderEnum;
    ids?: Array<string>;
    query?: string;
    masterOrderId?: Array<string>;
    orderNumber?: string;
    liveStreamId?: string;
    status?: Array<SearchApiControllerSearchUserOrdersStatusEnum>;
    pickupStatus?: Array<SearchApiControllerSearchUserOrdersPickupStatusEnum>;
    groupStatusMaster?: boolean;
    groupStatus?: Array<SearchApiControllerSearchUserOrdersGroupStatusEnum>;
    packed?: boolean;
    allOrders?: boolean;
    shippingMethod?: Array<SearchApiControllerSearchUserOrdersShippingMethodEnum>;
    createdBefore?: string;
    createdAfter?: string;
    couponCode?: string;
    aggregateTax?: boolean;
    noLiveStreamId?: boolean;
    sortBy?: SearchApiControllerSearchUserOrdersSortByEnum;
}

export interface SearchApiControllerSearchUserStatsUserReferralPresentersRequest {
    userId: string;
}

export interface SearchApiControllerSearchUserStatsUserReferralsRequest {
    userId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchUserStatsUserReferralsOrderEnum;
    referrerUserType?: Array<SearchApiControllerSearchUserStatsUserReferralsReferrerUserTypeEnum>;
    presenteeUserType?: Array<SearchApiControllerSearchUserStatsUserReferralsPresenteeUserTypeEnum>;
    minCreatedAt?: string;
    maxCreatedAt?: string;
    sortBy?: SearchApiControllerSearchUserStatsUserReferralsSortByEnum;
}

export interface SearchApiControllerSearchUserStatsUserReferralsByAdminRequest {
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchUserStatsUserReferralsByAdminOrderEnum;
    referrerUserType?: Array<SearchApiControllerSearchUserStatsUserReferralsByAdminReferrerUserTypeEnum>;
    presenteeUserType?: Array<SearchApiControllerSearchUserStatsUserReferralsByAdminPresenteeUserTypeEnum>;
    minCreatedAt?: string;
    maxCreatedAt?: string;
    sortBy?: SearchApiControllerSearchUserStatsUserReferralsByAdminSortByEnum;
    type?: SearchApiControllerSearchUserStatsUserReferralsByAdminTypeEnum;
    subType?: SearchApiControllerSearchUserStatsUserReferralsByAdminSubTypeEnum;
    presenteeUserId?: string;
    referrerUserId?: string;
}

export interface SearchApiControllerSearchWithdrawTransactionsRequest {
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchWithdrawTransactionsOrderEnum;
    id?: Array<string>;
    query?: string;
    status?: Array<SearchApiControllerSearchWithdrawTransactionsStatusEnum>;
    userId?: string;
    minCreatedAt?: string;
    maxCreatedAt?: string;
    sortBy?: SearchApiControllerSearchWithdrawTransactionsSortByEnum;
}

export interface SearchApiControllerSearchWithdrawTransactionsBySellerRequest {
    sellerId: string;
    pageNo: number;
    pageSize: number;
    order?: SearchApiControllerSearchWithdrawTransactionsBySellerOrderEnum;
    id?: Array<string>;
    query?: string;
    status?: Array<SearchApiControllerSearchWithdrawTransactionsBySellerStatusEnum>;
    userId?: string;
    minCreatedAt?: string;
    maxCreatedAt?: string;
    sortBy?: SearchApiControllerSearchWithdrawTransactionsBySellerSortByEnum;
}

/**
 * 
 */
export class SearchApi extends runtime.BaseAPI {

    /**
     */
    async searchApiControllerAggregateNotificationSettingsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<NotificationSettingsAggregateResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/notifications:aggregate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerAggregateNotificationSettings(initOverrides?: RequestInit): Promise<NotificationSettingsAggregateResponse> {
        const response = await this.searchApiControllerAggregateNotificationSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerAggregateOrdersByPickupStatusRaw(requestParameters: SearchApiControllerAggregateOrdersByPickupStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrdersAggregateByPickupStatusResponse>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling searchApiControllerAggregateOrdersByPickupStatus.');
        }

        const queryParameters: any = {};

        if (requestParameters.sellerId !== undefined) {
            queryParameters['sellerId'] = requestParameters.sellerId;
        }

        if (requestParameters.liveStreamId !== undefined) {
            queryParameters['liveStreamId'] = requestParameters.liveStreamId;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders:aggregate-by-pickup-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerAggregateOrdersByPickupStatus(requestParameters: SearchApiControllerAggregateOrdersByPickupStatusRequest, initOverrides?: RequestInit): Promise<OrdersAggregateByPickupStatusResponse> {
        const response = await this.searchApiControllerAggregateOrdersByPickupStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerAggregateShowListingRaw(requestParameters: SearchApiControllerAggregateShowListingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ListingsAggregatePerShowItem>>> {
        if (requestParameters.liveStreamId === null || requestParameters.liveStreamId === undefined) {
            throw new runtime.RequiredError('liveStreamId','Required parameter requestParameters.liveStreamId was null or undefined when calling searchApiControllerAggregateShowListing.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/liveStreams/{liveStreamId}:aggregate-listing`.replace(`{${"liveStreamId"}}`, encodeURIComponent(String(requestParameters.liveStreamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerAggregateShowListing(requestParameters: SearchApiControllerAggregateShowListingRequest, initOverrides?: RequestInit): Promise<Array<ListingsAggregatePerShowItem>> {
        const response = await this.searchApiControllerAggregateShowListingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerExportMyStoreListingsRaw(requestParameters: SearchApiControllerExportMyStoreListingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling searchApiControllerExportMyStoreListings.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerExportMyStoreListings.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerExportMyStoreListings.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.filterOutLiveListings !== undefined) {
            queryParameters['filterOutLiveListings'] = requestParameters.filterOutLiveListings;
        }

        if (requestParameters.loadLiveStatus !== undefined) {
            queryParameters['loadLiveStatus'] = requestParameters.loadLiveStatus;
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.soldOut !== undefined) {
            queryParameters['soldOut'] = requestParameters.soldOut;
        }

        if (requestParameters.minPrice !== undefined) {
            queryParameters['minPrice'] = requestParameters.minPrice;
        }

        if (requestParameters.maxPrice !== undefined) {
            queryParameters['maxPrice'] = requestParameters.maxPrice;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/listings:export`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async searchApiControllerExportMyStoreListings(requestParameters: SearchApiControllerExportMyStoreListingsRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.searchApiControllerExportMyStoreListingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerExportSellerOrderDetailsRaw(requestParameters: SearchApiControllerExportSellerOrderDetailsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling searchApiControllerExportSellerOrderDetails.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerExportSellerOrderDetails.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerExportSellerOrderDetails.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.masterOrderId) {
            queryParameters['masterOrderId'] = requestParameters.masterOrderId;
        }

        if (requestParameters.orderNumber !== undefined) {
            queryParameters['orderNumber'] = requestParameters.orderNumber;
        }

        if (requestParameters.liveStreamId !== undefined) {
            queryParameters['liveStreamId'] = requestParameters.liveStreamId;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.pickupStatus) {
            queryParameters['pickupStatus'] = requestParameters.pickupStatus;
        }

        if (requestParameters.groupStatusMaster !== undefined) {
            queryParameters['groupStatusMaster'] = requestParameters.groupStatusMaster;
        }

        if (requestParameters.groupStatus) {
            queryParameters['groupStatus'] = requestParameters.groupStatus;
        }

        if (requestParameters.packed !== undefined) {
            queryParameters['packed'] = requestParameters.packed;
        }

        if (requestParameters.allOrders !== undefined) {
            queryParameters['allOrders'] = requestParameters.allOrders;
        }

        if (requestParameters.shippingMethod) {
            queryParameters['shippingMethod'] = requestParameters.shippingMethod;
        }

        if (requestParameters.createdBefore !== undefined) {
            queryParameters['createdBefore'] = requestParameters.createdBefore;
        }

        if (requestParameters.createdAfter !== undefined) {
            queryParameters['createdAfter'] = requestParameters.createdAfter;
        }

        if (requestParameters.couponCode !== undefined) {
            queryParameters['couponCode'] = requestParameters.couponCode;
        }

        if (requestParameters.aggregateTax !== undefined) {
            queryParameters['aggregateTax'] = requestParameters.aggregateTax;
        }

        if (requestParameters.showTitle !== undefined) {
            queryParameters['showTitle'] = requestParameters.showTitle;
        }

        if (requestParameters.noLiveStreamId !== undefined) {
            queryParameters['noLiveStreamId'] = requestParameters.noLiveStreamId;
        }

        if (requestParameters.mainOrderNumber !== undefined) {
            queryParameters['mainOrderNumber'] = requestParameters.mainOrderNumber;
        }

        if (requestParameters.mainStatus) {
            queryParameters['mainStatus'] = requestParameters.mainStatus;
        }

        if (requestParameters.mainPacked !== undefined) {
            queryParameters['mainPacked'] = requestParameters.mainPacked;
        }

        if (requestParameters.showCreatedBefore !== undefined) {
            queryParameters['showCreatedBefore'] = requestParameters.showCreatedBefore;
        }

        if (requestParameters.showCreatedAfter !== undefined) {
            queryParameters['showCreatedAfter'] = requestParameters.showCreatedAfter;
        }

        if (requestParameters.shippingLabelStatus) {
            queryParameters['shippingLabelStatus'] = requestParameters.shippingLabelStatus;
        }

        if (requestParameters.mainShippingLabelStatus) {
            queryParameters['mainShippingLabelStatus'] = requestParameters.mainShippingLabelStatus;
        }

        if (requestParameters.shippingStatus !== undefined) {
            queryParameters['shippingStatus'] = requestParameters.shippingStatus;
        }

        if (requestParameters.mainShippingStatus !== undefined) {
            queryParameters['mainShippingStatus'] = requestParameters.mainShippingStatus;
        }

        if (requestParameters.filterLabelStatus !== undefined) {
            queryParameters['filterLabelStatus'] = requestParameters.filterLabelStatus;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.timezone !== undefined) {
            queryParameters['timezone'] = requestParameters.timezone;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/order-details:export`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async searchApiControllerExportSellerOrderDetails(requestParameters: SearchApiControllerExportSellerOrderDetailsRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.searchApiControllerExportSellerOrderDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerExportSellerOrderDetailsAsExcelRaw(requestParameters: SearchApiControllerExportSellerOrderDetailsAsExcelRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling searchApiControllerExportSellerOrderDetailsAsExcel.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerExportSellerOrderDetailsAsExcel.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerExportSellerOrderDetailsAsExcel.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.masterOrderId) {
            queryParameters['masterOrderId'] = requestParameters.masterOrderId;
        }

        if (requestParameters.orderNumber !== undefined) {
            queryParameters['orderNumber'] = requestParameters.orderNumber;
        }

        if (requestParameters.liveStreamId !== undefined) {
            queryParameters['liveStreamId'] = requestParameters.liveStreamId;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.pickupStatus) {
            queryParameters['pickupStatus'] = requestParameters.pickupStatus;
        }

        if (requestParameters.groupStatusMaster !== undefined) {
            queryParameters['groupStatusMaster'] = requestParameters.groupStatusMaster;
        }

        if (requestParameters.groupStatus) {
            queryParameters['groupStatus'] = requestParameters.groupStatus;
        }

        if (requestParameters.packed !== undefined) {
            queryParameters['packed'] = requestParameters.packed;
        }

        if (requestParameters.allOrders !== undefined) {
            queryParameters['allOrders'] = requestParameters.allOrders;
        }

        if (requestParameters.shippingMethod) {
            queryParameters['shippingMethod'] = requestParameters.shippingMethod;
        }

        if (requestParameters.createdBefore !== undefined) {
            queryParameters['createdBefore'] = requestParameters.createdBefore;
        }

        if (requestParameters.createdAfter !== undefined) {
            queryParameters['createdAfter'] = requestParameters.createdAfter;
        }

        if (requestParameters.couponCode !== undefined) {
            queryParameters['couponCode'] = requestParameters.couponCode;
        }

        if (requestParameters.aggregateTax !== undefined) {
            queryParameters['aggregateTax'] = requestParameters.aggregateTax;
        }

        if (requestParameters.showTitle !== undefined) {
            queryParameters['showTitle'] = requestParameters.showTitle;
        }

        if (requestParameters.noLiveStreamId !== undefined) {
            queryParameters['noLiveStreamId'] = requestParameters.noLiveStreamId;
        }

        if (requestParameters.mainOrderNumber !== undefined) {
            queryParameters['mainOrderNumber'] = requestParameters.mainOrderNumber;
        }

        if (requestParameters.mainStatus) {
            queryParameters['mainStatus'] = requestParameters.mainStatus;
        }

        if (requestParameters.mainPacked !== undefined) {
            queryParameters['mainPacked'] = requestParameters.mainPacked;
        }

        if (requestParameters.showCreatedBefore !== undefined) {
            queryParameters['showCreatedBefore'] = requestParameters.showCreatedBefore;
        }

        if (requestParameters.showCreatedAfter !== undefined) {
            queryParameters['showCreatedAfter'] = requestParameters.showCreatedAfter;
        }

        if (requestParameters.shippingLabelStatus) {
            queryParameters['shippingLabelStatus'] = requestParameters.shippingLabelStatus;
        }

        if (requestParameters.mainShippingLabelStatus) {
            queryParameters['mainShippingLabelStatus'] = requestParameters.mainShippingLabelStatus;
        }

        if (requestParameters.shippingStatus !== undefined) {
            queryParameters['shippingStatus'] = requestParameters.shippingStatus;
        }

        if (requestParameters.mainShippingStatus !== undefined) {
            queryParameters['mainShippingStatus'] = requestParameters.mainShippingStatus;
        }

        if (requestParameters.filterLabelStatus !== undefined) {
            queryParameters['filterLabelStatus'] = requestParameters.filterLabelStatus;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/order-details:exportExcel`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async searchApiControllerExportSellerOrderDetailsAsExcel(requestParameters: SearchApiControllerExportSellerOrderDetailsAsExcelRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.searchApiControllerExportSellerOrderDetailsAsExcelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerExportWithdrawTransactionsBySellerRaw(requestParameters: SearchApiControllerExportWithdrawTransactionsBySellerRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling searchApiControllerExportWithdrawTransactionsBySeller.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerExportWithdrawTransactionsBySeller.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerExportWithdrawTransactionsBySeller.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.minCreatedAt !== undefined) {
            queryParameters['minCreatedAt'] = requestParameters.minCreatedAt;
        }

        if (requestParameters.maxCreatedAt !== undefined) {
            queryParameters['maxCreatedAt'] = requestParameters.maxCreatedAt;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.timezone !== undefined) {
            queryParameters['timezone'] = requestParameters.timezone;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/withdraw-transactions:export`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async searchApiControllerExportWithdrawTransactionsBySeller(requestParameters: SearchApiControllerExportWithdrawTransactionsBySellerRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.searchApiControllerExportWithdrawTransactionsBySellerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerGetMyStoreListingTagsRaw(requestParameters: SearchApiControllerGetMyStoreListingTagsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling searchApiControllerGetMyStoreListingTags.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/listing-tags`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async searchApiControllerGetMyStoreListingTags(requestParameters: SearchApiControllerGetMyStoreListingTagsRequest, initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.searchApiControllerGetMyStoreListingTagsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchAggregateLiveStreamUserStatsRaw(requestParameters: SearchApiControllerSearchAggregateLiveStreamUserStatsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SearchAggregateLiveStreamUserStatsResponse>> {
        if (requestParameters.liveStreamId === null || requestParameters.liveStreamId === undefined) {
            throw new runtime.RequiredError('liveStreamId','Required parameter requestParameters.liveStreamId was null or undefined when calling searchApiControllerSearchAggregateLiveStreamUserStats.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/live-streams/{liveStreamId}/user-stats:aggregate`.replace(`{${"liveStreamId"}}`, encodeURIComponent(String(requestParameters.liveStreamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchAggregateLiveStreamUserStats(requestParameters: SearchApiControllerSearchAggregateLiveStreamUserStatsRequest, initOverrides?: RequestInit): Promise<SearchAggregateLiveStreamUserStatsResponse> {
        const response = await this.searchApiControllerSearchAggregateLiveStreamUserStatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchAppSellerApplicationsRaw(requestParameters: SearchApiControllerSearchAppSellerApplicationsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AppSellerApplicationsSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchAppSellerApplications.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchAppSellerApplications.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.phone !== undefined) {
            queryParameters['phone'] = requestParameters.phone;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/app-seller-applications:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchAppSellerApplications(requestParameters: SearchApiControllerSearchAppSellerApplicationsRequest, initOverrides?: RequestInit): Promise<AppSellerApplicationsSearchResponse> {
        const response = await this.searchApiControllerSearchAppSellerApplicationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchCampaignsRaw(requestParameters: SearchApiControllerSearchCampaignsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CampaignsSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchCampaigns.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchCampaigns.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.isActive !== undefined) {
            queryParameters['isActive'] = requestParameters.isActive;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/campaigns:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchCampaigns(requestParameters: SearchApiControllerSearchCampaignsRequest, initOverrides?: RequestInit): Promise<CampaignsSearchResponse> {
        const response = await this.searchApiControllerSearchCampaignsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchCategoriesRaw(requestParameters: SearchApiControllerSearchCategoriesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CategoriesSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchCategories.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchCategories.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/categories:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchCategories(requestParameters: SearchApiControllerSearchCategoriesRequest, initOverrides?: RequestInit): Promise<CategoriesSearchResponse> {
        const response = await this.searchApiControllerSearchCategoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchCategorizedResourcesRaw(requestParameters: SearchApiControllerSearchCategorizedResourcesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CategorizedResourcesSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchCategorizedResources.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchCategorizedResources.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.categoryId !== undefined) {
            queryParameters['categoryId'] = requestParameters.categoryId;
        }

        if (requestParameters.resourceType !== undefined) {
            queryParameters['resourceType'] = requestParameters.resourceType;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/categorized-resources:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchCategorizedResources(requestParameters: SearchApiControllerSearchCategorizedResourcesRequest, initOverrides?: RequestInit): Promise<CategorizedResourcesSearchResponse> {
        const response = await this.searchApiControllerSearchCategorizedResourcesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchCouponsRaw(requestParameters: SearchApiControllerSearchCouponsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CouponsSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchCoupons.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchCoupons.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.storeName !== undefined) {
            queryParameters['storeName'] = requestParameters.storeName;
        }

        if (requestParameters.couponCreateMethod !== undefined) {
            queryParameters['couponCreateMethod'] = requestParameters.couponCreateMethod;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/coupons:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchCoupons(requestParameters: SearchApiControllerSearchCouponsRequest, initOverrides?: RequestInit): Promise<CouponsSearchResponse> {
        const response = await this.searchApiControllerSearchCouponsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchFeaturedShowsRaw(requestParameters: SearchApiControllerSearchFeaturedShowsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeaturedShowSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchFeaturedShows.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchFeaturedShows.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.filterOutExpiredUpcomingShows !== undefined) {
            queryParameters['filterOutExpiredUpcomingShows'] = requestParameters.filterOutExpiredUpcomingShows;
        }

        if (requestParameters.filterOutLiveLiveStreams !== undefined) {
            queryParameters['filterOutLiveLiveStreams'] = requestParameters.filterOutLiveLiveStreams;
        }

        if (requestParameters.minSortedAt !== undefined) {
            queryParameters['minSortedAt'] = requestParameters.minSortedAt;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/featured-shows:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchFeaturedShows(requestParameters: SearchApiControllerSearchFeaturedShowsRequest, initOverrides?: RequestInit): Promise<FeaturedShowSearchResponse> {
        const response = await this.searchApiControllerSearchFeaturedShowsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchFeaturedStoresRaw(requestParameters: SearchApiControllerSearchFeaturedStoresRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeaturedStoresSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchFeaturedStores.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchFeaturedStores.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/featured-stores:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchFeaturedStores(requestParameters: SearchApiControllerSearchFeaturedStoresRequest, initOverrides?: RequestInit): Promise<FeaturedStoresSearchResponse> {
        const response = await this.searchApiControllerSearchFeaturedStoresRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchIncentivePayoutsRaw(requestParameters: SearchApiControllerSearchIncentivePayoutsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StoreIncentivePayoutSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchIncentivePayouts.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchIncentivePayouts.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.minCreatedAt !== undefined) {
            queryParameters['minCreatedAt'] = requestParameters.minCreatedAt;
        }

        if (requestParameters.maxCreatedAt !== undefined) {
            queryParameters['maxCreatedAt'] = requestParameters.maxCreatedAt;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.sellerId !== undefined) {
            queryParameters['sellerId'] = requestParameters.sellerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/incentive-payouts:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchIncentivePayouts(requestParameters: SearchApiControllerSearchIncentivePayoutsRequest, initOverrides?: RequestInit): Promise<StoreIncentivePayoutSearchResponse> {
        const response = await this.searchApiControllerSearchIncentivePayoutsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchLinksRaw(requestParameters: SearchApiControllerSearchLinksRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LinksSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchLinks.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchLinks.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.isActive !== undefined) {
            queryParameters['isActive'] = requestParameters.isActive;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/links:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchLinks(requestParameters: SearchApiControllerSearchLinksRequest, initOverrides?: RequestInit): Promise<LinksSearchResponse> {
        const response = await this.searchApiControllerSearchLinksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchLiveStreamAudiencesRaw(requestParameters: SearchApiControllerSearchLiveStreamAudiencesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LiveStreamAudiencesSearchResponse>> {
        if (requestParameters.liveStreamId === null || requestParameters.liveStreamId === undefined) {
            throw new runtime.RequiredError('liveStreamId','Required parameter requestParameters.liveStreamId was null or undefined when calling searchApiControllerSearchLiveStreamAudiences.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchLiveStreamAudiences.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchLiveStreamAudiences.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.fetchDefault !== undefined) {
            queryParameters['fetchDefault'] = requestParameters.fetchDefault;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/live-streams/{liveStreamId}/audiences:search`.replace(`{${"liveStreamId"}}`, encodeURIComponent(String(requestParameters.liveStreamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchLiveStreamAudiences(requestParameters: SearchApiControllerSearchLiveStreamAudiencesRequest, initOverrides?: RequestInit): Promise<LiveStreamAudiencesSearchResponse> {
        const response = await this.searchApiControllerSearchLiveStreamAudiencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchLiveStreamBuyerAddressesRaw(requestParameters: SearchApiControllerSearchLiveStreamBuyerAddressesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LiveStreamBuyerAddressSearchResponse>> {
        if (requestParameters.liveStreamId === null || requestParameters.liveStreamId === undefined) {
            throw new runtime.RequiredError('liveStreamId','Required parameter requestParameters.liveStreamId was null or undefined when calling searchApiControllerSearchLiveStreamBuyerAddresses.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchLiveStreamBuyerAddresses.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchLiveStreamBuyerAddresses.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/live-streams/{liveStreamId}/buyer-addresses:search`.replace(`{${"liveStreamId"}}`, encodeURIComponent(String(requestParameters.liveStreamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchLiveStreamBuyerAddresses(requestParameters: SearchApiControllerSearchLiveStreamBuyerAddressesRequest, initOverrides?: RequestInit): Promise<LiveStreamBuyerAddressSearchResponse> {
        const response = await this.searchApiControllerSearchLiveStreamBuyerAddressesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchLiveStreamUserStatsRaw(requestParameters: SearchApiControllerSearchLiveStreamUserStatsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SearchLiveStreamUserStatsResponse>> {
        if (requestParameters.liveStreamId === null || requestParameters.liveStreamId === undefined) {
            throw new runtime.RequiredError('liveStreamId','Required parameter requestParameters.liveStreamId was null or undefined when calling searchApiControllerSearchLiveStreamUserStats.');
        }

        if (requestParameters.searchLiveStreamUserStatsParams === null || requestParameters.searchLiveStreamUserStatsParams === undefined) {
            throw new runtime.RequiredError('searchLiveStreamUserStatsParams','Required parameter requestParameters.searchLiveStreamUserStatsParams was null or undefined when calling searchApiControllerSearchLiveStreamUserStats.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/live-streams/{liveStreamId}/user-stats:search`.replace(`{${"liveStreamId"}}`, encodeURIComponent(String(requestParameters.liveStreamId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.searchLiveStreamUserStatsParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchLiveStreamUserStats(requestParameters: SearchApiControllerSearchLiveStreamUserStatsRequest, initOverrides?: RequestInit): Promise<SearchLiveStreamUserStatsResponse> {
        const response = await this.searchApiControllerSearchLiveStreamUserStatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchLiveStreamsRaw(requestParameters: SearchApiControllerSearchLiveStreamsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LiveStreamsSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchLiveStreams.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchLiveStreams.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.title !== undefined) {
            queryParameters['title'] = requestParameters.title;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.storeName !== undefined) {
            queryParameters['storeName'] = requestParameters.storeName;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.permission) {
            queryParameters['permission'] = requestParameters.permission;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/live-streams:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchLiveStreams(requestParameters: SearchApiControllerSearchLiveStreamsRequest, initOverrides?: RequestInit): Promise<LiveStreamsSearchResponse> {
        const response = await this.searchApiControllerSearchLiveStreamsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchLiveStreamsActiveViewersRaw(requestParameters: SearchApiControllerSearchLiveStreamsActiveViewersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfileSearchResponse>> {
        if (requestParameters.liveStreamId === null || requestParameters.liveStreamId === undefined) {
            throw new runtime.RequiredError('liveStreamId','Required parameter requestParameters.liveStreamId was null or undefined when calling searchApiControllerSearchLiveStreamsActiveViewers.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchLiveStreamsActiveViewers.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchLiveStreamsActiveViewers.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/live-streams/{liveStreamId}/active-viewers:search`.replace(`{${"liveStreamId"}}`, encodeURIComponent(String(requestParameters.liveStreamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchLiveStreamsActiveViewers(requestParameters: SearchApiControllerSearchLiveStreamsActiveViewersRequest, initOverrides?: RequestInit): Promise<ProfileSearchResponse> {
        const response = await this.searchApiControllerSearchLiveStreamsActiveViewersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchLiveStreamsListingsRaw(requestParameters: SearchApiControllerSearchLiveStreamsListingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StoreListingSearchResponse>> {
        if (requestParameters.liveStreamId === null || requestParameters.liveStreamId === undefined) {
            throw new runtime.RequiredError('liveStreamId','Required parameter requestParameters.liveStreamId was null or undefined when calling searchApiControllerSearchLiveStreamsListings.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchLiveStreamsListings.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchLiveStreamsListings.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/live-streams/{liveStreamId}/listings:search`.replace(`{${"liveStreamId"}}`, encodeURIComponent(String(requestParameters.liveStreamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchLiveStreamsListings(requestParameters: SearchApiControllerSearchLiveStreamsListingsRequest, initOverrides?: RequestInit): Promise<StoreListingSearchResponse> {
        const response = await this.searchApiControllerSearchLiveStreamsListingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchLiveStreamsViewersRaw(requestParameters: SearchApiControllerSearchLiveStreamsViewersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LiveStreamsViewersSearchResponse>> {
        if (requestParameters.liveStreamId === null || requestParameters.liveStreamId === undefined) {
            throw new runtime.RequiredError('liveStreamId','Required parameter requestParameters.liveStreamId was null or undefined when calling searchApiControllerSearchLiveStreamsViewers.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchLiveStreamsViewers.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchLiveStreamsViewers.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.isWatching !== undefined) {
            queryParameters['isWatching'] = requestParameters.isWatching;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/live-streams/{liveStreamId}/viewers:search`.replace(`{${"liveStreamId"}}`, encodeURIComponent(String(requestParameters.liveStreamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchLiveStreamsViewers(requestParameters: SearchApiControllerSearchLiveStreamsViewersRequest, initOverrides?: RequestInit): Promise<LiveStreamsViewersSearchResponse> {
        const response = await this.searchApiControllerSearchLiveStreamsViewersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchMailsRaw(requestParameters: SearchApiControllerSearchMailsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MailsSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchMails.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchMails.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.filterBy !== undefined) {
            queryParameters['filterBy'] = requestParameters.filterBy;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/mails:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchMails(requestParameters: SearchApiControllerSearchMailsRequest, initOverrides?: RequestInit): Promise<MailsSearchResponse> {
        const response = await this.searchApiControllerSearchMailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * for admin page
     */
    async searchApiControllerSearchMessagesRaw(requestParameters: SearchApiControllerSearchMessagesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MessagesSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchMessages.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchMessages.');
        }

        if (requestParameters.filterBy === null || requestParameters.filterBy === undefined) {
            throw new runtime.RequiredError('filterBy','Required parameter requestParameters.filterBy was null or undefined when calling searchApiControllerSearchMessages.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.available !== undefined) {
            queryParameters['available'] = requestParameters.available;
        }

        if (requestParameters.userIdTo !== undefined) {
            queryParameters['userIdTo'] = requestParameters.userIdTo;
        }

        if (requestParameters.userIdFrom !== undefined) {
            queryParameters['userIdFrom'] = requestParameters.userIdFrom;
        }

        if (requestParameters.sellerId !== undefined) {
            queryParameters['sellerId'] = requestParameters.sellerId;
        }

        if (requestParameters.buyerId !== undefined) {
            queryParameters['buyerId'] = requestParameters.buyerId;
        }

        if (requestParameters.listingId !== undefined) {
            queryParameters['listingId'] = requestParameters.listingId;
        }

        if (requestParameters.orderId !== undefined) {
            queryParameters['orderId'] = requestParameters.orderId;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.filterBy !== undefined) {
            queryParameters['filterBy'] = requestParameters.filterBy;
        }

        if (requestParameters.reportFilterBy !== undefined) {
            queryParameters['reportFilterBy'] = requestParameters.reportFilterBy;
        }

        if (requestParameters.purpose !== undefined) {
            queryParameters['purpose'] = requestParameters.purpose;
        }

        if (requestParameters.thankYouType) {
            queryParameters['thankYouType'] = requestParameters.thankYouType;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/messages:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * for admin page
     */
    async searchApiControllerSearchMessages(requestParameters: SearchApiControllerSearchMessagesRequest, initOverrides?: RequestInit): Promise<MessagesSearchResponse> {
        const response = await this.searchApiControllerSearchMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchMessagesReportsRaw(requestParameters: SearchApiControllerSearchMessagesReportsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MessageReportSearchResponse>> {
        if (requestParameters.messageId === null || requestParameters.messageId === undefined) {
            throw new runtime.RequiredError('messageId','Required parameter requestParameters.messageId was null or undefined when calling searchApiControllerSearchMessagesReports.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchMessagesReports.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchMessagesReports.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.reason) {
            queryParameters['reason'] = requestParameters.reason;
        }

        if (requestParameters.resolveStatus) {
            queryParameters['resolveStatus'] = requestParameters.resolveStatus;
        }

        if (requestParameters.reporterId !== undefined) {
            queryParameters['reporterId'] = requestParameters.reporterId;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/messages/{messageId}/reports:search`.replace(`{${"messageId"}}`, encodeURIComponent(String(requestParameters.messageId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchMessagesReports(requestParameters: SearchApiControllerSearchMessagesReportsRequest, initOverrides?: RequestInit): Promise<MessageReportSearchResponse> {
        const response = await this.searchApiControllerSearchMessagesReportsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchMyOrdersRaw(requestParameters: SearchApiControllerSearchMyOrdersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrdersSearchResponse>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling searchApiControllerSearchMyOrders.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchMyOrders.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchMyOrders.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.masterOrderId) {
            queryParameters['masterOrderId'] = requestParameters.masterOrderId;
        }

        if (requestParameters.orderNumber !== undefined) {
            queryParameters['orderNumber'] = requestParameters.orderNumber;
        }

        if (requestParameters.liveStreamId !== undefined) {
            queryParameters['liveStreamId'] = requestParameters.liveStreamId;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.pickupStatus) {
            queryParameters['pickupStatus'] = requestParameters.pickupStatus;
        }

        if (requestParameters.groupStatusMaster !== undefined) {
            queryParameters['groupStatusMaster'] = requestParameters.groupStatusMaster;
        }

        if (requestParameters.groupStatus) {
            queryParameters['groupStatus'] = requestParameters.groupStatus;
        }

        if (requestParameters.packed !== undefined) {
            queryParameters['packed'] = requestParameters.packed;
        }

        if (requestParameters.allOrders !== undefined) {
            queryParameters['allOrders'] = requestParameters.allOrders;
        }

        if (requestParameters.shippingMethod) {
            queryParameters['shippingMethod'] = requestParameters.shippingMethod;
        }

        if (requestParameters.createdBefore !== undefined) {
            queryParameters['createdBefore'] = requestParameters.createdBefore;
        }

        if (requestParameters.createdAfter !== undefined) {
            queryParameters['createdAfter'] = requestParameters.createdAfter;
        }

        if (requestParameters.couponCode !== undefined) {
            queryParameters['couponCode'] = requestParameters.couponCode;
        }

        if (requestParameters.aggregateTax !== undefined) {
            queryParameters['aggregateTax'] = requestParameters.aggregateTax;
        }

        if (requestParameters.noLiveStreamId !== undefined) {
            queryParameters['noLiveStreamId'] = requestParameters.noLiveStreamId;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{sellerId}/orders:search`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchMyOrders(requestParameters: SearchApiControllerSearchMyOrdersRequest, initOverrides?: RequestInit): Promise<OrdersSearchResponse> {
        const response = await this.searchApiControllerSearchMyOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchMyStoreListingsRaw(requestParameters: SearchApiControllerSearchMyStoreListingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MyStoreListingSearchResponse>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling searchApiControllerSearchMyStoreListings.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchMyStoreListings.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchMyStoreListings.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.filterOutLiveListings !== undefined) {
            queryParameters['filterOutLiveListings'] = requestParameters.filterOutLiveListings;
        }

        if (requestParameters.loadLiveStatus !== undefined) {
            queryParameters['loadLiveStatus'] = requestParameters.loadLiveStatus;
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.soldOut !== undefined) {
            queryParameters['soldOut'] = requestParameters.soldOut;
        }

        if (requestParameters.minPrice !== undefined) {
            queryParameters['minPrice'] = requestParameters.minPrice;
        }

        if (requestParameters.maxPrice !== undefined) {
            queryParameters['maxPrice'] = requestParameters.maxPrice;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/listings:search`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchMyStoreListings(requestParameters: SearchApiControllerSearchMyStoreListingsRequest, initOverrides?: RequestInit): Promise<MyStoreListingSearchResponse> {
        const response = await this.searchApiControllerSearchMyStoreListingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchMyStorePublicListingsRaw(requestParameters: SearchApiControllerSearchMyStorePublicListingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StoreListingSearchResponse>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling searchApiControllerSearchMyStorePublicListings.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchMyStorePublicListings.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchMyStorePublicListings.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/public-listings:search`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchMyStorePublicListings(requestParameters: SearchApiControllerSearchMyStorePublicListingsRequest, initOverrides?: RequestInit): Promise<StoreListingSearchResponse> {
        const response = await this.searchApiControllerSearchMyStorePublicListingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchOrderTransactionsRaw(requestParameters: SearchApiControllerSearchOrderTransactionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderTransactionSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchOrderTransactions.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchOrderTransactions.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.orderId !== undefined) {
            queryParameters['orderId'] = requestParameters.orderId;
        }

        if (requestParameters.orderNumber !== undefined) {
            queryParameters['orderNumber'] = requestParameters.orderNumber;
        }

        if (requestParameters.sellerId !== undefined) {
            queryParameters['sellerId'] = requestParameters.sellerId;
        }

        if (requestParameters.buyerId !== undefined) {
            queryParameters['buyerId'] = requestParameters.buyerId;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/order-transactions:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchOrderTransactions(requestParameters: SearchApiControllerSearchOrderTransactionsRequest, initOverrides?: RequestInit): Promise<OrderTransactionSearchResponse> {
        const response = await this.searchApiControllerSearchOrderTransactionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchOrdersRaw(requestParameters: SearchApiControllerSearchOrdersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrdersSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchOrders.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchOrders.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.masterOrderId) {
            queryParameters['masterOrderId'] = requestParameters.masterOrderId;
        }

        if (requestParameters.orderNumber !== undefined) {
            queryParameters['orderNumber'] = requestParameters.orderNumber;
        }

        if (requestParameters.liveStreamId !== undefined) {
            queryParameters['liveStreamId'] = requestParameters.liveStreamId;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.pickupStatus) {
            queryParameters['pickupStatus'] = requestParameters.pickupStatus;
        }

        if (requestParameters.groupStatusMaster !== undefined) {
            queryParameters['groupStatusMaster'] = requestParameters.groupStatusMaster;
        }

        if (requestParameters.groupStatus) {
            queryParameters['groupStatus'] = requestParameters.groupStatus;
        }

        if (requestParameters.packed !== undefined) {
            queryParameters['packed'] = requestParameters.packed;
        }

        if (requestParameters.allOrders !== undefined) {
            queryParameters['allOrders'] = requestParameters.allOrders;
        }

        if (requestParameters.shippingMethod) {
            queryParameters['shippingMethod'] = requestParameters.shippingMethod;
        }

        if (requestParameters.createdBefore !== undefined) {
            queryParameters['createdBefore'] = requestParameters.createdBefore;
        }

        if (requestParameters.createdAfter !== undefined) {
            queryParameters['createdAfter'] = requestParameters.createdAfter;
        }

        if (requestParameters.couponCode !== undefined) {
            queryParameters['couponCode'] = requestParameters.couponCode;
        }

        if (requestParameters.aggregateTax !== undefined) {
            queryParameters['aggregateTax'] = requestParameters.aggregateTax;
        }

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.listingId !== undefined) {
            queryParameters['listingId'] = requestParameters.listingId;
        }

        if (requestParameters.sellerId !== undefined) {
            queryParameters['sellerId'] = requestParameters.sellerId;
        }

        if (requestParameters.buyerId !== undefined) {
            queryParameters['buyerId'] = requestParameters.buyerId;
        }

        if (requestParameters.shippingLabelFailed !== undefined) {
            queryParameters['shippingLabelFailed'] = requestParameters.shippingLabelFailed;
        }

        if (requestParameters.filterBy !== undefined) {
            queryParameters['filterBy'] = requestParameters.filterBy;
        }

        if (requestParameters.giftingStatus) {
            queryParameters['giftingStatus'] = requestParameters.giftingStatus;
        }

        if (requestParameters.isGiftPackage !== undefined) {
            queryParameters['isGiftPackage'] = requestParameters.isGiftPackage;
        }

        if (requestParameters.sourceType !== undefined) {
            queryParameters['sourceType'] = requestParameters.sourceType;
        }

        if (requestParameters.hasShipmentParcel !== undefined) {
            queryParameters['hasShipmentParcel'] = requestParameters.hasShipmentParcel;
        }

        if (requestParameters.shipmentOriginalParcelSource) {
            queryParameters['shipmentOriginalParcelSource'] = requestParameters.shipmentOriginalParcelSource;
        }

        if (requestParameters.sourceId !== undefined) {
            queryParameters['sourceId'] = requestParameters.sourceId;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchOrders(requestParameters: SearchApiControllerSearchOrdersRequest, initOverrides?: RequestInit): Promise<OrdersSearchResponse> {
        const response = await this.searchApiControllerSearchOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchPrivateProfilesPayoutHistoryRaw(requestParameters: SearchApiControllerSearchPrivateProfilesPayoutHistoryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PrivateProfilesPayoutHistorySearchResponse>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling searchApiControllerSearchPrivateProfilesPayoutHistory.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchPrivateProfilesPayoutHistory.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchPrivateProfilesPayoutHistory.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.sourceType) {
            queryParameters['sourceType'] = requestParameters.sourceType;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.operatorId !== undefined) {
            queryParameters['operatorId'] = requestParameters.operatorId;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{sellerId}/payout-history:search`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchPrivateProfilesPayoutHistory(requestParameters: SearchApiControllerSearchPrivateProfilesPayoutHistoryRequest, initOverrides?: RequestInit): Promise<PrivateProfilesPayoutHistorySearchResponse> {
        const response = await this.searchApiControllerSearchPrivateProfilesPayoutHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchProfilesRaw(requestParameters: SearchApiControllerSearchProfilesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfileSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchProfiles.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchProfiles.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.filterBy !== undefined) {
            queryParameters['filterBy'] = requestParameters.filterBy;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.userType) {
            queryParameters['userType'] = requestParameters.userType;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/profiles:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchProfiles(requestParameters: SearchApiControllerSearchProfilesRequest, initOverrides?: RequestInit): Promise<ProfileSearchResponse> {
        const response = await this.searchApiControllerSearchProfilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchProfilesFollowersRaw(requestParameters: SearchApiControllerSearchProfilesFollowersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfilesFollowersSearchResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling searchApiControllerSearchProfilesFollowers.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchProfilesFollowers.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchProfilesFollowers.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/profiles/{userId}/followers:search`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchProfilesFollowers(requestParameters: SearchApiControllerSearchProfilesFollowersRequest, initOverrides?: RequestInit): Promise<ProfilesFollowersSearchResponse> {
        const response = await this.searchApiControllerSearchProfilesFollowersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchProfilesFollowingsRaw(requestParameters: SearchApiControllerSearchProfilesFollowingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfilesFollowingsSearchResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling searchApiControllerSearchProfilesFollowings.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchProfilesFollowings.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchProfilesFollowings.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.userType) {
            queryParameters['userType'] = requestParameters.userType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/profiles/{userId}/followings:search`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchProfilesFollowings(requestParameters: SearchApiControllerSearchProfilesFollowingsRequest, initOverrides?: RequestInit): Promise<ProfilesFollowingsSearchResponse> {
        const response = await this.searchApiControllerSearchProfilesFollowingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchPurchasedStoresRaw(requestParameters: SearchApiControllerSearchPurchasedStoresRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PurchasedStoresResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling searchApiControllerSearchPurchasedStores.');
        }

        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{userId}/purchased-stores:search`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchPurchasedStores(requestParameters: SearchApiControllerSearchPurchasedStoresRequest, initOverrides?: RequestInit): Promise<PurchasedStoresResponse> {
        const response = await this.searchApiControllerSearchPurchasedStoresRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * search public reviews by user in app
     */
    async searchApiControllerSearchReviewsByUserRaw(requestParameters: SearchApiControllerSearchReviewsByUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MessagesSearchResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling searchApiControllerSearchReviewsByUser.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchReviewsByUser.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchReviewsByUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{userId}/reviews:search`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * search public reviews by user in app
     */
    async searchApiControllerSearchReviewsByUser(requestParameters: SearchApiControllerSearchReviewsByUserRequest, initOverrides?: RequestInit): Promise<MessagesSearchResponse> {
        const response = await this.searchApiControllerSearchReviewsByUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * search public reviews to seller in app
     */
    async searchApiControllerSearchReviewsToSellerRaw(requestParameters: SearchApiControllerSearchReviewsToSellerRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MessagesSearchResponse>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling searchApiControllerSearchReviewsToSeller.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchReviewsToSeller.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchReviewsToSeller.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/reviews:search`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * search public reviews to seller in app
     */
    async searchApiControllerSearchReviewsToSeller(requestParameters: SearchApiControllerSearchReviewsToSellerRequest, initOverrides?: RequestInit): Promise<MessagesSearchResponse> {
        const response = await this.searchApiControllerSearchReviewsToSellerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchRolesRaw(requestParameters: SearchApiControllerSearchRolesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RolesSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchRoles.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchRoles.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.uid !== undefined) {
            queryParameters['uid'] = requestParameters.uid;
        }

        if (requestParameters.isAdmin !== undefined) {
            queryParameters['isAdmin'] = requestParameters.isAdmin;
        }

        if (requestParameters.roles) {
            queryParameters['roles'] = requestParameters.roles;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/roles:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchRoles(requestParameters: SearchApiControllerSearchRolesRequest, initOverrides?: RequestInit): Promise<RolesSearchResponse> {
        const response = await this.searchApiControllerSearchRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchSellerIncentivePayoutsRaw(requestParameters: SearchApiControllerSearchSellerIncentivePayoutsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StoreIncentivePayoutSearchResponse>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling searchApiControllerSearchSellerIncentivePayouts.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchSellerIncentivePayouts.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchSellerIncentivePayouts.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.minCreatedAt !== undefined) {
            queryParameters['minCreatedAt'] = requestParameters.minCreatedAt;
        }

        if (requestParameters.maxCreatedAt !== undefined) {
            queryParameters['maxCreatedAt'] = requestParameters.maxCreatedAt;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/incentive-payouts:search`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchSellerIncentivePayouts(requestParameters: SearchApiControllerSearchSellerIncentivePayoutsRequest, initOverrides?: RequestInit): Promise<StoreIncentivePayoutSearchResponse> {
        const response = await this.searchApiControllerSearchSellerIncentivePayoutsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchSellerLiveStreamsStatsRaw(requestParameters: SearchApiControllerSearchSellerLiveStreamsStatsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SearchSellerLiveStreamsStatsResponse>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling searchApiControllerSearchSellerLiveStreamsStats.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchSellerLiveStreamsStats.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchSellerLiveStreamsStats.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.liveStreamId !== undefined) {
            queryParameters['liveStreamId'] = requestParameters.liveStreamId;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/live-stream-stats:search`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchSellerLiveStreamsStats(requestParameters: SearchApiControllerSearchSellerLiveStreamsStatsRequest, initOverrides?: RequestInit): Promise<SearchSellerLiveStreamsStatsResponse> {
        const response = await this.searchApiControllerSearchSellerLiveStreamsStatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * for seller dashboard page or seller in app
     */
    async searchApiControllerSearchSellerMessagesRaw(requestParameters: SearchApiControllerSearchSellerMessagesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MessagesSearchResponse>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling searchApiControllerSearchSellerMessages.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchSellerMessages.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchSellerMessages.');
        }

        if (requestParameters.filterBy === null || requestParameters.filterBy === undefined) {
            throw new runtime.RequiredError('filterBy','Required parameter requestParameters.filterBy was null or undefined when calling searchApiControllerSearchSellerMessages.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.available !== undefined) {
            queryParameters['available'] = requestParameters.available;
        }

        if (requestParameters.userIdTo !== undefined) {
            queryParameters['userIdTo'] = requestParameters.userIdTo;
        }

        if (requestParameters.userIdFrom !== undefined) {
            queryParameters['userIdFrom'] = requestParameters.userIdFrom;
        }

        if (requestParameters.sellerId2 !== undefined) {
            queryParameters['sellerId'] = requestParameters.sellerId2;
        }

        if (requestParameters.buyerId !== undefined) {
            queryParameters['buyerId'] = requestParameters.buyerId;
        }

        if (requestParameters.listingId !== undefined) {
            queryParameters['listingId'] = requestParameters.listingId;
        }

        if (requestParameters.orderId !== undefined) {
            queryParameters['orderId'] = requestParameters.orderId;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.filterBy !== undefined) {
            queryParameters['filterBy'] = requestParameters.filterBy;
        }

        if (requestParameters.reportFilterBy !== undefined) {
            queryParameters['reportFilterBy'] = requestParameters.reportFilterBy;
        }

        if (requestParameters.purpose !== undefined) {
            queryParameters['purpose'] = requestParameters.purpose;
        }

        if (requestParameters.thankYouType) {
            queryParameters['thankYouType'] = requestParameters.thankYouType;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/messages:search`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * for seller dashboard page or seller in app
     */
    async searchApiControllerSearchSellerMessages(requestParameters: SearchApiControllerSearchSellerMessagesRequest, initOverrides?: RequestInit): Promise<MessagesSearchResponse> {
        const response = await this.searchApiControllerSearchSellerMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchSellerOrderDetailsRaw(requestParameters: SearchApiControllerSearchSellerOrderDetailsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrderDetailsSearchResponse>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling searchApiControllerSearchSellerOrderDetails.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchSellerOrderDetails.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchSellerOrderDetails.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.masterOrderId) {
            queryParameters['masterOrderId'] = requestParameters.masterOrderId;
        }

        if (requestParameters.orderNumber !== undefined) {
            queryParameters['orderNumber'] = requestParameters.orderNumber;
        }

        if (requestParameters.liveStreamId !== undefined) {
            queryParameters['liveStreamId'] = requestParameters.liveStreamId;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.pickupStatus) {
            queryParameters['pickupStatus'] = requestParameters.pickupStatus;
        }

        if (requestParameters.groupStatusMaster !== undefined) {
            queryParameters['groupStatusMaster'] = requestParameters.groupStatusMaster;
        }

        if (requestParameters.groupStatus) {
            queryParameters['groupStatus'] = requestParameters.groupStatus;
        }

        if (requestParameters.packed !== undefined) {
            queryParameters['packed'] = requestParameters.packed;
        }

        if (requestParameters.allOrders !== undefined) {
            queryParameters['allOrders'] = requestParameters.allOrders;
        }

        if (requestParameters.shippingMethod) {
            queryParameters['shippingMethod'] = requestParameters.shippingMethod;
        }

        if (requestParameters.createdBefore !== undefined) {
            queryParameters['createdBefore'] = requestParameters.createdBefore;
        }

        if (requestParameters.createdAfter !== undefined) {
            queryParameters['createdAfter'] = requestParameters.createdAfter;
        }

        if (requestParameters.couponCode !== undefined) {
            queryParameters['couponCode'] = requestParameters.couponCode;
        }

        if (requestParameters.aggregateTax !== undefined) {
            queryParameters['aggregateTax'] = requestParameters.aggregateTax;
        }

        if (requestParameters.showTitle !== undefined) {
            queryParameters['showTitle'] = requestParameters.showTitle;
        }

        if (requestParameters.noLiveStreamId !== undefined) {
            queryParameters['noLiveStreamId'] = requestParameters.noLiveStreamId;
        }

        if (requestParameters.mainOrderNumber !== undefined) {
            queryParameters['mainOrderNumber'] = requestParameters.mainOrderNumber;
        }

        if (requestParameters.mainStatus) {
            queryParameters['mainStatus'] = requestParameters.mainStatus;
        }

        if (requestParameters.mainPacked !== undefined) {
            queryParameters['mainPacked'] = requestParameters.mainPacked;
        }

        if (requestParameters.showCreatedBefore !== undefined) {
            queryParameters['showCreatedBefore'] = requestParameters.showCreatedBefore;
        }

        if (requestParameters.showCreatedAfter !== undefined) {
            queryParameters['showCreatedAfter'] = requestParameters.showCreatedAfter;
        }

        if (requestParameters.shippingLabelStatus) {
            queryParameters['shippingLabelStatus'] = requestParameters.shippingLabelStatus;
        }

        if (requestParameters.mainShippingLabelStatus) {
            queryParameters['mainShippingLabelStatus'] = requestParameters.mainShippingLabelStatus;
        }

        if (requestParameters.shippingStatus !== undefined) {
            queryParameters['shippingStatus'] = requestParameters.shippingStatus;
        }

        if (requestParameters.mainShippingStatus !== undefined) {
            queryParameters['mainShippingStatus'] = requestParameters.mainShippingStatus;
        }

        if (requestParameters.filterLabelStatus !== undefined) {
            queryParameters['filterLabelStatus'] = requestParameters.filterLabelStatus;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/order-details:search`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchSellerOrderDetails(requestParameters: SearchApiControllerSearchSellerOrderDetailsRequest, initOverrides?: RequestInit): Promise<OrderDetailsSearchResponse> {
        const response = await this.searchApiControllerSearchSellerOrderDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchSellerOrdersRaw(requestParameters: SearchApiControllerSearchSellerOrdersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrdersSearchResponse>> {
        if (requestParameters.storeId === null || requestParameters.storeId === undefined) {
            throw new runtime.RequiredError('storeId','Required parameter requestParameters.storeId was null or undefined when calling searchApiControllerSearchSellerOrders.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchSellerOrders.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchSellerOrders.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.masterOrderId) {
            queryParameters['masterOrderId'] = requestParameters.masterOrderId;
        }

        if (requestParameters.orderNumber !== undefined) {
            queryParameters['orderNumber'] = requestParameters.orderNumber;
        }

        if (requestParameters.liveStreamId !== undefined) {
            queryParameters['liveStreamId'] = requestParameters.liveStreamId;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.pickupStatus) {
            queryParameters['pickupStatus'] = requestParameters.pickupStatus;
        }

        if (requestParameters.groupStatusMaster !== undefined) {
            queryParameters['groupStatusMaster'] = requestParameters.groupStatusMaster;
        }

        if (requestParameters.groupStatus) {
            queryParameters['groupStatus'] = requestParameters.groupStatus;
        }

        if (requestParameters.packed !== undefined) {
            queryParameters['packed'] = requestParameters.packed;
        }

        if (requestParameters.allOrders !== undefined) {
            queryParameters['allOrders'] = requestParameters.allOrders;
        }

        if (requestParameters.shippingMethod) {
            queryParameters['shippingMethod'] = requestParameters.shippingMethod;
        }

        if (requestParameters.createdBefore !== undefined) {
            queryParameters['createdBefore'] = requestParameters.createdBefore;
        }

        if (requestParameters.createdAfter !== undefined) {
            queryParameters['createdAfter'] = requestParameters.createdAfter;
        }

        if (requestParameters.couponCode !== undefined) {
            queryParameters['couponCode'] = requestParameters.couponCode;
        }

        if (requestParameters.aggregateTax !== undefined) {
            queryParameters['aggregateTax'] = requestParameters.aggregateTax;
        }

        if (requestParameters.noLiveStreamId !== undefined) {
            queryParameters['noLiveStreamId'] = requestParameters.noLiveStreamId;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{storeId}/orders:search`.replace(`{${"storeId"}}`, encodeURIComponent(String(requestParameters.storeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchSellerOrders(requestParameters: SearchApiControllerSearchSellerOrdersRequest, initOverrides?: RequestInit): Promise<OrdersSearchResponse> {
        const response = await this.searchApiControllerSearchSellerOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchSellerStatusRaw(requestParameters: SearchApiControllerSearchSellerStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserDetailSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchSellerStatus.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchSellerStatus.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/seller-stats:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchSellerStatus(requestParameters: SearchApiControllerSearchSellerStatusRequest, initOverrides?: RequestInit): Promise<UserDetailSearchResponse> {
        const response = await this.searchApiControllerSearchSellerStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchSellerViewersRaw(requestParameters: SearchApiControllerSearchSellerViewersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SearchSellerViewersResponse>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling searchApiControllerSearchSellerViewers.');
        }

        if (requestParameters.searchSellerViewersParams === null || requestParameters.searchSellerViewersParams === undefined) {
            throw new runtime.RequiredError('searchSellerViewersParams','Required parameter requestParameters.searchSellerViewersParams was null or undefined when calling searchApiControllerSearchSellerViewers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/user-stats:search`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.searchSellerViewersParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchSellerViewers(requestParameters: SearchApiControllerSearchSellerViewersRequest, initOverrides?: RequestInit): Promise<SearchSellerViewersResponse> {
        const response = await this.searchApiControllerSearchSellerViewersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchShowEventFeaturedStoresRaw(requestParameters: SearchApiControllerSearchShowEventFeaturedStoresRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FeaturedStoresSearchResponse>> {
        if (requestParameters.showEventId === null || requestParameters.showEventId === undefined) {
            throw new runtime.RequiredError('showEventId','Required parameter requestParameters.showEventId was null or undefined when calling searchApiControllerSearchShowEventFeaturedStores.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchShowEventFeaturedStores.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchShowEventFeaturedStores.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/show-events/{showEventId}/featured-stores:search`.replace(`{${"showEventId"}}`, encodeURIComponent(String(requestParameters.showEventId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchShowEventFeaturedStores(requestParameters: SearchApiControllerSearchShowEventFeaturedStoresRequest, initOverrides?: RequestInit): Promise<FeaturedStoresSearchResponse> {
        const response = await this.searchApiControllerSearchShowEventFeaturedStoresRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchShowEventsRaw(requestParameters: SearchApiControllerSearchShowEventsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ShowEventsSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchShowEvents.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchShowEvents.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.sellerIds) {
            queryParameters['sellerIds'] = requestParameters.sellerIds;
        }

        if (requestParameters.sellerUserGroupIds) {
            queryParameters['sellerUserGroupIds'] = requestParameters.sellerUserGroupIds;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.isClosed !== undefined) {
            queryParameters['isClosed'] = requestParameters.isClosed;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/show-events:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchShowEvents(requestParameters: SearchApiControllerSearchShowEventsRequest, initOverrides?: RequestInit): Promise<ShowEventsSearchResponse> {
        const response = await this.searchApiControllerSearchShowEventsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchShowEventsFeaturedShowsRaw(requestParameters: SearchApiControllerSearchShowEventsFeaturedShowsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ShowEventsFeaturedShowSearchResponse>> {
        if (requestParameters.showEventId === null || requestParameters.showEventId === undefined) {
            throw new runtime.RequiredError('showEventId','Required parameter requestParameters.showEventId was null or undefined when calling searchApiControllerSearchShowEventsFeaturedShows.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchShowEventsFeaturedShows.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchShowEventsFeaturedShows.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.filterOutExpiredUpcomingShows !== undefined) {
            queryParameters['filterOutExpiredUpcomingShows'] = requestParameters.filterOutExpiredUpcomingShows;
        }

        if (requestParameters.filterOutLiveLiveStreams !== undefined) {
            queryParameters['filterOutLiveLiveStreams'] = requestParameters.filterOutLiveLiveStreams;
        }

        if (requestParameters.minSortedAt !== undefined) {
            queryParameters['minSortedAt'] = requestParameters.minSortedAt;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/show-events/{showEventId}/featured-shows:search`.replace(`{${"showEventId"}}`, encodeURIComponent(String(requestParameters.showEventId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchShowEventsFeaturedShows(requestParameters: SearchApiControllerSearchShowEventsFeaturedShowsRequest, initOverrides?: RequestInit): Promise<ShowEventsFeaturedShowSearchResponse> {
        const response = await this.searchApiControllerSearchShowEventsFeaturedShowsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchShowEventsTicketsRaw(requestParameters: SearchApiControllerSearchShowEventsTicketsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ShowTicketsSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchShowEventsTickets.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchShowEventsTickets.');
        }

        if (requestParameters.eventId === null || requestParameters.eventId === undefined) {
            throw new runtime.RequiredError('eventId','Required parameter requestParameters.eventId was null or undefined when calling searchApiControllerSearchShowEventsTickets.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.isActive !== undefined) {
            queryParameters['isActive'] = requestParameters.isActive;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/show-events/{eventId}/tickets:search`.replace(`{${"eventId"}}`, encodeURIComponent(String(requestParameters.eventId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchShowEventsTickets(requestParameters: SearchApiControllerSearchShowEventsTicketsRequest, initOverrides?: RequestInit): Promise<ShowTicketsSearchResponse> {
        const response = await this.searchApiControllerSearchShowEventsTicketsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchStoreLiveStreamsRaw(requestParameters: SearchApiControllerSearchStoreLiveStreamsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LiveStreamsSearchResponse>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling searchApiControllerSearchStoreLiveStreams.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchStoreLiveStreams.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchStoreLiveStreams.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.eventId !== undefined) {
            queryParameters['eventId'] = requestParameters.eventId;
        }

        if (requestParameters.title !== undefined) {
            queryParameters['title'] = requestParameters.title;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.storeName !== undefined) {
            queryParameters['storeName'] = requestParameters.storeName;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.permission) {
            queryParameters['permission'] = requestParameters.permission;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.liveOrEnded !== undefined) {
            queryParameters['liveOrEnded'] = requestParameters.liveOrEnded;
        }

        if (requestParameters.minCreatedAt !== undefined) {
            queryParameters['minCreatedAt'] = requestParameters.minCreatedAt;
        }

        if (requestParameters.maxCreatedAt !== undefined) {
            queryParameters['maxCreatedAt'] = requestParameters.maxCreatedAt;
        }

        if (requestParameters.shippingMode) {
            queryParameters['shippingMode'] = requestParameters.shippingMode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/live-streams:search`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchStoreLiveStreams(requestParameters: SearchApiControllerSearchStoreLiveStreamsRequest, initOverrides?: RequestInit): Promise<LiveStreamsSearchResponse> {
        const response = await this.searchApiControllerSearchStoreLiveStreamsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchStoresRaw(requestParameters: SearchApiControllerSearchStoresRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<StoresSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchStores.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchStores.');
        }

        if (requestParameters.filterBy === null || requestParameters.filterBy === undefined) {
            throw new runtime.RequiredError('filterBy','Required parameter requestParameters.filterBy was null or undefined when calling searchApiControllerSearchStores.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.filterBy !== undefined) {
            queryParameters['filterBy'] = requestParameters.filterBy;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/stores:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchStores(requestParameters: SearchApiControllerSearchStoresRequest, initOverrides?: RequestInit): Promise<StoresSearchResponse> {
        const response = await this.searchApiControllerSearchStoresRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchUpcomingShowsRaw(requestParameters: SearchApiControllerSearchUpcomingShowsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UpcomingShowSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchUpcomingShows.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchUpcomingShows.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.storeName !== undefined) {
            queryParameters['storeName'] = requestParameters.storeName;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.available !== undefined) {
            queryParameters['available'] = requestParameters.available;
        }

        if (requestParameters.noEventId !== undefined) {
            queryParameters['noEventId'] = requestParameters.noEventId;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/upcoming-shows:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchUpcomingShows(requestParameters: SearchApiControllerSearchUpcomingShowsRequest, initOverrides?: RequestInit): Promise<UpcomingShowSearchResponse> {
        const response = await this.searchApiControllerSearchUpcomingShowsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchUpcomingShowsWatchersRaw(requestParameters: SearchApiControllerSearchUpcomingShowsWatchersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UpcomingShowsWatchersSearchResponse>> {
        if (requestParameters.upcomingShowId === null || requestParameters.upcomingShowId === undefined) {
            throw new runtime.RequiredError('upcomingShowId','Required parameter requestParameters.upcomingShowId was null or undefined when calling searchApiControllerSearchUpcomingShowsWatchers.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchUpcomingShowsWatchers.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchUpcomingShowsWatchers.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.isFollower !== undefined) {
            queryParameters['isFollower'] = requestParameters.isFollower;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/upcoming-shows/{upcomingShowId}/watchers:search`.replace(`{${"upcomingShowId"}}`, encodeURIComponent(String(requestParameters.upcomingShowId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchUpcomingShowsWatchers(requestParameters: SearchApiControllerSearchUpcomingShowsWatchersRequest, initOverrides?: RequestInit): Promise<UpcomingShowsWatchersSearchResponse> {
        const response = await this.searchApiControllerSearchUpcomingShowsWatchersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchUserDetailsRaw(requestParameters: SearchApiControllerSearchUserDetailsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserDetailSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchUserDetails.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchUserDetails.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.filterBy !== undefined) {
            queryParameters['filterBy'] = requestParameters.filterBy;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.userType) {
            queryParameters['userType'] = requestParameters.userType;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/user-details:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchUserDetails(requestParameters: SearchApiControllerSearchUserDetailsRequest, initOverrides?: RequestInit): Promise<UserDetailSearchResponse> {
        const response = await this.searchApiControllerSearchUserDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchUserGroupsRaw(requestParameters: SearchApiControllerSearchUserGroupsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserGroupsSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchUserGroups.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchUserGroups.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/user-groups:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchUserGroups(requestParameters: SearchApiControllerSearchUserGroupsRequest, initOverrides?: RequestInit): Promise<UserGroupsSearchResponse> {
        const response = await this.searchApiControllerSearchUserGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchUserGroupsUsersRaw(requestParameters: SearchApiControllerSearchUserGroupsUsersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfileSearchResponse>> {
        if (requestParameters.userGroupId === null || requestParameters.userGroupId === undefined) {
            throw new runtime.RequiredError('userGroupId','Required parameter requestParameters.userGroupId was null or undefined when calling searchApiControllerSearchUserGroupsUsers.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchUserGroupsUsers.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchUserGroupsUsers.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.userType) {
            queryParameters['userType'] = requestParameters.userType;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/user-groups/{userGroupId}/users:search`.replace(`{${"userGroupId"}}`, encodeURIComponent(String(requestParameters.userGroupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchUserGroupsUsers(requestParameters: SearchApiControllerSearchUserGroupsUsersRequest, initOverrides?: RequestInit): Promise<ProfileSearchResponse> {
        const response = await this.searchApiControllerSearchUserGroupsUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * for user in app
     */
    async searchApiControllerSearchUserMessagesRaw(requestParameters: SearchApiControllerSearchUserMessagesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MessagesSearchResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling searchApiControllerSearchUserMessages.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchUserMessages.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchUserMessages.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling searchApiControllerSearchUserMessages.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.available !== undefined) {
            queryParameters['available'] = requestParameters.available;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{userId}/messages:search`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * for user in app
     */
    async searchApiControllerSearchUserMessages(requestParameters: SearchApiControllerSearchUserMessagesRequest, initOverrides?: RequestInit): Promise<MessagesSearchResponse> {
        const response = await this.searchApiControllerSearchUserMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchUserOrdersRaw(requestParameters: SearchApiControllerSearchUserOrdersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrdersSearchResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling searchApiControllerSearchUserOrders.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchUserOrders.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchUserOrders.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.masterOrderId) {
            queryParameters['masterOrderId'] = requestParameters.masterOrderId;
        }

        if (requestParameters.orderNumber !== undefined) {
            queryParameters['orderNumber'] = requestParameters.orderNumber;
        }

        if (requestParameters.liveStreamId !== undefined) {
            queryParameters['liveStreamId'] = requestParameters.liveStreamId;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.pickupStatus) {
            queryParameters['pickupStatus'] = requestParameters.pickupStatus;
        }

        if (requestParameters.groupStatusMaster !== undefined) {
            queryParameters['groupStatusMaster'] = requestParameters.groupStatusMaster;
        }

        if (requestParameters.groupStatus) {
            queryParameters['groupStatus'] = requestParameters.groupStatus;
        }

        if (requestParameters.packed !== undefined) {
            queryParameters['packed'] = requestParameters.packed;
        }

        if (requestParameters.allOrders !== undefined) {
            queryParameters['allOrders'] = requestParameters.allOrders;
        }

        if (requestParameters.shippingMethod) {
            queryParameters['shippingMethod'] = requestParameters.shippingMethod;
        }

        if (requestParameters.createdBefore !== undefined) {
            queryParameters['createdBefore'] = requestParameters.createdBefore;
        }

        if (requestParameters.createdAfter !== undefined) {
            queryParameters['createdAfter'] = requestParameters.createdAfter;
        }

        if (requestParameters.couponCode !== undefined) {
            queryParameters['couponCode'] = requestParameters.couponCode;
        }

        if (requestParameters.aggregateTax !== undefined) {
            queryParameters['aggregateTax'] = requestParameters.aggregateTax;
        }

        if (requestParameters.noLiveStreamId !== undefined) {
            queryParameters['noLiveStreamId'] = requestParameters.noLiveStreamId;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{userId}/orders:search`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchUserOrders(requestParameters: SearchApiControllerSearchUserOrdersRequest, initOverrides?: RequestInit): Promise<OrdersSearchResponse> {
        const response = await this.searchApiControllerSearchUserOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchUserStatsUserReferralPresentersRaw(requestParameters: SearchApiControllerSearchUserStatsUserReferralPresentersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserReferralsSearchResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling searchApiControllerSearchUserStatsUserReferralPresenters.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/user-stats/{userId}/referral-presenters:search`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchUserStatsUserReferralPresenters(requestParameters: SearchApiControllerSearchUserStatsUserReferralPresentersRequest, initOverrides?: RequestInit): Promise<UserReferralsSearchResponse> {
        const response = await this.searchApiControllerSearchUserStatsUserReferralPresentersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchUserStatsUserReferralsRaw(requestParameters: SearchApiControllerSearchUserStatsUserReferralsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserReferralsSearchResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling searchApiControllerSearchUserStatsUserReferrals.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchUserStatsUserReferrals.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchUserStatsUserReferrals.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.referrerUserType) {
            queryParameters['referrerUserType'] = requestParameters.referrerUserType;
        }

        if (requestParameters.presenteeUserType) {
            queryParameters['presenteeUserType'] = requestParameters.presenteeUserType;
        }

        if (requestParameters.minCreatedAt !== undefined) {
            queryParameters['minCreatedAt'] = requestParameters.minCreatedAt;
        }

        if (requestParameters.maxCreatedAt !== undefined) {
            queryParameters['maxCreatedAt'] = requestParameters.maxCreatedAt;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/user-stats/{userId}/user-referrals:search`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchUserStatsUserReferrals(requestParameters: SearchApiControllerSearchUserStatsUserReferralsRequest, initOverrides?: RequestInit): Promise<UserReferralsSearchResponse> {
        const response = await this.searchApiControllerSearchUserStatsUserReferralsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchUserStatsUserReferralsByAdminRaw(requestParameters: SearchApiControllerSearchUserStatsUserReferralsByAdminRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserReferralsSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchUserStatsUserReferralsByAdmin.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchUserStatsUserReferralsByAdmin.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.referrerUserType) {
            queryParameters['referrerUserType'] = requestParameters.referrerUserType;
        }

        if (requestParameters.presenteeUserType) {
            queryParameters['presenteeUserType'] = requestParameters.presenteeUserType;
        }

        if (requestParameters.minCreatedAt !== undefined) {
            queryParameters['minCreatedAt'] = requestParameters.minCreatedAt;
        }

        if (requestParameters.maxCreatedAt !== undefined) {
            queryParameters['maxCreatedAt'] = requestParameters.maxCreatedAt;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.subType !== undefined) {
            queryParameters['subType'] = requestParameters.subType;
        }

        if (requestParameters.presenteeUserId !== undefined) {
            queryParameters['presenteeUserId'] = requestParameters.presenteeUserId;
        }

        if (requestParameters.referrerUserId !== undefined) {
            queryParameters['referrerUserId'] = requestParameters.referrerUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/user-stats/user-referrals:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchUserStatsUserReferralsByAdmin(requestParameters: SearchApiControllerSearchUserStatsUserReferralsByAdminRequest, initOverrides?: RequestInit): Promise<UserReferralsSearchResponse> {
        const response = await this.searchApiControllerSearchUserStatsUserReferralsByAdminRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchApiControllerSearchWithdrawTransactionsRaw(requestParameters: SearchApiControllerSearchWithdrawTransactionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WithdrawTransactionsSearchResponse>> {
        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchWithdrawTransactions.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchWithdrawTransactions.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.minCreatedAt !== undefined) {
            queryParameters['minCreatedAt'] = requestParameters.minCreatedAt;
        }

        if (requestParameters.maxCreatedAt !== undefined) {
            queryParameters['maxCreatedAt'] = requestParameters.maxCreatedAt;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/withdraw-transactions:search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async searchApiControllerSearchWithdrawTransactions(requestParameters: SearchApiControllerSearchWithdrawTransactionsRequest, initOverrides?: RequestInit): Promise<WithdrawTransactionsSearchResponse> {
        const response = await this.searchApiControllerSearchWithdrawTransactionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * search withdraw transactions in seller dashboard
     */
    async searchApiControllerSearchWithdrawTransactionsBySellerRaw(requestParameters: SearchApiControllerSearchWithdrawTransactionsBySellerRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WithdrawTransactionsSearchResponse>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling searchApiControllerSearchWithdrawTransactionsBySeller.');
        }

        if (requestParameters.pageNo === null || requestParameters.pageNo === undefined) {
            throw new runtime.RequiredError('pageNo','Required parameter requestParameters.pageNo was null or undefined when calling searchApiControllerSearchWithdrawTransactionsBySeller.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling searchApiControllerSearchWithdrawTransactionsBySeller.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNo !== undefined) {
            queryParameters['pageNo'] = requestParameters.pageNo;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.status) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.minCreatedAt !== undefined) {
            queryParameters['minCreatedAt'] = requestParameters.minCreatedAt;
        }

        if (requestParameters.maxCreatedAt !== undefined) {
            queryParameters['maxCreatedAt'] = requestParameters.maxCreatedAt;
        }

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/withdraw-transactions:search`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * search withdraw transactions in seller dashboard
     */
    async searchApiControllerSearchWithdrawTransactionsBySeller(requestParameters: SearchApiControllerSearchWithdrawTransactionsBySellerRequest, initOverrides?: RequestInit): Promise<WithdrawTransactionsSearchResponse> {
        const response = await this.searchApiControllerSearchWithdrawTransactionsBySellerRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportMyStoreListingsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportMyStoreListingsStatusEnum {
    Active = 'active',
    Archived = 'archived'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportMyStoreListingsSortByEnum {
    Id = 'id',
    Name = 'name',
    CreatedAt = 'createdAt',
    Quantity = 'quantity',
    Price = 'price',
    DiscountPrice = 'discountPrice',
    ShippingFee = 'shippingFee'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsStatusEnum {
    Unshipped = 'unshipped',
    Shipped = 'shipped',
    Completed = 'completed',
    Canceled = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsPickupStatusEnum {
    Open = 'open',
    New = 'new',
    Processing = 'processing',
    Pending = 'pending',
    Packed = 'packed',
    Completed = 'completed',
    Canceled = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsGroupStatusEnum {
    Master = 'master',
    Customer = 'customer'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsShippingMethodEnum {
    SellerProvide = 'sellerProvide',
    PopshopProvide = 'popshopProvide',
    Pickup = 'pickup'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsMainStatusEnum {
    Unshipped = 'unshipped',
    Shipped = 'shipped',
    Completed = 'completed',
    Canceled = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsShippingLabelStatusEnum {
    Disabled = 'disabled',
    NoLabel = 'noLabel',
    Generating = 'generating',
    Generated = 'generated',
    PreGenerated = 'preGenerated',
    Failed = 'failed'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsMainShippingLabelStatusEnum {
    Disabled = 'disabled',
    NoLabel = 'noLabel',
    Generating = 'generating',
    Generated = 'generated',
    PreGenerated = 'preGenerated',
    Failed = 'failed'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsShippingStatusEnum {
    Unknown = 'UNKNOWN',
    Delivered = 'DELIVERED',
    PreTransit = 'PRE_TRANSIT',
    Transit = 'TRANSIT',
    Failure = 'FAILURE',
    Returned = 'RETURNED'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsMainShippingStatusEnum {
    Unknown = 'UNKNOWN',
    Delivered = 'DELIVERED',
    PreTransit = 'PRE_TRANSIT',
    Transit = 'TRANSIT',
    Failure = 'FAILURE',
    Returned = 'RETURNED'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsFilterLabelStatusEnum {
    Opened = 'Opened',
    NeedLabel = 'NeedLabel',
    Generated = 'Generated',
    FlatRate = 'FlatRate',
    Cancelled = 'Cancelled'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsSortByEnum {
    BuyerFullName = 'buyerFullName',
    OrderNumber = 'orderNumber',
    Total = 'total',
    CreatedAt = 'createdAt',
    UpdatedAt = 'updatedAt',
    Bonus = 'bonus',
    ShippingFee = 'shippingFee',
    PickupStatus = 'pickupStatus',
    TotalPriceIncludeSlaveOrders = 'totalPriceIncludeSlaveOrders'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsAsExcelOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsAsExcelStatusEnum {
    Unshipped = 'unshipped',
    Shipped = 'shipped',
    Completed = 'completed',
    Canceled = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsAsExcelPickupStatusEnum {
    Open = 'open',
    New = 'new',
    Processing = 'processing',
    Pending = 'pending',
    Packed = 'packed',
    Completed = 'completed',
    Canceled = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsAsExcelGroupStatusEnum {
    Master = 'master',
    Customer = 'customer'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsAsExcelShippingMethodEnum {
    SellerProvide = 'sellerProvide',
    PopshopProvide = 'popshopProvide',
    Pickup = 'pickup'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsAsExcelMainStatusEnum {
    Unshipped = 'unshipped',
    Shipped = 'shipped',
    Completed = 'completed',
    Canceled = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsAsExcelShippingLabelStatusEnum {
    Disabled = 'disabled',
    NoLabel = 'noLabel',
    Generating = 'generating',
    Generated = 'generated',
    PreGenerated = 'preGenerated',
    Failed = 'failed'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsAsExcelMainShippingLabelStatusEnum {
    Disabled = 'disabled',
    NoLabel = 'noLabel',
    Generating = 'generating',
    Generated = 'generated',
    PreGenerated = 'preGenerated',
    Failed = 'failed'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsAsExcelShippingStatusEnum {
    Unknown = 'UNKNOWN',
    Delivered = 'DELIVERED',
    PreTransit = 'PRE_TRANSIT',
    Transit = 'TRANSIT',
    Failure = 'FAILURE',
    Returned = 'RETURNED'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsAsExcelMainShippingStatusEnum {
    Unknown = 'UNKNOWN',
    Delivered = 'DELIVERED',
    PreTransit = 'PRE_TRANSIT',
    Transit = 'TRANSIT',
    Failure = 'FAILURE',
    Returned = 'RETURNED'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsAsExcelFilterLabelStatusEnum {
    Opened = 'Opened',
    NeedLabel = 'NeedLabel',
    Generated = 'Generated',
    FlatRate = 'FlatRate',
    Cancelled = 'Cancelled'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportSellerOrderDetailsAsExcelSortByEnum {
    BuyerFullName = 'buyerFullName',
    OrderNumber = 'orderNumber',
    Total = 'total',
    CreatedAt = 'createdAt',
    UpdatedAt = 'updatedAt',
    Bonus = 'bonus',
    ShippingFee = 'shippingFee',
    PickupStatus = 'pickupStatus',
    TotalPriceIncludeSlaveOrders = 'totalPriceIncludeSlaveOrders'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportWithdrawTransactionsBySellerOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportWithdrawTransactionsBySellerStatusEnum {
    Approved = 'approved',
    Rejected = 'rejected',
    Pending = 'pending'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerExportWithdrawTransactionsBySellerSortByEnum {
    Id = 'id',
    CreatedAt = 'createdAt',
    UpdatedAt = 'updatedAt',
    Amount = 'amount',
    Balance = 'balance'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchAppSellerApplicationsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchAppSellerApplicationsSortByEnum {
    CreatedAt = 'createdAt',
    StoreName = 'storeName',
    Status = 'status'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchAppSellerApplicationsStatusEnum {
    Submitted = 'submitted',
    Approved = 'approved',
    Rejected = 'rejected'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchCampaignsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchCampaignsSortByEnum {
    Id = 'id',
    CreatedAt = 'createdAt'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchCategoriesOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchCategoriesSortByEnum {
    Sort = 'sort',
    Name = 'name',
    Title = 'title',
    Slug = 'slug',
    RootCategory = 'rootCategory',
    CreatedAt = 'createdAt',
    UpdatedAt = 'updatedAt'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchCategorizedResourcesOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchCategorizedResourcesResourceTypeEnum {
    Store = 'STORE',
    UpcomingShow = 'UPCOMING_SHOW',
    LiveStream = 'LIVE_STREAM',
    User = 'USER'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchCategorizedResourcesSortByEnum {
    Id = 'id',
    Sort = 'sort',
    CreatedAt = 'createdAt'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchCouponsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchCouponsCouponCreateMethodEnum {
    SellerProvide = 'sellerProvide',
    PopshopProvide = 'popshopProvide'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchCouponsSortByEnum {
    Code = 'code',
    CreatedAt = 'createdAt'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchFeaturedShowsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchFeaturedShowsTypeEnum {
    UpcomingShow = 'upcomingShow',
    LiveShow = 'liveShow',
    Event = 'event'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchFeaturedShowsSortByEnum {
    Id = 'id',
    SortedAt = 'sortedAt',
    Type = 'type',
    Active = 'active'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchFeaturedStoresOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchFeaturedStoresSortByEnum {
    SortedAt = 'sortedAt',
    Position = 'position'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchIncentivePayoutsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchIncentivePayoutsStatusEnum {
    Pending = 'Pending',
    Processed = 'Processed',
    Completed = 'Completed',
    Failed = 'Failed'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchIncentivePayoutsSortByEnum {
    Id = 'id',
    CreatedAt = 'createdAt',
    CreatorName = 'creatorName',
    StoreName = 'storeName'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchLinksOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchLinksTypeEnum {
    Share = 'SHARE',
    Campaign = 'CAMPAIGN'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchLinksSortByEnum {
    Id = 'id',
    CreatedAt = 'createdAt'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchLiveStreamBuyerAddressesOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchLiveStreamBuyerAddressesSortByEnum {
    LatestOrderCreatedAt = 'latest_order_created_at',
    FullName = 'full_name'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchLiveStreamsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchLiveStreamsStatusEnum {
    Initialized = 'initialized',
    Available = 'available',
    Finished = 'finished',
    Aborted = 'aborted'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchLiveStreamsPermissionEnum {
    All = 'all',
    Follower = 'follower',
    NoOne = 'no-one'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchLiveStreamsSortByEnum {
    Title = 'title',
    StoreFrontName = 'storeFrontName',
    CreatedAt = 'createdAt',
    DisconnectedAt = 'disconnectedAt'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchLiveStreamsActiveViewersOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchLiveStreamsActiveViewersSortByEnum {
    Name = 'name',
    Email = 'email',
    CreatedAt = 'createdAt',
    StoreFrontName = 'storeFrontName'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchLiveStreamsListingsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchLiveStreamsListingsSortByEnum {
    Id = 'id',
    Name = 'name',
    CreatedAt = 'createdAt',
    Quantity = 'quantity',
    Price = 'price',
    DiscountPrice = 'discountPrice',
    ShippingFee = 'shippingFee'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchLiveStreamsViewersOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchLiveStreamsViewersSortByEnum {
    StartedAt = 'startedAt',
    StoppedAt = 'stoppedAt',
    IsWatching = 'isWatching',
    Id = 'id'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMailsFilterByEnum {
    ExternalId = 'externalId',
    To = 'to',
    TemplateName = 'templateName',
    Any = 'any'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMailsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMailsSortByEnum {
    Id = 'id',
    CreatedAt = 'createdAt'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMailsStateEnum {
    Pending = 'PENDING',
    Processing = 'PROCESSING',
    Success = 'SUCCESS',
    Error = 'ERROR'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMessagesFilterByEnum {
    All = 'all',
    Sku = 'sku',
    ListingName = 'listingName',
    CustomerName = 'customerName',
    ShowName = 'showName'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMessagesOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMessagesReportFilterByEnum {
    All = 'all',
    None = 'none',
    Unresolved = 'unresolved',
    Resolved = 'resolved'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMessagesPurposeEnum {
    Review = 'review',
    ThankYou = 'thankYou'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMessagesThankYouTypeEnum {
    RecipientToBuyer = 'RecipientToBuyer',
    SellerToBuyer = 'SellerToBuyer'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMessagesSortByEnum {
    Id = 'id',
    CreatedAt = 'createdAt',
    UpdatedAt = 'updatedAt',
    UpvoteCount = 'upvoteCount',
    TotalReportCount = 'totalReportCount',
    NewReportCount = 'newReportCount'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMessagesReportsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMessagesReportsReasonEnum {
    Spam = 'spam',
    Inappropriate = 'inappropriate',
    Aggressive = 'aggressive'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMessagesReportsResolveStatusEnum {
    Unresolved = 'Unresolved',
    Resolved = 'Resolved',
    Dismissed = 'Dismissed'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMessagesReportsSortByEnum {
    Id = 'id',
    CreatedAt = 'createdAt',
    UpdatedAt = 'updatedAt'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMyOrdersOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMyOrdersStatusEnum {
    Unshipped = 'unshipped',
    Shipped = 'shipped',
    Completed = 'completed',
    Canceled = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMyOrdersPickupStatusEnum {
    Open = 'open',
    New = 'new',
    Processing = 'processing',
    Pending = 'pending',
    Packed = 'packed',
    Completed = 'completed',
    Canceled = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMyOrdersGroupStatusEnum {
    Master = 'master',
    Customer = 'customer'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMyOrdersShippingMethodEnum {
    SellerProvide = 'sellerProvide',
    PopshopProvide = 'popshopProvide',
    Pickup = 'pickup'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMyOrdersSortByEnum {
    BuyerFullName = 'buyerFullName',
    OrderNumber = 'orderNumber',
    Total = 'total',
    CreatedAt = 'createdAt',
    UpdatedAt = 'updatedAt',
    Bonus = 'bonus',
    ShippingFee = 'shippingFee',
    PickupStatus = 'pickupStatus'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMyStoreListingsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMyStoreListingsStatusEnum {
    Active = 'active',
    Archived = 'archived'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMyStoreListingsSortByEnum {
    Id = 'id',
    Name = 'name',
    CreatedAt = 'createdAt',
    Quantity = 'quantity',
    Price = 'price',
    DiscountPrice = 'discountPrice',
    ShippingFee = 'shippingFee'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMyStorePublicListingsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMyStorePublicListingsStatusEnum {
    Active = 'active',
    Archived = 'archived'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchMyStorePublicListingsSortByEnum {
    Id = 'id',
    Name = 'name',
    CreatedAt = 'createdAt',
    Quantity = 'quantity',
    Price = 'price',
    DiscountPrice = 'discountPrice',
    ShippingFee = 'shippingFee'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchOrderTransactionsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchOrderTransactionsTypeEnum {
    Sale = 'sale',
    RefundBraintree = 'refundBraintree',
    ShippingFee = 'shippingFee',
    RefundShippingFee = 'refundShippingFee',
    Bonus = 'bonus',
    RefundBonus = 'refundBonus',
    ProductCoupon = 'productCoupon',
    RefundProductCoupon = 'refundProductCoupon'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchOrderTransactionsStatusEnum {
    Pending = 'pending',
    Completed = 'completed',
    Canceled = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchOrderTransactionsSortByEnum {
    CreatedAt = 'createdAt',
    UpdatedAt = 'updatedAt',
    Amount = 'amount',
    FromBraintree = 'fromBraintree'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchOrdersOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchOrdersStatusEnum {
    Unshipped = 'unshipped',
    Shipped = 'shipped',
    Completed = 'completed',
    Canceled = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchOrdersPickupStatusEnum {
    Open = 'open',
    New = 'new',
    Processing = 'processing',
    Pending = 'pending',
    Packed = 'packed',
    Completed = 'completed',
    Canceled = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchOrdersGroupStatusEnum {
    Master = 'master',
    Customer = 'customer'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchOrdersShippingMethodEnum {
    SellerProvide = 'sellerProvide',
    PopshopProvide = 'popshopProvide',
    Pickup = 'pickup'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchOrdersFilterByEnum {
    SellerFirstName = 'seller.firstName',
    SellerLastName = 'seller.lastName',
    SellerEmail = 'seller.email',
    BuyerFirstName = 'buyer.firstName',
    BuyerLastName = 'buyer.lastName',
    BuyerEmail = 'buyer.email'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchOrdersGiftingStatusEnum {
    Pending = 'PENDING',
    Accepted = 'ACCEPTED',
    Rejected = 'REJECTED'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchOrdersSourceTypeEnum {
    UpcomingShow = 'UPCOMING_SHOW',
    LiveStream = 'LIVE_STREAM',
    Store = 'STORE',
    TwoWayStream = 'TWO_WAY_STREAM'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchOrdersShipmentOriginalParcelSourceEnum {
    Default = 'DEFAULT',
    ShowEvent = 'SHOW_EVENT'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchOrdersSortByEnum {
    BuyerFullName = 'buyerFullName',
    OrderNumber = 'orderNumber',
    Total = 'total',
    CreatedAt = 'createdAt',
    UpdatedAt = 'updatedAt',
    Bonus = 'bonus',
    ShippingFee = 'shippingFee',
    PickupStatus = 'pickupStatus'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchPrivateProfilesPayoutHistoryOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchPrivateProfilesPayoutHistoryStatusEnum {
    Approved = 'approved',
    Pending = 'pending',
    Rejected = 'rejected',
    Canceled = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchPrivateProfilesPayoutHistorySourceTypeEnum {
    Admin = 'admin',
    Seller = 'seller'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchPrivateProfilesPayoutHistorySortByEnum {
    Id = 'id',
    CreatedAt = 'createdAt',
    UpdatedAt = 'updatedAt',
    Status = 'status',
    SourceType = 'sourceType'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchProfilesOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchProfilesFilterByEnum {
    Any = 'any',
    FirstName = 'firstName',
    LastName = 'lastName',
    Email = 'email',
    StoreFrontName = 'storeFrontName',
    Username = 'username'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchProfilesUserTypeEnum {
    Normal = 'normal',
    Seller = 'seller',
    VerifiedSeller = 'verifiedSeller'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchProfilesSortByEnum {
    Name = 'name',
    Email = 'email',
    CreatedAt = 'createdAt',
    StoreFrontName = 'storeFrontName'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchProfilesFollowersOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchProfilesFollowersSortByEnum {
    Id = 'id',
    Username = 'username',
    FirstName = 'firstName',
    LastName = 'lastName',
    StoreFrontName = 'storeFrontName',
    Timestamp = 'timestamp'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchProfilesFollowingsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchProfilesFollowingsSortByEnum {
    Id = 'id',
    Username = 'username',
    FirstName = 'firstName',
    LastName = 'lastName',
    StoreFrontName = 'storeFrontName',
    Timestamp = 'timestamp'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchProfilesFollowingsUserTypeEnum {
    Normal = 'normal',
    Seller = 'seller',
    VerifiedSeller = 'verifiedSeller'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchReviewsByUserOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchReviewsByUserSortByEnum {
    Id = 'id',
    CreatedAt = 'createdAt',
    UpdatedAt = 'updatedAt',
    UpvoteCount = 'upvoteCount',
    TotalReportCount = 'totalReportCount',
    NewReportCount = 'newReportCount'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchReviewsToSellerOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchReviewsToSellerSortByEnum {
    Id = 'id',
    CreatedAt = 'createdAt',
    UpdatedAt = 'updatedAt',
    UpvoteCount = 'upvoteCount',
    TotalReportCount = 'totalReportCount',
    NewReportCount = 'newReportCount'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchRolesOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchRolesRolesEnum {
    Moderator = 'moderator',
    Seller = 'seller',
    SystemAdmin = 'system-admin',
    User = 'user',
    Finance = 'finance'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchRolesSortByEnum {
    Id = 'id'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerIncentivePayoutsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerIncentivePayoutsStatusEnum {
    Pending = 'Pending',
    Processed = 'Processed',
    Completed = 'Completed',
    Failed = 'Failed'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerIncentivePayoutsSortByEnum {
    Id = 'id',
    CreatedAt = 'createdAt',
    CreatorName = 'creatorName',
    StoreName = 'storeName'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerLiveStreamsStatsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerLiveStreamsStatsSortByEnum {
    CreatedAt = 'created_at',
    Gmv = 'gmv',
    MainOrderCnt = 'main_order_cnt',
    CustomerCnt = 'customer_cnt',
    NewCustomerCnt = 'new_customer_cnt',
    ViewerCnt = 'viewer_cnt',
    NewViewerCnt = 'new_viewer_cnt'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerMessagesFilterByEnum {
    All = 'all',
    Sku = 'sku',
    ListingName = 'listingName',
    CustomerName = 'customerName',
    ShowName = 'showName'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerMessagesOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerMessagesReportFilterByEnum {
    All = 'all',
    None = 'none',
    Unresolved = 'unresolved',
    Resolved = 'resolved'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerMessagesPurposeEnum {
    Review = 'review',
    ThankYou = 'thankYou'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerMessagesThankYouTypeEnum {
    RecipientToBuyer = 'RecipientToBuyer',
    SellerToBuyer = 'SellerToBuyer'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerMessagesSortByEnum {
    Id = 'id',
    CreatedAt = 'createdAt',
    UpdatedAt = 'updatedAt',
    UpvoteCount = 'upvoteCount',
    TotalReportCount = 'totalReportCount',
    NewReportCount = 'newReportCount'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerOrderDetailsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerOrderDetailsStatusEnum {
    Unshipped = 'unshipped',
    Shipped = 'shipped',
    Completed = 'completed',
    Canceled = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerOrderDetailsPickupStatusEnum {
    Open = 'open',
    New = 'new',
    Processing = 'processing',
    Pending = 'pending',
    Packed = 'packed',
    Completed = 'completed',
    Canceled = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerOrderDetailsGroupStatusEnum {
    Master = 'master',
    Customer = 'customer'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerOrderDetailsShippingMethodEnum {
    SellerProvide = 'sellerProvide',
    PopshopProvide = 'popshopProvide',
    Pickup = 'pickup'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerOrderDetailsMainStatusEnum {
    Unshipped = 'unshipped',
    Shipped = 'shipped',
    Completed = 'completed',
    Canceled = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerOrderDetailsShippingLabelStatusEnum {
    Disabled = 'disabled',
    NoLabel = 'noLabel',
    Generating = 'generating',
    Generated = 'generated',
    PreGenerated = 'preGenerated',
    Failed = 'failed'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerOrderDetailsMainShippingLabelStatusEnum {
    Disabled = 'disabled',
    NoLabel = 'noLabel',
    Generating = 'generating',
    Generated = 'generated',
    PreGenerated = 'preGenerated',
    Failed = 'failed'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerOrderDetailsShippingStatusEnum {
    Unknown = 'UNKNOWN',
    Delivered = 'DELIVERED',
    PreTransit = 'PRE_TRANSIT',
    Transit = 'TRANSIT',
    Failure = 'FAILURE',
    Returned = 'RETURNED'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerOrderDetailsMainShippingStatusEnum {
    Unknown = 'UNKNOWN',
    Delivered = 'DELIVERED',
    PreTransit = 'PRE_TRANSIT',
    Transit = 'TRANSIT',
    Failure = 'FAILURE',
    Returned = 'RETURNED'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerOrderDetailsFilterLabelStatusEnum {
    Opened = 'Opened',
    NeedLabel = 'NeedLabel',
    Generated = 'Generated',
    FlatRate = 'FlatRate',
    Cancelled = 'Cancelled'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerOrderDetailsSortByEnum {
    BuyerFullName = 'buyerFullName',
    OrderNumber = 'orderNumber',
    Total = 'total',
    CreatedAt = 'createdAt',
    UpdatedAt = 'updatedAt',
    Bonus = 'bonus',
    ShippingFee = 'shippingFee',
    PickupStatus = 'pickupStatus',
    TotalPriceIncludeSlaveOrders = 'totalPriceIncludeSlaveOrders'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerOrdersOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerOrdersStatusEnum {
    Unshipped = 'unshipped',
    Shipped = 'shipped',
    Completed = 'completed',
    Canceled = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerOrdersPickupStatusEnum {
    Open = 'open',
    New = 'new',
    Processing = 'processing',
    Pending = 'pending',
    Packed = 'packed',
    Completed = 'completed',
    Canceled = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerOrdersGroupStatusEnum {
    Master = 'master',
    Customer = 'customer'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerOrdersShippingMethodEnum {
    SellerProvide = 'sellerProvide',
    PopshopProvide = 'popshopProvide',
    Pickup = 'pickup'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerOrdersSortByEnum {
    BuyerFullName = 'buyerFullName',
    OrderNumber = 'orderNumber',
    Total = 'total',
    CreatedAt = 'createdAt',
    UpdatedAt = 'updatedAt',
    Bonus = 'bonus',
    ShippingFee = 'shippingFee',
    PickupStatus = 'pickupStatus'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerStatusOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchSellerStatusSortByEnum {
    StoreFrontName = 'storeFrontName',
    Name = 'name',
    ShowCount = 'showCount',
    ViewerCount = 'viewerCount',
    CommentCount = 'commentCount',
    MainOrderCount = 'mainOrderCount',
    PurchaseCount = 'purchaseCount',
    LikeCount = 'likeCount',
    Revenue = 'revenue',
    DurationSecond = 'durationSecond',
    StoreCreatedAt = 'storeCreatedAt'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchShowEventFeaturedStoresOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchShowEventFeaturedStoresSortByEnum {
    SortedAt = 'sortedAt',
    Position = 'position'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchShowEventsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchShowEventsSortByEnum {
    Id = 'id',
    SortedAt = 'sortedAt',
    Name = 'name',
    CreatedAt = 'createdAt'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchShowEventsFeaturedShowsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchShowEventsFeaturedShowsTypeEnum {
    UpcomingShow = 'upcomingShow',
    LiveShow = 'liveShow',
    Event = 'event'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchShowEventsFeaturedShowsSortByEnum {
    Id = 'id',
    SortedAt = 'sortedAt',
    Type = 'type',
    Active = 'active'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchShowEventsTicketsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchShowEventsTicketsSortByEnum {
    Id = 'id',
    CreatedAt = 'createdAt',
    Name = 'name',
    SortedAt = 'sortedAt'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchStoreLiveStreamsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchStoreLiveStreamsStatusEnum {
    Initialized = 'initialized',
    Available = 'available',
    Finished = 'finished',
    Aborted = 'aborted'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchStoreLiveStreamsPermissionEnum {
    All = 'all',
    Follower = 'follower',
    NoOne = 'no-one'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchStoreLiveStreamsSortByEnum {
    Title = 'title',
    StoreFrontName = 'storeFrontName',
    CreatedAt = 'createdAt',
    DisconnectedAt = 'disconnectedAt'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchStoreLiveStreamsShippingModeEnum {
    Shipping = 'shipping',
    Pickup = 'pickup'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchStoresFilterByEnum {
    Any = 'any',
    Name = 'name',
    FirstName = 'firstName',
    LastName = 'lastName',
    Email = 'email',
    FullName = 'fullName'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchStoresOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchStoresSortByEnum {
    Name = 'name'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUpcomingShowsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUpcomingShowsSortByEnum {
    StartsAt = 'startsAt'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserDetailsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserDetailsFilterByEnum {
    Any = 'any',
    FirstName = 'firstName',
    LastName = 'lastName',
    Email = 'email',
    StoreFrontName = 'storeFrontName',
    Username = 'username'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserDetailsUserTypeEnum {
    Normal = 'normal',
    Seller = 'seller',
    VerifiedSeller = 'verifiedSeller'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserDetailsSortByEnum {
    Name = 'name',
    Email = 'email',
    CreatedAt = 'createdAt',
    StoreFrontName = 'storeFrontName'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserGroupsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserGroupsSortByEnum {
    CreatedAt = 'createdAt',
    Name = 'name'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserGroupsUsersOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserGroupsUsersUserTypeEnum {
    Normal = 'normal',
    Seller = 'seller',
    VerifiedSeller = 'verifiedSeller'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserGroupsUsersSortByEnum {
    Id = 'id',
    Name = 'name',
    StoreName = 'storeName',
    CreatedAt = 'createdAt'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserMessagesTypeEnum {
    Review = 'review',
    ThankyouFromGiftReceiver = 'thankyouFromGiftReceiver'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserMessagesOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserMessagesSortByEnum {
    Id = 'id',
    CreatedAt = 'createdAt',
    UpdatedAt = 'updatedAt',
    UpvoteCount = 'upvoteCount',
    TotalReportCount = 'totalReportCount',
    NewReportCount = 'newReportCount'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserOrdersOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserOrdersStatusEnum {
    Unshipped = 'unshipped',
    Shipped = 'shipped',
    Completed = 'completed',
    Canceled = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserOrdersPickupStatusEnum {
    Open = 'open',
    New = 'new',
    Processing = 'processing',
    Pending = 'pending',
    Packed = 'packed',
    Completed = 'completed',
    Canceled = 'canceled'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserOrdersGroupStatusEnum {
    Master = 'master',
    Customer = 'customer'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserOrdersShippingMethodEnum {
    SellerProvide = 'sellerProvide',
    PopshopProvide = 'popshopProvide',
    Pickup = 'pickup'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserOrdersSortByEnum {
    BuyerFullName = 'buyerFullName',
    OrderNumber = 'orderNumber',
    Total = 'total',
    CreatedAt = 'createdAt',
    UpdatedAt = 'updatedAt',
    Bonus = 'bonus',
    ShippingFee = 'shippingFee',
    PickupStatus = 'pickupStatus'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserStatsUserReferralsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserStatsUserReferralsReferrerUserTypeEnum {
    Normal = 'normal',
    Seller = 'seller',
    VerifiedSeller = 'verifiedSeller'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserStatsUserReferralsPresenteeUserTypeEnum {
    Normal = 'normal',
    Seller = 'seller',
    VerifiedSeller = 'verifiedSeller'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserStatsUserReferralsSortByEnum {
    Id = 'id',
    CreatedAt = 'createdAt'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserStatsUserReferralsByAdminOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserStatsUserReferralsByAdminReferrerUserTypeEnum {
    Normal = 'normal',
    Seller = 'seller',
    VerifiedSeller = 'verifiedSeller'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserStatsUserReferralsByAdminPresenteeUserTypeEnum {
    Normal = 'normal',
    Seller = 'seller',
    VerifiedSeller = 'verifiedSeller'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserStatsUserReferralsByAdminSortByEnum {
    Id = 'id',
    CreatedAt = 'createdAt'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserStatsUserReferralsByAdminTypeEnum {
    Share = 'SHARE',
    Passcode = 'PASSCODE'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchUserStatsUserReferralsByAdminSubTypeEnum {
    Profile = 'PROFILE',
    LiveStream = 'LIVE_STREAM',
    UpcomingShow = 'UPCOMING_SHOW',
    ListingLiveStream = 'LISTING_LIVE_STREAM',
    ListingEvergreen = 'LISTING_EVERGREEN',
    ListingUpcomingShow = 'LISTING_UPCOMING_SHOW',
    ListingPromotion = 'LISTING_PROMOTION',
    Event = 'EVENT',
    EventDiscovery = 'EVENT_DISCOVERY',
    EventTicket = 'EVENT_TICKET',
    HomePage = 'HOME_PAGE',
    Promotion = 'PROMOTION'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchWithdrawTransactionsOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchWithdrawTransactionsStatusEnum {
    Approved = 'approved',
    Rejected = 'rejected',
    Pending = 'pending'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchWithdrawTransactionsSortByEnum {
    Id = 'id',
    CreatedAt = 'createdAt',
    UpdatedAt = 'updatedAt',
    Amount = 'amount',
    Balance = 'balance'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchWithdrawTransactionsBySellerOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchWithdrawTransactionsBySellerStatusEnum {
    Approved = 'approved',
    Rejected = 'rejected',
    Pending = 'pending'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchApiControllerSearchWithdrawTransactionsBySellerSortByEnum {
    Id = 'id',
    CreatedAt = 'createdAt',
    UpdatedAt = 'updatedAt',
    Amount = 'amount',
    Balance = 'balance'
}
