import React, { memo } from 'react';
import Button from '@mui/material/Button';
import AmountButton from './AmountButton';
import { Avatar } from '@mui/material';
import { POPSHOP_LOGO, SHOPIFY_LOGO } from '../../constant/pics';
import { CircularProgress } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

interface SyncButtonProps {
  type: 'shopify' | 'popshop';
  amount: number;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  sx?: SxProps<Theme>;
}

export default memo(function SyncButton({
  type,
  amount,
  disabled,
  loading,
  sx,
  onClick,
}: SyncButtonProps) {
  return (
    <Button
      variant="contained"
      color="primary"
      sx={{
        backgroundColor: 'white',
        '&:focus': {
          backgroundColor: 'white',
        },
        paddingTop: 'spacing(1)',
        paddingBottom: 'spacing(1)',
        ...sx,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {loading ? (
        <CircularProgress color="primary" size="1rem" />
      ) : (
        <>
          <AmountButton disabled>{amount}</AmountButton>
          <Avatar
            src={type === 'shopify' ? SHOPIFY_LOGO : POPSHOP_LOGO}
            sx={{
              width: 24,
              height: 24,
              marginLeft: 1,
            }}
          />
        </>
      )}
    </Button>
  );
});
