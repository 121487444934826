import React, { memo } from 'react';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/system';

interface ProductCardContentProps {
  title: string;
  discountPrice?: number;
  price: number;
  variants?: string;
  variantsTextColor?: 'primary' | 'textSecondary';
  sx?: SxProps<Theme>;
  qty: number;
}

export default memo(function ProductCardContent({
  title,
  discountPrice,
  price,
  variants,
  variantsTextColor = 'textSecondary',
  sx,
  qty,
}: ProductCardContentProps) {
  return (
    <CardContent sx={sx}>
      <Typography
        component="div"
        variant="body2"
        sx={{
          flex: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          fontWeight: 'bold',
        }}
      >
        <Box>{title}</Box>
      </Typography>
      <Typography component="div" variant="caption">
        {discountPrice ? (
          <Box fontWeight="bold" sx={{ color: 'red' }}>
            ${discountPrice.toFixed(2)}
            {'  '}
            <span style={{ color: 'black', textDecoration: 'line-through' }}>
              ${price.toFixed(2)}
            </span>
          </Box>
        ) : (
          <Box fontWeight="bold">${price.toFixed(2)}</Box>
        )}
      </Typography>
      {!!variants && (
        <Typography component="div" variant="caption" color={variantsTextColor}>
          <Box fontWeight="bold" sx={{ marginTop: 1 }}>
            {variants}{' '}
            <span style={{ color: 'black', marginLeft: 8 }}>
              {qty} in stock
            </span>
          </Box>
        </Typography>
      )}
    </CardContent>
  );
});
