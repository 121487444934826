import { initializeApp } from 'firebase/app';
import firebaseConfig from '../config/hostShopifyConfig.json';
import { getAuth, signOut as so } from 'firebase/auth';
import {
  getFirestore,
  deleteField as df,
  getDoc,
  doc,
} from 'firebase/firestore';
import { ShopifyStore } from '../types/shopifyStore';

export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const getUser = () => auth.currentUser;
export const signOut = () => so(auth);
export const deleteField = df;
export const getShopifyStore = async (storeId: string) => {
  const shopifyStore = await getDoc<ShopifyStore>(
    doc(db, `shopifyStores/${storeId}`)
  );
  return shopifyStore.data();
};
export const getReadonlyProfile = async () => {
  const user = getUser();
  if (user) {
    const profile = await getDoc<{
      features?: {
        pickup?: boolean;
        unlimitedStoreFavorites?: boolean;
        shopifyOrderSync?: boolean;
        // set order number format as PS-#{PSL order number}
        shopifyOrderSyncWithPSPrefix?: boolean;
        shopifyListingSyncWithoutSKU?: boolean;
        nftGatedShows?: boolean;
        patreonGatedShows?: boolean;
        customersTab?: boolean;
        // if false, user can't schedule upcoming show or start livestream
        streaming?: boolean;
        voidLabel?: boolean;
      };
    }>(doc(db, `readonlyProfiles/${user.uid}`));
    return profile.data();
  } else {
    return {};
  }
};
