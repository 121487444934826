/* tslint:disable */
/* eslint-disable */
/**
 * PopshopLive API
 * PopshopLive API service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BatchImportResponse,
    BatchImportStoreListingsRequest,
    BatchUpdateListingDiscountPriceRequest,
    CreateStoreListingRequest,
    CreateStoreListingResponse,
    EditStoreListingRequest,
    ImportStoreListing,
    PatchStoreListingRequest,
} from '../models';

export interface StoreListingApiControllerBatchImportStoreListingsRequest {
    storeId: string;
    batchImportStoreListingsRequest: BatchImportStoreListingsRequest;
}

export interface StoreListingApiControllerBatchRemoveListingDiscountPriceRequest {
    storeId: string;
}

export interface StoreListingApiControllerBatchUpdateListingDiscountPriceRequest {
    storeId: string;
    batchUpdateListingDiscountPriceRequest: BatchUpdateListingDiscountPriceRequest;
}

export interface StoreListingApiControllerCreateStoreListingRequest {
    storeId: string;
    createStoreListingRequest: CreateStoreListingRequest;
}

export interface StoreListingApiControllerDeleteStoreListingRequest {
    storeId: string;
    listingId: string;
}

export interface StoreListingApiControllerEditStoreListingRequest {
    storeId: string;
    listingId: string;
    editStoreListingRequest: EditStoreListingRequest;
}

export interface StoreListingApiControllerImportStoreListingsRequest {
    storeId: string;
    importStoreListing: ImportStoreListing;
}

export interface StoreListingApiControllerPatchStoreListingRequest {
    storeId: string;
    listingId: string;
    patchStoreListingRequest: PatchStoreListingRequest;
}

/**
 * 
 */
export class StoreListingApi extends runtime.BaseAPI {

    /**
     */
    async storeListingApiControllerBatchImportStoreListingsRaw(requestParameters: StoreListingApiControllerBatchImportStoreListingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BatchImportResponse>> {
        if (requestParameters.storeId === null || requestParameters.storeId === undefined) {
            throw new runtime.RequiredError('storeId','Required parameter requestParameters.storeId was null or undefined when calling storeListingApiControllerBatchImportStoreListings.');
        }

        if (requestParameters.batchImportStoreListingsRequest === null || requestParameters.batchImportStoreListingsRequest === undefined) {
            throw new runtime.RequiredError('batchImportStoreListingsRequest','Required parameter requestParameters.batchImportStoreListingsRequest was null or undefined when calling storeListingApiControllerBatchImportStoreListings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{storeId}/listings:batch-import`.replace(`{${"storeId"}}`, encodeURIComponent(String(requestParameters.storeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.batchImportStoreListingsRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async storeListingApiControllerBatchImportStoreListings(requestParameters: StoreListingApiControllerBatchImportStoreListingsRequest, initOverrides?: RequestInit): Promise<BatchImportResponse> {
        const response = await this.storeListingApiControllerBatchImportStoreListingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async storeListingApiControllerBatchRemoveListingDiscountPriceRaw(requestParameters: StoreListingApiControllerBatchRemoveListingDiscountPriceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.storeId === null || requestParameters.storeId === undefined) {
            throw new runtime.RequiredError('storeId','Required parameter requestParameters.storeId was null or undefined when calling storeListingApiControllerBatchRemoveListingDiscountPrice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{storeId}:batchRemoveDiscountPrice`.replace(`{${"storeId"}}`, encodeURIComponent(String(requestParameters.storeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async storeListingApiControllerBatchRemoveListingDiscountPrice(requestParameters: StoreListingApiControllerBatchRemoveListingDiscountPriceRequest, initOverrides?: RequestInit): Promise<void> {
        await this.storeListingApiControllerBatchRemoveListingDiscountPriceRaw(requestParameters, initOverrides);
    }

    /**
     */
    async storeListingApiControllerBatchUpdateListingDiscountPriceRaw(requestParameters: StoreListingApiControllerBatchUpdateListingDiscountPriceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.storeId === null || requestParameters.storeId === undefined) {
            throw new runtime.RequiredError('storeId','Required parameter requestParameters.storeId was null or undefined when calling storeListingApiControllerBatchUpdateListingDiscountPrice.');
        }

        if (requestParameters.batchUpdateListingDiscountPriceRequest === null || requestParameters.batchUpdateListingDiscountPriceRequest === undefined) {
            throw new runtime.RequiredError('batchUpdateListingDiscountPriceRequest','Required parameter requestParameters.batchUpdateListingDiscountPriceRequest was null or undefined when calling storeListingApiControllerBatchUpdateListingDiscountPrice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{storeId}:batchUpdateDiscountPrice`.replace(`{${"storeId"}}`, encodeURIComponent(String(requestParameters.storeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.batchUpdateListingDiscountPriceRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async storeListingApiControllerBatchUpdateListingDiscountPrice(requestParameters: StoreListingApiControllerBatchUpdateListingDiscountPriceRequest, initOverrides?: RequestInit): Promise<void> {
        await this.storeListingApiControllerBatchUpdateListingDiscountPriceRaw(requestParameters, initOverrides);
    }

    /**
     */
    async storeListingApiControllerCreateStoreListingRaw(requestParameters: StoreListingApiControllerCreateStoreListingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreateStoreListingResponse>> {
        if (requestParameters.storeId === null || requestParameters.storeId === undefined) {
            throw new runtime.RequiredError('storeId','Required parameter requestParameters.storeId was null or undefined when calling storeListingApiControllerCreateStoreListing.');
        }

        if (requestParameters.createStoreListingRequest === null || requestParameters.createStoreListingRequest === undefined) {
            throw new runtime.RequiredError('createStoreListingRequest','Required parameter requestParameters.createStoreListingRequest was null or undefined when calling storeListingApiControllerCreateStoreListing.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{storeId}/listings`.replace(`{${"storeId"}}`, encodeURIComponent(String(requestParameters.storeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createStoreListingRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async storeListingApiControllerCreateStoreListing(requestParameters: StoreListingApiControllerCreateStoreListingRequest, initOverrides?: RequestInit): Promise<CreateStoreListingResponse> {
        const response = await this.storeListingApiControllerCreateStoreListingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async storeListingApiControllerDeleteStoreListingRaw(requestParameters: StoreListingApiControllerDeleteStoreListingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.storeId === null || requestParameters.storeId === undefined) {
            throw new runtime.RequiredError('storeId','Required parameter requestParameters.storeId was null or undefined when calling storeListingApiControllerDeleteStoreListing.');
        }

        if (requestParameters.listingId === null || requestParameters.listingId === undefined) {
            throw new runtime.RequiredError('listingId','Required parameter requestParameters.listingId was null or undefined when calling storeListingApiControllerDeleteStoreListing.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{storeId}/listings/{listingId}`.replace(`{${"storeId"}}`, encodeURIComponent(String(requestParameters.storeId))).replace(`{${"listingId"}}`, encodeURIComponent(String(requestParameters.listingId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async storeListingApiControllerDeleteStoreListing(requestParameters: StoreListingApiControllerDeleteStoreListingRequest, initOverrides?: RequestInit): Promise<void> {
        await this.storeListingApiControllerDeleteStoreListingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async storeListingApiControllerEditStoreListingRaw(requestParameters: StoreListingApiControllerEditStoreListingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.storeId === null || requestParameters.storeId === undefined) {
            throw new runtime.RequiredError('storeId','Required parameter requestParameters.storeId was null or undefined when calling storeListingApiControllerEditStoreListing.');
        }

        if (requestParameters.listingId === null || requestParameters.listingId === undefined) {
            throw new runtime.RequiredError('listingId','Required parameter requestParameters.listingId was null or undefined when calling storeListingApiControllerEditStoreListing.');
        }

        if (requestParameters.editStoreListingRequest === null || requestParameters.editStoreListingRequest === undefined) {
            throw new runtime.RequiredError('editStoreListingRequest','Required parameter requestParameters.editStoreListingRequest was null or undefined when calling storeListingApiControllerEditStoreListing.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{storeId}/listings/{listingId}`.replace(`{${"storeId"}}`, encodeURIComponent(String(requestParameters.storeId))).replace(`{${"listingId"}}`, encodeURIComponent(String(requestParameters.listingId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.editStoreListingRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async storeListingApiControllerEditStoreListing(requestParameters: StoreListingApiControllerEditStoreListingRequest, initOverrides?: RequestInit): Promise<void> {
        await this.storeListingApiControllerEditStoreListingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async storeListingApiControllerImportStoreListingsRaw(requestParameters: StoreListingApiControllerImportStoreListingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.storeId === null || requestParameters.storeId === undefined) {
            throw new runtime.RequiredError('storeId','Required parameter requestParameters.storeId was null or undefined when calling storeListingApiControllerImportStoreListings.');
        }

        if (requestParameters.importStoreListing === null || requestParameters.importStoreListing === undefined) {
            throw new runtime.RequiredError('importStoreListing','Required parameter requestParameters.importStoreListing was null or undefined when calling storeListingApiControllerImportStoreListings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{storeId}/listings:import`.replace(`{${"storeId"}}`, encodeURIComponent(String(requestParameters.storeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.importStoreListing,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async storeListingApiControllerImportStoreListings(requestParameters: StoreListingApiControllerImportStoreListingsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.storeListingApiControllerImportStoreListingsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async storeListingApiControllerPatchStoreListingRaw(requestParameters: StoreListingApiControllerPatchStoreListingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.storeId === null || requestParameters.storeId === undefined) {
            throw new runtime.RequiredError('storeId','Required parameter requestParameters.storeId was null or undefined when calling storeListingApiControllerPatchStoreListing.');
        }

        if (requestParameters.listingId === null || requestParameters.listingId === undefined) {
            throw new runtime.RequiredError('listingId','Required parameter requestParameters.listingId was null or undefined when calling storeListingApiControllerPatchStoreListing.');
        }

        if (requestParameters.patchStoreListingRequest === null || requestParameters.patchStoreListingRequest === undefined) {
            throw new runtime.RequiredError('patchStoreListingRequest','Required parameter requestParameters.patchStoreListingRequest was null or undefined when calling storeListingApiControllerPatchStoreListing.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{storeId}/listings/{listingId}`.replace(`{${"storeId"}}`, encodeURIComponent(String(requestParameters.storeId))).replace(`{${"listingId"}}`, encodeURIComponent(String(requestParameters.listingId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.patchStoreListingRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async storeListingApiControllerPatchStoreListing(requestParameters: StoreListingApiControllerPatchStoreListingRequest, initOverrides?: RequestInit): Promise<void> {
        await this.storeListingApiControllerPatchStoreListingRaw(requestParameters, initOverrides);
    }

}
