import { Middleware, ResponseContext } from '../../../generated/api';

export default class ErrorMiddleware implements Middleware {
  async post(context: ResponseContext) {
    if (!context.response.ok) {
      const data = await context.response.json();
      const error = new Error(data?.error?.message ?? 'Failed');
      error.name = data?.error?.code ?? 500;
      throw error;
    }
  }
}
