import React from 'react';
import {
  ShopifyProduct,
  ShopifyVariant,
  PopVariant,
  PopProduct,
} from '../../types/products';
import { useSnackbar } from 'notistack';
import { syncToPopShop } from './ProductCardVariantAdd';
import SyncButton from './SyncButton';
import Base from './ProductCardVariantBase';
import { shopifyApi } from '../../apis/services';
import { getUser } from '../../util/setupFirebase';

const syncToShopify = (data: {
  popProduct?: PopProduct;
  product: ShopifyProduct;
  popShopVariant: PopVariant;
  shopifyVariant: ShopifyVariant;
}) => {
  const { popShopVariant, shopifyVariant, product } = data;
  const user = getUser();

  if (user && product.originalInfo.shopifyStoreId) {
    return shopifyApi.shopifyApiControllerEditProduct({
      sellerId: user.uid,
      shopifyStoreId: product.originalInfo.shopifyStoreId,
      inventoryLevelId: shopifyVariant.originalInfo!.shopifyInventoryLevelId!,
      setupProductRequestDto: {
        availableDelta: popShopVariant.quantity - shopifyVariant.quantity,
      },
    });
  } else {
    throw new Error('Please Login');
  }
};

const Sync = (props: {
  popProduct?: PopProduct;
  shopifyVariant: ShopifyVariant;
  product: ShopifyProduct;
  popShopVariant: PopVariant;
  updateShopifyProduct: (product: ShopifyProduct) => void;
}) => {
  const {
    popProduct,
    shopifyVariant,
    product,
    popShopVariant,
    updateShopifyProduct,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  return (
    <Base
      left={
        shopifyVariant.name === 'Default Title'
          ? 'Default'
          : shopifyVariant.name
      }
      right={
        <>
          <SyncButton
            type="shopify"
            amount={shopifyVariant.quantity}
            sx={{ marginLeft: 2 }}
            disabled={loading}
            loading={loading}
            onClick={async () => {
              setLoading(true);
              const data = await syncToPopShop({
                popProduct,
                variant: shopifyVariant,
                product,
              });
              setLoading(false);
              if (data.errorMessage) {
                enqueueSnackbar(data.errorMessage, { variant: 'error' });
              } else {
                enqueueSnackbar(data.successMessage, { variant: 'success' });
              }
            }}
          />
          <SyncButton
            type="popshop"
            disabled={loading}
            loading={loading}
            sx={{ marginLeft: 2 }}
            amount={popShopVariant.quantity}
            onClick={async () => {
              try {
                setLoading(true);
                await syncToShopify(props);
                shopifyVariant.quantity = popShopVariant.quantity;
                updateShopifyProduct({ ...product });
                enqueueSnackbar('Success', { variant: 'success' });
              } catch (error) {
                enqueueSnackbar((error as any).message, { variant: 'error' });
              } finally {
                setLoading(false);
              }
            }}
          />
        </>
      }
    />
  );
};

export default Sync;
