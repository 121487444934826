import React, { memo } from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

const AmountButton = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
  '&:focus': {
    boxShadow: 'none',
  },
  '&:disabled': {
    color: 'white',
    backgroundColor: theme.palette.fade.main,
  },
  pointerEvents: 'none',
  padding: 0,
  paddingLeft: 8,
  paddingRight: 8,
  minWidth: 30,
  height: 24,
  borderRadius: 12,
  display: 'flex', // after adjust the height, the label will move up in Safari, so make it center
  justifyContent: 'center',
  alignItems: 'center',
}));

export default memo(function ProductCardVariants(props: ButtonProps) {
  return <AmountButton variant="contained" color="primary" {...props} />;
});
