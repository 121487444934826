import React, { CSSProperties, memo } from 'react';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import { SvgIcon } from '@mui/material';
import { ReactComponent as ImporedIcon } from '../images/imported.svg';
import { ReactComponent as SyncedIcon } from '../images/synced.svg';

interface ProductCardThumbnailProps {
  src: string;
  synced?: boolean;
  imported?: boolean;
  loading?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
}

export default memo(function ProductCardThumbnail({
  src,
  synced,
  imported,
  loading,
  disabled,
  style,
  onClick,
}: ProductCardThumbnailProps) {
  return (
    <Box
      sx={{
        borderRadius: 2,
        overflow: 'hidden',
        ...style,
      }}
      boxShadow={3}
    >
      <ButtonBase sx={{ width: '100%' }} disabled={disabled} onClick={onClick}>
        <CardMedia
          sx={{
            width: '100%',
            height: 80,
            opacity: loading ? 0.6 : 1,
          }}
          image={src}
          title="shopify"
        />
        {loading ? (
          <div
            style={{
              pointerEvents: 'none',
              display: 'flex',
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : synced ? (
          <SvgIcon
            component={SyncedIcon}
            sx={{
              pointerEvents: 'none',
              display: 'flex',
              position: 'absolute',
              width: 24,
              height: 24,
              left: 5,
              top: 5,
            }}
          />
        ) : imported ? (
          <SvgIcon
            component={ImporedIcon}
            sx={{
              pointerEvents: 'none',
              display: 'flex',
              position: 'absolute',
              width: 24,
              height: 24,
              left: 5,
              top: 5,
            }}
          />
        ) : null}
      </ButtonBase>
    </Box>
  );
});
