import { ShopifyVariant } from '../types/products';

export const updateVariantDefaultName = (variant: ShopifyVariant) => ({
  ...variant,
  name: variant.name === 'Default Title' ? 'Default' : variant.name,
});

export const removeVariantSKU = (variant: ShopifyVariant) => {
  const { sku, ...rest } = variant;
  return rest;
};
