/* tslint:disable */
/* eslint-disable */
/**
 * PopshopLive API
 * PopshopLive API service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateInviteRequest,
    CreateNewsRequest,
    EditNewsRequest,
    Invite,
    News,
} from '../models';

export interface SellerDashboardApiControllerCreateInviteRequest {
    createInviteRequest: CreateInviteRequest;
}

export interface SellerDashboardApiControllerCreateNewsRequest {
    createNewsRequest: CreateNewsRequest;
}

export interface SellerDashboardApiControllerEditNewsRequest {
    id: string;
    editNewsRequest: EditNewsRequest;
}

/**
 * 
 */
export class SellerDashboardApi extends runtime.BaseAPI {

    /**
     */
    async sellerDashboardApiControllerCreateInviteRaw(requestParameters: SellerDashboardApiControllerCreateInviteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Invite>> {
        if (requestParameters.createInviteRequest === null || requestParameters.createInviteRequest === undefined) {
            throw new runtime.RequiredError('createInviteRequest','Required parameter requestParameters.createInviteRequest was null or undefined when calling sellerDashboardApiControllerCreateInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/seller-dashboard/invites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createInviteRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async sellerDashboardApiControllerCreateInvite(requestParameters: SellerDashboardApiControllerCreateInviteRequest, initOverrides?: RequestInit): Promise<Invite> {
        const response = await this.sellerDashboardApiControllerCreateInviteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sellerDashboardApiControllerCreateNewsRaw(requestParameters: SellerDashboardApiControllerCreateNewsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<News>> {
        if (requestParameters.createNewsRequest === null || requestParameters.createNewsRequest === undefined) {
            throw new runtime.RequiredError('createNewsRequest','Required parameter requestParameters.createNewsRequest was null or undefined when calling sellerDashboardApiControllerCreateNews.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/seller-dashboard/news`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createNewsRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async sellerDashboardApiControllerCreateNews(requestParameters: SellerDashboardApiControllerCreateNewsRequest, initOverrides?: RequestInit): Promise<News> {
        const response = await this.sellerDashboardApiControllerCreateNewsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sellerDashboardApiControllerEditNewsRaw(requestParameters: SellerDashboardApiControllerEditNewsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<News>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sellerDashboardApiControllerEditNews.');
        }

        if (requestParameters.editNewsRequest === null || requestParameters.editNewsRequest === undefined) {
            throw new runtime.RequiredError('editNewsRequest','Required parameter requestParameters.editNewsRequest was null or undefined when calling sellerDashboardApiControllerEditNews.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/seller-dashboard/news/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.editNewsRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async sellerDashboardApiControllerEditNews(requestParameters: SellerDashboardApiControllerEditNewsRequest, initOverrides?: RequestInit): Promise<News> {
        const response = await this.sellerDashboardApiControllerEditNewsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
