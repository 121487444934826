import React from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Base = (props: {
  left: string;
  leftOfQTY?: React.ReactNode;
  right: React.ReactNode;
}) => {
  const { left, leftOfQTY, right } = props;
  const theme = useTheme();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
      }}
    >
      <Typography component="div" variant="body2" sx={{ flex: 1 }}>
        <Box fontWeight="bold">{left}</Box>
      </Typography>
      {leftOfQTY}
      <Typography component="div" variant="body2">
        <Box fontWeight="bold" color={theme.palette.secondary.main}>
          QTY
        </Box>
      </Typography>
      {right}
    </div>
  );
};

export default Base;
