/* tslint:disable */
/* eslint-disable */
/**
 * PopshopLive API
 * PopshopLive API service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateOrderDto,
    CreateOrderResponse,
    CreateShipmentRequest,
    LabelRegenerationJobStatus,
    PickupNotifyDto,
    PreviewOrderDto,
    PreviewOrderResponse,
    RefundOrderRequest,
    ShippingFeeResponse,
    UpdateOrderByCommissionFeeRateRequest,
    UpdateOrderPackedStatusDto,
    UpdateOrderPickupStatusDto,
    UpdateOrderTrackingInfoDto,
    UpdateShippingAddressBySellerRequest,
    UpdateShippingAddressRequest,
} from '../models';

export interface OrderApiControllerAcceptGiftingRequest {
    orderId: string;
}

export interface OrderApiControllerCancelOrderRequest {
    orderId: string;
}

export interface OrderApiControllerCreateOrderRequest {
    createOrderDto: CreateOrderDto;
}

export interface OrderApiControllerCreateShipmentRequest {
    orderId: string;
    createShipmentRequest?: CreateShipmentRequest;
}

export interface OrderApiControllerExportLiveStreamOrdersAsCSVRequest {
    liveStreamId: string;
    target?: OrderApiControllerExportLiveStreamOrdersAsCSVTargetEnum;
    shippingMethod?: Array<OrderApiControllerExportLiveStreamOrdersAsCSVShippingMethodEnum>;
}

export interface OrderApiControllerFinalizeOrderRequest {
    orderId: string;
}

export interface OrderApiControllerGetShippingFeeRequest {
    sellerId: string;
    liveStreamId?: string;
}

export interface OrderApiControllerNotifyRequest {
    masterOrderId: string;
    pickupNotifyDto?: PickupNotifyDto;
}

export interface OrderApiControllerPreviewOrderRequest {
    previewOrderDto: PreviewOrderDto;
}

export interface OrderApiControllerReCreatShipmentRequest {
    orderId: string;
}

export interface OrderApiControllerRefundRequest {
    orderId: string;
    refundOrderRequest: RefundOrderRequest;
}

export interface OrderApiControllerSendOrderConfirmationEmailRequest {
    orderId: string;
}

export interface OrderApiControllerUpdateBraintreeTransactionsRequest {
    orderId: string;
}

export interface OrderApiControllerUpdateOrderByCommissionFeeRateRequest {
    liveStreamId: string;
    updateOrderByCommissionFeeRateRequest: UpdateOrderByCommissionFeeRateRequest;
}

export interface OrderApiControllerUpdateOrderPackedStatusRequest {
    masterOrderId: string;
    updateOrderPackedStatusDto: UpdateOrderPackedStatusDto;
}

export interface OrderApiControllerUpdateOrderPickupStatusRequest {
    masterOrderId: string;
    updateOrderPickupStatusDto: UpdateOrderPickupStatusDto;
}

export interface OrderApiControllerUpdateShippingAddressRequest {
    orderId: string;
    updateShippingAddressRequest: UpdateShippingAddressRequest;
}

export interface OrderApiControllerUpdateShippingAddressBySellerRequest {
    orderId: string;
    updateShippingAddressBySellerRequest: UpdateShippingAddressBySellerRequest;
}

export interface OrderApiControllerUpdateTrackingInfoRequest {
    orderId: string;
    updateOrderTrackingInfoDto: UpdateOrderTrackingInfoDto;
}

export interface OrderApiControllerVoidShipmentRequest {
    orderId: string;
}

/**
 * 
 */
export class OrderApi extends runtime.BaseAPI {

    /**
     */
    async orderApiControllerAcceptGiftingRaw(requestParameters: OrderApiControllerAcceptGiftingRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling orderApiControllerAcceptGifting.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}:accept-gifting`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderApiControllerAcceptGifting(requestParameters: OrderApiControllerAcceptGiftingRequest, initOverrides?: RequestInit): Promise<void> {
        await this.orderApiControllerAcceptGiftingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async orderApiControllerCancelOrderRaw(requestParameters: OrderApiControllerCancelOrderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling orderApiControllerCancelOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}:cancel`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderApiControllerCancelOrder(requestParameters: OrderApiControllerCancelOrderRequest, initOverrides?: RequestInit): Promise<void> {
        await this.orderApiControllerCancelOrderRaw(requestParameters, initOverrides);
    }

    /**
     */
    async orderApiControllerCreateOrderRaw(requestParameters: OrderApiControllerCreateOrderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreateOrderResponse>> {
        if (requestParameters.createOrderDto === null || requestParameters.createOrderDto === undefined) {
            throw new runtime.RequiredError('createOrderDto','Required parameter requestParameters.createOrderDto was null or undefined when calling orderApiControllerCreateOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createOrderDto,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async orderApiControllerCreateOrder(requestParameters: OrderApiControllerCreateOrderRequest, initOverrides?: RequestInit): Promise<CreateOrderResponse> {
        const response = await this.orderApiControllerCreateOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderApiControllerCreateShipmentRaw(requestParameters: OrderApiControllerCreateShipmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling orderApiControllerCreateShipment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}:create-shipment`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.createShipmentRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderApiControllerCreateShipment(requestParameters: OrderApiControllerCreateShipmentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.orderApiControllerCreateShipmentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async orderApiControllerExportLiveStreamOrdersAsCSVRaw(requestParameters: OrderApiControllerExportLiveStreamOrdersAsCSVRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.liveStreamId === null || requestParameters.liveStreamId === undefined) {
            throw new runtime.RequiredError('liveStreamId','Required parameter requestParameters.liveStreamId was null or undefined when calling orderApiControllerExportLiveStreamOrdersAsCSV.');
        }

        const queryParameters: any = {};

        if (requestParameters.target !== undefined) {
            queryParameters['target'] = requestParameters.target;
        }

        if (requestParameters.shippingMethod) {
            queryParameters['shippingMethod'] = requestParameters.shippingMethod;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/live-streams/{liveStreamId}/orders:export-csv`.replace(`{${"liveStreamId"}}`, encodeURIComponent(String(requestParameters.liveStreamId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async orderApiControllerExportLiveStreamOrdersAsCSV(requestParameters: OrderApiControllerExportLiveStreamOrdersAsCSVRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.orderApiControllerExportLiveStreamOrdersAsCSVRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderApiControllerFinalizeOrderRaw(requestParameters: OrderApiControllerFinalizeOrderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling orderApiControllerFinalizeOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}:finalize`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderApiControllerFinalizeOrder(requestParameters: OrderApiControllerFinalizeOrderRequest, initOverrides?: RequestInit): Promise<void> {
        await this.orderApiControllerFinalizeOrderRaw(requestParameters, initOverrides);
    }

    /**
     */
    async orderApiControllerGetShippingFeeRaw(requestParameters: OrderApiControllerGetShippingFeeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ShippingFeeResponse>> {
        if (requestParameters.sellerId === null || requestParameters.sellerId === undefined) {
            throw new runtime.RequiredError('sellerId','Required parameter requestParameters.sellerId was null or undefined when calling orderApiControllerGetShippingFee.');
        }

        const queryParameters: any = {};

        if (requestParameters.liveStreamId !== undefined) {
            queryParameters['liveStreamId'] = requestParameters.liveStreamId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/stores/{sellerId}/shipping-fee`.replace(`{${"sellerId"}}`, encodeURIComponent(String(requestParameters.sellerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async orderApiControllerGetShippingFee(requestParameters: OrderApiControllerGetShippingFeeRequest, initOverrides?: RequestInit): Promise<ShippingFeeResponse> {
        const response = await this.orderApiControllerGetShippingFeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderApiControllerNotifyRaw(requestParameters: OrderApiControllerNotifyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.masterOrderId === null || requestParameters.masterOrderId === undefined) {
            throw new runtime.RequiredError('masterOrderId','Required parameter requestParameters.masterOrderId was null or undefined when calling orderApiControllerNotify.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{masterOrderId}:packed-notify`.replace(`{${"masterOrderId"}}`, encodeURIComponent(String(requestParameters.masterOrderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.pickupNotifyDto,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderApiControllerNotify(requestParameters: OrderApiControllerNotifyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.orderApiControllerNotifyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async orderApiControllerPreviewOrderRaw(requestParameters: OrderApiControllerPreviewOrderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PreviewOrderResponse>> {
        if (requestParameters.previewOrderDto === null || requestParameters.previewOrderDto === undefined) {
            throw new runtime.RequiredError('previewOrderDto','Required parameter requestParameters.previewOrderDto was null or undefined when calling orderApiControllerPreviewOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders:preview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.previewOrderDto,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async orderApiControllerPreviewOrder(requestParameters: OrderApiControllerPreviewOrderRequest, initOverrides?: RequestInit): Promise<PreviewOrderResponse> {
        const response = await this.orderApiControllerPreviewOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async orderApiControllerReCreatShipmentRaw(requestParameters: OrderApiControllerReCreatShipmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling orderApiControllerReCreatShipment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}:recreate-shipment`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderApiControllerReCreatShipment(requestParameters: OrderApiControllerReCreatShipmentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.orderApiControllerReCreatShipmentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async orderApiControllerRefundRaw(requestParameters: OrderApiControllerRefundRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling orderApiControllerRefund.');
        }

        if (requestParameters.refundOrderRequest === null || requestParameters.refundOrderRequest === undefined) {
            throw new runtime.RequiredError('refundOrderRequest','Required parameter requestParameters.refundOrderRequest was null or undefined when calling orderApiControllerRefund.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}:refund`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.refundOrderRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderApiControllerRefund(requestParameters: OrderApiControllerRefundRequest, initOverrides?: RequestInit): Promise<void> {
        await this.orderApiControllerRefundRaw(requestParameters, initOverrides);
    }

    /**
     */
    async orderApiControllerRegenerateLabelsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders:regenerate-labels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderApiControllerRegenerateLabels(initOverrides?: RequestInit): Promise<void> {
        await this.orderApiControllerRegenerateLabelsRaw(initOverrides);
    }

    /**
     */
    async orderApiControllerRegenerateLabelsStatusRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<LabelRegenerationJobStatus>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders:regenerate-labels-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async orderApiControllerRegenerateLabelsStatus(initOverrides?: RequestInit): Promise<LabelRegenerationJobStatus> {
        const response = await this.orderApiControllerRegenerateLabelsStatusRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async orderApiControllerSendOrderConfirmationEmailRaw(requestParameters: OrderApiControllerSendOrderConfirmationEmailRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling orderApiControllerSendOrderConfirmationEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}:send-confirmation-mail`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderApiControllerSendOrderConfirmationEmail(requestParameters: OrderApiControllerSendOrderConfirmationEmailRequest, initOverrides?: RequestInit): Promise<void> {
        await this.orderApiControllerSendOrderConfirmationEmailRaw(requestParameters, initOverrides);
    }

    /**
     */
    async orderApiControllerUpdateBraintreeTransactionsRaw(requestParameters: OrderApiControllerUpdateBraintreeTransactionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling orderApiControllerUpdateBraintreeTransactions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}:updateTransactions`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderApiControllerUpdateBraintreeTransactions(requestParameters: OrderApiControllerUpdateBraintreeTransactionsRequest, initOverrides?: RequestInit): Promise<void> {
        await this.orderApiControllerUpdateBraintreeTransactionsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async orderApiControllerUpdateOrderByCommissionFeeRateRaw(requestParameters: OrderApiControllerUpdateOrderByCommissionFeeRateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.liveStreamId === null || requestParameters.liveStreamId === undefined) {
            throw new runtime.RequiredError('liveStreamId','Required parameter requestParameters.liveStreamId was null or undefined when calling orderApiControllerUpdateOrderByCommissionFeeRate.');
        }

        if (requestParameters.updateOrderByCommissionFeeRateRequest === null || requestParameters.updateOrderByCommissionFeeRateRequest === undefined) {
            throw new runtime.RequiredError('updateOrderByCommissionFeeRateRequest','Required parameter requestParameters.updateOrderByCommissionFeeRateRequest was null or undefined when calling orderApiControllerUpdateOrderByCommissionFeeRate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/live-streams/{liveStreamId}/orders:updateOrderByCommissionFeeRate`.replace(`{${"liveStreamId"}}`, encodeURIComponent(String(requestParameters.liveStreamId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updateOrderByCommissionFeeRateRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderApiControllerUpdateOrderByCommissionFeeRate(requestParameters: OrderApiControllerUpdateOrderByCommissionFeeRateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.orderApiControllerUpdateOrderByCommissionFeeRateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async orderApiControllerUpdateOrderPackedStatusRaw(requestParameters: OrderApiControllerUpdateOrderPackedStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.masterOrderId === null || requestParameters.masterOrderId === undefined) {
            throw new runtime.RequiredError('masterOrderId','Required parameter requestParameters.masterOrderId was null or undefined when calling orderApiControllerUpdateOrderPackedStatus.');
        }

        if (requestParameters.updateOrderPackedStatusDto === null || requestParameters.updateOrderPackedStatusDto === undefined) {
            throw new runtime.RequiredError('updateOrderPackedStatusDto','Required parameter requestParameters.updateOrderPackedStatusDto was null or undefined when calling orderApiControllerUpdateOrderPackedStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{masterOrderId}:pack`.replace(`{${"masterOrderId"}}`, encodeURIComponent(String(requestParameters.masterOrderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updateOrderPackedStatusDto,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderApiControllerUpdateOrderPackedStatus(requestParameters: OrderApiControllerUpdateOrderPackedStatusRequest, initOverrides?: RequestInit): Promise<void> {
        await this.orderApiControllerUpdateOrderPackedStatusRaw(requestParameters, initOverrides);
    }

    /**
     */
    async orderApiControllerUpdateOrderPickupStatusRaw(requestParameters: OrderApiControllerUpdateOrderPickupStatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.masterOrderId === null || requestParameters.masterOrderId === undefined) {
            throw new runtime.RequiredError('masterOrderId','Required parameter requestParameters.masterOrderId was null or undefined when calling orderApiControllerUpdateOrderPickupStatus.');
        }

        if (requestParameters.updateOrderPickupStatusDto === null || requestParameters.updateOrderPickupStatusDto === undefined) {
            throw new runtime.RequiredError('updateOrderPickupStatusDto','Required parameter requestParameters.updateOrderPickupStatusDto was null or undefined when calling orderApiControllerUpdateOrderPickupStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{masterOrderId}:set-pickup-status`.replace(`{${"masterOrderId"}}`, encodeURIComponent(String(requestParameters.masterOrderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updateOrderPickupStatusDto,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderApiControllerUpdateOrderPickupStatus(requestParameters: OrderApiControllerUpdateOrderPickupStatusRequest, initOverrides?: RequestInit): Promise<void> {
        await this.orderApiControllerUpdateOrderPickupStatusRaw(requestParameters, initOverrides);
    }

    /**
     */
    async orderApiControllerUpdateShippingAddressRaw(requestParameters: OrderApiControllerUpdateShippingAddressRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling orderApiControllerUpdateShippingAddress.');
        }

        if (requestParameters.updateShippingAddressRequest === null || requestParameters.updateShippingAddressRequest === undefined) {
            throw new runtime.RequiredError('updateShippingAddressRequest','Required parameter requestParameters.updateShippingAddressRequest was null or undefined when calling orderApiControllerUpdateShippingAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}:updateShippingAddress`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updateShippingAddressRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderApiControllerUpdateShippingAddress(requestParameters: OrderApiControllerUpdateShippingAddressRequest, initOverrides?: RequestInit): Promise<void> {
        await this.orderApiControllerUpdateShippingAddressRaw(requestParameters, initOverrides);
    }

    /**
     */
    async orderApiControllerUpdateShippingAddressBySellerRaw(requestParameters: OrderApiControllerUpdateShippingAddressBySellerRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling orderApiControllerUpdateShippingAddressBySeller.');
        }

        if (requestParameters.updateShippingAddressBySellerRequest === null || requestParameters.updateShippingAddressBySellerRequest === undefined) {
            throw new runtime.RequiredError('updateShippingAddressBySellerRequest','Required parameter requestParameters.updateShippingAddressBySellerRequest was null or undefined when calling orderApiControllerUpdateShippingAddressBySeller.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}:updateShippingAddressBySeller`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updateShippingAddressBySellerRequest,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderApiControllerUpdateShippingAddressBySeller(requestParameters: OrderApiControllerUpdateShippingAddressBySellerRequest, initOverrides?: RequestInit): Promise<void> {
        await this.orderApiControllerUpdateShippingAddressBySellerRaw(requestParameters, initOverrides);
    }

    /**
     */
    async orderApiControllerUpdateTrackingInfoRaw(requestParameters: OrderApiControllerUpdateTrackingInfoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling orderApiControllerUpdateTrackingInfo.');
        }

        if (requestParameters.updateOrderTrackingInfoDto === null || requestParameters.updateOrderTrackingInfoDto === undefined) {
            throw new runtime.RequiredError('updateOrderTrackingInfoDto','Required parameter requestParameters.updateOrderTrackingInfoDto was null or undefined when calling orderApiControllerUpdateTrackingInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}:track`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.updateOrderTrackingInfoDto,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderApiControllerUpdateTrackingInfo(requestParameters: OrderApiControllerUpdateTrackingInfoRequest, initOverrides?: RequestInit): Promise<void> {
        await this.orderApiControllerUpdateTrackingInfoRaw(requestParameters, initOverrides);
    }

    /**
     */
    async orderApiControllerVoidShipmentRaw(requestParameters: OrderApiControllerVoidShipmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.orderId === null || requestParameters.orderId === undefined) {
            throw new runtime.RequiredError('orderId','Required parameter requestParameters.orderId was null or undefined when calling orderApiControllerVoidShipment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/orders/{orderId}:voidShipment`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters.orderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async orderApiControllerVoidShipment(requestParameters: OrderApiControllerVoidShipmentRequest, initOverrides?: RequestInit): Promise<void> {
        await this.orderApiControllerVoidShipmentRaw(requestParameters, initOverrides);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum OrderApiControllerExportLiveStreamOrdersAsCSVTargetEnum {
    Admin = 'admin',
    Seller = 'seller'
}
/**
    * @export
    * @enum {string}
    */
export enum OrderApiControllerExportLiveStreamOrdersAsCSVShippingMethodEnum {
    SellerProvide = 'sellerProvide',
    PopshopProvide = 'popshopProvide',
    Pickup = 'pickup'
}
