import React from 'react';
import Loading from './views/Loading';
import { Dispatcher } from './views/Dispatcher';
import { ErrorBoundary } from './views/ErrorBoundary';
import 'fontsource-montserrat';
import { ThemeProvider, createTheme } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { setupAnalytics } from './analytics';

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
  },
  palette: {
    primary: {
      main: '#7B4BF6',
    },
    secondary: {
      main: '#6d6d6d',
    },
    fade: {
      main: 'rgba(0, 0, 0, 0.25)',
    },
  },
});

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    fade: Palette['primary'];
  }
  interface PaletteOptions {
    fade: PaletteOptions['primary'];
  }
}

setupAnalytics();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <div className="App">
          <ErrorBoundary>
            <React.Suspense
              fallback={<Loading tip="Communicating with Popshop.Live" />}
            >
              <Dispatcher />
            </React.Suspense>
          </ErrorBoundary>
        </div>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
