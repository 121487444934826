import { Middleware, RequestContext } from '../../../generated/api';
import { auth } from '../../../util/setupFirebase';

export default class BearerAuthMiddleware implements Middleware {
  async pre(request: RequestContext) {
    if (auth.currentUser == null) {
      return request;
    }
    const token = await auth.currentUser.getIdToken();
    return {
      ...request,
      init: {
        ...request.init,
        headers: {
          ...request.init.headers,
          Authorization: `Bearer ${token}`,
        },
      },
    };
  }
}
