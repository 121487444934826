import React, { memo } from 'react';
import Card from '@mui/material/Card';
import { PopProduct, PopVariant, ShopifyProduct } from '../../types/products';
import ProductCardThumbnail from './ProductCardThumbnail';
import ProductCardContent from './ProductCardContent';
import ProductCardExpandButton from './ProductCardExpandButton';
import ProductCardVariants from './ProductCardVariants';
import { useSnackbar } from 'notistack';
import { createPopProduct, updatePopProduct } from '../../apis/listing';

interface ProductCardProps {
  data: ShopifyProduct;
  popProduct?: PopProduct;
  updateShopifyProduct: (product: ShopifyProduct) => void;
}

export default memo(function ProductCard({
  data,
  popProduct,
  updateShopifyProduct,
}: ProductCardProps) {
  if (popProduct) {
    // ever imported
    const importedVariant: PopVariant[] = [];
    const syncedVariant: PopVariant[] = [];
    (popProduct.variants || []).forEach((popVariant) => {
      data.variants?.forEach((shopifyVariant) => {
        if (
          shopifyVariant.originalInfo.shopifyVariantId ===
          popVariant.originalInfo?.shopifyVariantId
        ) {
          importedVariant.push(popVariant);
          if (popVariant.quantity === shopifyVariant.quantity) {
            syncedVariant.push(popVariant);
          }
        }
      });
    });

    return (
      <MultiplyVariants
        shopifyProduct={data}
        popProduct={popProduct}
        synced={syncedVariant.length === data.variants?.length}
        imported={importedVariant.length > syncedVariant.length}
        syncedVariantsCount={syncedVariant.length}
        updateShopifyProduct={updateShopifyProduct}
      />
    );
  } else {
    // not imported
    return (
      <MultiplyVariants
        shopifyProduct={data}
        popProduct={popProduct}
        updateShopifyProduct={updateShopifyProduct}
      />
    );
  }
});

const MultiplyVariants = (props: {
  shopifyProduct: ShopifyProduct;
  popProduct?: PopProduct;
  synced?: boolean;
  imported?: boolean;
  syncedVariantsCount?: number;
  updateShopifyProduct: (product: ShopifyProduct) => void;
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const {
    shopifyProduct,
    popProduct,
    synced,
    syncedVariantsCount = 0,
    imported,
    updateShopifyProduct,
  } = props;
  const totalVariantsCount = shopifyProduct.variants?.length;

  const syncedVariantStr = syncedVariantsCount > 1 ? 'Variants' : 'Variant';
  const totalVariantStr = totalVariantsCount > 1 ? 'Variants' : 'Variant';

  return (
    <Card
      sx={{
        display: 'flex',
        marginTop: 1,
        borderRadius: 2,
        overflow: 'hidden',
        flexDirection: 'column',
        width: '100%',
        opacity: props.synced ? 0.5 : 1,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <ProductCardThumbnail
          src={
            shopifyProduct.defaultImage.startsWith('https://cdn.shopify.com')
              ? shopifyProduct.defaultImage
                  .replace('.jpg?', '_240x.jpg?')
                  .replace('.png?', '_240x.png?')
                  .replace('.jepg?', '_240x.jepg?')
              : shopifyProduct.defaultImage
          }
          style={{ flex: 2, margin: 1 }}
          synced={synced}
          imported={imported}
          loading={loading}
          disabled={loading || synced}
          onClick={async () => {
            setLoading(true);
            const result =
              imported && popProduct
                ? await updatePopProduct(
                    popProduct!.id,
                    shopifyProduct,
                    popProduct.variants
                  )
                : await createPopProduct(shopifyProduct);
            setLoading(false);
            if (result.errorMessage) {
              enqueueSnackbar(result.errorMessage, { variant: 'error' });
            } else {
              enqueueSnackbar(result.successMessage, { variant: 'success' });
            }
          }}
        />
        <ProductCardContent
          title={shopifyProduct.name}
          price={shopifyProduct.price}
          discountPrice={shopifyProduct.discountPrice}
          variants={
            syncedVariantsCount === totalVariantsCount
              ? `${syncedVariantsCount} ${syncedVariantStr} - Imported`
              : syncedVariantsCount > 0
              ? `${syncedVariantsCount} of ${totalVariantsCount} ${syncedVariantStr} - Imported`
              : `${totalVariantsCount} ${totalVariantStr}`
          }
          variantsTextColor={
            syncedVariantsCount > 0 ? 'primary' : 'textSecondary'
          }
          sx={{ flex: 5, padding: 1 }}
          qty={shopifyProduct.quantity}
        />
        <ProductCardExpandButton
          expanded={expanded}
          onExpandClick={setExpanded}
        />
      </div>
      <ProductCardVariants
        data={shopifyProduct}
        popProduct={popProduct}
        expanded={expanded}
        updateShopifyProduct={updateShopifyProduct}
      />
    </Card>
  );
};
