import React, { memo } from 'react';
import ProductPicker from './ProductPicker';
import 'firebase/firestore';
import { useReadStoreListing } from '../util/readStoreListings';
import Button from '@mui/material/Button';
import { Avatar, Typography } from '@mui/material';
import { SHOPIFY_LOGO, POPSHOP_LOGO } from '../constant/pics';
import SearchInput from './components/SearchInput';
import ProfileButton from './components/ProfileButton';
import Loading from './Loading';
import { useStoreInfo } from './Dispatcher';

const HEADER_HEIGHT = 160;

export default memo(function RootPage() {
  const { storeId } = useStoreInfo();
  const [keyword, setKeyword] = React.useState<string>();
  const [inStock, setInStock] = React.useState(false);
  const {
    data: currentPopProducts,
    error,
    loading,
  } = useReadStoreListing(storeId);

  const fromDS =
    new URLSearchParams(window.location.search).get('fromDS') === 'true';

  if (error) {
    return <Typography variant="body1">{error}</Typography>;
  } else if (loading) {
    return <Loading tip="Loading Products"></Loading>;
  }

  return (
    <div
      style={{
        backgroundColor: '#E3E4E5',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        paddingBottom: 16,
      }}
    >
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          height: HEADER_HEIGHT,
          backgroundColor: 'white',
          zIndex: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            paddingTop: 16,
            paddingBottom: 16,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {!fromDS && (
            <Button
              variant="contained"
              color="primary"
              sx={{
                position: 'absolute',
                left: 16,
                boxShadow: 'none',
                backgroundColor: 'fade.main',
                '&:focus': {
                  boxShadow: 'none',
                  backgroundColor: 'fade.main',
                },
                height: 28,
                borderRadius: 14,
                fontWeight: 'bold',
              }}
              onClick={() => {
                window.location.replace('popshoplive://');
              }}
            >
              Back to App
            </Button>
          )}
          <>
            <Avatar
              src={SHOPIFY_LOGO}
              sx={{
                position: 'relative',
                left: 10,
                zIndex: 1,
              }}
            />
            <Avatar src={POPSHOP_LOGO} />
          </>
          <ProfileButton
            style={{
              position: 'absolute',
              right: 16,
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            padding: 16,
            justifyContent: 'center',
          }}
        >
          <SearchInput
            onSearchClicked={({ inStock, keyword }) => {
              setKeyword(keyword);
              setInStock(inStock);
            }}
          />
        </div>
      </div>
      <div
        style={{
          marginTop: HEADER_HEIGHT,
        }}
      >
        <ProductPicker
          currentPopProducts={currentPopProducts}
          keyword={keyword}
          inStock={inStock}
        />
      </div>
    </div>
  );
});
