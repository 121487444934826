import React, { memo } from 'react';
import Collapse from '@mui/material/Collapse';
import {
  PopProduct,
  ShopifyProduct,
  ShopifyVariant,
} from '../../types/products';
import Add from './ProductCardVariantAdd';
import Imported from './ProductCardVariantImported';
import Sync from './ProductCardVariantSync';

interface ProductCardVariantsProps {
  data: ShopifyProduct;
  popProduct?: PopProduct;
  expanded: boolean;
  updateShopifyProduct: (product: ShopifyProduct) => void;
}

export default memo(function ProductCardVariants({
  data,
  popProduct,
  expanded,
  updateShopifyProduct,
}: ProductCardVariantsProps) {
  return (
    <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ padding: 2 }}>
      {data.variants.map((shopifyVariant) => {
        return (
          <Variant
            key={shopifyVariant.id}
            shopifyVariant={shopifyVariant}
            popProduct={popProduct}
            product={data}
            updateShopifyProduct={updateShopifyProduct}
          />
        );
      })}
    </Collapse>
  );
});

function usePrevious<T>(value: T) {
  const ref = React.useRef<T>();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

type State = 'add' | 'imported' | 'sync';

const Variant = (props: {
  shopifyVariant: ShopifyVariant;
  product: ShopifyProduct;
  popProduct?: PopProduct;
  updateShopifyProduct: (product: ShopifyProduct) => void;
}) => {
  const { shopifyVariant, product, popProduct, updateShopifyProduct } = props;

  const popVariant = (popProduct?.variants || []).find(
    (item) =>
      item.originalInfo?.shopifyVariantId ===
      shopifyVariant.originalInfo.shopifyVariantId
  );

  let state: State = 'add';
  if (!popVariant) {
    state = 'add';
  } else if (popVariant.quantity === shopifyVariant.quantity) {
    state = 'imported';
  } else {
    state = 'sync';
  }

  const preProps = usePrevious({ state });
  const newImported =
    preProps && preProps.state !== 'imported' && state === 'imported';

  if (state === 'add') {
    return (
      <Add
        key={shopifyVariant.id}
        popProduct={popProduct}
        variant={shopifyVariant}
        product={product}
      />
    );
  } else if (state === 'imported') {
    return (
      <Imported
        key={shopifyVariant.id}
        data={popVariant!}
        newImported={newImported}
      />
    );
  } else {
    return (
      <Sync
        key={shopifyVariant.id}
        popProduct={popProduct}
        shopifyVariant={shopifyVariant}
        popShopVariant={popVariant!}
        product={product}
        updateShopifyProduct={updateShopifyProduct}
      />
    );
  }
};
