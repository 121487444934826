import React, { memo, useState } from 'react';
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Conatiner from '../components/Conatiner';
import { AnalyticEvents } from '../../generated/AnalyticEvents';
import { getUser } from '../../util/setupFirebase';

interface StoreSelectionProps {
  stores: {
    id: string;
    name: string;
  }[];
  setStoreId: (storeId: string) => void;
}

export default memo(function StoreSelection({
  setStoreId,
  stores,
}: StoreSelectionProps) {
  const [value, setValue] = useState(stores[0]?.id);

  return (
    <Conatiner>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          overflow: 'auto',
        }}
      >
        <Typography variant="h4">Choose a store</Typography>
        <RadioGroup
          aria-label="gender"
          value={value}
          onChange={(data) => setValue(data.target.value)}
        >
          {stores.map((store) => (
            <FormControlLabel
              key={store.id}
              value={store.id}
              control={<Radio />}
              label={store.name}
            />
          ))}
        </RadioGroup>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setStoreId(value);
            AnalyticEvents.default.shopifyLinkerInitializeSelectStore({
              id: value,
              sellerId: getUser()?.uid ?? '',
            });
          }}
        >
          Next
        </Button>
      </div>
    </Conatiner>
  );
});
