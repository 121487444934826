import { PopVariant, ShopifyProduct } from '../types/products';
import { getReadonlyProfile, getUser } from '../util/setupFirebase';
import {
  removeVariantSKU,
  updateVariantDefaultName,
} from '../util/updateVariantDefaultName';
import { storeListingApi } from './services';

let shopifyListingSyncWithoutSKU: boolean | undefined = undefined;
const getShopifyListingSyncWithoutSKU = async () => {
  if (shopifyListingSyncWithoutSKU == null) {
    const profile = await getReadonlyProfile();
    shopifyListingSyncWithoutSKU =
      profile?.features?.shopifyListingSyncWithoutSKU;
  }
  return !!shopifyListingSyncWithoutSKU;
};

// This only fixed the bug by multiply calling by one client.
// Need fix on the remote side.
const listingCalling: string[] = [];

export const updatePopProduct = async (
  listingId: string,
  product: ShopifyProduct,
  popVariants?: PopVariant[]
) => {
  const user = getUser();
  if (user) {
    try {
      const shopifyListingSyncWithoutSKU =
        await getShopifyListingSyncWithoutSKU();
      if (listingCalling.includes(listingId)) {
        return {
          errorMessage: 'The listing is updating please try again later.',
        };
      } else {
        listingCalling.push(listingId);
      }
      await storeListingApi.storeListingApiControllerEditStoreListing({
        storeId: user.uid,
        listingId,
        editStoreListingRequest: {
          ...product,
          variants: product.variants?.map((variant) => {
            const data = updateVariantDefaultName(variant);
            return shopifyListingSyncWithoutSKU ? removeVariantSKU(data) : data;
          }),
          currentVariants: popVariants,
          currentQuantity: (popVariants ?? []).reduce(
            (pre, cur) => pre + cur.quantity,
            0
          ),
        },
      });
      const index = listingCalling.findIndex((item) => item === listingId);
      if (index >= 0) {
        listingCalling.splice(index, 1);
      }
    } catch (error) {
      return {
        errorMessage: (error as any)?.message ?? 'Failed, please try again',
      };
    }

    return { successMessage: 'Success' };
  } else {
    return { errorMessage: 'Please Login' };
  }
};

export const createPopProduct = async (product: ShopifyProduct) => {
  const user = getUser();
  if (user) {
    try {
      const shopifyListingSyncWithoutSKU =
        await getShopifyListingSyncWithoutSKU();
      const { id, ...rest } = product;
      const productId = product.originalInfo.shopifyProductId ?? id;
      if (listingCalling.includes(productId)) {
        return {
          errorMessage: 'The listing is updating please try again later.',
        };
      } else {
        listingCalling.push(productId);
      }
      await storeListingApi.storeListingApiControllerImportStoreListings({
        storeId: user.uid,
        importStoreListing: {
          ...rest,
          variants: product.variants?.map((variant) => {
            const data = updateVariantDefaultName(variant);
            return shopifyListingSyncWithoutSKU ? removeVariantSKU(data) : data;
          }),
        } as any,
      });
      const index = listingCalling.findIndex((item) => item === productId);
      if (index >= 0) {
        listingCalling.splice(index, 1);
      }
    } catch (error) {
      return {
        errorMessage: (error as any)?.message ?? 'Failed, please try again',
      };
    }

    return { successMessage: 'Success' };
  } else {
    return { errorMessage: 'Please Login' };
  }
};

// Deprecated
export const queryAllProducts = async (shopifyStoreId: string) => {
  const user = getUser();
  if (user) {
    try {
      // const { products } =
      //   await shopifyApi.shopifyApiControllerQueryAllProducts({
      //     sellerId: user.uid,
      //     shopifyStoreId,
      //   });
      // console.log({ products });
    } catch (error) {
      return {
        errorMessage: (error as any)?.message ?? 'Failed, please try again',
      };
    }

    return { successMessage: 'Success' };
  } else {
    return { errorMessage: 'Please Login' };
  }
};
