import { AnalyticEvents } from '../generated/AnalyticEvents';
import apiConfig from '../../src/config/apiConfig.json';

const { isProd } = apiConfig;

declare global {
  interface Window {
    // when run in storybook mode, this method does not exist
    analytics?: {
      _writeKey: string;
      load: (key: string) => void;
      track: (msg: string, extraData?: any, options?: any) => void;
      page: (screen: string, params: any) => void;
      identify: (userId?: string) => void;
    };
  }
}

export function setupAnalytics() {
  const key =
    isProd === 'true'
      ? '20CrFsS9C4dUfatgeewjYuVs97XwgHFr'
      : 'SyMZtV3iOHsCcL1FbKnat4DJ0VjlM30o';

  if (window.analytics) {
    window.analytics._writeKey = key;
    window.analytics.load(key);
  }
}
export function setAnalyticsUser(uid?: string) {
  if (uid != null) {
    void window.analytics?.identify?.(uid);
  }
}

const analytics = {
  track(msg: string, extraData?: any, options?: any) {
    void window.analytics?.track?.(msg, extraData, options);
  },
  screen: (page: string, params: any) => {
    void window.analytics?.page(page, params);
  },
};

AnalyticEvents.default.addEventListener((event, properties) => {
  analytics.track(event, properties);
  // @ts-ignore
  if (window.DEBUG_TRACKING) {
    console.log(`[EVENT]: ${event} - ${JSON.stringify(properties)}`);
  }
});

export default analytics;
