import React from 'react';
import {
  PopProduct,
  ShopifyProduct,
  ShopifyVariant,
} from '../../types/products';
import AmountButton from './AmountButton';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import { CircularProgress } from '@mui/material';
import Base from './ProductCardVariantBase';
import { createPopProduct, updatePopProduct } from '../../apis/listing';

export const syncToPopShop = async (data: {
  popProduct?: PopProduct;
  variant: ShopifyVariant;
  product: ShopifyProduct;
}) => {
  const { popProduct, product, variant } = data;

  if (popProduct) {
    const existedVariantIndex =
      popProduct.variants?.findIndex(
        (popVariant) =>
          popVariant.originalInfo?.shopifyVariantId ===
          variant.originalInfo.shopifyVariantId
      ) ?? -1;
    if (existedVariantIndex > -1) {
      const variants = [...(popProduct.variants || [])];
      variants.splice(existedVariantIndex, 1, variant);
      return await updatePopProduct(
        popProduct.id,
        {
          ...product,
          variants: variants as any,
        },
        popProduct.variants
      );
    } else {
      return await updatePopProduct(
        popProduct.id,
        {
          ...product,
          variants: [...(popProduct.variants || []), variant] as any,
        },
        popProduct.variants
      );
    }
  } else {
    return await createPopProduct({
      ...product,
      variants: [variant],
    });
  }
};

const Add = (props: {
  popProduct?: PopProduct;
  variant: ShopifyVariant;
  product: ShopifyProduct;
}) => {
  const { name, quantity } = props.variant;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  return (
    <Base
      left={name === 'Default Title' ? 'Default' : name}
      right={
        <>
          <AmountButton
            sx={{
              backgroundColor: 'fade.main',
              '&:focus': {
                backgroundColor: 'fade.main',
              },
              marginLeft: 2,
            }}
          >
            {quantity}
          </AmountButton>
          <Button
            variant="contained"
            color="primary"
            sx={{
              color: 'fade.main',
              backgroundColor: 'white',
              '&:focus': {
                backgroundColor: 'white',
              },
              marginLeft: 2,
            }}
            disabled={loading}
            onClick={async () => {
              setLoading(true);
              const data = await syncToPopShop(props);
              setLoading(false);
              if (data.errorMessage) {
                enqueueSnackbar(data.errorMessage, { variant: 'error' });
              } else {
                enqueueSnackbar(data.successMessage, { variant: 'success' });
              }
            }}
          >
            {loading ? <CircularProgress color="primary" size="1rem" /> : 'ADD'}
          </Button>
        </>
      }
    />
  );
};

export default Add;
