import React, { ReactNode, useCallback, useContext, useState } from 'react';
import useSWR from 'swr';
import { loginToFirebase } from '../util/loginToFirebase';
import RootPage from './RootPage';
import SWRKeys from '../constant/swr';
import LocationSelection from './components/LocationSelection';
import { shopifyApi } from '../apis/services';
import StoreSelection from './login/StoreSelection';
import { localStorageHelper } from '../util/localStorageHelper';
import { LocationDto } from '../generated/api';
import { AnalyticEvents } from '../generated/AnalyticEvents';
import { setAnalyticsUser } from '../analytics';
import { auth, getUser, signOut } from '../util/setupFirebase';
import { onAuthStateChanged } from 'firebase/auth';
import { ErrorBoundary, ErrorView } from './ErrorBoundary';
import Loading from './Loading';
import { ShopifyAuth } from './components/ShopifyAuth';

const uuid = new URLSearchParams(window.location.search).get('uuid');

type Props = { children: ReactNode };

export const reload = () => {
  const params = new URLSearchParams(window.location.search);
  params.delete('uuid');
  const search = params.toString();
  window.location.replace(
    !!search
      ? `${window.location.pathname}?${search}`
      : window.location.pathname
  );
};

const StoreProviderContext = React.createContext<{
  storeId: string;
}>(null as any);

const StoreProvider: React.FC<Props> = React.memo((props) => {
  const initializer = useCallback(async () => {
    const login = async () => {
      return new Promise<string | undefined>(async (resolve, reject) => {
        if (uuid) {
          try {
            await signOut();
            const data =
              await shopifyApi.shopifyApiControllerGenerateCustomToken({
                generateCustomTokenRequest: { uuid },
              } as any);
            await loginToFirebase(data.token);

            localStorageHelper.set(`storeId`, data.storeId);

            reload();
            resolve(data.storeId);
          } catch (error) {
            reject((error as any)?.message);
          }
        } else {
          const timer = setTimeout(() => {
            reject('Please open by the PopShop.Live app.');
          }, 3000);
          onAuthStateChanged(
            auth,
            async (state) => {
              if (state && state.uid) {
                clearTimeout(timer);
                resolve(undefined);
              }
            },
            (e) => {
              clearTimeout(timer);
              reject(e.message);
            }
          );
        }
      });
    };
    await login();

    let storeId = localStorageHelper.get(`storeId`)?.value as
      | string
      | undefined;

    const user = getUser();
    setAnalyticsUser(user?.uid);
    const fromDS =
      new URLSearchParams(window.location.search).get('fromDS') === 'true';
    const launched =
      localStorageHelper.get(`launched-${user?.uid ?? ''}`)?.value ?? 0;
    const currentLaunched = launched + 1;
    localStorageHelper.set(`launched-${user?.uid ?? ''}`, currentLaunched);

    if (!storeId && user) {
      const { stores } = await shopifyApi.shopifyApiControllerQueryStores({
        sellerId: user.uid,
      });

      if (stores.length === 1) {
        storeId = stores[0].id;
      } else {
        AnalyticEvents.default.shopifyLinkerLaunched({
          counter: currentLaunched,
          sellerId: user?.uid ?? '',
          origin: fromDS ? 'sd' : 'app',
        });
        return { stores };
      }
    }

    AnalyticEvents.default.shopifyLinkerLaunched({
      counter: launched?.value ?? 1,
      sellerId: user?.uid ?? '',
      storeId,
      origin: fromDS ? 'sd' : 'app',
    });
    return { storeId };
  }, []);

  const { data } = useSWR(SWRKeys.Store, initializer, {
    suspense: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const [storeId, setStoreId] = useState<string>();

  return data == null ? null : 'storeId' in data || storeId != null ? (
    <StoreProviderContext.Provider
      value={storeId ? { storeId } : (data as any)}
    >
      {props.children}
    </StoreProviderContext.Provider>
  ) : 'stores' in data ? (
    <StoreSelection stores={data.stores!} setStoreId={setStoreId} />
  ) : null;
});

const LocationContext = React.createContext<{
  storeId: string;
  locationId: string;
  locations: LocationDto[];
}>(null as any);

export const useShopifyStoreId = () => useContext(StoreProviderContext).storeId;

const LocationProvider: React.FC<Props> = (props) => {
  const user = getUser();
  const sellerId = user?.uid;

  const { storeId } = useContext(StoreProviderContext);

  const initializer = useCallback(async () => {
    if (!sellerId) return;

    try {
      const { locationId } = await shopifyApi.shopifyApiControllerValidateStore(
        {
          shopifyStoreId: storeId,
          sellerId,
        }
      );
      const { locations } = await shopifyApi.shopifyApiControllerQueryLocations(
        {
          shopifyStoreId: storeId,
          sellerId,
        }
      );
      return { locationId, locations };
    } catch (error) {
      if (
        (error as any)?.name === 403 &&
        (error as any)?.message.startsWith('https://')
      ) {
        return { url: (error as any).message as string };
      } else if (
        (error as any)?.name === 404 &&
        (error as any)?.message === 'location not found'
      ) {
        const { locations } =
          await shopifyApi.shopifyApiControllerQueryLocations({
            shopifyStoreId: storeId,
            sellerId,
          });
        return { locations };
      } else {
        throw error;
      }
    }
  }, [storeId, sellerId]);

  const { data, error } = useSWR(SWRKeys.Location, initializer, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    shouldRetryOnError: false,
  });

  return error ? (
    <ErrorView errorMessage={error.message} />
  ) : data == null ? (
    <Loading />
  ) : 'url' in data ? (
    <ShopifyAuth url={data.url!} />
  ) : 'locationId' in data && 'locations' in data ? (
    <LocationContext.Provider
      value={{
        storeId,
        locationId: data.locationId!,
        locations: data.locations,
      }}
    >
      {props.children}
    </LocationContext.Provider>
  ) : 'locations' in data ? (
    <LocationSelection storeId={storeId} locations={data.locations!} />
  ) : null;
};

export const useStoreInfo = () => useContext(LocationContext);

export const Dispatcher = () => {
  return (
    <StoreProvider>
      <ErrorBoundary>
        <LocationProvider>
          <RootPage />
        </LocationProvider>
      </ErrorBoundary>
    </StoreProvider>
  );
};
