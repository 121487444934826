/* tslint:disable */
/* eslint-disable */
/**
 * PopshopLive API
 * PopshopLive API service
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddPaymentMethodRequest,
    AddPaymentMethodRequestV2,
    BraintreeTokenResponse,
    IdResponse,
    SetPaymentMethodBillingAddressRequest,
} from '../models';

export interface PaymentApiControllerAddPaymentMethodRequest {
    userId: string;
    addPaymentMethodRequest: AddPaymentMethodRequest;
}

export interface PaymentApiControllerAddPaymentMethodV2Request {
    userId: string;
    addPaymentMethodRequestV2: AddPaymentMethodRequestV2;
}

export interface PaymentApiControllerEditPaymentMethodRequest {
    userId: string;
    paymentId: string;
    setPaymentMethodBillingAddressRequest: SetPaymentMethodBillingAddressRequest;
}

export interface PaymentApiControllerRemovePaymentMethodRequest {
    userId: string;
    paymentId: string;
}

/**
 * 
 */
export class PaymentApi extends runtime.BaseAPI {

    /**
     */
    async paymentApiControllerAddPaymentMethodRaw(requestParameters: PaymentApiControllerAddPaymentMethodRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IdResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling paymentApiControllerAddPaymentMethod.');
        }

        if (requestParameters.addPaymentMethodRequest === null || requestParameters.addPaymentMethodRequest === undefined) {
            throw new runtime.RequiredError('addPaymentMethodRequest','Required parameter requestParameters.addPaymentMethodRequest was null or undefined when calling paymentApiControllerAddPaymentMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{userId}/payment-methods`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.addPaymentMethodRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async paymentApiControllerAddPaymentMethod(requestParameters: PaymentApiControllerAddPaymentMethodRequest, initOverrides?: RequestInit): Promise<IdResponse> {
        const response = await this.paymentApiControllerAddPaymentMethodRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async paymentApiControllerAddPaymentMethodV2Raw(requestParameters: PaymentApiControllerAddPaymentMethodV2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IdResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling paymentApiControllerAddPaymentMethodV2.');
        }

        if (requestParameters.addPaymentMethodRequestV2 === null || requestParameters.addPaymentMethodRequestV2 === undefined) {
            throw new runtime.RequiredError('addPaymentMethodRequestV2','Required parameter requestParameters.addPaymentMethodRequestV2 was null or undefined when calling paymentApiControllerAddPaymentMethodV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v2/users/{userId}/payment-methods`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.addPaymentMethodRequestV2,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async paymentApiControllerAddPaymentMethodV2(requestParameters: PaymentApiControllerAddPaymentMethodV2Request, initOverrides?: RequestInit): Promise<IdResponse> {
        const response = await this.paymentApiControllerAddPaymentMethodV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async paymentApiControllerEditPaymentMethodRaw(requestParameters: PaymentApiControllerEditPaymentMethodRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IdResponse>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling paymentApiControllerEditPaymentMethod.');
        }

        if (requestParameters.paymentId === null || requestParameters.paymentId === undefined) {
            throw new runtime.RequiredError('paymentId','Required parameter requestParameters.paymentId was null or undefined when calling paymentApiControllerEditPaymentMethod.');
        }

        if (requestParameters.setPaymentMethodBillingAddressRequest === null || requestParameters.setPaymentMethodBillingAddressRequest === undefined) {
            throw new runtime.RequiredError('setPaymentMethodBillingAddressRequest','Required parameter requestParameters.setPaymentMethodBillingAddressRequest was null or undefined when calling paymentApiControllerEditPaymentMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{userId}/payment-methods/{paymentId}:setBillingAddress`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"paymentId"}}`, encodeURIComponent(String(requestParameters.paymentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.setPaymentMethodBillingAddressRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async paymentApiControllerEditPaymentMethod(requestParameters: PaymentApiControllerEditPaymentMethodRequest, initOverrides?: RequestInit): Promise<IdResponse> {
        const response = await this.paymentApiControllerEditPaymentMethodRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async paymentApiControllerGenerateBraintreeTokenRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<BraintreeTokenResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/braintree:create-token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     */
    async paymentApiControllerGenerateBraintreeToken(initOverrides?: RequestInit): Promise<BraintreeTokenResponse> {
        const response = await this.paymentApiControllerGenerateBraintreeTokenRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async paymentApiControllerRemovePaymentMethodRaw(requestParameters: PaymentApiControllerRemovePaymentMethodRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling paymentApiControllerRemovePaymentMethod.');
        }

        if (requestParameters.paymentId === null || requestParameters.paymentId === undefined) {
            throw new runtime.RequiredError('paymentId','Required parameter requestParameters.paymentId was null or undefined when calling paymentApiControllerRemovePaymentMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/users/{userId}/payment-methods/{paymentId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"paymentId"}}`, encodeURIComponent(String(requestParameters.paymentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async paymentApiControllerRemovePaymentMethod(requestParameters: PaymentApiControllerRemovePaymentMethodRequest, initOverrides?: RequestInit): Promise<void> {
        await this.paymentApiControllerRemovePaymentMethodRaw(requestParameters, initOverrides);
    }

}
