import React, { memo } from 'react';
import IconButton from '@mui/material/IconButton';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     expand: {
//       transform: 'rotate(0deg)',
//       marginLeft: 'auto',
//       transition: theme.transitions.create('transform', {
//         duration: theme.transitions.duration.shortest,
//       }),
//     },
//     expandOpen: {
//       transform: 'rotate(180deg)',
//     },
//   })
// );

interface ProductCardExpandButtonProps {
  expanded: boolean;
  onExpandClick: (expanded: boolean) => void;
}

export default memo(function ProductCardExpandButton({
  expanded,
  onExpandClick,
}: ProductCardExpandButtonProps) {
  const handleExpandClick = () => {
    onExpandClick(!expanded);
  };

  return (
    <IconButton
      sx={{
        marginLeft: 'auto',
        transition: `transitions.create('transform', {
        duration: transitions.duration.shortest,
      })`,
        transform: expanded ? 'rotate(180deg)' : undefined,
      }}
      onClick={handleExpandClick}
      aria-expanded={expanded}
      aria-label="show more"
    >
      <ExpandMoreRoundedIcon />
    </IconButton>
  );
});
