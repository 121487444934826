const SWRKeys = {
  Token: 'Token',
  Store: 'Store',
  Location: 'Location',
  FetchPopProducts: 'FetchPopProducts',
  FetchShopifyProducts: 'FetchShopifyProducts',
  FetchUserProfile: 'FetchUserProfile',
};

export default SWRKeys;
