import React, { memo } from 'react';
import { Avatar } from '@mui/material';
import { SHOPIFY_LOGO, POPSHOP_LOGO } from '../../constant/pics';

const HEADER_HEIGHT = 160;

export default memo(function Conatiner({
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <div
      style={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        paddingBottom: 16,
      }}
    >
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          height: 80,
          backgroundColor: 'white',
          zIndex: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
            paddingTop: 16,
            paddingBottom: 16,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <>
            <Avatar
              src={SHOPIFY_LOGO}
              style={{
                position: 'relative',
                left: 10,
                zIndex: 1,
              }}
            />
            <Avatar src={POPSHOP_LOGO} />
          </>
        </div>
      </div>
      <div
        style={{
          marginTop: HEADER_HEIGHT,
        }}
      >
        {children}
      </div>
    </div>
  );
});
