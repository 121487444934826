/**
 * @file: analytic-events.ts
 * @copyright: 2021, popshop live, inc.
 *
 * Analytic events functions in this file are generated by a tool from the
 * definition at https://github.com/popshoplive/psl-analytics-events.
 * Don't make changes to this file, otherwise all changes you made will be
 * overwritten the next time this file is automatically generated again.
 */

export type AnalyticEventProps = {
  [key: string]: any;
  [index: number]: any;
};

export type AnalyticEventListener = (
  event: string,
  props?: AnalyticEventProps,
) => void;

export interface AnalyticEventEmitter {
  emit(event: string, props?: AnalyticEventProps): void;
  addEventListener(listener: AnalyticEventListener): () => void;
  removeEventListener(listener: AnalyticEventListener): void;
}

/**
 * Class that provides methods for psl analytic event tracking.
 * @abstract
 *
 * @description
 * This class is abstract thus is not intended be used directly by creating
 * instance of this class and call event tracking methods on it.
 *
 * Instead, either use the singleton instance `AnalyticEvents.default` that
 * provides the default implementation in a event driven manner, for example:
 *
 * @example
 * ```typescript
 * // register event listener that handles emitted events
 * AnalyticEvents.default.addEventListener((event, props) => {
 *  // call other event library method to do the real event dispatch
 *  someEventLib.track(event, props);
 * });
 * // call instance methods on the default instance to emit events
 * AnalyticEvents.default.liveShowBroadcastStarted({
 *   showId: '...',
 *   agoraAccount: '...'
 * });
 * ```
 * Or, you can implement this class yourself by implementing the `track` method,
 * for example,
 *
 * @example
 * ```typescript
 * class MyAnalyticEventsImpl extends AnalyticEvents {
 *   track(event: string, props: AnalyticEventProps) {
 *     // call other event library method to do the real event dispatch
 *     someEventLib.track(event, props);
 *   }
 * }
 * // create instance of your implementation
 * const analyticEvents = new MyAnalyticEventsImpl();
 * // and then call instance event tracking methods on it
 * analyticEvents.liveShowBroadcastStarted({
 *   showId: '...',
 *   agoraAccount: '...'
 * });
 * ```
 * The default implementation is recommended to use unless custom implementation
 * is necessary in rare cases.
 */
export abstract class AnalyticEvents {
  //#region Default Implementation
  /** @readonly instance that implements the default analytic event tracking */
  static readonly default = new (class DefaultAnalyticEventsImpl
    extends AnalyticEvents
    implements AnalyticEventEmitter
  {
    private listeners: AnalyticEventListener[] = [];

    /**
     * Method that can be called to emit custom analytic event that is not
     * exposed via the automatically generated tracking methods.
     * @param event the name of the custom event to emit
     * @param [props] the custom event properties
     */
    emit(event: string, props?: AnalyticEventProps): void {
      props = eventPropsConverted(props);
      for (const listener of this.listeners) {
        listener(event, props);
      }
    }

    /**
     * Add analytic event listener and implement actual event forward there.
     * @param listener the event listener to add
     * @returns a function that can be called to remove the listener
     */
    addEventListener(listener: AnalyticEventListener): () => void {
      this.listeners.push(listener);
      return () => {
        this.removeEventListener(listener);
      };
    }

    /**
     * Remove the given event listener.
     * @param listener the listener to remove
     */
    removeEventListener(listener: AnalyticEventListener): void {
      const index = this.listeners.indexOf(listener);
      if (index >= 0) this.listeners.splice(index, 1);
    }

    // the default track method implementation
    protected track(event: string, props?: AnalyticEventProps) {
      this.emit(event, props);
    }
  })();
  //#endregion

  /**
   * Derived class should implement this method to track the given events
   * with properties.
   * @param {string} event - event name
   * @param {string} [props] - event properties
   */
  protected abstract track(event: string, props?: AnalyticEventProps): void;

  //#region Application-level Analytic Events

  /**
   * Analytic event logged on app launched
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  appLaunched(
    props: {
      /** number of application launches */
      counter: number;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('app_launched', { ...props, ...extra });
  }

  /**
   * Analytic event logged on app installed
   * @param [extra] - extra properties for this event
   */
  appInstalled(extra?: AnalyticEventProps) {
    this.track('app_installed', extra);
  }

  //#endregion

  //#region Share Play Analytic Events

  /**
   * Analytic event logged on share play session start
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  sharePlaySessionStart(
    props: {
      /** id of the session */
      sessionId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('share_play_session_start', { ...props, ...extra });
  }

  /**
   * Analytic event logged on share play session left
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  sharePlaySessionLeft(
    props: {
      /** id of the session */
      sessionId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('share_play_session_left', { ...props, ...extra });
  }

  /**
   * Analytic event logged on share play session join
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  sharePlaySessionJoin(
    props: {
      /** id of the session */
      sessionId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('share_play_session_join', { ...props, ...extra });
  }

  /**
   * Analytic event logged on share play button press
   * @param [extra] - extra properties for this event
   */
  sharePlayButtonPress(extra?: AnalyticEventProps) {
    this.track('share_play_button_press', extra);
  }

  /**
   * Analytic event logged on share play showcase button press
   * @param [props] - event properties
   * @param [extra] - extra properties for this event
   */
  sharePlayShowcaseButtonPress(
    props?: {
      /** id of the session */
      sessionId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('share_play_showcase_button_press', { ...props, ...extra });
  }

  /**
   * Analytic event logged on share play wishlist add
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  sharePlayWishlistAdd(
    props: {
      /** id of the session */
      sessionId: string;
      /** id of the product */
      listingId: string;
      /** id of the show */
      showId: string;
      /** type of the show */
      showType: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('share_play_wishlist_add', { ...props, ...extra });
  }

  /**
   * Analytic event logged on share play wishlist remove
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  sharePlayWishlistRemove(
    props: {
      /** id of the session */
      sessionId: string;
      /** id of the product */
      listingId: string;
      /** id of the show */
      showId: string;
      /** type of the show */
      showType: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('share_play_wishlist_remove', { ...props, ...extra });
  }

  /**
   * Analytic event logged on share play wishlist thumbnail press
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  sharePlayWishlistThumbnailPress(
    props: {
      /** url of the link */
      linkUrl: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('share_play_wishlist_thumbnail_press', { ...props, ...extra });
  }

  /**
   * Analytic event logged on share play show thumbnail press
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  sharePlayShowThumbnailPress(
    props: {
      /** id of the session */
      sessionId: string;
      /** url of the show */
      showUrl: string;
      /** id of the show */
      showId: string;
      /** type of the show */
      showType: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('share_play_show_thumbnail_press', { ...props, ...extra });
  }

  /**
   * Analytic event logged on share play waveover press
   * @param [props] - event properties
   * @param [extra] - extra properties for this event
   */
  sharePlayWaveoverPress(
    props?: {
      /** id of the session */
      sessionId?: string | null;
      /** url of the show */
      showUrl?: string | null;
      /** id of the show */
      showId?: string | null;
      /** type of the show */
      showType?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('share_play_waveover_press', { ...props, ...extra });
  }

  /**
   * Analytic event logged on share play waveover success
   * @param [props] - event properties
   * @param [extra] - extra properties for this event
   */
  sharePlayWaveoverSuccess(
    props?: {
      /** id of the session */
      sessionId?: string | null;
      /** url of the show */
      showUrl?: string | null;
      /** id of the show */
      showId?: string | null;
      /** type of the show */
      showType?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('share_play_waveover_success', { ...props, ...extra });
  }

  /**
   * Analytic event logged on share play waveover timeout
   * @param [props] - event properties
   * @param [extra] - extra properties for this event
   */
  sharePlayWaveoverTimeout(
    props?: {
      /** id of the session */
      sessionId?: string | null;
      /** url of the show */
      showUrl?: string | null;
      /** id of the show */
      showId?: string | null;
      /** type of the show */
      showType?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('share_play_waveover_timeout', { ...props, ...extra });
  }

  //#endregion

  //#region General User Related Analytic Events

  /**
   * Analytic event logged on user profile updated
   * @param [extra] - extra properties for this event
   */
  userProfileUpdated(extra?: AnalyticEventProps) {
    this.track('user_profile_updated', extra);
  }

  /**
   * Analytic event logged on user avatar uploaded
   * @param [extra] - extra properties for this event
   */
  userAvatarUploaded(extra?: AnalyticEventProps) {
    this.track('user_avatar_uploaded', extra);
  }

  /**
   * Analytic event logged on user followed
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  userFollowed(
    props: {
      /** id of the user that is being followed */
      targetUserId: string;
      /** name of the user that is being followed */
      targetUserName?: string | null;
      /** type of the user that is being followed */
      targetUserType?: ('seller' | 'buyer') | null;
      /** in which scene (user case) the user is followed */
      scene?: string | null;
      /** id of the user that is following */
      userId?: string | null;
      /** name of the user that is following */
      userName?: string | null;
      /** type of the user that is being following */
      userType?: ('seller' | 'buyer') | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('user_followed', { ...props, ...extra });
  }

  /**
   * Analytic event logged on user unfollowed
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  userUnfollowed(
    props: {
      /** id of the user that is being unfollowed */
      targetUserId: string;
      /** name of the user that is being unfollowed */
      targetUserName?: string | null;
      /** type of the user that is being unfollowed */
      targetUserType?: ('seller' | 'buyer') | null;
      /** in which scene (user case) the user is followed */
      scene?: string | null;
      /** id of the user that is unfollowing */
      userId?: string | null;
      /** name of the user that is unfollowing */
      userName?: string | null;
      /** type of the user that is being unfollowing */
      userType?: ('seller' | 'buyer') | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('user_unfollowed', { ...props, ...extra });
  }

  /**
   * Analytic event logged on user become seller clicked
   * @param [extra] - extra properties for this event
   */
  userBecomeSellerClicked(extra?: AnalyticEventProps) {
    this.track('user_become_seller_clicked', extra);
  }

  /**
   * Analytic event logged on user login started
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  userLoginStarted(
    props: {
      /** firebase authentication provider identifier */
      providerId: string;
      /** in which context the user logged in */
      scene?: string | null;
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('user_login_started', { ...props, ...extra });
  }

  /**
   * Analytic event logged on user login success
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  userLoginSuccess(
    props: {
      /** firebase authentication provider identifier */
      providerId: string;
      /** in which context the user logged in */
      scene?: string | null;
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
      /** firebase authentication user id of user that has logged in */
      userId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('user_login_success', { ...props, ...extra });
  }

  /**
   * Analytic event logged on user login failed
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  userLoginFailed(
    props: {
      /** firebase authentication provider identifier */
      providerId: string;
      /** in which context the user logged in */
      scene?: string | null;
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
      /** the error detail about this login failure */
      detail: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('user_login_failed', { ...props, ...extra });
  }

  /**
   * Analytic event logged on user signup finished
   * @param [props] - event properties
   * @param [extra] - extra properties for this event
   */
  userSignupFinished(
    props?: {
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
      /** firebase authentication user id */
      userId?: string | null;
      /** firebase authentication provider identifier */
      providerId?: string | null;
      /** in which context the user signed up */
      scene?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('user_signup_finished', { ...props, ...extra });
  }

  /**
   * Analytic event logged on user signup fill profile
   * @param [props] - event properties
   * @param [extra] - extra properties for this event
   */
  userSignupFillProfile(
    props?: {
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('user_signup_fill_profile', { ...props, ...extra });
  }

  /**
   * Analytic event logged on user signup avatar uploaded
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  userSignupAvatarUploaded(
    props: {
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
      /** the url of the avatar that has been uploaded */
      avatarUrl: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('user_signup_avatar_uploaded', { ...props, ...extra });
  }

  /**
   * Analytic event logged on user signup avatar take photo
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  userSignupAvatarTakePhoto(
    props: {
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
      /** the url of the avatar that has been taken */
      avatarUrl: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('user_signup_avatar_take_photo', { ...props, ...extra });
  }

  /**
   * Analytic event logged on user signup avatar use default
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  userSignupAvatarUseDefault(
    props: {
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
      /** default avatar url */
      avatarUrl: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('user_signup_avatar_use_default', { ...props, ...extra });
  }

  /**
   * Analytic event logged on user signup avatar skip
   * @param [props] - event properties
   * @param [extra] - extra properties for this event
   */
  userSignupAvatarSkip(
    props?: {
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('user_signup_avatar_skip', { ...props, ...extra });
  }

  /**
   * Analytic event logged on user signup category skip
   * @param [props] - event properties
   * @param [extra] - extra properties for this event
   */
  userSignupCategorySkip(
    props?: {
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('user_signup_category_skip', { ...props, ...extra });
  }

  /**
   * Analytic event logged on user signup category selected
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  userSignupCategorySelected(
    props: {
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
      /** the number of categories that the user has selected */
      categoryCount: number;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('user_signup_category_selected', { ...props, ...extra });
  }

  /**
   * Analytic event logged on user signup category follow store
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  userSignupCategoryFollowStore(
    props: {
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
      /** the store id of the store that the user has followed */
      storeId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('user_signup_category_follow_store', { ...props, ...extra });
  }

  /**
   * Analytic event logged on user signup category follow store finished
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  userSignupCategoryFollowStoreFinished(
    props: {
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
      /** the number of stores that the user has followed */
      storeCount: number;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('user_signup_category_follow_store_finished', {
      ...props,
      ...extra,
    });
  }

  /**
   * Analytic event logged on user shipping address updated
   * @param [extra] - extra properties for this event
   */
  userShippingAddressUpdated(extra?: AnalyticEventProps) {
    this.track('user_shipping_address_updated', extra);
  }

  /**
   * Analytic event logged on user logout started
   * @param [extra] - extra properties for this event
   */
  userLogoutStarted(extra?: AnalyticEventProps) {
    this.track('user_logout_started', extra);
  }

  /**
   * Analytic event logged on user logout success
   * @param [props] - event properties
   * @param [extra] - extra properties for this event
   */
  userLogoutSuccess(
    props?: {
      /** user_id property */
      userId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('user_logout_success', { ...props, ...extra });
  }

  /**
   * Analytic event logged on user logout failed
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  userLogoutFailed(
    props: {
      /** user_id property */
      userId?: string | null;
      /** detail property */
      detail: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('user_logout_failed', { ...props, ...extra });
  }

  //#endregion

  //#region Live show Related Analaytic Events

  /**
   * Analytic event logged on live show broadcast started
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowBroadcastStarted(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** agora account of the live show room */
      agoraAccount: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_broadcast_started', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show broadcast paused
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowBroadcastPaused(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** agora account of the live show room */
      agoraAccount: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_broadcast_paused', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show broadcast resumed
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowBroadcastResumed(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** agora account of the live show room */
      agoraAccount: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_broadcast_resumed', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show broadcast ended
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowBroadcastEnded(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** agora account of the live show room */
      agoraAccount: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_broadcast_ended', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show broadcast aborted
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowBroadcastAborted(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** agora account of the live show room */
      agoraAccount: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_broadcast_aborted', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show broadcast active
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowBroadcastActive(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** agora account of the live show room */
      agoraAccount: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_broadcast_active', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show broadcast inactive
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowBroadcastInactive(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** agora account of the live show room */
      agoraAccount: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_broadcast_inactive', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show shared by link
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowSharedByLink(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_shared_by_link', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show shared by sms
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowSharedBySms(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_shared_by_sms', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show shared by email
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowSharedByEmail(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_shared_by_email', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show shared to instagram
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowSharedToInstagram(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** instagram sharing type */
      shareType?: ('stories' | 'library') | null;
      /** type of media shared to instagram */
      mediaType?: ('video' | 'image') | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_shared_to_instagram', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show room joined
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowRoomJoined(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the buyer */
      buyerId?: string | null;
      /** name of the buyer */
      buyerName?: string | null;
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_room_joined', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show room leaved
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowRoomLeaved(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the buyer */
      buyerId?: string | null;
      /** name of the buyer */
      buyerName?: string | null;
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_room_leaved', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show watch started
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowWatchStarted(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the buyer */
      buyerId?: string | null;
      /** name of the buyer */
      buyerName?: string | null;
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_watch_started', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show watch ended
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowWatchEnded(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the buyer */
      buyerId?: string | null;
      /** name of the buyer */
      buyerName?: string | null;
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_watch_ended', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show watch over prompted
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowWatchOverPrompted(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the buyer */
      buyerId?: string | null;
      /** name of the buyer */
      buyerName?: string | null;
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_watch_over_prompted', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show watch login reminder shown
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowWatchLoginReminderShown(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the buyer */
      buyerId?: string | null;
      /** name of the buyer */
      buyerName?: string | null;
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_watch_login_reminder_shown', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show watch login reminder taken
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowWatchLoginReminderTaken(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the buyer */
      buyerId?: string | null;
      /** name of the buyer */
      buyerName?: string | null;
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_watch_login_reminder_taken', { ...props, ...extra });
  }

  /**
   * event that occurs when the current live show that has been just over is
   * *automatically* switched to the next live show
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowWatchAutoSwitched(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the buyer */
      buyerId?: string | null;
      /** name of the buyer */
      buyerName?: string | null;
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_watch_auto_switched', { ...props, ...extra });
  }

  /**
   * event that occurs when the current live show that has been just over is
   * *manually* switched to the next live show by the user
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowWatchManuallySwitched(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the buyer */
      buyerId?: string | null;
      /** name of the buyer */
      buyerName?: string | null;
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_watch_manually_switched', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show call clicked
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowCallClicked(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_call_clicked', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show call start clicked
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowCallStartClicked(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_call_start_clicked', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show call end clicked
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowCallEndClicked(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_call_end_clicked', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show call ended by host
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowCallEndedByHost(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_call_ended_by_host', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show call ended by guest
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowCallEndedByGuest(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the guest user being called */
      guestId: string;
      /** nick name of the guest user being called */
      guestNick: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_call_ended_by_guest', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show call canceled
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowCallCanceled(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_call_canceled', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show call request requested
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowCallRequestRequested(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the guest user being called */
      guestId: string;
      /** nick name of the guest user being called */
      guestNick: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_call_request_requested', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show call request accepted
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowCallRequestAccepted(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the guest user being called */
      guestId: string;
      /** nick name of the guest user being called */
      guestNick: string;
      /** device identifier of the guest user that accepts this call */
      guestDeviceId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_call_request_accepted', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show call request refused
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowCallRequestRefused(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the guest user being called */
      guestId: string;
      /** nick name of the guest user being called */
      guestNick: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_call_request_refused', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show share button clicked
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowShareButtonClicked(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_share_button_clicked', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show drawer open
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowDrawerOpen(
    props: {
      /** id of the live show */
      showId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_drawer_open', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show drawer closed
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowDrawerClosed(
    props: {
      /** id of the live show */
      showId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_drawer_closed', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show camera flipped
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowCameraFlipped(
    props: {
      /** id of the live show */
      showId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_camera_flipped', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show more button clicked
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowMoreButtonClicked(
    props: {
      /** id of the live show */
      showId: string;
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_more_button_clicked', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show add listing clicked
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowAddListingClicked(
    props: {
      /** id of the live show */
      showId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_add_listing_clicked', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show listing added
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowListingAdded(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** quantity of the product listing */
      listingQty: number;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_listing_added', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show listing updated
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowListingUpdated(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** quantity of the product listing */
      listingQty: number;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_listing_updated', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show listing clicked by buyer
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowListingClickedByBuyer(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** quantity of the product listing */
      listingQty: number;
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_listing_clicked_by_buyer', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show listing in grid selected
   * @param [extra] - extra properties for this event
   */
  liveShowListingInGridSelected(extra?: AnalyticEventProps) {
    this.track('live_show_listing_in_grid_selected', extra);
  }

  /**
   * Analytic event logged on live show listings clicked
   * @param [props] - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowListingsClicked(
    props?: {
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_listings_clicked', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show last listing clicked by buyer
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowLastListingClickedByBuyer(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** quantity of the product listing */
      listingQty: number;
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_last_listing_clicked_by_buyer', {
      ...props,
      ...extra,
    });
  }

  /**
   * Analytic event logged on live show last listing clicked by seller
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowLastListingClickedBySeller(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** quantity of the product listing */
      listingQty: number;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_last_listing_clicked_by_seller', {
      ...props,
      ...extra,
    });
  }

  /**
   * Analytic event logged on live show all listings clicked
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowAllListingsClicked(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_all_listings_clicked', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show listing detail buy button clicked
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowListingDetailBuyButtonClicked(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** quantity of the product listing */
      listingQty: number;
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_listing_detail_buy_button_clicked', {
      ...props,
      ...extra,
    });
  }

  /**
   * Analytic event logged on live show auction clicked in actions
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowAuctionClickedInActions(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_auction_clicked_in_actions', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show auction clicked by seller
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowAuctionClickedBySeller(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the live stream auction */
      auctionId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_auction_clicked_by_seller', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show auction clicked by buyer
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowAuctionClickedByBuyer(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the live stream auction */
      auctionId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_auction_clicked_by_buyer', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show auction being managed
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowAuctionBeingManaged(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the live stream auction */
      auctionId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_auction_being_managed', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show auction created
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowAuctionCreated(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the live stream auction */
      auctionId: string;
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** quantity of the product listing */
      listingQty: number;
      /** image url of the product listing */
      listingImage?: string | null;
      /** sku of the product listing */
      listingSku?: string | null;
      /** staring bid price for the just created auction */
      startingBid: number;
      /** if the seller enabled the timer feature for the auction */
      isTimerEnabled?: boolean | null;
      /** maximum duration (in seconds) specified for the auction */
      durationInSeconds?: number | null;
      /** number of expriry seconds to start the countdown timer with, which is currently configured to `15s` as the system default. */
      countdownInSeconds?: number | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_auction_created', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show auction cancel clicked
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowAuctionCancelClicked(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the live stream auction */
      auctionId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_auction_cancel_clicked', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show auction canceled
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowAuctionCanceled(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the live stream auction */
      auctionId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_auction_canceled', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show auction finish clicked
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowAuctionFinishClicked(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the live stream auction */
      auctionId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_auction_finish_clicked', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show auction finished
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowAuctionFinished(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the live stream auction */
      auctionId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_auction_finished', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show auction bid remove clicked
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowAuctionBidRemoveClicked(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the live stream auction */
      auctionId: string;
      /** id of the bid that is intended to be removed */
      bidId: string;
      /** price of the bid that is intended to be removed */
      bidPrice: number;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_auction_bid_remove_clicked', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show auction bid removed
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowAuctionBidRemoved(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the live stream auction */
      auctionId: string;
      /** id of the bid that has been removed */
      bidId: string;
      /** price of the bid that has been removed */
      bidPrice: number;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_auction_bid_removed', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show auction bid place clicked
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowAuctionBidPlaceClicked(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the live stream auction */
      auctionId: string;
      /** price placed for this bid */
      price: number;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_auction_bid_place_clicked', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show auction bid place succeeded
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowAuctionBidPlaceSucceeded(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the live stream auction */
      auctionId: string;
      /** price placed for this bid */
      price: number;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_auction_bid_place_succeeded', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show auction bid price increased
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowAuctionBidPriceIncreased(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the live stream auction */
      auctionId: string;
      /** new bid price after being increased */
      price: number;
      /** increased amount of bid price */
      increment: number;
      /** by which method the bid price is increased */
      method: 'click' | 'select';
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_auction_bid_price_increased', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show new auction clicked
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowNewAuctionClicked(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_new_auction_clicked', { ...props, ...extra });
  }

  /**
   * Analytic event logged on live show new auction listing selected
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  liveShowNewAuctionListingSelected(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      title: string;
      /** user id of host of the live show */
      userId: string;
      /** nick name of host of the live show */
      userName: string;
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** quantity of the product listing */
      listingQty: number;
      /** image url of the product listing */
      listingImage?: string | null;
      /** sku of the product listing */
      listingSku?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('live_show_new_auction_listing_selected', {
      ...props,
      ...extra,
    });
  }

  //#endregion

  //#region Upcoming show Related Analaytic Events

  /**
   * Analytic event logged on upcoming show share button clicked
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  upcomingShowShareButtonClicked(
    props: {
      /** id of the upcoming show */
      showId: string;
      /** title of the upcoming show */
      title: string;
      /** the scheduled start time of the upcoming show */
      startsAt: string;
      /** user id of owner of the upcoming show */
      userId: string;
      /** nick name of owner of the upcoming show */
      userName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('upcoming_show_share_button_clicked', { ...props, ...extra });
  }

  /**
   * Analytic event logged on upcoming show shared to local
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  upcomingShowSharedToLocal(
    props: {
      /** id of the upcoming show */
      showId: string;
      /** title of the upcoming show */
      title: string;
      /** the scheduled start time of the upcoming show */
      startsAt: string;
      /** user id of owner of the upcoming show */
      userId: string;
      /** nick name of owner of the upcoming show */
      userName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('upcoming_show_shared_to_local', { ...props, ...extra });
  }

  /**
   * Analytic event logged on upcoming show shared by link
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  upcomingShowSharedByLink(
    props: {
      /** id of the upcoming show */
      showId: string;
      /** title of the upcoming show */
      title: string;
      /** the scheduled start time of the upcoming show */
      startsAt: string;
      /** user id of owner of the upcoming show */
      userId: string;
      /** nick name of owner of the upcoming show */
      userName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('upcoming_show_shared_by_link', { ...props, ...extra });
  }

  /**
   * Analytic event logged on upcoming show shared by sms
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  upcomingShowSharedBySms(
    props: {
      /** id of the upcoming show */
      showId: string;
      /** title of the upcoming show */
      title: string;
      /** the scheduled start time of the upcoming show */
      startsAt: string;
      /** user id of owner of the upcoming show */
      userId: string;
      /** nick name of owner of the upcoming show */
      userName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('upcoming_show_shared_by_sms', { ...props, ...extra });
  }

  /**
   * Analytic event logged on upcoming show shared by email
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  upcomingShowSharedByEmail(
    props: {
      /** id of the upcoming show */
      showId: string;
      /** title of the upcoming show */
      title: string;
      /** the scheduled start time of the upcoming show */
      startsAt: string;
      /** user id of owner of the upcoming show */
      userId: string;
      /** nick name of owner of the upcoming show */
      userName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('upcoming_show_shared_by_email', { ...props, ...extra });
  }

  /**
   * Analytic event logged on upcoming show shared to instagram
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  upcomingShowSharedToInstagram(
    props: {
      /** id of the upcoming show */
      showId: string;
      /** title of the upcoming show */
      title: string;
      /** the scheduled start time of the upcoming show */
      startsAt: string;
      /** user id of owner of the upcoming show */
      userId: string;
      /** nick name of owner of the upcoming show */
      userName: string;
      /** instagram sharing type */
      shareType?: ('stories' | 'library') | null;
      /** type of media shared to instagram */
      mediaType?: ('video' | 'image') | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('upcoming_show_shared_to_instagram', { ...props, ...extra });
  }

  /**
   * Analytic event logged on upcoming show scheduled
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  upcomingShowScheduled(
    props: {
      /** id of the upcoming show */
      showId: string;
      /** title of the upcoming show */
      title: string;
      /** the scheduled start time of the upcoming show */
      startsAt: string;
      /** user id of owner of the upcoming show */
      userId: string;
      /** nick name of owner of the upcoming show */
      userName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('upcoming_show_scheduled', { ...props, ...extra });
  }

  /**
   * Analytic event logged on upcoming show watch added
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  upcomingShowWatchAdded(
    props: {
      /** id of the upcoming show */
      showId: string;
      /** title of the upcoming show */
      title: string;
      /** the scheduled start time of the upcoming show */
      startsAt: string;
      /** user id of owner of the upcoming show */
      userId: string;
      /** nick name of owner of the upcoming show */
      userName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('upcoming_show_watch_added', { ...props, ...extra });
  }

  /**
   * Analytic event logged on upcoming show watch removed
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  upcomingShowWatchRemoved(
    props: {
      /** id of the upcoming show */
      showId: string;
      /** title of the upcoming show */
      title: string;
      /** the scheduled start time of the upcoming show */
      startsAt: string;
      /** user id of owner of the upcoming show */
      userId: string;
      /** nick name of owner of the upcoming show */
      userName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('upcoming_show_watch_removed', { ...props, ...extra });
  }

  /**
   * Analytic event logged on upcoming show listing clicked by buyer
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  upcomingShowListingClickedByBuyer(
    props: {
      /** id of the upcoming show */
      showId: string;
      /** title of the upcoming show */
      title: string;
      /** the scheduled start time of the upcoming show */
      startsAt: string;
      /** user id of owner of the upcoming show */
      userId: string;
      /** nick name of owner of the upcoming show */
      userName: string;
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** quantity of the product listing */
      listingQty: number;
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('upcoming_show_listing_clicked_by_buyer', {
      ...props,
      ...extra,
    });
  }

  /**
   * Analytic event logged on upcoming show all listings clicked
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  upcomingShowAllListingsClicked(
    props: {
      /** id of the upcoming show */
      showId: string;
      /** title of the upcoming show */
      title: string;
      /** the scheduled start time of the upcoming show */
      startsAt: string;
      /** user id of owner of the upcoming show */
      userId: string;
      /** nick name of owner of the upcoming show */
      userName: string;
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('upcoming_show_all_listings_clicked', { ...props, ...extra });
  }

  /**
   * Analytic event logged on upcoming show listing detail buy button clicked
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  upcomingShowListingDetailBuyButtonClicked(
    props: {
      /** id of the upcoming show */
      showId: string;
      /** title of the upcoming show */
      title: string;
      /** the scheduled start time of the upcoming show */
      startsAt: string;
      /** user id of owner of the upcoming show */
      userId: string;
      /** nick name of owner of the upcoming show */
      userName: string;
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** quantity of the product listing */
      listingQty: number;
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('upcoming_show_listing_detail_buy_button_clicked', {
      ...props,
      ...extra,
    });
  }

  //#endregion

  //#region Review Related Analaytic Events

  /**
   * Analytic event logged on review shared to local
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  reviewSharedToLocal(
    props: {
      /** id of the review */
      reviewId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('review_shared_to_local', { ...props, ...extra });
  }

  /**
   * Analytic event logged on review shared by link
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  reviewSharedByLink(
    props: {
      /** id of the review */
      reviewId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('review_shared_by_link', { ...props, ...extra });
  }

  /**
   * Analytic event logged on review shared by sms
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  reviewSharedBySms(
    props: {
      /** id of the review */
      reviewId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('review_shared_by_sms', { ...props, ...extra });
  }

  /**
   * Analytic event logged on review shared by email
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  reviewSharedByEmail(
    props: {
      /** id of the review */
      reviewId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('review_shared_by_email', { ...props, ...extra });
  }

  /**
   * Analytic event logged on review shared to instagram
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  reviewSharedToInstagram(
    props: {
      /** id of the review */
      reviewId: string;
      /** instagram sharing type */
      shareType?: ('stories' | 'library') | null;
      /** type of media shared to instagram */
      mediaType?: ('video' | 'image') | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('review_shared_to_instagram', { ...props, ...extra });
  }

  /**
   * Analytic event logged on review share button clicked
   * @param [extra] - extra properties for this event
   */
  reviewShareButtonClicked(extra?: AnalyticEventProps) {
    this.track('review_share_button_clicked', extra);
  }

  /**
   * event that occurs when the current review that has been just over is
   * *automatically* switched to the next review
   * @param [props] - event properties
   * @param [extra] - extra properties for this event
   */
  reviewAutoSwitched(
    props?: {
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('review_auto_switched', { ...props, ...extra });
  }

  /**
   * event that occurs when the current review that has been just over is
   * *manually* switched to the next review by the user
   * @param [props] - event properties
   * @param [extra] - extra properties for this event
   */
  reviewManuallySwitched(
    props?: {
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('review_manually_switched', { ...props, ...extra });
  }

  //#endregion

  //#region Greenroom Related Analaytic Events

  /**
   * Analytic event logged on greenroom started
   * @param [extra] - extra properties for this event
   */
  greenroomStarted(extra?: AnalyticEventProps) {
    this.track('greenroom_started', extra);
  }

  /**
   * Analytic event logged on greenroom connected with upcoming show
   * @param [extra] - extra properties for this event
   */
  greenroomConnectedWithUpcomingShow(extra?: AnalyticEventProps) {
    this.track('greenroom_connected_with_upcoming_show', extra);
  }

  /**
   * Analytic event logged on greenroom product added
   * @param [extra] - extra properties for this event
   */
  greenroomProductAdded(extra?: AnalyticEventProps) {
    this.track('greenroom_product_added', extra);
  }

  /**
   * Analytic event logged on greenroom title set
   * @param [extra] - extra properties for this event
   */
  greenroomTitleSet(extra?: AnalyticEventProps) {
    this.track('greenroom_title_set', extra);
  }

  /**
   * Analytic event logged on greenroom visibility set
   * @param [extra] - extra properties for this event
   */
  greenroomVisibilitySet(extra?: AnalyticEventProps) {
    this.track('greenroom_visibility_set', extra);
  }

  /**
   * Analytic event logged on greenroom reviewed
   * @param [extra] - extra properties for this event
   */
  greenroomReviewed(extra?: AnalyticEventProps) {
    this.track('greenroom_reviewed', extra);
  }

  /**
   * Analytic event logged on greenroom being live
   * @param [extra] - extra properties for this event
   */
  greenroomBeingLive(extra?: AnalyticEventProps) {
    this.track('greenroom_being_live', extra);
  }

  //#endregion

  //#region Order Related Analaytic Events

  /**
   * Analytic event logged on order shipping label sent
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  orderShippingLabelSent(
    props: {
      /** id of the order document */
      orderId?: string | null;
      /** order number for shipping tracking */
      orderNumber: string;
      /** id of the listing being shipped */
      listingId: string;
      /** quantity of the lising being shipped */
      qty: number;
      /** url of the shipment of the listing being shipped */
      shipmentUrl?: string | null;
      /** id of the seller */
      sellerId: string;
      /** name of the seller */
      sellerName: string;
      /** id of the buyer */
      buyerId: string;
      /** name of the buyer */
      buyerName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('order_shipping_label_sent', { ...props, ...extra });
  }

  /**
   * Analytic event logged on order created
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  orderCreated(
    props: {
      /** id of the seller */
      sellerId: string;
      /** name of the seller */
      sellerName: string;
      /** id of the buyer */
      buyerId: string;
      /** name of the buyer */
      buyerName: string;
      /** type of the show */
      showType?: ('live_show' | 'upcoming_show') | null;
      /** id of the show */
      showId?: string | null;
      /** title of the show */
      title?: string | null;
      /** payment method the user used for the purchase */
      paymentType: string;
      /** id of the listing that is purchased by user */
      listingId: string;
      /** quantity of the purchased listing by the user */
      qty: number;
      /** the firebase authentication provider identifier list associated with the user that have made the purchase */
      authProviderIds?: string[] | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('order_created', { ...props, ...extra });
  }

  //#endregion

  //#region Storefront Related Analaytic Events

  /**
   * Analytic event logged on storefront listing photo uploaded
   * @param [extra] - extra properties for this event
   */
  storefrontListingPhotoUploaded(extra?: AnalyticEventProps) {
    this.track('storefront_listing_photo_uploaded', extra);
  }

  /**
   * Analytic event logged on storefront listing added
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  storefrontListingAdded(
    props: {
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** quantity of the product listing */
      listingQty: number;
      /** image url of the product listing */
      listingImage?: string | null;
      /** sku of the product listing */
      listingSku?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('storefront_listing_added', { ...props, ...extra });
  }

  /**
   * Analytic event logged on storefront listing updated
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  storefrontListingUpdated(
    props: {
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** quantity of the product listing */
      listingQty: number;
      /** image url of the product listing */
      listingImage?: string | null;
      /** sku of the product listing */
      listingSku?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('storefront_listing_updated', { ...props, ...extra });
  }

  /**
   * Analytic event logged on storefront listing deleted
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  storefrontListingDeleted(
    props: {
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** quantity of the product listing */
      listingQty: number;
      /** image url of the product listing */
      listingImage?: string | null;
      /** sku of the product listing */
      listingSku?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('storefront_listing_deleted', { ...props, ...extra });
  }

  /**
   * Analytic event logged on storefront public listing added
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  storefrontPublicListingAdded(
    props: {
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** quantity of the product listing */
      listingQty: number;
      /** image url of the product listing */
      listingImage?: string | null;
      /** sku of the product listing */
      listingSku?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('storefront_public_listing_added', { ...props, ...extra });
  }

  /**
   * Analytic event logged on storefront public listing deleted
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  storefrontPublicListingDeleted(
    props: {
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** quantity of the product listing */
      listingQty: number;
      /** image url of the product listing */
      listingImage?: string | null;
      /** sku of the product listing */
      listingSku?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('storefront_public_listing_deleted', { ...props, ...extra });
  }

  /**
   * Analytic event logged on storefront creation guard
   * @param [extra] - extra properties for this event
   */
  storefrontCreationGuard(extra?: AnalyticEventProps) {
    this.track('storefront_creation_guard', extra);
  }

  /**
   * Analytic event logged on storefront share button clicked
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  storefrontShareButtonClicked(
    props: {
      /** id of the seller */
      sellerId: string;
      /** name of the seller */
      sellerName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('storefront_share_button_clicked', { ...props, ...extra });
  }

  /**
   * Analytic event logged on storefront shared by link
   * @param [extra] - extra properties for this event
   */
  storefrontSharedByLink(extra?: AnalyticEventProps) {
    this.track('storefront_shared_by_link', extra);
  }

  /**
   * Analytic event logged on storefront shared by sms
   * @param [extra] - extra properties for this event
   */
  storefrontSharedBySms(extra?: AnalyticEventProps) {
    this.track('storefront_shared_by_sms', extra);
  }

  /**
   * Analytic event logged on storefront shared by email
   * @param [extra] - extra properties for this event
   */
  storefrontSharedByEmail(extra?: AnalyticEventProps) {
    this.track('storefront_shared_by_email', extra);
  }

  //#endregion

  //#region Seller Related Analaytic Events

  /**
   * Analytic event logged on seller profile follower list shown
   * @param [extra] - extra properties for this event
   */
  sellerProfileFollowerListShown(extra?: AnalyticEventProps) {
    this.track('seller_profile_follower_list_shown', extra);
  }

  /**
   * Analytic event logged on seller profile following list shown
   * @param [extra] - extra properties for this event
   */
  sellerProfileFollowingListShown(extra?: AnalyticEventProps) {
    this.track('seller_profile_following_list_shown', extra);
  }

  /**
   * Analytic event logged on seller profile referral list shown
   * @param [extra] - extra properties for this event
   */
  sellerProfileReferralListShown(extra?: AnalyticEventProps) {
    this.track('seller_profile_referral_list_shown', extra);
  }

  /**
   * Analytic event logged on seller profile shared by link
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  sellerProfileSharedByLink(
    props: {
      /** id of the seller */
      sellerId: string;
      /** name of the seller */
      sellerName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('seller_profile_shared_by_link', { ...props, ...extra });
  }

  /**
   * Analytic event logged on seller profile shared by sms
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  sellerProfileSharedBySms(
    props: {
      /** id of the seller */
      sellerId: string;
      /** name of the seller */
      sellerName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('seller_profile_shared_by_sms', { ...props, ...extra });
  }

  /**
   * Analytic event logged on seller profile shared by email
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  sellerProfileSharedByEmail(
    props: {
      /** id of the seller */
      sellerId: string;
      /** name of the seller */
      sellerName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('seller_profile_shared_by_email', { ...props, ...extra });
  }

  /**
   * Analytic event logged on seller profile button clicked
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  sellerProfileButtonClicked(
    props: {
      /** id of the seller */
      sellerId: string;
      /** name of the seller */
      sellerName: string;
      /** type of the show */
      showType?: ('live_show' | 'upcoming_show') | null;
      /** id of the show */
      showId?: string | null;
      /** title of the show */
      title?: string | null;
      /** scene property */
      scene?:
        | (
            | 'live_host_streamer'
            | 'live_guest_streamer'
            | 'discovery'
            | 'search'
          )
        | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('seller_profile_button_clicked', { ...props, ...extra });
  }

  /**
   * Analytic event logged on seller income withdrawn
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  sellerIncomeWithdrawn(
    props: {
      /** amount of money withdrawn */
      amount: number;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('seller_income_withdrawn', { ...props, ...extra });
  }

  /**
   * Analytic event logged on seller shipping fee updated
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  sellerShippingFeeUpdated(
    props: {
      /** the shipping fee updated by the seller */
      shippingFee: number;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('seller_shipping_fee_updated', { ...props, ...extra });
  }

  /**
   * Analytic event logged on seller shopify store name updated
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  sellerShopifyStoreNameUpdated(
    props: {
      /** the name of the seller's shopify store */
      storeName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('seller_shopify_store_name_updated', { ...props, ...extra });
  }

  /**
   * Analytic event logged on seller shopify products fetched
   * @param [props] - event properties
   * @param [extra] - extra properties for this event
   */
  sellerShopifyProductsFetched(
    props?: {
      /** the page number of all products being fetched */
      page?: number | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('seller_shopify_products_fetched', { ...props, ...extra });
  }

  /**
   * Analytic event logged on seller shopify product imported
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  sellerShopifyProductImported(
    props: {
      /** name of the imported product from shopify */
      title: string;
      /** price of the imported product from shopify */
      price: string;
      /** qty of the imported product from shopify */
      qty?: number | null;
      /** sku of the imported product from shopify */
      sku?: string | null;
      /** image url of the imported product from shopify */
      imageUrl?: string | null;
      /** description of the imported product from shopify */
      description?: string | null;
      /** the id of the imported product listing */
      listingId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('seller_shopify_product_imported', { ...props, ...extra });
  }

  /**
   * Analytic event logged on seller tag added
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  sellerTagAdded(
    props: {
      /** tag property */
      tag: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('seller_tag_added', { ...props, ...extra });
  }

  /**
   * Analytic event logged on seller tag removed
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  sellerTagRemoved(
    props: {
      /** tag property */
      tag: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('seller_tag_removed', { ...props, ...extra });
  }

  /**
   * Analytic event logged on seller share button clicked
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  sellerShareButtonClicked(
    props: {
      /** id of the seller */
      sellerId: string;
      /** name of the seller */
      sellerName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('seller_share_button_clicked', { ...props, ...extra });
  }

  //#endregion

  //#region Campaign Related Analaytic Events

  /**
   * Analytic event logged on campaign received
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  campaignReceived(
    props: {
      /** id of the campaign */
      campaignId: string;
      /** user id related to this campaign event */
      uid: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('campaign_received', { ...props, ...extra });
  }

  /**
   * Analytic event logged on campaign user signed up
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  campaignUserSignedUp(
    props: {
      /** id of the campaign */
      campaignId: string;
      /** user id related to this campaign event */
      uid: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('campaign_user_signed_up', { ...props, ...extra });
  }

  /**
   * Analytic event logged on campaign download popup app store clicked
   * @param [props] - event properties
   * @param [extra] - extra properties for this event
   */
  campaignDownloadPopupAppStoreClicked(
    props?: {
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('campaign_download_popup_app_store_clicked', {
      ...props,
      ...extra,
    });
  }

  /**
   * Analytic event logged on campaign download popup google play clicked
   * @param [props] - event properties
   * @param [extra] - extra properties for this event
   */
  campaignDownloadPopupGooglePlayClicked(
    props?: {
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('campaign_download_popup_google_play_clicked', {
      ...props,
      ...extra,
    });
  }

  /**
   * Analytic event logged on campaign download popup closed
   * @param [props] - event properties
   * @param [extra] - extra properties for this event
   */
  campaignDownloadPopupClosed(
    props?: {
      /** used to track users' behaviour when they are anonymous */
      linkId?: string | null;
      /** in which way user closes the popup window */
      scene?: ('cross_button' | 'background_area') | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('campaign_download_popup_closed', { ...props, ...extra });
  }

  //#endregion

  //#region Invitation/Referral Related Analytic Events

  /**
   * Analytic event logged on referral code used
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  referralCodeUsed(
    props: {
      /** the referral code used by the user */
      code: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('referral_code_used', { ...props, ...extra });
  }

  /**
   * Analytic event logged on referral code skipped
   * @param [extra] - extra properties for this event
   */
  referralCodeSkipped(extra?: AnalyticEventProps) {
    this.track('referral_code_skipped', extra);
  }

  /**
   * Analytic event logged on referral passcode added by user
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  referralPasscodeAddedByUser(
    props: {
      /** the watch passcode that is added by the user */
      code: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('referral_passcode_added_by_user', { ...props, ...extra });
  }

  //#endregion

  //#region Remote Push Notification Related Analytic Events

  /**
   * Remote Push Notification Related Analytic Events
   * @param [props] - event properties
   * @param [extra] - extra properties for this event
   */
  notification(
    props?: {
      /** type of this notification */
      type?:
        | ('custom' | 'live_show_started' | 'upcoming_show_about_to_start')
        | null;
      /** type of the show */
      showType?: ('live_show' | 'upcoming_show') | null;
      /** id of the show */
      showId?: string | null;
      /** title of the show */
      title?: string | null;
      /** id of the seller */
      sellerId?: string | null;
      /** name of the seller */
      sellerName?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('notification', { ...props, ...extra });
  }

  //#endregion

  //#region Comment Related Analytic Events

  /**
   * Analytic event logged on comment click on mention user
   * @param [extra] - extra properties for this event
   */
  commentClickOnMentionUser(extra?: AnalyticEventProps) {
    this.track('comment_click_on_mention_user', extra);
  }

  /**
   * Analytic event logged on comment click on report user
   * @param [extra] - extra properties for this event
   */
  commentClickOnReportUser(extra?: AnalyticEventProps) {
    this.track('comment_click_on_report_user', extra);
  }

  /**
   * Analytic event logged on comment click on comment
   * @param [extra] - extra properties for this event
   */
  commentClickOnComment(extra?: AnalyticEventProps) {
    this.track('comment_click_on_comment', extra);
  }

  /**
   * Analytic event logged on comment click comment to follow
   * @param [extra] - extra properties for this event
   */
  commentClickCommentToFollow(extra?: AnalyticEventProps) {
    this.track('comment_click_comment_to_follow', extra);
  }

  /**
   * Analytic event logged on comment click comment to unfollow
   * @param [extra] - extra properties for this event
   */
  commentClickCommentToUnfollow(extra?: AnalyticEventProps) {
    this.track('comment_click_comment_to_unfollow', extra);
  }

  //#endregion

  //#region Event Related Analaytic Events

  /**
   * Analytic event logged on event share button clicked
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  eventShareButtonClicked(
    props: {
      /** id of the show event */
      eventId: string;
      /** name of the show event */
      name: string;
      /** TODO: disable anonymous user share */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('event_share_button_clicked', { ...props, ...extra });
  }

  /**
   * Analytic event logged on event shared by link
   * @param [props] - event properties
   * @param [extra] - extra properties for this event
   */
  eventSharedByLink(
    props?: {
      /** TODO: disable anonymous user share */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('event_shared_by_link', { ...props, ...extra });
  }

  /**
   * Analytic event logged on event shared by sms
   * @param [props] - event properties
   * @param [extra] - extra properties for this event
   */
  eventSharedBySms(
    props?: {
      /** TODO: disable anonymous user share */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('event_shared_by_sms', { ...props, ...extra });
  }

  /**
   * Analytic event logged on event shared by email
   * @param [props] - event properties
   * @param [extra] - extra properties for this event
   */
  eventSharedByEmail(
    props?: {
      /** TODO: disable anonymous user share */
      linkId?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('event_shared_by_email', { ...props, ...extra });
  }

  //#endregion

  //#region Promotion Related Analaytic Events

  /**
   * Analytic event logged on promotion share button clicked
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  promotionShareButtonClicked(
    props: {
      /** id of the promotion */
      promotionId: string;
      /** title of the promotion page */
      title: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('promotion_share_button_clicked', { ...props, ...extra });
  }

  //#endregion

  //#region Gifting Related Analaytic Events

  /**
   * event tracked when either host or audience clicks the gift button in the
   * listing detail page to initiate a gifting workflow.
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  giftingInitiatedFromListingDetail(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      showTitle: string;
      /** user id of host of the live show */
      showUserId: string;
      /** nick name of host of the live show */
      showUserName: string;
      /** type of the gifting initiator */
      initiatorType: 'host' | 'audience';
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('gifting_initiated_from_listing_detail', { ...props, ...extra });
  }

  /**
   * event tracked when either host or audience clicks a comment and then clicks
   * "send a gift" from the context menu to initiate a gifting workflow.
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  giftingInitiatedFromLiveShowComment(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      showTitle: string;
      /** user id of host of the live show */
      showUserId: string;
      /** nick name of host of the live show */
      showUserName: string;
      /** type of the gifting initiator */
      initiatorType: 'host' | 'audience';
      /** user id of the target audience as the gift recipient */
      selectedUserId: string;
      /** display name of the target audience as the gift recipient */
      selectedUserName: string;
      /** type of the comment, e.g., comment, join, purchase, etc. */
      commentType: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('gifting_initiated_from_live_show_comment', {
      ...props,
      ...extra,
    });
  }

  /**
   * event tracked if gift recipient's shipping address is missing when someone
   * is trying to send a gift to that user.
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  giftingRecipientShippingAddrMissing(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      showTitle: string;
      /** user id of host of the live show */
      showUserId: string;
      /** nick name of host of the live show */
      showUserName: string;
      /** type of the gifting initiator */
      initiatorType: 'host' | 'audience';
      /** user id of the user of selected comment */
      selectedUserId: string;
      /** display name of the user of selected comment */
      selectedUserName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('gifting_recipient_shipping_addr_missing', {
      ...props,
      ...extra,
    });
  }

  /**
   * event tracked if gift recipient's shipping address is added as a response
   * to address missing reminder.
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  giftingRecipientShippingAddrAdded(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      showTitle: string;
      /** user id of host of the live show */
      showUserId: string;
      /** nick name of host of the live show */
      showUserName: string;
      /** type of the gifting initiator */
      initiatorType: 'host' | 'audience';
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('gifting_recipient_shipping_addr_added', { ...props, ...extra });
  }

  /**
   * event tracked if gift recipient ignored the received shipping address
   * reminder send to him when someone is trying to send a gift to it.
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  giftingRecipientShippingAddrReminderIgnored(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      showTitle: string;
      /** user id of host of the live show */
      showUserId: string;
      /** nick name of host of the live show */
      showUserName: string;
      /** type of the gifting initiator */
      initiatorType: 'host' | 'audience';
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('gifting_recipient_shipping_addr_reminder_ignored', {
      ...props,
      ...extra,
    });
  }

  /**
   * event tracked when the listing selected while sending a gift
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  giftingListingSelected(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      showTitle: string;
      /** user id of host of the live show */
      showUserId: string;
      /** nick name of host of the live show */
      showUserName: string;
      /** type of the gifting initiator */
      initiatorType: 'host' | 'audience';
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('gifting_listing_selected', { ...props, ...extra });
  }

  /**
   * event tracked when gift sender changed the quantity to send
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  giftingListingQtyChanged(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      showTitle: string;
      /** user id of host of the live show */
      showUserId: string;
      /** nick name of host of the live show */
      showUserName: string;
      /** type of the gifting initiator */
      initiatorType: 'host' | 'audience';
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** number of products to send as gift */
      qty: number;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('gifting_listing_qty_changed', { ...props, ...extra });
  }

  /**
   * event tracked when gift sender changed the variant to send
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  giftingListingVariantChanged(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      showTitle: string;
      /** user id of host of the live show */
      showUserId: string;
      /** nick name of host of the live show */
      showUserName: string;
      /** type of the gifting initiator */
      initiatorType: 'host' | 'audience';
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** id of the listing variant */
      variantId: string;
      /** name of the listing variant */
      variantName: string;
      /** sku of the listing variant */
      variantSku?: string | null;
      /** quantity of the listing variant */
      variantQuantity?: number | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('gifting_listing_variant_changed', { ...props, ...extra });
  }

  /**
   * event tracked when gift sender opens the viewers list to in order to select
   * a target audience user to send gift to.
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  giftingViewersListOpen(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      showTitle: string;
      /** user id of host of the live show */
      showUserId: string;
      /** nick name of host of the live show */
      showUserName: string;
      /** type of the gifting initiator */
      initiatorType: 'host' | 'audience';
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('gifting_viewers_list_open', { ...props, ...extra });
  }

  /**
   * event tracked when the gift sender selected one of the viewers
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  giftingRecipientSelected(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      showTitle: string;
      /** user id of host of the live show */
      showUserId: string;
      /** nick name of host of the live show */
      showUserName: string;
      /** type of the gifting initiator */
      initiatorType: 'host' | 'audience';
      /** user id of the selected user to be sent gift to */
      selectedUserId: string;
      /** display name of the selected user to be sent gift to */
      selectedUserName: string;
      /** if the giftrecipient is selected randomly */
      isRandom: boolean;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('gifting_recipient_selected', { ...props, ...extra });
  }

  /**
   * event tracked when the host turns the gift option on while sending item
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  giftingOptionToggledOn(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      showTitle: string;
      /** user id of host of the live show */
      showUserId: string;
      /** nick name of host of the live show */
      showUserName: string;
      /** type of the gifting initiator, 'host' in this case */
      initiatorType: 'host';
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('gifting_option_toggled_on', { ...props, ...extra });
  }

  /**
   * event tracked when the host turns the gift option off while sending item
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  giftingOptionToggledOff(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      showTitle: string;
      /** user id of host of the live show */
      showUserId: string;
      /** nick name of host of the live show */
      showUserName: string;
      /** type of the gifting initiator, 'host' in this case */
      initiatorType: 'host';
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('gifting_option_toggled_off', { ...props, ...extra });
  }

  /**
   * event tracked when the gifting order is confirmed.
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  giftingOrderConfirmed(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      showTitle: string;
      /** user id of host of the live show */
      showUserId: string;
      /** nick name of host of the live show */
      showUserName: string;
      /** type of the gifting initiator */
      initiatorType: 'host' | 'audience';
      /** user id of the gift sender */
      senderUserId?: string | null;
      /** display name of the gift sender */
      senderUserName?: string | null;
      /** user id of the gift receiver */
      receiverUserId?: string | null;
      /** display name of the gift receiver */
      receiverUserName?: string | null;
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** quantity of the product listing */
      listingQty: number;
      /** id of the listing variant */
      variantId?: string | null;
      /** name of the listing variant */
      variantName?: string | null;
      /** sku of the listing variant */
      variantSku?: string | null;
      /** quantity of the listing variant */
      variantQuantity?: number | null;
      /** payment method the user used for the purchase */
      paymentType?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('gifting_order_confirmed', { ...props, ...extra });
  }

  /**
   * event tracked when the gifting order is successfully placed.
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  giftingOrderPlaced(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      showTitle: string;
      /** user id of host of the live show */
      showUserId: string;
      /** nick name of host of the live show */
      showUserName: string;
      /** type of the gifting initiator */
      initiatorType: 'host' | 'audience';
      /** user id of the gift sender */
      senderUserId?: string | null;
      /** display name of the gift sender */
      senderUserName?: string | null;
      /** user id of the gift receiver */
      receiverUserId?: string | null;
      /** display name of the gift receiver */
      receiverUserName?: string | null;
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** quantity of the product listing */
      listingQty: number;
      /** id of the listing variant */
      variantId?: string | null;
      /** name of the listing variant */
      variantName?: string | null;
      /** sku of the listing variant */
      variantSku?: string | null;
      /** quantity of the listing variant */
      variantQuantity?: number | null;
      /** payment method the user used for the purchase */
      paymentType?: string | null;
      /** order id of the gifting order */
      orderId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('gifting_order_placed', { ...props, ...extra });
  }

  /**
   * event tracked when the gifting order placing is failed.
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  giftingOrderFailedToPlace(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      showTitle: string;
      /** user id of host of the live show */
      showUserId: string;
      /** nick name of host of the live show */
      showUserName: string;
      /** type of the gifting initiator */
      initiatorType: 'host' | 'audience';
      /** user id of the gift sender */
      senderUserId?: string | null;
      /** display name of the gift sender */
      senderUserName?: string | null;
      /** user id of the gift receiver */
      receiverUserId?: string | null;
      /** display name of the gift receiver */
      receiverUserName?: string | null;
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** quantity of the product listing */
      listingQty: number;
      /** id of the listing variant */
      variantId?: string | null;
      /** name of the listing variant */
      variantName?: string | null;
      /** sku of the listing variant */
      variantSku?: string | null;
      /** quantity of the listing variant */
      variantQuantity?: number | null;
      /** the code of the error if exists */
      errorCode?: string | null;
      /** the detailed information about this error */
      errorDetail: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('gifting_order_failed_to_place', { ...props, ...extra });
  }

  /**
   * event tracked when the gift recipient accepted the gift.
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  giftingOrderAccepted(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      showTitle: string;
      /** user id of host of the live show */
      showUserId: string;
      /** nick name of host of the live show */
      showUserName: string;
      /** type of the gifting initiator */
      initiatorType: 'host' | 'audience';
      /** user id of the gift sender */
      senderUserId?: string | null;
      /** display name of the gift sender */
      senderUserName?: string | null;
      /** user id of the gift receiver */
      receiverUserId?: string | null;
      /** display name of the gift receiver */
      receiverUserName?: string | null;
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** quantity of the product listing */
      listingQty: number;
      /** id of the listing variant */
      variantId?: string | null;
      /** name of the listing variant */
      variantName?: string | null;
      /** sku of the listing variant */
      variantSku?: string | null;
      /** quantity of the listing variant */
      variantQuantity?: number | null;
      /** order id of the gifting order */
      orderId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('gifting_order_accepted', { ...props, ...extra });
  }

  /**
   * event tracked when the gift recipient declined the gift.
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  giftingOrderDeclined(
    props: {
      /** id of the live show */
      showId: string;
      /** title of the live show */
      showTitle: string;
      /** user id of host of the live show */
      showUserId: string;
      /** nick name of host of the live show */
      showUserName: string;
      /** type of the gifting initiator */
      initiatorType: 'host' | 'audience';
      /** user id of the gift sender */
      senderUserId?: string | null;
      /** display name of the gift sender */
      senderUserName?: string | null;
      /** user id of the gift receiver */
      receiverUserId?: string | null;
      /** display name of the gift receiver */
      receiverUserName?: string | null;
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** quantity of the product listing */
      listingQty: number;
      /** id of the listing variant */
      variantId?: string | null;
      /** name of the listing variant */
      variantName?: string | null;
      /** sku of the listing variant */
      variantSku?: string | null;
      /** quantity of the listing variant */
      variantQuantity?: number | null;
      /** order id of the gifting order */
      orderId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('gifting_order_declined', { ...props, ...extra });
  }

  //#endregion

  //#region Dcon Related Analaytic Events

  /**
   * Analytic event logged on dcon content shown
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  dconContentShown(
    props: {
      /** name of the dcon event */
      name: string;
      /** content name or content that is shown */
      content: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('dcon_content_shown', { ...props, ...extra });
  }

  /**
   * Analytic event logged on dcon button click
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  dconButtonClick(
    props: {
      /** name of the dcon event */
      name: string;
      /** name of the action button clicked */
      button: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('dcon_button_click', { ...props, ...extra });
  }

  //#endregion

  //#region Performance Related Analytic Events

  /**
   * Analytic event logged on perf fuse triggered
   * @param [extra] - extra properties for this event
   */
  perfFuseTriggered(extra?: AnalyticEventProps) {
    this.track('perf_fuse_triggered', extra);
  }

  //#endregion

  //#region Shopify linker Related Analaytic Events

  /**
   * Analytic event logged on shopify linker launched
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  shopifyLinkerLaunched(
    props: {
      /** id of the seller */
      sellerId: string;
      /** number of shopify linker launches */
      counter: number;
      /** store id of the store where the user launched the shopify linker */
      storeId?: string | null;
      /** landing from 'app' or 'sd' */
      origin: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('shopify_linker_launched', { ...props, ...extra });
  }

  /**
   * Analytic event logged on shopify linker launching shopify for access
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  shopifyLinkerLaunchingShopifyForAccess(
    props: {
      /** id of the seller */
      sellerId: string;
      /** the shopify url to be launched */
      url: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('shopify_linker_launching_shopify_for_access', {
      ...props,
      ...extra,
    });
  }

  /**
   * Analytic event logged on shopify linker launched home page
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  shopifyLinkerLaunchedHomePage(
    props: {
      /** id of the seller */
      sellerId: string;
      /** the shopify shop name */
      shopName: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('shopify_linker_launched_home_page', { ...props, ...extra });
  }

  /**
   * Analytic event logged on shopify linker unlink
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  shopifyLinkerUnlink(
    props: {
      /** id of the seller */
      sellerId: string;
      /** shopify store id */
      shopifyStoreId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('shopify_linker_unlink', { ...props, ...extra });
  }

  /**
   * Analytic event logged on shopify linker reset location
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  shopifyLinkerResetLocation(
    props: {
      /** id of the seller */
      sellerId: string;
      /** shopify store id */
      shopifyStoreId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('shopify_linker_reset_location', { ...props, ...extra });
  }

  /**
   * Analytic event logged on shopify linker back to app
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  shopifyLinkerBackToApp(
    props: {
      /** id of the seller */
      sellerId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('shopify_linker_back_to_app', { ...props, ...extra });
  }

  /**
   * Analytic event logged on shopify linker initialize select store
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  shopifyLinkerInitializeSelectStore(
    props: {
      /** id of the seller */
      sellerId: string;
      /** the store/location id */
      id: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('shopify_linker_initialize_select_store', {
      ...props,
      ...extra,
    });
  }

  /**
   * Analytic event logged on shopify linker initialize select location
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  shopifyLinkerInitializeSelectLocation(
    props: {
      /** id of the seller */
      sellerId: string;
      /** the store/location id */
      id: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('shopify_linker_initialize_select_location', {
      ...props,
      ...extra,
    });
  }

  /**
   * query popshop live listings which are imported from shopify
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  shopifyLinkerQueryImportedPslListings(
    props: {
      /** id of the seller */
      sellerId: string;
      /** shopify store id */
      shopifyStoreId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('shopify_linker_query_imported_psl_listings', {
      ...props,
      ...extra,
    });
  }

  /**
   * Analytic event logged on shopify linker query shopify listings
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  shopifyLinkerQueryShopifyListings(
    props: {
      /** id of the seller */
      sellerId: string;
      /** shopify store id */
      shopifyStoreId: string;
      /** query size of the shopify listings */
      first: number;
      /** query after the shopify listings */
      after?: string | null;
      /** query string */
      query?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('shopify_linker_query_shopify_listings', { ...props, ...extra });
  }

  /**
   * Analytic event logged on shopify linker create listing
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  shopifyLinkerCreateListing(
    props: {
      /** id of the seller */
      sellerId: string;
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** quantity of the product listing */
      listingQty: number;
      /** image url of the product listing */
      listingImage?: string | null;
      /** sku of the product listing */
      listingSku?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('shopify_linker_create_listing', { ...props, ...extra });
  }

  /**
   * Analytic event logged on shopify linker edit listing
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  shopifyLinkerEditListing(
    props: {
      /** id of the seller */
      sellerId: string;
      /** id of the product listing */
      listingId: string;
      /** name of the product listing */
      listingName: string;
      /** price of the product listing */
      listingPrice: number;
      /** quantity of the product listing */
      listingQty: number;
      /** image url of the product listing */
      listingImage?: string | null;
      /** sku of the product listing */
      listingSku?: string | null;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('shopify_linker_edit_listing', { ...props, ...extra });
  }

  /**
   * Analytic event logged on shopify linker add listing variant
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  shopifyLinkerAddListingVariant(
    props: {
      /** id of the seller */
      sellerId: string;
      /** id of the listing variant */
      variantId: string;
      /** name of the listing variant */
      variantName: string;
      /** sku of the listing variant */
      variantSku?: string | null;
      /** quantity of the listing variant */
      variantQuantity?: number | null;
      /** popshop listing id */
      listingId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('shopify_linker_add_listing_variant', { ...props, ...extra });
  }

  /**
   * Analytic event logged on shopify linker edit listing variant
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  shopifyLinkerEditListingVariant(
    props: {
      /** id of the seller */
      sellerId: string;
      /** id of the listing variant */
      variantId: string;
      /** name of the listing variant */
      variantName: string;
      /** sku of the listing variant */
      variantSku?: string | null;
      /** quantity of the listing variant */
      variantQuantity?: number | null;
      /** popshop listing id */
      listingId: string;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('shopify_linker_edit_listing_variant', { ...props, ...extra });
  }

  /**
   * Analytic event logged on shopify linker edit shopify listing variant
   * @param props - event properties
   * @param [extra] - extra properties for this event
   */
  shopifyLinkerEditShopifyListingVariant(
    props: {
      /** id of the seller */
      sellerId: string;
      /** shopify store id */
      shopifyStoreId: string;
      /** shopify inventory level id */
      inventoryLevelId: string;
      /** the diff of the available quantity between popshop and shopify */
      availableDelta: number;
      /** popshop listing id */
      pslListingId: string;
      /** popshop listing variant id */
      pslVariantId: string;
      /** the quantity of the variant in popshop */
      pslVariantQuantity: number;
    },
    extra?: AnalyticEventProps,
  ) {
    this.track('shopify_linker_edit_shopify_listing_variant', {
      ...props,
      ...extra,
    });
  }

  //#endregion
}

/**
 * Convert the given event props to the canonical form so that the property
 * keys all take the snake_cased form in order to keep them consistent with
 * their original definition in the schema configuration file.
 * @param [props] - the event props to convert
 * @returns converted props with canonical property keys
 */
export function eventPropsConverted(props: AnalyticEventProps | undefined) {
  if (!props) {
    return props;
  }
  const transformedProps: typeof props = {};
  for (const p in props) {
    const key = p
      .replace(
        /([^a-z])([a-z]*)/g,
        (_, l, t, i) =>
          (i ? '_' : '') + (l >= 'A' && l <= 'Z' ? l.toLowerCase() : l) + t,
      )
      .replace(/_{2,}/g, '_')
      .replace(/^_/, '')
      .replace(/_$/, '');
    transformedProps[key] = props[p];
  }
  return transformedProps;
}
