import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { PopVariant } from '../../types/products';
import AmountButton from './AmountButton';
import Grow from '@mui/material/Grow';
import { useState } from 'react';
import Base from './ProductCardVariantBase';

const Imported = (props: { data: PopVariant; newImported?: boolean }) => {
  const { newImported } = props;
  const { name, quantity } = props.data;
  const [animationIn, setAnimationIn] = useState(true);
  React.useEffect(() => {
    const timer = setTimeout(() => setAnimationIn(false), 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [setAnimationIn]);
  return (
    <Base
      left={name === 'Default Title' ? 'Default' : name}
      leftOfQTY={
        newImported && (
          <div style={{ marginRight: 2 }}>
            <Grow in={animationIn} timeout={200}>
              <Typography component="div" variant="caption" color="primary">
                <Box fontWeight="bold">IMPORTED</Box>
              </Typography>
            </Grow>
          </div>
        )
      }
      right={
        <>
          <AmountButton sx={{ marginLeft: 2 }}>{quantity}</AmountButton>
        </>
      }
    />
  );
};

export default Imported;
