import React, { memo } from 'react';
import { List } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { PopProduct, ShopifyProduct } from '../types/products';
import useSWR from 'swr';
import SWRKeys from '../constant/swr';
import ProductCard from './components/ProductCard';
import CircularProgress from '@mui/material/CircularProgress';
import { Container, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { shopifyApi } from '../apis/services';
import { useStoreInfo } from './Dispatcher';
import { getUser } from '../util/setupFirebase';

interface Props {
  currentPopProducts: PopProduct[];
  keyword?: string;
  inStock: boolean;
}

const FETCH_LIMIT = 50;

const useFetchProducts = ({
  afterCursor,
  keyword,
  inStock,
  setErrorMessage,
  storeId,
}: {
  afterCursor?: string;
  keyword?: string;
  inStock: boolean;
  setErrorMessage: (error: string) => void;
  storeId: string;
}) => {
  const user = getUser();
  const { enqueueSnackbar } = useSnackbar();
  const fetcher = async () => {
    try {
      if (!user) return;

      const query = `product_status:active${
        inStock ? ' AND inventory_quantity:>0' : ''
      }${keyword ? ` ${keyword}` : ''}`;

      const { products } = await shopifyApi.shopifyApiControllerQueryProducts({
        first: FETCH_LIMIT,
        after: afterCursor,
        query,
        shopifyStoreId: storeId,
        sellerId: user.uid,
      });

      return {
        products: (inStock
          ? products.edges.filter(({ node }) => node.quantity > 0)
          : products.edges
        ).map(({ node }) => node),
        lastProductCursor: products.pageInfo.endCursor,
        hasNextPage: products.pageInfo.hasNextPage,
      };
    } catch (error) {
      return Promise.reject((error as any)?.message);
    }
  };

  return useSWR(
    `${SWRKeys.FetchShopifyProducts}/${storeId}/${
      inStock ? 'inStock' : 'all'
    }/${keyword ?? ''}/${afterCursor}`,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onError: (error) => {
        if (error !== 'The Shop is not setup') {
          enqueueSnackbar('Please try again later.', { variant: 'error' });
        } else {
          setErrorMessage(error);
        }
      },
    }
  );
};

export default memo(function ProductPicker({
  currentPopProducts,
  keyword,
  inStock,
}: Props) {
  const { storeId } = useStoreInfo();
  const [data, setData] = React.useState<ShopifyProduct[]>([]);
  const [cache, setCache] = React.useState<ShopifyProduct[]>([]);
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [afterCursor, setAfterCursor] = React.useState<string>();
  const shopifyProductsState = useFetchProducts({
    afterCursor,
    keyword,
    inStock,
    setErrorMessage,
    storeId,
  });
  React.useEffect(() => {
    setAfterCursor(undefined);
  }, [keyword, inStock]);

  const loading = shopifyProductsState.isValidating;
  const hasMore = shopifyProductsState.data
    ? shopifyProductsState.data.hasNextPage
    : false;
  const lastCursor = shopifyProductsState.data
    ? shopifyProductsState.data.lastProductCursor
    : undefined;

  React.useEffect(() => {
    if (shopifyProductsState.data?.products) {
      const newData = shopifyProductsState.data.products as ShopifyProduct[];
      if (loading) {
        // the data is cache by swr
        if (afterCursor) {
          setCache(data.concat(newData));
        } else {
          setCache(newData);
        }
      } else {
        // the data is return by the fetch
        setCache([]);
        if (afterCursor) {
          setData(data.concat(newData));
        } else {
          setData(newData);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const updateShopifyProduct = React.useCallback(
    (product: ShopifyProduct) => {
      const targetIndex = data.findIndex((item) => item.id === product.id);
      data.splice(targetIndex, 1, product);
      setData([...data]);
    },
    [data, setData]
  );

  if (errorMessage) {
    return (
      <Container maxWidth="sm">
        <Typography variant="body1">{errorMessage}</Typography>
        <Typography variant="body1">
          Please back to app and try again
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={() => hasMore && setAfterCursor(lastCursor)}
        hasMore={hasMore}
      >
        {!(loading && data.length === 0) && (
          <List
            dataSource={cache.length > 0 ? cache : data}
            grid={{ gutter: 16 }}
            loadMore
            renderItem={(item) => {
              const popProduct = currentPopProducts.find(
                (product) =>
                  item.originalInfo?.shopifyProductId ===
                  product.originalInfo?.shopifyProductId
              );
              return (
                <ProductCard
                  key={item.id}
                  data={item}
                  popProduct={popProduct}
                  updateShopifyProduct={updateShopifyProduct}
                />
              );
            }}
          />
        )}
        {loading && (
          <div
            style={{
              position: 'fixed',
              left: '0px',
              top: '0px',
              right: '0px',
              bottom: '0px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pointerEvents: 'none',
            }}
          >
            <div style={{ height: '30%' }} />
            <CircularProgress color="primary" />
          </div>
        )}
      </InfiniteScroll>
    </Container>
  );
});
