import React, { memo } from 'react';
import { Box, IconButton, Menu, Typography } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { StyledMenuItem } from './ProfileButton';

const getDisplayName = (inStock: boolean) =>
  inStock ? 'Only in stock listings' : 'All Results';

export default memo(function FilterButton(props: {
  inStock: boolean;
  onFilterChanged: (inStock: boolean) => void;
}) {
  const { inStock, onFilterChanged } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={openMenu}
      >
        <Typography component="div" variant="caption">
          <Box fontWeight="fontWeightBold">{getDisplayName(inStock)}</Box>
        </Typography>
        <KeyboardArrowDown />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={closeMenu}
      >
        <StyledMenuItem
          onClick={() => {
            onFilterChanged(false);
            closeMenu();
          }}
        >
          <Typography component="div" variant="caption">
            <Box fontWeight="bold">{getDisplayName(false)}</Box>
          </Typography>
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            onFilterChanged(true);
            closeMenu();
          }}
        >
          <Typography component="div" variant="caption">
            <Box fontWeight="bold">{getDisplayName(true)}</Box>
          </Typography>
        </StyledMenuItem>
      </Menu>
    </div>
  );
});
