import React, { memo } from 'react';
import { Box, InputBase, InputBaseProps, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterButton from './FilterButton';

export default memo(function SearchInput(
  props: InputBaseProps & {
    onSearchClicked: (data: { inStock: boolean; keyword?: string }) => void;
  }
) {
  const [keyword, setKeyword] = React.useState<string>();
  const [inStock, setInStock] = React.useState(false);

  return (
    <Box
      boxShadow={3}
      sx={{
        display: 'flex',
        borderRadius: 2,
        width: '50%',
        minWidth: 300,
        padding: 0.5,
      }}
    >
      <FilterButton
        inStock={inStock}
        onFilterChanged={(inStock) => {
          setInStock(inStock);
          props.onSearchClicked({ inStock, keyword });
        }}
      />
      <InputBase
        sx={{ flex: 1, marginLeft: 2 }}
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            props.onSearchClicked({ inStock, keyword });
          }
        }}
        {...props}
      />
      <IconButton
        aria-label="search"
        onClick={() => props.onSearchClicked({ inStock, keyword })}
      >
        <SearchIcon color="primary" />
      </IconButton>
    </Box>
  );
});
